import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CustomInput, ModalComponent } from "../../../components";
import { Axios } from "../../../config";

export default function ModalPricingPartner({
  openModal,
  closeModal,
  getDataTable,
}) {
  const classes = useStyles();
  const [dataPricing, setDataPricing] = useState([]);
  const [dataPartner, setDataPartner] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    getPricing();
    getPartner();
    getProduct();
    if (openModal?.id) {
      Axios.get(`/partner_pricing/detail/${openModal?.id}`).then(({ data }) => {
        setValue("partner_id", data.data.partner.id);
        setValue("price_id", data.data.price.id);
      });
    }
  }, []);

  const getPricing = () => {
    Axios.get("/pricing").then((res) => setDataPricing(res.data.data));
  };

  const getPartner = () => {
    Axios.get("/partner").then((res) => setDataPartner(res.data.data));
  };

  const getProduct = () => {
    Axios.get("/product").then((res) => setDataProduct(res.data.data));
  };

  const onSubmit = (values) => {
    if (openModal?.id) {
      Axios.put(`/partner_pricing/update/${openModal?.id}`, values)
        .then((res) => {
          if (res.status === 200) {
            closeModal();
            Swal.fire({
              icon: res.status === 200 ? "success" : "error",
              title: res.status === 200 ? "Success" : "Error",
              text: res.data.message,
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post("/partner_pricing/create", values)
        .then((res) => {
          if (res.status === 201) {
            closeModal();
            Swal.fire({
              icon: res.status === 201 ? "success" : "error",
              title: res.status === 201 ? "Success" : "Error",
              text: res.data.message,
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ModalComponent open={Boolean(openModal)} onClose={closeModal}>
      <Grid className={classes.ModalWrapper} conteiner>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>Assign Pricing</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid
          sx={{
            padding: "24px",
            paddingTop: "4px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="form-partner">
            <CustomInput.Select
              label="Partner"
              placeholder="Choose Partner"
              register={{ ...register("partner_id", { required: true }) }}
              error={Boolean(errors["partner_id"])}
              value={watch("partner_id") ?? ""}
            >
              {dataPartner.map((val) => {
                return <MenuItem value={val.id}>{val.partner_name}</MenuItem>;
              })}
            </CustomInput.Select>
            <CustomInput.Select
              label="Pricing"
              placeholder="Choose Pricing"
              register={{ ...register("price_id", { required: true }) }}
              error={Boolean(errors["price_id"])}
              value={watch("price_id") ?? ""}
            >
              {dataPricing.map((val) => {
                return <MenuItem value={val.id}>{val.name}</MenuItem>;
              })}
            </CustomInput.Select>
            <CustomInput.Select
              label="Product"
              placeholder="Choose Product"
              register={{ ...register("product_id", { required: true }) }}
              error={Boolean(errors["product_id"])}
              value={watch("product_id") ?? ""}
            >
              {dataProduct.map((val) => {
                return <MenuItem value={val.id}>{val.product_desc}</MenuItem>;
              })}
            </CustomInput.Select>
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          disabled={
            !watch("partner_id") || !watch("product_id") || !watch("price_id")
          }
          variant="contained"
          type="submit"
          form="form-partner"
          sx={{
            backgroundColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
          }}
          fullWidth
        >
          Assign
        </Button>
      </Grid>
    </ModalComponent>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
  Errors: {
    margin: 0,
    color: "red",
    marginTop: "4px",
  },
});
