import { Button, Grid, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Colors } from "../../assets";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";

export default function FilterDashboardFSA({ onReset, onApply, filters }) {
  const classes = useStyles();
  const [merchant, setMerchant] = useState([]);
  const [institution, setInstitution] = useState(filters?.institution);

  const handleReset = () => {
    onReset();
  };

  const getDataMerchant = () => {
    Axios.get("partner/merchant?partner_type=3").then((res) => {
      setMerchant(res.data.data);
    });
  };

  useEffect(() => {
    getDataMerchant();
  }, []);

  return (
    <Grid className={classes.root}>
      <h3>Filter</h3>
      <CustomInput.Select
        onChange={({ target }) => setInstitution(target.value)}
        label="Institution"
        value={institution ?? null}
      >
        {merchant?.map((val) => {
          return <MenuItem value={val.id}>{val.partner_name}</MenuItem>;
        })}
      </CustomInput.Select>
      <Grid className={classes.buttonWrapper}>
        <Button
          className={classes.btnReset}
          variant="outlined"
          fullWidth
          onClick={handleReset}
        >
          <h3>Reset</h3>
        </Button>
        <Button
          className={classes.btnApply}
          fullWidth
          onClick={() => onApply(institution)}
        >
          <h3>Terapkan</h3>
        </Button>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  root: {
    width: "316px",
    padding: "24px",
    "& h3": {
      margin: 0,
    },
  },
  buttonWrapper: {
    display: "flex",
    marginTop: "16px ",
    gap: "16px",
  },
  btnReset: {
    backgroundColor: "white !important",
    color: "black !important",
    textTransform: "none !important",
  },
  btnApply: {
    backgroundColor: Colors.SECONDARY + "!important",
    color: "white !important",
    textTransform: "none !important",
  },
});
