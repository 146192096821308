import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Card, Fade, Popper } from "@mui/material";
import React, { Fragment, useState } from "react";

const CustomButton = (props) => {
  return (
    <Button
      variant={props?.variant || "contained"}
      {...props}
      sx={{
        backgroundColor: props?.sx?.backgroundColor || "#A0B842",
        "&:hover": {
          backgroundColor: "#83992A",
        },
        textTransform: "none",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        ...props.sx,
      }}
    >
      {props?.children}
    </Button>
  );
};

const ButtonOutline = (props) => {
  const { variant } = props;
  return (
    <Button
      {...props}
      variant="outlined"
      sx={{
        textTransform: "none",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        color: variant === "dark" ? "black" : "#9AB825",
        borderColor: variant === "dark" ? "none" : "#9AB825",
      }}
    >
      {props?.children}
    </Button>
  );
};

CustomButton.Outline = ButtonOutline;

const ButtonFilter = ({ children, onClick, open = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<FilterListIcon sx={{ color: "#9AA2B1" }} />}
        sx={{
          color: "black",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          padding: "8px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        Filter
      </Button>
      <Popper open={open} anchorEl={anchorEl}>
        <Fade in={open}>
          <Card container>{children}</Card>
        </Fade>
      </Popper>
    </Fragment>
  );
};

CustomButton.Filter = ButtonFilter;

export default CustomButton;
