import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  BasicButton,
  CustomInput,
  TableTypeAmount,
  TableTypeColumn,
  TableTypeRow,
} from "../../../components";
import { Axios } from "../../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ModalDataFormatV2({
  open,
  onClose,
  product_id,
  getDataTable,
}) {
  const classes = useStyles();
  const [dataFormat, setDataFormat] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {}, []);

  const onSubmit = (val) => {
    if (open?.id) {
      Axios.put(`/data_format/update/${open?.id}`, {
        ...val,
        row_code_position: +val.row_code_position,
      }).then((res) => {
        onClose();
        Swal.fire({
          icon: res.status === 201 ? "success" : "error",
          title: res.status === 201 ? "Success" : "Error",
          text: res.data.message || "Berhasil Edit Assign Pricing",
        }).then(() => getDataTable());
      });
    } else {
      Axios.post("/data_format/create", {
        ...val,
        row_code_position: +val.row_code_position,
      }).then((res) => {
        onClose();
        Swal.fire({
          icon: res.status === 201 ? "success" : "error",
          title: res.status === 201 ? "Success" : "Error",
          text: res.data.message || "Berhasil Edit Assign Pricing",
        }).then(() => getDataTable());
      });
    }
  };

  useEffect(() => {
    if (open?.id) {
      getDataDetail();
    }
  }, []);

  const getDataDetail = () => {
    Axios.get(`/data_format/detail/${open?.id}`).then(({ data }) => {
      const v = data.data;
      console.log(v);
      setDataFormat(v);
      setValue("data_format_type_code", v.data_format_type_code);
      setValue("folder_path", v.folder_path);
      setValue(
        "is_recursive_file_search_flag",
        v.is_recursive_file_search_flag
      );
      setValue("last_line_content", v.last_line_content);
      setValue("is_has_header_row_flag", v.is_has_header_row_flag);
      setValue(
        "is_translate_merchant_code_flag",
        v.is_translate_merchant_code_flag
      );
      setValue(
        "transaction_datetime_timezone",
        v.transaction_datetime_timezone
      );
      setValue("data_file_format_type_code", v.data_file_format_type_code);
      setValue("file_pattern", v.file_pattern);
      setValue("record_delimeter", v.record_delimeter);
      setValue("row_code_position", v.row_code_position);
      setValue(
        "is_translate_merchant_code_flag",
        v.is_translate_merchant_code_flag
      );
      setValue(
        "is_translate_product_code_flag",
        v.is_translate_product_code_flag
      );
      setValue("transaction_datetime_format", v.transaction_datetime_format);
      setValue(
        "approved_transaction_status_value",
        v.approved_transaction_status_value
      );
      setValue("data_format_type_desc", v.data_format_type_desc);
    });
  };

  console.log(watch());

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "white" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "black", fontWeight: "bold" }}
              variant="h6"
              component="div"
            >
              {open?.id ? "Update Data Format" : "Add Data Format"}
            </Typography>

            <BasicButton
              autoFocus
              label={open?.id ? "Save Changes" : "Submit Data"}
              type="submit"
              form="form-data-format"
            />
          </Toolbar>
        </AppBar>

        <Grid container className={classes.modalContainer}>
          <Grid className={classes.modalContent}>
            <form
              action=""
              id="form-data-format"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing="24px">
                <Grid
                  item
                  md={6}
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                >
                  <CustomInput
                    label="Data Format Code"
                    placeholder="Data Format Code"
                    register={{ ...register("data_format_type_code") }}
                  />
                  <CustomInput
                    label="Folder Path"
                    register={{ ...register("folder_path") }}
                    placeholder="Folder Path"
                  />
                  <CustomInput.Select
                    marginTop="0px"
                    label="Recursive File Search"
                    placeholder="Recursive File Search"
                    register={{
                      ...register("is_recursive_file_search_flag"),
                    }}
                    value={watch("is_recursive_file_search_flag") ?? null}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </CustomInput.Select>
                  <CustomInput
                    label="Last Line Content"
                    placeholder="Last Line Content"
                    register={{ ...register("last_line_content") }}
                  />
                  <CustomInput.Select
                    marginTop="0px"
                    label="Has Header Row Flag"
                    placeholder="Has Header Row Flag"
                    register={{ ...register("is_has_header_row_flag") }}
                    value={watch("is_has_header_row_flag") ?? null}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </CustomInput.Select>
                  <CustomInput.Select
                    marginTop="0px"
                    label="Translate Product Code"
                    placeholder="Translate Product Code"
                    register={{
                      ...register("is_translate_product_code_flag"),
                    }}
                    value={watch("is_translate_product_code_flag") ?? null}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </CustomInput.Select>
                  <CustomInput
                    label="Transaction Date Time Zone"
                    placeholder="Transaction Date Time Zone"
                    register={{
                      ...register("transaction_datetime_timezone"),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                >
                  <CustomInput
                    label="Data File Format Type Code"
                    placeholder="Data File Format Type Code"
                    register={{ ...register("data_file_format_type_code") }}
                  />
                  <CustomInput
                    label="File Pattern"
                    placeholder="File Pattern"
                    register={{ ...register("file_pattern") }}
                  />
                  <CustomInput
                    label="Record Delimiter"
                    placeholder="Record Delimiter"
                    register={{ ...register("record_delimeter") }}
                  />
                  <CustomInput
                    label="Row Code Position"
                    placeholder="Row Code Position"
                    register={{ ...register("row_code_position") }}
                    type="number"
                    min={1}
                  />
                  <CustomInput.Select
                    marginTop="0px"
                    label="Translate Merchant Code"
                    placeholder="Translate Merchant Code"
                    register={{
                      ...register("is_translate_merchant_code_flag"),
                    }}
                    value={watch("is_translate_merchant_code_flag") ?? null}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </CustomInput.Select>
                  <CustomInput
                    label="Transaction Date Format"
                    placeholder="Transaction Date Format"
                    register={{
                      ...register("transaction_datetime_format"),
                    }}
                  />
                  <CustomInput
                    label="Transaction Status Active Value"
                    placeholder="Transaction Status Active Value"
                    register={{
                      ...register("approved_transaction_status_value"),
                    }}
                  />
                </Grid>
                <Grid md={12} paddingTop="12px" paddingLeft="24px">
                  <CustomInput
                    label="Data Format Description"
                    placeholder="Data Format Description"
                    multiline
                    rows={4}
                    register={{
                      ...register("data_format_type_desc"),
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          {open?.id && (
            <Grid className={classes.modalContent} mt="24px">
              <Grid
                width="100%"
                sx={{
                  "& h4": {
                    margin: 0,
                    marginBottom: "8px",
                    fontSize: "14px",
                  },
                  "& .link": {
                    color: "#9AB825",
                    marginTop: "8px",
                    cursor: "pointer",
                  },

                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <TableTypeRow
                  dataFormat={dataFormat}
                  getDataDetail={() => getDataDetail()}
                />
                <TableTypeColumn
                  dataFormat={dataFormat}
                  getDataDetail={() => getDataDetail()}
                />
                <TableTypeAmount
                  dataFormat={dataFormat}
                  getDataDetail={() => getDataDetail()}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  modalHeader: { position: "relative", backgroundColor: "white" },
  modalContainer: {
    padding: "32px 0px 32px 0px",
    height: "100vh !important",
    overflowY: "auto",
    backgroundColor: "#F5F5F5",
  },
  modalContent: {
    padding: "24px",
    backgroundColor: "white",
    boxShadow:
      "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
    border: "1px solid #E4E7EB",
    borderRadius: "12px",
    width: "100%",
    marginLeft: "220px",
    marginRight: "220px",
    overflow: "auto",
  },
});
