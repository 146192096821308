import Button from "@mui/material/Button";
import React from "react";
import { Colors } from "../../assets";

const BasicButton = ({ label, type, onClick, fullWidth, disabled, form }) => {
  return (
    <Button
      form={form}
      type={type}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      sx={{
        backgroundColor: Colors.SECONDARY,
        "&:hover": {
          backgroundColor: Colors.SECONDARY_DARK,
          cursor: "pointer",
        },
        textTransform: "none",
        fontWeight: 600,
        fontSize: "14px",
      }}
    >
      {label}
    </Button>
  );
};

export default BasicButton;
