import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Colors } from "../../../assets";
import {
  CustomInput,
  InputComponent,
  ModalComponent,
} from "../../../components";
import { Axios } from "../../../config";

const ModalProduct = ({ openModal, closeModal, getDataTable }) => {
  const classes = useStyles();
  const [currency, setCurrency] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const getDataCurrency = () => {
    Axios.get(`/currency`).then((res) => {
      setCurrency(res.data.data);
    });
  };

  useEffect(() => {
    getDataCurrency();
    if (openModal?.id) {
      getProductDetail();
      setValue("code", openModal?.code);
      setValue("name", openModal?.name);
      setValue("principal_id", openModal?.principal?.id);
      setValue("description", openModal?.description);
    }
  }, []);

  const getProductDetail = () => {
    Axios.get(`/product/detail/${openModal?.id}`).then((res) => {
      const { name, product_code, product_desc, currency_id } = res.data.data;
      setValue("name", name);
      setValue("product_code", product_code);
      setValue("product_desc", product_desc);
      setValue("currency_id", currency_id);
    });
  };

  const onSubmit = (values) => {
    if (openModal?.id) {
      Axios.put(`/product/update/${openModal?.id}`, values).then((res) => {
        closeModal();
        Swal.fire({
          icon: res.status === 201 ? "success" : "error",
          title: res.status === 201 ? "Success" : "Error",
          text: res.data.message || "Succesfully updated partner",
        }).then(() => {
          getDataTable();
        });
      });
    } else {
      Axios.post("/product/create", values).then((res) => {
        closeModal();
        Swal.fire({
          icon: res.status === 201 ? "success" : "error",
          title: res.status === 201 ? "Success" : "Error",
          text: res.data.message || "Succesfully updated partner",
        }).then(() => {
          getDataTable();
        });
      });
    }
  };

  return (
    <ModalComponent open={Boolean(openModal)} onClose={closeModal}>
      <Grid>
        <Grid className={classes.ModalWrapper}>
          <h3>{openModal?.id ? "Update" : "Create"} Product</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid className={classes.inputContainer}>
          <form action="" onSubmit={handleSubmit(onSubmit)} id="form-product">
            <InputComponent
              register={{
                ...register("product_desc", {
                  required: true,
                }),
              }}
              error={Boolean(errors["product_desc"])}
              id="code"
              label="Product Name"
            />
            <InputComponent
              error={Boolean(errors["product_code"])}
              register={{
                ...register("product_code", {
                  required: true,
                }),
              }}
              id="code"
              label="Product Code"
            />
            <CustomInput.Select
              label="Currency"
              register={{
                ...register("currency_id", {
                  required: true,
                }),
              }}
              value={watch("currency_id") ?? null}
              error={Boolean(errors["currency_id"])}
            >
              {currency.map((val) => (
                <MenuItem value={val.id}>{val.currency_desc}</MenuItem>
              ))}
            </CustomInput.Select>
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          type="submit"
          form="form-product"
          variant="contained"
          className={classes.btnSubmit}
          fullWidth
        >
          {openModal?.id ? "Update" : "Create"}
        </Button>
      </Grid>
    </ModalComponent>
  );
};

const useStyles = makeStyles({
  ModalWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px 10px 24px",

    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  inputContainer: {
    padding: "24px",
    paddingTop: "4px",
  },
  btnSubmit: {
    backgroundColor: Colors.SECONDARY + "!important",
    "&:hover": {
      backgroundColor: Colors.SECONDARY_DARK + "!important",
    },
    textTransform: "none !important",
    fontWeight: 600 + "!important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
});

export default ModalProduct;
