import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";

export default function DateHeading({ title, endElement }) {
  const classes = useStyles();
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <Grid className={classes.wrapper}>
        <Box className={classes.left}>
          <p className={classes.time}>{date.format("D MMM YYYY, HH:mm")}</p>
          <h3>{title}</h3>
        </Box>
        <Box className={classes.right}>{endElement && endElement}</Box>
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "24px",
    alignItems: "center",
    justifyContent: "space-between",
    left: {
      display: "flex",
      flexDirection: "column",
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "400px",
      lineHeight: "24px",
      margin: 0,
      color: "gray",
    },
    "& h3": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
});
