import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    backgroundColor: "#FFFFFF",
    "& h3": {
      margin: 0,
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px",
    },
    "& hr": {
      border: "1px solid #E4E7EB",
    },
  },
});

export default function ModalComponent({
  open,
  onClose,
  children,
  fullSize,
  title,
  hideScroll = false,
  backgroundColor,
}) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.wrapper}
          sx={{
            borderRadius: fullSize ? "0px" : "12px",
            width: fullSize ? "100%" : "392px",
            height: fullSize ? "100%" : "auto",
            overflow: hideScroll ? "hidden" : "auto",
            backgroundColor: backgroundColor,
          }}
        >
          {title && (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingTop: "16px",
                  paddingBottom: "10px",
                }}
              >
                <h3>{title}</h3>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <hr className={classes.Divider} />
            </>
          )}
          {children}
        </Box>
      </Modal>
    </div>
  );
}
