import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import {
  AdvancedFraudDetectionImg,
  DelightCustomerImg,
  DividerImage,
  EverVigilantImg,
  FraudManagementBanner,
} from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function FraudManagementPage() {
  const classes = useStyles();

  return (
    <Fragment>
      <ButtonAppBar />
      <section className={classes.root}>
        <section className={classes.banner}>
          <Container>
            <h1>Fraud Management Solutions​</h1>
            <p>
              Fraud not only causes financial loss, it can significantly affect
              brand reputation and drives customer attrition.
              <br />
              <br />
              According to a Nilson report, by 2020 global card fraud losses
              will exceed $35 billion despite the introduction of EMV. Fraud is
              shifting to the ‘card not present’ environment, targeting
              individuals and businesses alike, and fraud attempts and
              cyber-attacks start to focus on new payments technologies.
              <br />
              <br />
              Payment service providers need to mitigate fraud risk, analyse
              each transaction in real-time and monitor fraud across all their
              channels. Even though each channel has its characteristics and
              specific fraud risks, there is a lot of benefit in managing fraud
              detection and prevention across all channels
            </p>
          </Container>
          <img src={FraudManagementBanner} alt="" />
        </section>
        <section className={classes.bodyContent}>
          <Container>
            <Grid ml={{ md: "102px" }} mr={{ md: "102px" }}>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={EverVigilantImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Ever vigilant, across all channels​​</h2>
                  <p>
                    BPC’s advanced fraud prevention solution helps card issuers
                    and acquirers combat the growing threat by monitoring 100%
                    of transactions, online, in real-time across all channels​
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6} className="content" order={{ xs: 1, md: 0 }}>
                  <h2>Advanced fraud detection and prevention​​</h2>
                  <p>
                    Rather than sampling transactions, hoping to catch fraud
                    after it happens, SmartGuard monitors 100% of transactions
                    in real-time so you can stop fraud before it takes place.
                  </p>
                </Grid>
                <Grid item md={6}>
                  <img src={AdvancedFraudDetectionImg} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={DelightCustomerImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Delight customers and save money​​​</h2>
                  <p>
                    By proactively contacting customers rather than blocking
                    their cards, you can turn a potentially embarrassing
                    situation for them into a positive experience – reinforcing
                    the reasons why they placed their trust in your brand. ​
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <img src={DividerImage} alt="" className="divider" />
        </section>
        <FooterLandingPage />
      </section>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
      fontFamily: ["Raleway", "sans-serif"],
      "& button": {
        textTransform: "none",
      },
      "& h1,h2,h3,h4,p": {
        margin: 0,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "48px",
      },
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      display: "flex",
      background: "linear-gradient(90deg, #30BBF2 0%, #B2CC49 100%)",
      position: "relative",
      color: "white",
      "& h1": {
        [theme.breakpoints.up("md")]: {
          marginTop: "168px",
          fontWeight: 300,
          fontSize: "54px",
          lineHeight: "56px",
          width: "588px",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "18px",
          width: "160px",
        },
      },
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "6px",
          lineHeight: "9px",
          width: "200px",
          marginTop: "12px",
          marginBottom: "12px",
        },
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: "128px",
        width: "588px",
        marginTop: "48px",
      },
      "& img": {
        position: "absolute",
        height: "100%",
        right: "0px",
      },
    },
    bodyContent: {
      backgroundColor: "#F7F9FC",
      marginTop: "120px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10px",
      },
      "& .content": {
        marginTop: "103px",
        marginBottom: "103px",
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          marginBottom: "24px",
        },
        "& h2": {
          fontWeight: 700,
          fontSize: "40px",
          lineHeight: "48px",
          color: "#2C3038",
        },
        "& p": {
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "30px",
          textAlign: "justify",
          marginTop: "24px",
        },
      },
      "& .divider": {
        height: "100%",
        marginBottom: "-3px",
      },
    },
  };
});
