import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { BasicButton, OTP } from "../../../components";

const DialogForOTP = ({
  isOpen,
  onClose,
  onSubmit,
  onReset,
  loading,
  resetLoading,
}) => {
  const [otp, setOTP] = useState("");

  const validateOtp = otp.length === 6;
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle fontWeight={600}>Confirmation</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Enter the OTP from Google Authenticator
        </DialogContentText>
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          mt={3}
          gap={3}
        >
          <Box justifyContent="center" display="flex">
            <OTP value={otp} onChange={setOTP} length={6} />
          </Box>
          <DialogActions>
            <Button variant="outlined" width="100%" onClick={() => onReset()}>
              {resetLoading ? "Loading..." : "Reset OTP?"}
            </Button>
            <BasicButton
              disabled={!validateOtp || loading}
              label={loading ? "Loading..." : "Submit"}
              onClick={() => onSubmit(otp)}
            />
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogForOTP;
