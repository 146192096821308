import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CustomButton } from "../../../components";
import { Axios } from "../../../config";

const useStyles = makeStyles({
  input: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "6px",
      width: "344px",
    },
  },
  hiddenActions: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1A3650",
  },
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ModalEditContract = ({
  openModalEditContract,
  handleCloseModalEditContract,
  getDataTableContract,
  setOpenModalEditContract,
  contractNumberEdit,
  contractDateData,
  billingCycleTypeData,
  billingCycleDateData,
  contractId,
}) => {
  const classes = useStyles();
  const [contractDate, setContractDate] = useState(new Date());
  const [hoursContractDate, setHoursContractDate] = useState("00");
  const [minuteContractDate, setMinuteContractDate] = useState("00");
  const [secondContractDate, setSecondContractDate] = useState("00");
  const [billingType, setBillingType] = useState("D");
  const [billingDate, setBillingDate] = useState(new Date());
  const [hoursBillingDate, setHoursBillingDate] = useState("00");
  const [minuteBillingDate, setMinuteBillingDate] = useState("00");
  const [secondBillingDate, setSecondBillingDate] = useState("00");
  const [openModalBillingDate, setOpenModalBillingDate] = useState(false);
  const [lastBillingDate, setLastBillingDate] = useState(new Date());
  const [hoursLastBillingDate, setHoursLastBillingDate] = useState("00");
  const [minuteLastBillingDate, setMinuteLastBillingDate] = useState("00");
  const [secondLastBillingDate, setSecondLastBillingDate] = useState("00");
  const [openModalLastBillingDate, setOpenModalLastBillingDate] =
    useState(false);
  const [feeFlag, setFeeFlag] = useState("T");
  const [contractEndDate, setContractEndDate] = useState(new Date());
  const [hoursContractEndDate, setHoursContractEndDate] = useState("00");
  const [minuteContractEndDate, setMinuteContractEndDate] = useState("00");
  const [secondContractEndDate, setSecondContractEndDate] = useState("00");
  const [openModalContractEndDate, setOpenModalContractEndDate] =
    useState(false);
  const [contractStatus, setContractStatus] = useState(true);
  const [contractNumber, setContractNumber] = useState(contractNumberEdit);
  const [amountFeeFlag, setAmountFeeFlag] = useState("T");
  const [openModalContractDate, setOpenModalContractDate] = useState(false);
  console.log("ct data", contractDateData);
  console.log("ct date", contractDate);
  console.log("ct id", contractId);

  const onChangeAmountFeeFlag = (e) => {
    setAmountFeeFlag(e.target.value);
  };

  const handleChangeContractNumber = (e) => {
    setContractNumber(e.target.value);
  };

  const onChangeContractStatus = (e) => {
    setContractStatus(e.target.value);
  };

  const handleOpenModalContractEndDate = () => {
    setOpenModalContractEndDate(true);
  };

  const handleCloseModalContractEndDate = () => {
    setOpenModalContractEndDate(false);
  };

  const onChangeContractEndDate = (date) => {
    setContractEndDate(date.$d);
  };

  const handleChangeHoursContractEndDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursContractEndDate(e.target.value);
        } else {
          setHoursContractEndDate(hoursContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteContractEndDate = (e) => {
    // minute only accept numbers, max 59, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteContractEndDate(e.target.value);
        } else {
          setMinuteContractEndDate(minuteContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondContractEndDate = (e) => {
    // second only accept numbers, max 59, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondContractEndDate(e.target.value);
        } else {
          setSecondContractEndDate(secondContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const onChangeFeeFlag = (e) => {
    setFeeFlag(e.target.value);
  };

  const handleOpenModalLastBillingDate = () => {
    setOpenModalLastBillingDate(true);
  };

  const handleCloseModalLastBillingDate = () => {
    setOpenModalLastBillingDate(false);
  };

  const onChangeLastBillingDate = (date) => {
    setLastBillingDate(date.$d);
  };

  const handleChangeHoursLastBillingDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursLastBillingDate(e.target.value);
        } else {
          setHoursLastBillingDate(hoursLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteLastBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteLastBillingDate(e.target.value);
        } else {
          setMinuteLastBillingDate(minuteLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondLastBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondLastBillingDate(e.target.value);
        } else {
          setSecondLastBillingDate(secondLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };
  const handleOpenModalBillingDate = () => {
    setOpenModalBillingDate(true);
  };

  const handleCloseModalBillingDate = () => {
    setOpenModalBillingDate(false);
  };

  const onChangeBillingDate = (date) => {
    setBillingDate(date.$d);
  };

  const handleChangeHoursBillingDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursBillingDate(e.target.value);
        } else {
          setHoursBillingDate(hoursBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteBillingDate(e.target.value);
        } else {
          setMinuteBillingDate(minuteBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondBillingDate(e.target.value);
        } else {
          setSecondBillingDate(secondBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const onChangeBillingType = (e) => {
    setBillingType(e.target.value);
  };

  const handleOpenModalContractDate = () => {
    setOpenModalContractDate(true);
  };

  const handleCloseModalContractDate = () => {
    setOpenModalContractDate(false);
  };

  const onChangeContractDate = (date) => {
    setContractDate(date.$d);
  };
  const handleChangeHoursContractDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursContractDate(e.target.value);
        } else {
          setHoursContractDate(hoursContractDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteContractDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteContractDate(e.target.value);
        } else {
          setMinuteContractDate(minuteContractDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondContractDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondContractDate(e.target.value);
        } else {
          setSecondContractDate(secondContractDate);
        }
      }
    } else {
      return false;
    }
  };
  const dateTimeStringToIso = (
    dateString,
    hoursString,
    minuteString,
    secondString
  ) => {
    const date = new Date(dateString);
    date.setHours(hoursString);
    date.setMinutes(minuteString);
    date.setSeconds(secondString);
    return date.toISOString();
  };
  const onApply = () => {
    const payload = {
      contract_no: contractNumber,
      contract_date: dateTimeStringToIso(
        contractDate,
        hoursContractDate,
        minuteContractDate,
        secondContractDate
      ),
      contract_desc: "",
      billing_cycle_type_flag: billingType,
      billing_cycle_date: dateTimeStringToIso(
        billingDate,
        hoursBillingDate,
        minuteBillingDate,
        secondBillingDate
      ),
      last_billing_cycle_date: dateTimeStringToIso(
        lastBillingDate,
        hoursLastBillingDate,
        minuteLastBillingDate,
        secondLastBillingDate
      ),
      include_fee_flag: feeFlag,
      include_amount_flag: amountFeeFlag,
      contract_end_date: dateTimeStringToIso(
        contractEndDate,
        hoursContractEndDate,
        minuteContractEndDate,
        secondContractEndDate
      ),
      is_active_flag: contractStatus === "true" ? true : false,
    };

    console.log("payload", payload);
    Axios.put("/contract/update/" + contractId, payload).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setOpenModalEditContract(false);
        Swal.fire({
          title: "Success",
          text: res.data.message || `Success edit contract`,
          icon: "success",
        });
        getDataTableContract();
      }
    });
  };

  const getDetailEdit = () => {
    Axios.get(`/contract/detail/${contractId}`).then((res) => {
      if (res.status === 200 || res.status === 201) {
        const { data } = res.data;
        setContractNumber(data.contract_no);
        setContractDate(dayjs(data.contract_date).format("DD MMMM YYYY"));
        setHoursContractDate(dayjs(data.contract_date).format("HH"));
        setMinuteContractDate(dayjs(data.contract_date).format("mm"));
        setSecondContractDate(dayjs(data.contract_date).format("ss"));
        setBillingType(data.billing_cycle_type_flag);
        setBillingDate(dayjs(data.billing_cycle_date).format("DD MMMM YYYY"));
        setHoursBillingDate(dayjs(data.billing_cycle_date).format("HH"));
        setMinuteBillingDate(dayjs(data.billing_cycle_date).format("mm"));
        setSecondBillingDate(dayjs(data.billing_cycle_date).format("ss"));
        setLastBillingDate(
          dayjs(data.last_billing_cycle_date).format("DD MMMM YYYY")
        );
        setHoursLastBillingDate(
          dayjs(data.last_billing_cycle_date).format("HH")
        );
        setMinuteLastBillingDate(
          dayjs(data.last_billing_cycle_date).format("mm")
        );
        setSecondLastBillingDate(
          dayjs(data.last_billing_cycle_date).format("ss")
        );
        setFeeFlag(data.include_fee_flag);
        setAmountFeeFlag(data.include_amount_flag);
        setContractEndDate(
          dayjs(data.contract_end_date).format("DD MMMM YYYY")
        );
        setHoursContractEndDate(dayjs(data.contract_end_date).format("HH"));
        setMinuteContractEndDate(dayjs(data.contract_end_date).format("mm"));
        setSecondContractEndDate(dayjs(data.contract_end_date).format("ss"));
        setContractStatus(data.is_active_flag);
      } else {
        Swal.fire({
          title: "Error",
          text: res.data.message || `Error get detail contract`,
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (contractId) {
      getDetailEdit();
    }
  }, []);

  return (
    <div>
      <Dialog
        maxWidth={"xs"}
        open={openModalEditContract}
        onClose={handleCloseModalEditContract}
      >
        <BootstrapDialogTitle onClose={handleCloseModalEditContract}>
          Edit Contract
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p className={classes.label}>Contract Number</p>
          <TextField
            name="contract-number"
            type="number"
            onChange={handleChangeContractNumber}
            className={classes.input}
            value={contractNumber}
          />

          <p className={classes.label}>Contract Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${contractDate} (${hoursContractDate}:${minuteContractDate}:${secondContractDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalContractDate}
          />

          <p className={classes.label}>Billing Cycle Type</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={billingType}
            onChange={onChangeBillingType}
          >
            <MenuItem value={"D"}>Daily</MenuItem>
            <MenuItem value={"W"}>Weekly</MenuItem>
            <MenuItem value={"M"}>Monthly</MenuItem>
          </TextField>

          <p className={classes.label}>Billing Cycle Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${billingDate} (${hoursBillingDate}:${minuteBillingDate}:${secondBillingDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalBillingDate}
          />

          <p className={classes.label}>Last Billing Cycle Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${lastBillingDate} (${hoursLastBillingDate}:${minuteLastBillingDate}:${secondLastBillingDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalLastBillingDate}
          />

          <p className={classes.label}>Include Fee Flag</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={feeFlag}
            onChange={onChangeFeeFlag}
          >
            <MenuItem value={"T"}>True</MenuItem>
            <MenuItem value={"F"}>False</MenuItem>
          </TextField>

          <p className={classes.label}>Amount Fee Flag</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={amountFeeFlag}
            onChange={onChangeAmountFeeFlag}
          >
            <MenuItem value={"T"}>True</MenuItem>
            <MenuItem value={"F"}>False</MenuItem>
          </TextField>

          <p className={classes.label}>Contract End Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${contractEndDate} (${hoursContractEndDate}:${minuteContractEndDate}:${secondContractEndDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalContractEndDate}
          />
          <p className={classes.label}>Status</p>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={contractStatus}
              name="radio-buttons-group"
              onChange={onChangeContractStatus}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            onClick={onApply}
            style={{
              width: "100%",
              height: "40px",
            }}
          >
            Save Changes
          </CustomButton>{" "}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalContractDate}
        onClose={handleCloseModalContractDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={contractDate}
              onChange={onChangeContractDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursContractDate}
              onChange={handleChangeHoursContractDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteContractDate}
              onChange={handleChangeMinuteContractDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondContractDate}
              onChange={handleChangeSecondContractDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openModalBillingDate} onClose={handleCloseModalBillingDate}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={billingDate}
              onChange={onChangeBillingDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursBillingDate}
              onChange={handleChangeHoursBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteBillingDate}
              onChange={handleChangeMinuteBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondBillingDate}
              onChange={handleChangeSecondBillingDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalLastBillingDate}
        onClose={handleCloseModalLastBillingDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={lastBillingDate}
              onChange={onChangeLastBillingDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursLastBillingDate}
              onChange={handleChangeHoursLastBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteLastBillingDate}
              onChange={handleChangeMinuteLastBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondLastBillingDate}
              onChange={handleChangeSecondLastBillingDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalContractEndDate}
        onClose={handleCloseModalContractEndDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={contractEndDate}
              onChange={onChangeContractEndDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursContractEndDate}
              onChange={handleChangeHoursContractEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteContractEndDate}
              onChange={handleChangeMinuteContractEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondContractEndDate}
              onChange={handleChangeSecondContractEndDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalEditContract;
