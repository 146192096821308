import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CustomInput, ModalComponent } from "../../../components";
import { Axios } from "../../../config";

const useStyles = makeStyles({
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});

export default function ModalProductPartner({
  openModal,
  closeModal,
  dataEdit,
  id,
  getDataTable,
}) {
  const classes = useStyles();
  const [dataProduct, setDataProduct] = useState([]);
  const [dataPartnerType, setDataPartnerType] = useState([]);
  const [dataFormat, setDataFormat] = useState([]);
  const [dataParentPartner, setDataParentPartner] = useState([]);
  const [productPartner, setProductPartner] = useState({
    product_id: null,
    partner_id: null,
    partner_type_id: null,
    parent_product_partner_id: null,
    data_format_type_id: null,
    recon_output_path: "",
  });

  const getDataProduct = () => {
    Axios.get("/product").then(({ data }) => setDataProduct(data.data));
  };

  const getDataPartnerType = () => {
    Axios.get("/partner-type").then(({ data }) =>
      setDataPartnerType(data.data)
    );
  };

  const getDataFormat = () => {
    Axios.get("/data_format/product").then(({ data }) =>
      setDataFormat(data?.data)
    );
  };

  const getParentPartner = (idPartnerType) => {
    Axios.get(`/product_partner/parent/${idPartnerType}`).then(({ data }) => {
      setDataParentPartner(data?.data);
    });
  };

  useEffect(() => {
    getDataFormat();
    getDataProduct();
    getDataPartnerType();
    if (openModal?.ID) {
      Axios.get(`/product_partner/detail/${openModal?.ID}`).then((res) => {
        const { data } = res?.data;
        getParentPartner(data?.partner_type_id);
        setProductPartner({
          ...productPartner,
          product_id: data.product?.id,
          product_code: data?.product?.product_desc,
          partner_type_id: data?.partner_type_id,
          parent_product_partner_id: data?.parent_product_partner_id,
          data_format_type_id: data?.data_format_type?.id,
          recon_output_path: data?.recon_output_path,
        });
      });
    }
  }, []);

  const handleChangePartner = ({ target }) => {
    setProductPartner({ ...productPartner, partner_type_id: target.value });
    getParentPartner(target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    closeModal();
    if (openModal?.ID) {
      Axios.put(
        `/product_partner/update/${openModal?.ID}`,
        productPartner
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataTable());
      });
    } else {
      Axios.post(`/product_partner/create/${id}`, productPartner).then(
        (res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          }).then(() => getDataTable());
        }
      );
    }
  };

  console.log(productPartner);

  return (
    <ModalComponent open={openModal} onClose={closeModal}>
      <Grid className={classes.ModalWrapper} conteiner>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>
            {dataEdit?.id ? "Update Product Partner" : "Create Product Partner"}
          </h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid sx={{ padding: "24px", paddingTop: "4px" }}>
          <form action="" id="modal_product_partner" onSubmit={onSubmit}>
            <CustomInput.Select
              label="Product Name"
              required
              value={productPartner?.product_id ?? null}
              onChange={({ target }) => {
                const value = dataProduct.find(({ id }) => id === target.value);
                setProductPartner({
                  ...productPartner,
                  product_id: target.value,
                  product_code: value.product_code,
                });
              }}
            >
              {dataProduct.map((val) => (
                <MenuItem value={val.id}>{val.product_desc}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput
              disabled
              value={productPartner?.product_code}
              mt="20px"
              label="Product Code"
            />
            <CustomInput.Select
              label="Partner Type"
              onChange={handleChangePartner}
              value={productPartner?.partner_type_id ?? null}
              required
            >
              {dataPartnerType.map(({ id, partner_type_code }) => (
                <MenuItem value={id}>{partner_type_code}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput.Select
              label="Parent Partner"
              value={productPartner?.parent_product_partner_id ?? null}
              onChange={({ target }) =>
                setProductPartner({
                  ...productPartner,
                  parent_product_partner_id: target.value,
                })
              }
            >
              {dataParentPartner.map(({ ID, partner }) => (
                <MenuItem value={ID}>{partner?.partner_name}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput.Select
              label="Data Format"
              required
              value={productPartner?.data_format_type_id ?? null}
              onChange={({ target }) =>
                setProductPartner({
                  ...productPartner,
                  data_format_type_id: target.value,
                })
              }
            >
              {dataFormat.map(({ id, data_format_type_code }) => (
                <MenuItem value={id}>{data_format_type_code}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput
              value={productPartner?.recon_output_path}
              mt="20px"
              required
              label="Recon Output Path"
              onChange={({ target }) =>
                setProductPartner({
                  ...productPartner,
                  recon_output_path: target.value,
                })
              }
            />
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          type="submit"
          form="modal_product_partner"
          sx={(theme) => theme.buttonGreen}
          fullWidth
        >
          Create
        </Button>
      </Grid>
    </ModalComponent>
  );
}
