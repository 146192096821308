import { Fade, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardSkeleton, Forbidden } from "../../components";
import { Axios } from "../../config/helper";
import DashboardFBA from "./DashboardFBA";
import DashboardFSA from "./DashboardFSA";
import DashboardPA from "./DashboardPA";
import DashboardPO from "./DashboardPO";

function DashboarHandler() {
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRole();
  }, []);

  const getRole = () => {
    setLoading(true);
    Axios.get("/profil").then((res) => {
      setRole(res.data.data.currnet_role.code);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  if (loading) {
    return <DashboardSkeleton />;
  } else {
    if (role === "FSA") {
      return (
        <Fade in={!loading} timeout={600}>
          <Grid>
            <DashboardFSA />
          </Grid>
        </Fade>
      );
    } else if (role === "FBA") {
      return (
        <Fade in={!loading} timeout={600}>
          <Grid>
            <DashboardFBA />
          </Grid>
        </Fade>
      );
    } else if (role === "PA") {
      return (
        <Fade in={!loading} timeout={600}>
          <Grid>
            <DashboardPA />
          </Grid>
        </Fade>
      );
    } else if (role === "PO") {
      return (
        <Fade in={!loading} timeout={600}>
          <Grid>
            <DashboardPO />
          </Grid>
        </Fade>
      );
    } else {
      return (
        <Fade in={!loading} timeout={600}>
          <Grid>
            <Forbidden />
          </Grid>
        </Fade>
      );
    }
  }
}

export default DashboarHandler;
