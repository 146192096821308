import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Grid, List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListMenuSidebar } from "../../assets/icons/CustomIcon";

export const MainListItems = ({ menus }) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState({
    id: 0,
    name: "",
  });
  const [subMenu, setSubMenu] = useState({});
  const url = useLocation().pathname.split("/");

  // const getCurrentURL = () => {
  //   menus.filter((val) =>
  //     val.name.toLowerCase() === url[2]
  //       ? setOpen(val)
  //       : val.child?.filter(
  //           (res) =>
  //             res.link.toLowerCase() === `/${url[2]}` &&
  //             (setOpen(val), setSubMenu(res))
  //         )
  //   );
  // };

  useEffect(() => {
    // getCurrentURL();
  }, []);

  const menuHead = (res) => {
    setOpen(res.id === open.id ? {} : res);
    if (res.child_menu) {
    } else {
      return navigate(`${res.url}`);
    }
  };

  const handleSubmenu = (val) => {
    setSubMenu(val);
    return navigate(`${val.url}`);
  };

  return (
    <React.Fragment>
      {menus.map((res, i) => {
        return (
          <Grid key={i}>
            <ListItemButton
              onClick={() => menuHead(res)}
              sx={{
                backgroundColor: open.id === res.id ? "#bae6fd50" : "",
                "&:hover": {
                  backgroundColor:
                    open.id === res.id ? "#bae6fd40" : "#bae6fd20",
                },
                margin: "0px",
                borderRadius: "6px",
                ml: { xs: "0px", sm: "16px" },
                mr: { xs: "0px", sm: "16px" },
                mb: "5px",
              }}
              key={i}
            >
              <ListItemIcon
                sx={{
                  "& svg": {
                    color: "white",
                  },
                }}
              >
                {
                  ListMenuSidebar.find((val) => Number(val.id) === res.icon_id)
                    .icon
                }
              </ListItemIcon>
              <ListItemText
                primary={res.title}
                sx={{ marginLeft: -2, color: "white" }}
              />
              {res.child_menu ? (
                open.id === res.id ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={open.id === res.id} timeout="auto" unmountOnExit>
              {res.child_menu
                ? res.child_menu.map((val, i) => {
                    return (
                      <List
                        component="div"
                        disablePadding
                        onClick={() => handleSubmenu(val)}
                        key={i}
                      >
                        <ListItemButton
                          sx={{
                            borderLeft: subMenu.id === val.id && "2px solid",
                            padding: 0,
                            paddingLeft: 2,
                            mb: 1,
                            ml: 8.7,
                            backgroundColor:
                              subMenu.id === val.id && "#bae6fd20",
                            "&:hover": {
                              backgroundColor:
                                subMenu.id === val.id && "#bae6fd30",
                            },
                          }}
                        >
                          <ListItemText primary={val.title} />
                        </ListItemButton>
                      </List>
                    );
                  })
                : ""}
            </Collapse>
          </Grid>
        );
      })}
    </React.Fragment>
  );
};
