import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputComponent from "../../components/Global/InputComponent";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterPartner({ onApply, onReset, filters }) {
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [dataMerchant, setDataMerchant] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      product: null,
      merchant: null,
      type: "total_transaction",
      date: new Date(),
    }
  );

  const getDataProduct = () => {
    Axios.get("/product", {
      params: {
        page: 1,
        offset: 0,
        limit: 100,
      },
    }).then(({ data }) => setDataProduct(data?.data) || []);
  };

  const getDataMerchant = () => {
    Axios.get("/product_partner/partner-type?partner_type_code=merchant").then(
      ({ data }) => setDataMerchant(data?.data) || []
    );
  };
  const getDataBank = () => {
    Axios.get("/product_partner/partner-type?partner_type_code=acquirer").then(
      ({ data }) => setDataBank(data?.data) || []
    );
  };
  const getAllListFilter = () => {
    return Promise.all([getDataProduct(), getDataBank(), getDataMerchant()]);
  };
  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    getAllListFilter();
  }, []);
  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <InputComponent
          value={dayjs(dataFilter?.date).format("MMMM YYYY")}
          type="date"
          id="dateTransaction"
          name="date"
          control={control}
          error={errors}
          formatDate={"MMMM YYYY"}
          viewFormat={["year", "month"]}
          customIcon={KeyboardArrowDownIcon}
          showButton
          sx={{ width: "100%" }}
          onChange={(e) => {
            setDataFilter({ ...dataFilter, date: e.target.value });
          }}
        />
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, product: target.value })
          }
          placeholder="Product"
          value={dataFilter.product}
        >
          {dataProduct.map((val) => {
            return <MenuItem value={val.id}>{val.product_desc}</MenuItem>;
          })}
        </CustomInput.Select>
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, merchant: target.value })
          }
          placeholder="Merchant"
          value={dataFilter?.merchant}
        >
          {dataMerchant.map((val) => {
            return <MenuItem value={val.id}>{val.partner_name}</MenuItem>;
          })}
        </CustomInput.Select>
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, type: target.value })
          }
          placeholder="Type Data"
          value={dataFilter.type}
        >
          {[
            { key: "total_success", text: "Success Transaction" },
            { key: "total_failed", text: "Failed Transaction" },
            { key: "total_transaction", text: "Total Transaction" },
          ].map((val) => {
            return <MenuItem value={val.key}>{val.text}</MenuItem>;
          })}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
