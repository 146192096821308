import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";

function CustomInput({
  id,
  label = "",
  placeholder = "",
  mt,
  width,
  endAdornment,
  type = "",
  startAdornment,
  register,
  required = false,
  onChange = () => {},
  value,
  defaultValue,
  min,
  max,
  disabled = false,
  multiline = false,
  rows,
  sx,
}) {
  return (
    <Grid
      container={Boolean(!width)}
      sx={{
        "& h4": {
          margin: 0,
          marginBottom: "8px",
          fontWeight: 600,
          fontSize: "14px",
          width: "100%",
        },
        "& textarea": {
          padding: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
        },
      }}
      mt={mt}
    >
      <h4>{label}</h4>
      <TextField
        multiline={multiline}
        rows={multiline && rows}
        placeholder={placeholder || label}
        fullWidth={width ? false : true}
        size="small"
        id={id}
        variant="outlined"
        sx={{
          width: width,
          ...sx,
        }}
        value={value}
        onChange={onChange || register?.onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        // type={type}
        InputProps={{
          ...register,
          required: required,
          type: type,
          inputProps: {
            max: max,
            min: min,
          },
          endAdornment: endAdornment && (
            <InputAdornment sx={{ paddingRight: "8px" }} position="end">
              {endAdornment}
            </InputAdornment>
          ),
          startAdornment: startAdornment && (
            <InputAdornment sx={{ paddingRight: "8px" }} position="end">
              {startAdornment}
            </InputAdornment>
          ),
          sx: {
            padding: 0,
          },
        }}
      />
    </Grid>
  );
}

const SelectInput = ({
  value,
  onChange = () => {},
  children,
  marginTop,
  width,
  register,
  label,
  errorMessage,
  id,
  error,
  sx,
  inputStyle,
  defaultValue,
  placeholder = "",
  required = false,
  multiple = false,
  renderValue,
}) => {
  let isError =
    typeof error === "object"
      ? error[id]
      : typeof error === "boolean"
      ? error
      : false;

  return (
    <Grid
      sx={{
        "& h4": {
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          marginBottom: "6px",
          marginTop: marginTop || "20px",
        },
        "& .msgError": {
          margin: 0,
          marginTop: "4px",
          color: "red",
        },
        "& .MuiInputLabel-shrink": {
          visibility: "hidden",
        },
        width: width || "100%",
        marginTop: "3px",
        backgroundColor: "white",
      }}
    >
      {label && <h4>{label}</h4>}
      <FormControl fullWidth size="small">
        <InputLabel
          sx={{
            color: "#9AA2B1",
          }}
        >
          {(value === undefined || value?.length === 0) &&
            (placeholder || `Select ${label}`)}
        </InputLabel>
        <Select
          value={value}
          defaultValue={defaultValue}
          error={isError}
          multiple={multiple}
          renderValue={renderValue}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ ...sx }}
          onChange={onChange || register?.onChange}
          inputProps={{
            ...register,
            sx: { ...inputStyle },
            required: required,
          }}
        >
          {children}
        </Select>
      </FormControl>
      {Boolean(isError) && (
        <p className="msgError">
          {errorMessage ? errorMessage : `${label || id} cannot be empty!`}{" "}
        </p>
      )}
    </Grid>
  );
};

CustomInput.Select = SelectInput;

const PickerMonth = ({ onChange, formatValue = "MM-YYYY" }) => {
  const [value, setValue] = useState(new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat={"MMMM YYYY"}
        onChange={(day) => {
          setValue(day);
          // onChange(day.format('MM-YYYY'));
        }}
        onAccept={(val) => onChange(val.format(formatValue))}
        value={value}
        views={["month", "year"]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", maxWidth: "180px" }}
            readOnly
          />
        )}
      />
    </LocalizationProvider>
  );
};

CustomInput.MonthPicker = PickerMonth;

export default CustomInput;
