import { IconButton, Tooltip } from "@mui/material";
import { Fragment, default as React } from "react";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const ActionButton = ({ onEdit, onDelete }) => {
  return (
    <Fragment>
      <Tooltip title="Edit">
        <IconButton onClick={onEdit}>
          <BorderColorIcon size="small" style={{ fill: "#A0B842" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={onDelete}>
          <DeleteOutlineIcon size="small" style={{ fill: "#FF0000" }} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

export default ActionButton;
