import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, Chip, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  ModalUserExternal,
  SelectFilterComponent,
  TableComponent,
  TitleWithBackButton,
} from "../../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../../config";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
  sort: "",
};

export default function UserEksternalDetail() {
  const classes = useStyles();
  let { id } = useParams();

  const [SearchParams, setSearchParams] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Partner, setPartner] = useState("");
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [params, setParams] = useState(initialParams);
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    status: "",
    roles: [],
  });

  const column = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <ActionButton
            onEdit={() => setOpen({ ...params, title: "Edit" })}
            onDelete={() => onDeleteUser(params)}
          />
        ),
    },
    {
      name: "Name",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },

    {
      name: "Role",
      renderCell: ({ user_role }) =>
        loading ? <Skeleton /> : user_role?.map((res) => res.name).join(", "),
    },
    {
      name: "Email",
      renderCell: (params) => (loading ? <Skeleton /> : params.email),
    },
    {
      name: "Status",
      renderCell: ({ is_active }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active ? "Active" : "Inactive"}
            color={is_active ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  useEffect(() => {
    let mappingParams = Object.keys(params)
      .map((obj) => obj + "=" + params[obj])
      .join("&");
    setSearchParams(mappingParams);
  }, [params]);

  useEffect(() => {
    if (id && SearchParams !== "") onGetData();
  }, [SearchParams]);

  useEffect(() => {
    if (id) onGetPartner();
  }, [id]);

  const onGetPartner = async () => {
    await Axios.get("/partner/detail/" + id)
      .then((res) => (res.data ? setPartner(res.data.data.name) : ""))
      .catch((err) => console.log(err));
  };

  const onGetData = async (filterStatus = "", filterRoles = "") => {
    setLoading(true);
    await Axios.get(
      `/user/${id}?${SearchParams}&active=${filterStatus}&roles=${filterRoles}`
    )
      .then((res) => {
        if (res.data) {
          setMeta(res.data.meta);
          setRows(res.data.data || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setMeta({});
        setRows([]);
        setLoading(false);
      });
  };

  const onDeleteUser = async (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Delete user ${params.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/user/delete/${params.id}`)
          .then(async (res) => {
            if (res.status === 200) {
              await onGetData();
              Swal.fire("Deleted!", "User has been deleted", "success", 3000);
            }
            if (res.status === 201) {
              await onGetData();
              Swal.fire("Deleted!", res.data.message, "success", 3000);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  useEffect(() => {
    let status = selectedFilter?.status;
    let idRoles = selectedFilter?.roles?.map((el) => el.id).toString();
    onGetData(status, idRoles);
  }, [selectedFilter]);

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <TitleWithBackButton title={Partner} />
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Create User
          </Button>
        </Grid>
        <TableComponent
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          onFilterTable
          multipleFilterComponent={
            <UserEksternalFilterComponent
              setSelectedFilter={setSelectedFilter}
            />
          }
          totalData={meta?.total}
          page={meta?.page}
          rows={rows}
          column={column}
        />
      </Grid>

      {open && (
        <ModalUserExternal
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={onGetData}
          partnerId={id}
        />
      )}
    </Fragment>
  );
}

const UserEksternalFilterComponent = ({ setSelectedFilter }) => {
  const [dataRole, setDataRole] = useState({
    loading: true,
    data: [],
  });
  const [selectedFilterRole, setSelectedFilterRole] = useState([]);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState([]);
  const dataStatus = {
    loading: false,
    data: [
      { id: "true", name: "Active", value: "true" },
      { id: "false", name: "Inactive", value: "false" },
    ],
  };
  const getListRole = async () => {
    await Axios.get(`/role`).then((res) => {
      // console.log("role", res);
      setDataRole({
        loading: false,
        data: res.data.data || [],
      });
    });
  };

  useEffect(() => {
    getListRole();
  }, []);

  const handleApply = () => {
    setSelectedFilter({
      roles: selectedFilterRole,
      status: selectedFilterStatus?.value ?? "",
    });
  };
  const handleReset = () => {
    if (selectedFilterRole.length > 0 || selectedFilterStatus.length > 0) {
      setSelectedFilter({
        roles: [],
        status: "",
      });
    }
    setSelectedFilterRole([]);
    setSelectedFilterStatus([]);
  };

  return (
    <Box
      sx={{
        paddingBottom: "15px",
        paddingLeft: "20px",
        paddingRight: "20px",
        zIndex: 999,
        overflow: "unset",
      }}
    >
      <h4 style={{ paddingTop: "15px" }}>Filter</h4>
      <SelectFilterComponent
        dataFilter={dataRole}
        multipleValue
        filterPlaceholder={"Role"}
        selectedFilter={selectedFilterRole}
        setSelectedFilter={setSelectedFilterRole}
      />
      <div style={{ height: "10px" }} />
      <SelectFilterComponent
        dataFilter={dataStatus}
        selectedFilter={selectedFilterStatus}
        filterPlaceholder={"Status"}
        setSelectedFilter={setSelectedFilterStatus}
      />
      <div style={{ height: "20px" }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => handleReset()}
          variant="outlined"
          sx={{
            color: "#A0B842",
            borderColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            marginRight: "5px",
            minWidth: "90px",
            "&:hover": {
              borderColor: "#A0B842",
            },
          }}
        >
          Reset
        </Button>
        <Button
          onClick={() => handleApply()}
          variant="contained"
          disableElevation
          sx={{
            backgroundColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            minWidth: "90px",
            "&:hover": {
              backgroundColor: "#A0B842",
            },
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
});
