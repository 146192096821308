import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Colors } from "../../assets";
import { InputComponent, TitleWithBackButton } from "../../components";
import { Axios } from "../../config";
import { AxiosFinpoint } from "../../config/helperFinpoint";
import TreeReport from "./TreeReport";

let initialBody = {
  start_date: "", // '01-10-2022 09:30:01' //format = mm-dd-yyyy jam:menit:detik
  end_date: "", // '03-11-2022 09:30:01' //format = mm-dd-yyyy jam:menit:detik
};

export default function StaticReport() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const [error, setError] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [bankCode, setBankCode] = useState(null);
  const [institutionCode, setInstitutionCode] = useState(null);
  const [institutionName, setInstitutionName] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [body, setBody] = useState(initialBody);
  const [merchantCode, setMerchantCode] = useState({
    loading: true,
    data: [],
  });

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? ["1", "2", "3", "4", "5", "6", "7", "8", "11"]
        : []
    );
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const getDataUser = () => {
    Axios.get("/profil")
      .then((res) => {
        setDataUser(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDataUser();
  }, []);

  const onLoadData = async () => {
    setExpanded([]);
    setIsLoading(true);
    await AxiosFinpoint.post("/report/static", body)
      .then((res) => {
        const result = res.data.data.data;
        if (result.status === "SUCCESS" && result.details.length > 0) {
          const datas = result.details.filter((val) => val.merchant_code);
          setData(datas);
          handleExpandClick(["1", "2", "3", "4", "5", "6", "7", "8", "11"]);
        } else {
          setError("No data found");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
        if (error.response.data.message) setError(error.response.data.message);
      });
  };

  useEffect(() => {
    let merchant_code = [
      ...new Set(data?.map((item) => item.merchant_code)),
    ].filter((el) => el !== ""); //pisah berdasar merchant codenya
    setMerchantCode({
      loading: false,
      data: merchant_code || [],
    });

    let bank_code = [...new Set(data?.map((item) => item.bank_code))].filter(
      (el) => el !== ""
    ); //pisah berdasar bank codenya
    setBankCode(bank_code);
    let institution_code = [
      ...new Set(data?.map((item) => item.institution_code)),
    ].filter((el) => el !== ""); //pisah berdasar institution codenya
    setInstitutionCode(institution_code);
  }, [data]);

  useEffect(() => {
    if (body?.end_date !== "") {
      body.start_date = dayjs(body.start_date.split(" ")[0]).format(
        "MM-DD-YYYY"
      );
      body.end_date = dayjs(body.end_date.split(" ")[0]).format("MM-DD-YYYY");
    }
  }, [body]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer, position: "relative" };
      }}
      style={{
        minHeight: "400px",
      }}
    >
      <TitleWithBackButton title="Static Report" hiddenBackButton />

      <Grid className={classes.root}>
        <Box display="flex" alignItems={"end"} gap={1}>
          <InputComponent
            value={body.start_date}
            disabled={IsLoading}
            label="Start Date"
            type="date"
            id="start_date"
            name="start_date"
            control={control}
            error={errors}
            formatDate={"DD MMMM YYYY"}
            sx={{ width: "100%" }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <InputComponent
            value={body.end_date}
            disabled={IsLoading}
            label="End Date"
            type="date"
            id="end_date"
            name="end_date"
            control={control}
            error={errors}
            formatDate={"DD MMMM YYYY"}
            sx={{ width: "100%" }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <IconButton
            className={classes.searchBtn}
            onClick={() => onLoadData()}
            variant="contained"
            disabled={IsLoading}
          >
            {IsLoading ? (
              <CircularProgress size={23} sx={{ color: "white" }} />
            ) : (
              <SearchIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </Box>
        <Grid className={classes.treeContainer}>
          {data.length > 0 ? (
            <TreeReport
              expanded={expanded}
              watch={watch}
              errors={errors}
              control={control}
              dataMerchant={merchantCode}
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
              data={data}
              dataUser={dataUser}
              bankCode={bankCode}
              institutionCode={institutionCode}
              institutionName={institutionName}
            />
          ) : (
            <Grid sx={{ width: "100%", textAlign: "center", padding: 10 }}>
              No data
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingLeft: "-100px",
    "& h3": {
      margin: 0,
      fontSize: "28px",
    },
  },
  searchBtn: {
    backgroundColor: Colors.SECONDARY + " !important",
    borderRadius: "8px !important",
    "&:disabled": {
      backgroundColor: "#B7B7B780 !important",
    },
  },
  progress: { width: "20px", height: "20px", marginRight: 10 },
  treeContainer: {
    backgroundColor: "#FFFFFF",
    minHeight: "300px",
    borderRadius: "10px",
    boxShadow: "1px 2px 5px #B7B7B7",
    paddingBottom: "30px",
    marginTop: "20px",
  },
}));
