import axios from "axios";
import Swal from "sweetalert2";
import { getToken } from "./cookies";

export const URL_API = process.env.REACT_APP_URL_API;

export const DEFAULT_PAGINATION_LIMIT = 5;

export const Axios = axios.create({
  baseURL: URL_API,
  headers: {
    "Content-Type": ["text/plain", "application/json"],
    "Access-Control-Allow-Origin": "*",
  },
});

Axios.interceptors.request.use(
  (config) => {
    const tokens = getToken();
    if (tokens) {
      config.headers["Authorization"] = `Bearer ${tokens}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    const errors = error.response.data.errors;
    let errorMessage = error.message;
    if (errors?.length)
      errorMessage = error.response.data.errors
        .map((err) => err.message)
        .join("\n");

    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errorMessage,
    }).then((res) => {
      if (res.isConfirmed && error?.response?.data?.meta?.http_status === 403) {
        window.location.replace(window.location.origin + "/login");
      }
    });
    // Do something with response error
    return Promise.reject(error);
  }
);

export const handleErrorInput = (error, val) => {
  let errorVar = error.filter((res) => res.name === val);
  return errorVar.length > 0 ? true : false;
};

export const formatNumber = (number, separator, float) => {
  if (number === "" || number === null) {
    return 0;
  }
  if (!float) {
    if (typeof number === "string") {
      number = number.replaceAll(separator, "");
      number = number.replace(/[^0-9]/g, "");
    }
    return parseFloat(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
  } else {
    const regex = /[^\d.]|\.(?=.*\.)/g;
    const subst = ``;
    number = number.replace(regex, subst);
    return number;
  }
};

export function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { Axios };
