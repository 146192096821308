import { Divider, Grid, MenuItem, Paper } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardComponent,
  CustomInput,
  TableComponent,
  TitleWithBackButton,
} from "../../../components";
import {
  Axios,
  currencyFormat,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";

Chart.register(CategoryScale, BarElement, Title, Tooltip, Legend);

export default function TransactionDetails() {
  const navigate = useNavigate();
  const { id: productId } = useParams();
  const [params, setParams] = useState({
    product: productId,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    date: dayjs(new Date()).format("MM-YYYY"),
  });

  const [bankCode, setBankCode] = useState("");
  const [merchantCode, setMerchantCode] = useState("");
  const [bankList, setBankList] = useState([]);
  const [merchantList, setMerchantList] = useState([]);

  const [transactionData, setTransactionData] = useState({});

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Transaction Error");
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState({});

  const column =
    activeTab === "Transaction Error"
      ? [
          { name: "ID", renderCell: ({ id }) => id },
          {
            name: "Date",
            renderCell: ({ rns_process_start_at }) =>
              moment(new Date(rns_process_start_at)).format("lll"),
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ bank_partner }) => bank_partner,
          },
          {
            name: "Principal",
            renderCell: ({ principal_partner }) => principal_partner,
          },
          {
            name: "Count",
            renderCell: ({ count, id }) => (
              <Grid
                onClick={() => navigate(`/dashboard/${id}/error`)}
                color="red"
                sx={{ cursor: "pointer" }}
              >
                {count ?? 1}
              </Grid>
            ),
          },
        ]
      : [
          { name: "ID", renderCell: ({ id }) => id },
          {
            name: "Date",
            renderCell: ({ date }) => moment(new Date(date)).format("lll"),
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ merchant_name, remote_transaction_amount }) => (
              <Grid>
                {currencyFormat(remote_transaction_amount)}
                <Grid
                  sx={{
                    color: "#687083",
                  }}
                >
                  {merchant_name}
                </Grid>
              </Grid>
            ),
          },
          {
            name: "Principal",
            renderCell: ({ principal_name, original_transaction_amount }) => (
              <Grid>
                {currencyFormat(original_transaction_amount)}
                <Grid
                  sx={{
                    color: "#687083",
                  }}
                >
                  {principal_name}
                </Grid>
              </Grid>
            ),
          },
          {
            name: "Difference",
            renderCell: ({ difference_amount }) => (
              <Grid color="red">{currencyFormat(difference_amount)}</Grid>
            ),
          },
          {
            name: "Count",
            renderCell: ({ count, id }) => (
              <Grid
                onClick={() => navigate(`/dashboard/${id}/missmatch`)}
                sx={{ cursor: "pointer" }}
              >
                {count ?? 1}
              </Grid>
            ),
          },
        ];

  const chartData = {
    labels: transactionData.transaction?.map((el) => el.time),
    datasets: [
      {
        fill: true,
        label: "Success",
        data: transactionData.transaction?.map((el) => el.success),
        backgroundColor: "#54B371",
      },
      {
        fill: true,
        label: "Mismatch",
        data: transactionData.transaction?.map((el) => el.mismatch),
        backgroundColor: "#EA9437",
      },
      {
        fill: true,
        label: "Error",
        data: transactionData.transaction?.map((el) => el.transaction_error),
        backgroundColor: "#DE5242",
        pointRadius: 0,
      },
    ],
  };

  const getMismatch = () => {
    Axios.get(`/dashboard/mismatch-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getError = () => {
    Axios.get(`/dashboard/error-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getTransaction = () => {
    Axios.get(
      `/dashboard/detail/${params.product}?merchant_code=${merchantCode.partner_Code}&bank_code=${bankCode.partner_Code}&date=${params.date}`
    ).then((res) => {
      setTransactionData(res?.data.data);
    });
  };

  useEffect(() => {
    getTransaction();
  }, [params.date, params.limit, params.page, bankCode, merchantCode]);

  useEffect(() => {
    if (activeTab === "Transaction Error") getError();
    else getMismatch();
  }, [activeTab, params]);

  useEffect(() => {
    (async () => {
      const iteration = [
        Axios.get("/product_partner/partner-type?partner_type_code=merchant"),
        Axios.get("/product_partner/partner-type?partner_type_code=principal"),
      ];

      const [merchantList, bankList] = await Promise.all(iteration);

      setMerchantList(merchantList.data.data);
      setBankList(bankList.data.data);
    })();
  }, []);

  function transactionDate() {
    if (!params.date) return "";
    const dateArr = params.date.split("-");
    dateArr.splice(1, 0, "01");
    return moment(dateArr.join("/")).format("MMMM YYYY");
  }

  return (
    <Grid sx={{ mt: "12px", p: "24px" }}>
      <Grid>
        <TitleWithBackButton
          title="Transaction Details"
          EndElement={
            <Grid sx={{ display: "flex" }}>
              <CustomInput.MonthPicker
                onChange={(val) => setParams({ ...params, date: val })}
              />
            </Grid>
          }
        />
      </Grid>
      <Grid
        display="flex"
        mt="24px"
        component={Paper}
        container
        p="32px"
        position="relative"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "16px",
            left: "32px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            "& h6": {
              m: 0,
              p: 0,
              fontWeight: 700,
              fontSize: "20px",
            },
            "& p": {
              m: 0,
              p: 0,
              fontSize: "16px",
              color: "#9AA2B1",
            },
          }}
        >
          <h6>Transaction</h6>
          <p>{transactionDate()}</p>
        </Grid>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                align: "end",
                position: "top",
                labels: {
                  // color: "#687083",
                },
              },
              title: {
                align: "start",
                position: "top",
                display: false,
                text: "Transaction",
                color: "#092540",
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#687083",
                  font: {
                    size: 14,
                  },
                },
              },
              y: {
                grid: {
                  display: true,
                  color: "#E4E7EB",
                },
                ticks: {
                  color: "#687083",
                  font: {
                    size: 12,
                  },
                },
              },
            },
          }}
        />
      </Grid>
      <Grid
        mt="24px"
        component={Paper}
        container
        sx={{
          "& h3": {
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px",
          },
        }}
      >
        <Grid
          container
          sx={{
            // padding: "24px",
            "& .active": {
              backgroundColor: "rgba(165, 189, 68, 0.1)",
              color: "#9AB825",
            },
          }}
        >
          <Grid
            container
            sx={{
              padding: "24px",
              "& h3": {
                margin: 0,
                fontSize: "28px",
              },
            }}
            justifyContent="end"
            alignItems="center"
          >
            <Grid display="flex" alignItems="center" gap="24px">
              <CustomInput.Select
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                placeholder="Semua Bank"
                sx={{ width: "180px" }}
              >
                <MenuItem value="">Semua Bank</MenuItem>
                {bankList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el.partner_name}
                  </MenuItem>
                ))}
              </CustomInput.Select>
              <CustomInput.Select
                value={merchantCode}
                onChange={(e) => setMerchantCode(e.target.value)}
                placeholder="Semua Merchant"
                sx={{ width: "180px" }}
              >
                <MenuItem value="">Semua Merchant</MenuItem>
                {merchantList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el.partner_name}
                  </MenuItem>
                ))}
              </CustomInput.Select>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%" }} />
          <Grid container mt="24px" p="24px">
            <Grid sx={{ marginBottom: "24px" }} container spacing="24px">
              <Grid item>
                <CardComponent
                  width="100%"
                  counter={`${transactionData.transaction_count}`}
                  desc="Transaction Count"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  width="100%"
                  counter={
                    "Rp" + transactionData.total_fee?.toLocaleString("id-ID")
                  }
                  desc="Total Fee"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  width="100%"
                  counter={
                    "Rp" + transactionData.total_amount?.toLocaleString("id-ID")
                  }
                  desc="Total Amount"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  width="100%"
                  counter={
                    "Rp" +
                    transactionData.total_settlement?.toLocaleString("id-ID")
                  }
                  desc="Total Settlement"
                />
              </Grid>
            </Grid>
            {["Transaction Error", "Transaction Mismatch"].map((el) => (
              <button
                key={el}
                type="button"
                onClick={() => setActiveTab(el)}
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  border: "none",
                  padding: "10px 20px",
                  fontWeight: 600,
                  fontSize: "14px",
                  background: activeTab === el ? "#F4FADC" : "transparent",
                  color: activeTab === el ? "#9AB825" : "#687083",
                }}
              >
                {el}
              </button>
            ))}
          </Grid>
        </Grid>

        <Grid
          sx={{
            pl: "24px",
            pr: "24px",
            mb: "24px",
          }}
          container
        >
          <TableComponent
            loading={loading}
            rows={rows || []}
            column={column}
            onPageChange={(val) => setParams({ ...params, page: val })}
            onRowsChange={({ target }) =>
              setParams({ ...params, limit: target.value, page: 1 })
            }
            limit={params?.limit}
            totalData={meta?.total}
            page={meta?.page}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        // color: "#687083",
      },
    },
    title: {
      display: true,
      text: "Transaction",
      color: "#092540",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#687083",
        font: {
          size: 14,
        },
      },
    },
    y: {
      grid: {
        display: true,
        color: "#E4E7EB",
      },
      ticks: {
        color: "#687083",
        font: {
          size: 12,
        },
      },
    },
  },
};
