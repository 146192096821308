import { Button, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  ModalDataFormat,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT, monthsArrayLong } from "../../config";

export default function DetailDataFormat() {
  const url = useLocation().pathname;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 1
      ? dataParams
      : {
          ...dataParams,
          sort: "-id",
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataProduct, setDataProduct] = useState({});

  useEffect(() => {
    if (search) {
      console.log(dataParams);
      getDataTable();
    }
  }, [search]);

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/product/detail/" + dataParams.id)
      .then((res) => setDataProduct(res.data.data))
      .then(() => {
        Axios.get(`/data_format/${dataParams.id}` + search).then((res) => {
          console.log(res);
          setMeta(res.data.meta);
          setRows(res.data.data || []);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
      });
  };

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  console.log(monthsArrayLong);

  const column = [
    {
      name: "Action",
      renderCell: (params) => {
        return (
          <Fragment>
            {loading ? (
              <Skeleton />
            ) : (
              <ActionButton
                onEdit={() => setOpen(params)}
                onDelete={() => handleDelete(params)}
              />
            )}
          </Fragment>
        );
      },
    },
    {
      name: "Data Format",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Last Update",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          `${
            monthsArrayLong[new Date(params.updated_at).getMonth()]
          }, ${new Date(params.updated_at).getDate()}, ${new Date(
            params.updated_at
          ).getFullYear()}, ${new Date(
            params.updated_at
          ).getHours()}:${new Date(params.updated_at).getMinutes()}`
        ),
    },
  ];

  const handleDelete = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/data_format/delete/${params.id}`).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success delete data`,
              icon: "success",
            });
            getDataTable();
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <TitleWithBackButton
            title={loading ? <Skeleton width={300} /> : dataProduct?.name}
            url="/data-format"
          />
        </Grid>

        <TableComponent
          rows={rows}
          column={column}
          onFilterTable
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create Data Format
            </Button>
          }
        />
      </Grid>

      {open && (
        <ModalDataFormat
          open={open}
          onClose={() => setOpen(false)}
          classes={classes}
          product_id={dataParams?.id}
          getDataTable={getDataTable}
        />
      )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    height: "100%",
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
  wrapper: {
    border: "1px solid #E4E7EB",
    backgroundColor: "white",
    padding: "24px",
    paddingTop: 0,
    borderRadius: "12px",
    boxShadow:
      "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
  },
  tableWrapper: {
    marginTop: "20px",
    "& h4, .link": { ...theme.typography.NeutralLabel },
    "& .link": {
      color: theme.colorSecondary,
      textDecoration: "none",
      marginTop: "8px",
      "&:hover": {
        cursor: "pointer",
      },
      "&:active": {
        color: theme.colorPrimary,
      },
    },
    "& h4": {
      marginBottom: "8px",
    },
  },
}));
