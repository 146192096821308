import CancelIcon from "@mui/icons-material/Cancel";
import HeightIcon from "@mui/icons-material/Height";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TitleWithBackButton } from "../../../components";

const TransactionMismatch = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        sx={(theme) => {
          return {
            ...theme.customContainer,
            "& h6": {
              m: 0,
              p: 0,
            },
            "& p": {
              m: 0,
              p: 0,
            },
            "& span": {
              m: 0,
            },
          };
        }}
      >
        <TitleWithBackButton title="Mismatch Detail : Transaction #ID-12734512643" />
        <div className={classes.wrapper}>
          <div>
            <div className={classes.header1}>
              <div className={classes.header1left}>
                <h6 className={classes.headerText}>#ID-12734512643</h6>
                <div
                  style={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    background: "#687083",
                  }}
                ></div>
                <h6 className={classes.headerText}>Feb 22, 2022 at 11:23</h6>
              </div>
              <span className={classes.badges}>
                <div
                  style={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    background: "#DE5242",
                  }}
                ></div>{" "}
                Mismatch
              </span>
            </div>
            <div className={classes.body1}>
              <h6 className={classes.bodyText}>JD.ID / BTN</h6>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HeightIcon
                  sx={{
                    rotate: "90deg",
                  }}
                />
              </Box>
              <h6 className={classes.bodyText}>Mastercard</h6>
              <div className={classes.verticalLine}></div>
              <div className={classes.errorValueWrapper}>
                <Grid display="flex" alignItems="center" gap="4px">
                  <h6 className={classes.bodyText}>1</h6>
                  <CancelIcon fontSize="small" color="error" />
                </Grid>
                <p className={classes.errorText}>Error</p>
              </div>
            </div>
          </div>
          <div>
            <Grid display="flex">
              <div className={classes.header2left}>Message</div>
              <div className={classes.header2Right}>Detail</div>
            </Grid>
            <Grid display="flex">
              <div className={classes.body2left}>
                Remote recon file did not found
              </div>
              <div className={classes.body2Right}>
                2022.02.08_recon-detail_mcsend_jdid_not-found-rmt.csv
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: "hidden",
    marginTop: "24px",
    width: "100%",
    border: "1px solid #E4E7EB",
    borderRadius: "8px",
    filter:
      "drop-shadow(0px 0.5px 2px rgba(65, 78, 98, 0.12)) drop-shadow(0px 0px 1px rgba(65, 78, 98, 0.05))",
  },
  header1: {
    padding: "8px",
    paddingLeft: "16px",
    border: "1px solid #E4E7EB",
    background: "#F5F6F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header1left: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: "#687083",
    fontWeight: "600",
    fontSize: "12px",
  },
  badges: {
    gap: "8px",
    fontSize: "14px",
    fontWeight: "500",
    padding: "2px 10px",
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid #F1A69E",
    color: "#A63124",
    background: "#FCF3F2",
    borderRadius: "12px",
  },
  body1: {
    padding: "24px",
    border: "1px solid #E4E7EB",
    background: "white",
    display: "flex",
    alignItems: "center",
    gap: "64px",
  },
  bodyText: {
    color: "#092540",
    fontWeight: "600",
    fontSize: "14px",
  },
  verticalLine: {
    height: "44px",
    width: "1px",
    background: "#E4E7EB",
  },
  errorValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  errorText: {
    color: "#687083",
    fontSize: "14px",
  },
  header2left: {
    width: "300px",
    background: "#F5F6F7",
    color: "#687083",
    fontWeight: "600",
    fontSize: "12px",
    padding: "11px 16px",
  },
  header2Right: {
    width: "100%",
    background: "#F5F6F7",
    color: "#687083",
    fontWeight: "600",
    fontSize: "12px",
    padding: "11px 16px",
  },
  body2left: {
    width: "300px",
    background: "white",
    color: "#092540",
    fontSize: "14px",
    padding: "18px 16px",
  },
  body2Right: {
    width: "100%",
    background: "white",
    color: "#092540",
    fontSize: "14px",
    padding: "18px 16px",
  },
}));

export default TransactionMismatch;
