import { Button, Grid, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useForm } from "react-hook-form";
import {
  CustomInput,
  InputComponent,
  ModalComponent,
} from "../../../components";

const ModalAddData = ({ open, onClose, getData, setDataParam }) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    onClose();
    setDataParam(data);
  };

  return (
    <ModalComponent open={open} onClose={onClose} title="Create Parameter">
      <Grid container>
        <Grid className={classes.modalWrapper}>
          <form id="create" onSubmit={handleSubmit(onSubmit)}>
            <InputComponent
              register={{ ...register("code", { required: true }) }}
              label="Parameter Code"
              id="code"
              error={Boolean(errors["code"])}
            />
            <InputComponent
              id="title"
              label="Title"
              register={{ ...register("title", { required: true }) }}
              error={Boolean(errors["title"])}
            />
            <InputComponent
              label="Description"
              id="description"
              register={{ ...register("description", { required: true }) }}
              error={Boolean(errors["description"])}
            />
            <CustomInput.Select
              register={{ ...register("data_type", { required: true }) }}
              label="Data Type"
              error={Boolean(errors["data_type"])}
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="integer">Integer</MenuItem>
            </CustomInput.Select>
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          type="submit"
          form="create"
          variant="contained"
          className={classes.button}
          fullWidth
        >
          Create
        </Button>
      </Grid>
    </ModalComponent>
  );
};

const useStyles = makeStyles({
  modalWrapper: {
    padding: "24px",
    paddingTop: "4px",
    width: "100%",
  },
  button: {
    backgroundColor: "#A0B842 !important",
    textTransform: "none !important",
    fontWeight: 600 + "!important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
});

export default ModalAddData;
