import { Button, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterPricing,
  ModalPricingPartner,
  TableComponent,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  FilterObject,
  ObjectToParams,
} from "../../../config";

export default function TablePricingPartner() {
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
  });
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [filterData, setFilterData] = useState({
    price_model_type_code: "",
    price_scheme_type_code: "",
  });
  const column = [
    {
      name: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={(e) => {
              e.stopPropagation();
              setOpen(params);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Partner Name",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params?.partner?.partner_name,
    },
    {
      name: "Product",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params?.product?.product_desc,
    },
    {
      name: "Pricing",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params?.price?.name,
    },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/partner_pricing/delete/${id}`).then((res) => {
          Swal.fire({
            icon: res.status === 200 ? "success" : "error",
            title: res.status === 200 ? "Success" : "Error",
            text: res.data.message || "Berhasil mengubah role menus",
          }).then(() => getDataTable());
        });
      }
    });
  };

  const getDataTable = useCallback(() => {
    setLoading(true);
    let filterParams = "&andFilter=";
    if (Object.values(filterData).some((value) => value)) {
      Object.entries(filterData).forEach((el) => {
        if (el[1]) filterParams += `${el[0]}:${el[1]},`;
      });
    }
    Axios.get(
      filterParams !== "&andfilter="
        ? `/partner_pricing?` + ObjectToParams(params) + filterParams
        : `/partner_pricing?` + ObjectToParams(params)
    ).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  }, [params, filterData]);

  useEffect(() => {
    getDataTable();
  }, [getDataTable]);

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        defaultValueSearch={params?.search}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterPricing
                onApply={(value) => {
                  // setParams({ ...params, ...FilterObject(value) });
                  setFilterData({ ...filterData, ...FilterObject(value) });
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setFilterData({
                    price_model_type_code: "",
                    price_scheme_type_code: "",
                  });
                  setOpenFilter(false);
                }}
                filters={{
                  price_model_type_code: filterData.price_model_type_code,
                  price_scheme_type_code: filterData.price_scheme_type_code,
                }}
              />
            </CustomButton.Filter>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Assign Pricing
            </Button>
          </Grid>
        }
      />
      {open && (
        <ModalPricingPartner
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
          dataPartner={rows}
        />
      )}
    </Fragment>
  );
}
