import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Chip, Grid, MenuItem, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  BasicButton,
  CustomInput,
  ModalCurrency,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";

export default function Currency() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          sort: "-id",
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
          andFilter: "",
        }
  );
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();

  const column = [
    {
      name: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={(e) => {
              e.stopPropagation();
              setOpen(params);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Currency Code",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params.currency_code,
    },
    {
      name: "Name",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params.currency_desc,
    },
    {
      name: "Status",
      renderCell: ({ is_active_flag }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active_flag ? "Active" : "Inactive"}
            color={is_active_flag ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/currency/delete/${id}`).then((res) => {
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Berhasil mengubah role menus",
          }).then(() => getDataTable());
        });
      }
    });
  };

  const getDataTable = (filterStatus = "", filterPartnerType = "") => {
    setLoading(true);
    Axios.get(`/currency${search}`).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);
  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Currency" hiddenBackButton />
      <Grid mt="28px">
        <TableComponent
          rows={rows}
          column={column}
          defaultValueSearch={dataParams?.search}
          value={params.active}
          filterComponent={
            <Grid width={"200px"}>
              <CustomInput.Select
                placeholder="Status"
                onChange={(e) =>
                  setParams({ ...params, andFilter: e.target.value })
                }
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"is_active_flag:true"}>Active</MenuItem>
                <MenuItem value={"is_active_flag:false"}>Non Active</MenuItem>
              </CustomInput.Select>
            </Grid>
          }
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <BasicButton
              onClick={() => setOpen(true)}
              label="Create Currency"
            />
          }
        />
        {open && (
          <ModalCurrency
            openModal={open}
            closeModal={() => setOpen(false)}
            getDataTable={getDataTable}
            dataPartner={rows}
          />
        )}
      </Grid>
    </Grid>
  );
}
