import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ListRecon from "../../components/Global/ListRecon";
import TitleWithBackPage from "../../components/Global/TitleWithBackButton";
import TableMissmatchError from "../../components/Table/TableMissmatchError";

const DashboardMissmatchError = () => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <TitleWithBackPage title="Error / Mismatch: Transaction #ID-12734512643" />
        <br />
        <ListRecon Loading={Loading} accessFrom={"mismatch"} />

        {/* table */}
        <Grid
          xs={12}
          className={classes.ListWrapper}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <TableMissmatchError />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((a, b) => ({
  ListRoot: {
    borderRadius: "6px",
    marginTop: "28px",
    "& h4": {
      padding: "11px",
      margin: 0,
      color: "#687083",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      margin: 0,
      color: "black",
      display: "flex",
      marginBottom: "4px",
      marginTop: "5px",
      alignItems: "center",
      "& img": {
        padding: 0,
        marginLeft: "4px",
      },
    },
    "& p": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      padding: 0,
      margin: 0,
      marginTop: "5px",
    },
  },
  ListWrapper: {
    marginTop: "20px",
    backgroundColor: "#F9FAFB",
    borderRadius: "6px 6px 0px 0px",
    border: "1px solid #E4E7EB",
    borderBottom: "none",
  },
}));

export default DashboardMissmatchError;
