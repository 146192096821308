import {
  Button,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  PieChart,
  TableTransactionMismatch,
  TitleWithBackButton,
} from "../../components";
import { Axios, currencyFormat } from "../../config";

export default function DashboardDetail(props) {
  const state = useLocation();
  const [Loading, setLoading] = useState(true);
  const [buttonActive, setButtonActive] = useState("Transaction Mismatch");
  const [transactionCount, setTransactionCount] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalSettelment, setTotalSettelment] = useState(0);
  const [dataCount, setDataCount] = useState([]);
  const [chartData, setChartData] = useState(null);

  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  const getDetail = async () => {
    setLoading(true);

    await Axios.get(
      `/dashboard/monitoring/detail/` +
        state.state.data.rns_process_result_summary_history_id
    )
      .then((res) => {
        if (res.data) {
          setTransactionCount(res.data.data.transaction_count);
          setTotalFee(res.data.data.total_fee);
          setTotalSettelment(res.data.data.total_sattlement);

          const newDataCount = [
            {
              count: res.data.data.transaction_success,
              desc: "Success",
              color: "#34774C",
            },

            {
              count: res.data.data.transancation_mismatch,
              desc: "Mismatch",
              color: "#F39C12",
            },
          ];
          setDataCount(newDataCount);

          let newChartData = {
            labels: [],
            datasets: [
              {
                label: "# of Votes",
                data: [
                  res.data.data.transaction_success,
                  res.data.data.transancation_mismatch,
                ],
                backgroundColor: ["#54B371", "#EA9437"],
              },
            ],
          };
          setChartData(newChartData);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const formatNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton
        title={state.state.data.merchant.partner_name}
        EndElement={<Grid sx={{ display: "flex" }}></Grid>}
      />
      <Grid
        container
        sx={{
          padding: "24px 0",
          "& .active": {
            backgroundColor: "rgba(165, 189, 68, 0.1)",
            color: "#9AB825",
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            padding: "24px",
            minWidth: "352px",
            width: "100%",
            display: "flex",
          }}
        >
          <Grid sx={{ width: "33%", padding: "0 3%" }}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "30px", color: "#9AB825" }}
            >
              {formatNumberWithCommas(transactionCount)}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#687083", marginTop: "16px" }}
            >
              Transaction Count
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: "33%",
              padding: "0 3%",
              borderLeft: "1px solid #E4E7EB",
            }}
          >
            <Typography
              sx={{ fontWeight: "600", fontSize: "30px", color: "#9AB825" }}
            >
              {currencyFormat(totalFee)}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#687083", marginTop: "16px" }}
            >
              Total Fee
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: "33%",
              padding: "0 3%",
              borderLeft: "1px solid #E4E7EB",
            }}
          >
            <Typography
              sx={{ fontWeight: "600", fontSize: "30px", color: "#9AB825" }}
            >
              {currencyFormat(totalSettelment)}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", color: "#687083", marginTop: "16px" }}
            >
              Total Settlement
            </Typography>
          </Grid>
        </Card>
      </Grid>
      {Loading ? (
        <Grid sx={{ width: "100%", textAlign: "center", padding: 10 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <PieChart dataCount={dataCount} chartData={chartData} />
      )}
      <Grid mt="24px" display="flex" gap="12px">
        {["Transaction Mismatch"].map((res, i) => (
          <Button
            variant="text"
            onClick={() => setButtonActive(res)}
            sx={{
              color: buttonActive === res ? "#9AB825" : "#687083",
              backgroundColor:
                buttonActive === res && "rgba(165, 189, 68, 0.1)",
            }}
            key={i}
          >
            {res}
          </Button>
        ))}
      </Grid>

      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        {buttonActive === "Transaction Mismatch" && (
          <Fade in={true} timeout={600}>
            <Grid container>
              <TableTransactionMismatch
                id={state.state.data.rns_process_result_summary_history_id}
              />
            </Grid>
          </Fade>
        )}
      </Grid>
      {/* <Grid mt={'24px'}>
        <Grid
          component={Paper}
          container
          sx={{
            '& button': {
              textTransform: 'none',
              color: 'black',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
            },
            '& h3': {
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '30px',
            },
          }}
        >
          <Grid
            container
            display="inline"
            sx={{
              padding: '10px 24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2>
              Error / Mismatch <span style={{ color: '#687083', fontWeight: '400' }}>(248)</span>
            </h2>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon sx={{ color: '#9AA2B1' }} />}
              sx={{
                color: 'black',
                fontWeight: 600,
                fontSize: '14px',
                height: '40px',
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                border: '1px solid #D1D5DC',
              }}
              id="composition-button"
            >
              Download CSV
            </Button>
          </Grid>

          <Divider sx={{ width: '100%' }} />
          <Grid
            container
            sx={{
              padding: '24px',
              paddingTop: '10px',
              '& .active': {
                backgroundColor: 'rgba(165, 189, 68, 0.1)',
                color: '#9AB825',
              },
            }}
          ></Grid>
          {[1, 2, 3, 4].map((res, i) => {
            return (
              <Grid
                sx={{
                  pl: '24px',
                  pr: '24px',
                  mb: '24px',
                }}
                container
              >
                <ListRecon
                  onRowsClick={() => navigate('/dashboard/error')}
                  data={res}
                  Loading={Loading}
                />
              </Grid>
            );
          })}
          <Toolbar
            sx={{
              paddingLeft: '0px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              color: '#687083',
              width: '100%',
            }}
          >
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Limit
                <FormControl fullWidth sx={{ ml: 1, width: '97px', mr: 1 }}>
                  <Select
                    size='small'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={params.limit}
                    onChange={(e) =>
                      setParams({ ...params, limit: e.target.value })
                    }
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                  </Select>
                </FormControl>
                entry
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                justifyContent='flex-end'
              >
                Show {params.limit * (params.page - 1) + 1}-
                {params.limit * params.page > params.totalData
                  ? params.totalData
                  : params.limit * params.page}{' '}
                from {params.totalData}
                <IconButton
                  disabled={params.page === 1}
                  onClick={() =>
                    setParams({ ...params, page: params.page - 1 })
                  }
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  disabled={
                    params.limit * params.page > params.totalData ? true : false
                  }
                  onClick={() =>
                    setParams({ ...params, page: params.page + 1 })
                  }
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid> */}
    </Grid>
  );
}

const useStyles = makeStyles({
  wrapper: {
    "& .header": {
      backgroundColor: "white",
      boxShadow:
        "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
      "& h1": {
        margin: 0,
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    "& buton": {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
