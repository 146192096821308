import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CustomInput, ModalComponent } from "../../../components";
import { Axios } from "../../../config";

const ModalTypeColumn = ({ open, onClose, dataFormatId, getDataDetail }) => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (open?.id) {
      setValue("column_header", open?.column_header);
      setValue("rule_expression", open?.rule_expression);
    }
  }, []);

  const onSubmit = (values) => {
    onClose();
    if (open.id) {
      Axios.put(
        `/data_format/${dataFormatId}/trans_condition/update/${open?.id}`,
        values
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataDetail());
      });
    } else {
      Axios.post(
        `/data_format/${dataFormatId}/trans_condition/create`,
        values
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataDetail());
      });
    }
  };

  return (
    <ModalComponent open={Boolean(open)} onClose={onClose}>
      <Grid
        conteiner
        style={{
          maxHeight: "90vh",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>{open?.id ? "Edit" : "Add"} Row</h3>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr />
        <form action="" id="add-type-column" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            sx={{
              padding: "24px",
              paddingTop: "4px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <CustomInput
              register={{ ...register("column_header") }}
              label="Column Header"
              required
            />
            <CustomInput
              register={{ ...register("rule_expression") }}
              label="Column Position"
              required
            />
          </Grid>
        </form>
        <hr />
        <Grid sx={{ padding: "20px" }}>
          <Button
            type="submit"
            form="add-type-column"
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
            fullWidth
          >
            {open?.id ? "Edit" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default ModalTypeColumn;
