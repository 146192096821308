import { Grid } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  ActionButton,
  ModalTypeColumn,
  TableComponent,
} from "../../../components";
import { Axios } from "../../../config";

export default function TableTypeAmount({ dataFormat, getDataDetail }) {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/data_format/${dataFormat?.id}/column/delete/${id}`).then(
          (res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            }).then(() => getDataDetail());
          }
        );
      }
    });
  };

  return (
    <Grid>
      <h4>Record Data Format Transaction Amount Condition</h4>
      <TableComponent
        rows={dataFormat?.data_format_settle_trans_amount_condition || []}
        column={[
          {
            name: "Action",
            renderCell: (params) => (
              <ActionButton
                onEdit={(e) => {
                  setOpen(params);
                }}
                onDelete={(e) => {
                  handleDelete(params.id);
                }}
              />
            ),
          },
          {
            name: "Column Header",
            renderCell: (params) => params.column_header,
          },
          {
            name: "Rule Expression",
            renderCell: (params) => params.rule_expression,
          },
        ]}
      />
      <h4 className="link" onClick={() => setOpen(true)}>
        + Add New Row
      </h4>
      {open && (
        <ModalTypeColumn
          open={open}
          onClose={onClose}
          dataFormatId={dataFormat?.id}
          getDataDetail={getDataDetail}
        />
      )}
    </Grid>
  );
}
