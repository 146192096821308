import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Axios } from "../../config/helper";
import SelectFilterComponent from "../Global/SelectFilterComponent";

const FilterUserInternalEksternal = ({ setSelectedFilter }) => {
  const [dataRole, setDataRole] = useState({
    loading: true,
    data: [],
  });
  const [selectedFilterRole, setSelectedFilterRole] = useState([]);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState([]);
  const dataStatus = {
    loading: false,
    data: [
      { id: "true", name: "Active", value: "true" },
      { id: "false", name: "Inactive", value: "false" },
    ],
  };
  const getListRole = async () => {
    await Axios.get(`/role`).then((res) => {
      const filterData = res.data?.data?.filter((data) => {
        if (data?.name?.indexOf("Finpoint") === -1) return data;
      });
      setDataRole({
        loading: false,
        data: filterData || [],
      });
    });
  };

  useEffect(() => {
    getListRole();
  }, []);

  const handleApply = () => {
    // console.log('selectedFilterRole', selectedFilterRole)
    // console.log('selectedFilterStatus', selectedFilterStatus)
    setSelectedFilter({
      roles: selectedFilterRole,
      status: selectedFilterStatus?.value ?? "",
    });
  };
  const handleReset = () => {
    if (selectedFilterRole.length > 0 || selectedFilterStatus.length > 0) {
      setSelectedFilter({
        roles: [],
        status: "",
      });
    }
    setSelectedFilterRole([]);
    setSelectedFilterStatus([]);
  };

  return (
    <Box
      sx={{
        paddingBottom: "15px",
        paddingLeft: "20px",
        paddingRight: "20px",
        zIndex: 999,
      }}
    >
      <h4 style={{ paddingTop: "15px" }}>Filter</h4>
      <SelectFilterComponent
        dataFilter={dataRole}
        multipleValue
        filterPlaceholder={"Role"}
        selectedFilter={selectedFilterRole}
        setSelectedFilter={setSelectedFilterRole}
      />
      <div style={{ height: "10px" }} />
      <SelectFilterComponent
        dataFilter={dataStatus}
        selectedFilter={selectedFilterStatus}
        filterPlaceholder={"Status"}
        setSelectedFilter={setSelectedFilterStatus}
      />
      <div style={{ height: "20px" }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => handleReset()}
          variant="outlined"
          sx={{
            color: "#A0B842",
            borderColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            marginRight: "5px",
            minWidth: "90px",
            "&:hover": {
              borderColor: "#A0B842",
            },
          }}
        >
          Reset
        </Button>
        <Button
          onClick={() => handleApply()}
          variant="contained"
          disableElevation
          sx={{
            backgroundColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            minWidth: "90px",
            "&:hover": {
              backgroundColor: "#A0B842",
            },
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default FilterUserInternalEksternal;
