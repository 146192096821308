import { Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableComponent, TitleWithBackButton } from "../../components";
import { Axios } from "../../config/helper";

export default function PartnerType() {
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [loading, setLoading] = useState(false);

  const column = [
    {
      name: "Type",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params?.partner_type_code,
    },
    {
      name: "Parent?",
      renderCell: (params) =>
        loading ? (
          <Skeleton type="text" />
        ) : loading ? (
          <Skeleton type="text" />
        ) : params?.parent_partner_type?.partner_type_code ? (
          params?.parent_partner_type?.partner_type_code
        ) : (
          "-"
        ),
    },
    {
      name: "Description",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params?.partner_type_desc,
    },
  ];

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/partner-type?offset=1&status=1&limit=20&page=0").then((res) => {
      setTimeout(() => {
        setRows(res.data.data);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    getDataTable();
  }, []);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton hiddenBackButton title="Partner Type" />
      <Grid mt="24px">
        <TableComponent column={column} rows={rows} />
      </Grid>
    </Grid>
  );
}
