import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";
import { Chip } from "@mui/material";
import React from "react";

function CustomChip(props) {
  const error = props.color === "error";
  return (
    <Chip
      icon={error && <NoiseControlOffIcon sx={{ width: "16px" }} />}
      sx={{
        fontWeight: error && 500,
        backgroundColor: error && "#FCF3F2",
      }}
      {...props}
    />
  );
}

export default CustomChip;
