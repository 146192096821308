import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Colors, FinpointLogo, LoginImage } from "../../assets";
import { CardComponent, DialogForOTP, DialogForQR } from "../../components";
import useLogin from "../../hooks/Auth/useLogin";

export default function Login() {
  const classes = useStyles();
  const {
    loading,
    otpLoading,
    resetLoading,
    openDialog,
    error,
    qrImage,
    body,
    responseError,
    closeVerificationDialog,
    openTypeDialog,
    handleLogin,
    handleOtp,
    handleChange,
    handleReset,
  } = useLogin();

  const { typeOTP, typeQR } = openDialog;

  return (
    <Grid container className={classes.root}>
      <DialogForQR
        openOTP={openTypeDialog}
        isOpen={typeQR}
        onClose={() => closeVerificationDialog("typeQR")}
        qrImage={qrImage}
      />
      <DialogForOTP
        isOpen={typeOTP}
        onClose={() => closeVerificationDialog("typeOTP")}
        onSubmit={handleOtp}
        onReset={handleReset}
        loading={otpLoading}
        resetLoading={resetLoading}
      />
      <Grid item md={6} className={classes.leftContainer}>
        <Grid className={classes.leftContent}>
          <Grid className={classes.logoContainer}>
            <img src={FinpointLogo} alt="" />
          </Grid>
          <Grid className={classes.cardContainer}>
            <CardComponent
              fullWidth
              boxShadow="0px 12px 24px rgba(0, 0, 0, 0.05)"
            >
              <Grid className={classes.cardContent}>
                <h5>Sign In</h5>
                <Grid className={classes.formContainer}>
                  {error.length > 0 && (
                    <Alert severity="error">
                      <ul>
                        {(error || []).map((res, index) => (
                          <li key={index}>{res.message}</li>
                        ))}
                      </ul>
                    </Alert>
                  )}
                  {(responseError?.name || responseError?.message) && (
                    <Alert severity="error">
                      {(responseError?.response?.data ||
                        responseError?.message) && (
                        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                          <li>
                            {responseError?.response?.data?.data?.status_desc ||
                              responseError?.message}
                          </li>
                        </ul>
                      )}
                    </Alert>
                  )}
                </Grid>
                <Grid className={classes.form}>
                  <form onSubmit={handleLogin}>
                    <TextField
                      label="Username or Email Address"
                      id="email"
                      variant="outlined"
                      onChange={handleChange}
                      disabled={loading}
                      required
                      fullWidth
                    />
                    <Grid className={classes.inputContainer}>
                      <TextField
                        label="Password"
                        type="password"
                        id="password"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={loading}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid className={classes.forgotPassword}>
                      <a href="/forgot-password">Forgot Password?</a>
                    </Grid>
                    <Button
                      className={classes.customButton}
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={30} sx={{ color: "white" }} />
                      ) : (
                        "Sign In"
                      )}
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </CardComponent>
            <Grid className={classes.signupText}>
              <p>
                Don’t have an account yet? <a href="/sign-up">Sign Up here</a>
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.rightImageContainer}>
        <img src={LoginImage} alt="" className={classes.rightImage} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#F7F9FC",
    overflow: "hidden",
  },
  leftContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  leftContent: {
    width: "100% ",
    padding: "20px",
    height: "100%",
    paddingTop: "36px",
    marginRight: "172px",
    marginLeft: "172px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  logoContainer: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  cardContainer: {
    height: "100% !important",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContent: {
    textAlign: "center",
    "& h5 ": {
      fontWeight: "700 !important",
      fontSize: "20px !important",
      lineHeight: "24px !important",
      color: "black !important",
    },
  },
  formContainer: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    "& ul, li": {
      margin: 0,
      textAlign: "left",
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    marginTop: theme.spacing(2.0),
    marginBottom: theme.spacing(1.5),
  },
  forgotPassword: {
    textAlign: "right",
    marginBottom: theme.spacing(1.5),
    "& a": {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      color: Colors.SECONDARY,
      textDecoration: "none",
      "&:hover": {
        color: Colors.SECONDARY_DARK,
        textDecoration: "underline",
      },
    },
  },
  customButton: {
    height: "48px !important",
    backgroundColor: Colors.SECONDARY + "!important",
    "&:hover": {
      backgroundColor: Colors.SECONDARY_DARK + "!important",
    },
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    position: "relative !important",
  },
  signupText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    "& a": {
      color: Colors.SECONDARY,
      textDecoration: "none",
      fontWeight: 700,
    },
    "&:hover a": {
      color: Colors.SECONDARY_DARK,
      textDecoration: "underline",
    },
  },
  rightImageContainer: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rightImage: {
    height: "200%",
    borderRadius: "50% 0% 0% 50%",
    marginTop: "-50%",
    objectFit: "contain",
    width: "100%",
  },
}));
