import React from "react";
import { getUserRole } from "../../../config/cookies";
import DailyReconciliation from "./DailyReconciliation";
import DailyReconciliationFBA from "./DailyReconciliationFBA";

function DailyReconHandler() {
  const role = getUserRole();

  if (role === "FBA") {
    return <DailyReconciliationFBA />;
  } else {
    return <DailyReconciliation />;
  }
}

export default DailyReconHandler;
