import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

const DialogForQR = ({ isOpen, onClose, qrImage, openOTP }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  const closeDialog = () => {
    onClose();
    setImageLoading(true);
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={closeDialog}>
      <DialogTitle>Scan QR Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Scan the QR code with Google Authenticator
        </DialogContentText>
        <Box
          maxWidth="80%"
          py={3}
          px={5}
          justifyContent="center"
          display="flex"
        >
          {imageLoading && <CircularProgress />}
          <img
            src={qrImage}
            alt="qr"
            style={{
              width: "100%",
              display: imageLoading ? "none" : "block",
            }}
            onLoad={handleImageLoaded}
          />
        </Box>
        <DialogContentText>
          Already scanned the QR code?
          <Button onClick={() => openOTP("typeQR", "typeOTP")} size="small">
            Enter the OTP
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DialogForQR;
