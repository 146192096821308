import { Grid, Skeleton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CustomButton,
  FilterInvoice,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT, FilterObject } from "../../config";

function Billing() {
  const [openFilter, setOpenFilter] = useState(false);
  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    billing_cycle_type: "",
    status: "",
  });
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([1, 2, 3, 4, 5]);

  const column = [
    {
      name: "Invoice Number",
      renderCell: ({ invoice_no, id }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Link
            to={"/invoice/" + id}
            style={{ textDecoration: "none", color: "black" }}
          >
            {invoice_no}
          </Link>
        ),
    },
    {
      name: "Invoice Date",
      renderCell: ({ invoice_date }) => (
        <Grid>
          {moment(invoice_date).format("DD MMM YYYY")}
          <Grid
            sx={{
              color: "#687083",
            }}
          >
            {moment(invoice_date).format("hh:mm:ss")}
          </Grid>
        </Grid>
      ),
    },
    {
      name: "Billing Cycle Type",
      renderCell: ({ billing_cycle_type_flag }) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : billing_cycle_type_flag === "D" ? (
            "Daily"
          ) : billing_cycle_type_flag === "M" ? (
            "Monthly"
          ) : (
            "Yearly"
          )}
        </Grid>
      ),
    },
    {
      name: "Billing Cycle Date",
      renderCell: ({ billing_cycle_date }) => (
        <Grid>
          {moment(billing_cycle_date).format("DD MMM YYYY")}
          <Grid
            sx={{
              color: "#687083",
            }}
          >
            {moment(billing_cycle_date).format("hh:mm:ss")}
          </Grid>
        </Grid>
      ),
    },
    {
      name: "Last Billing Cycle Date",
      renderCell: ({ billing_cycle_date }) => (
        <Grid>
          {moment(billing_cycle_date).format("DD MMM YYYY")}
          <Grid
            sx={{
              color: "#687083",
            }}
          >
            {moment(billing_cycle_date).format("hh:mm:ss")}
          </Grid>
        </Grid>
      ),
    },
    {
      name: "Include Fee Flag",
      renderCell: ({ include_fee_flag }) =>
        include_fee_flag === "T" ? `True` : "False",
    },
    {
      name: "Include Amount Flag",
      renderCell: ({ include_amount_flag }) =>
        include_amount_flag === "T" ? `True` : "False",
    },
    {
      name: "Contract End Date",
      renderCell: ({ contract_end_date }) => (
        <Grid>
          {moment(contract_end_date).format("DD MMM YYYY")}
          <Grid
            sx={{
              color: "#687083",
            }}
          >
            {moment(contract_end_date).format("hh:mm:ss")}
          </Grid>
        </Grid>
      ),
    },
    {
      name: "Status",
      renderCell: ({ is_active_flag }) =>
        is_active_flag ? "Active" : "Inactive",
    },
  ];

  const getDataTable = () => {
    setLoading(true);
    Axios.get(
      `/invoice?search=${params.search}&offset=${params.offset}&limit=${params.limit}&page=${params.page}&billing_cycle_type=${params.billing_cycle_type}&status=${params.status}`
    ).then((res) => {
      setMeta(res.data.meta);
      setRows(res.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    getDataTable();
  }, [params]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Invoice" hiddenBackButton />

      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px" position="relative">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterInvoice
                onApply={(value) => {
                  setParams({ ...params, ...FilterObject(value) });
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({
                    ...params,
                    billing_cycle_type: "",
                    status: "",
                  });
                  setOpenFilter(false);
                }}
                filters={{
                  billing_cycle_type: params.billing_cycle_type,
                  status: params.status,
                }}
              />
            </CustomButton.Filter>
          </Grid>
        }
      />
    </Grid>
  );
}

export default Billing;
