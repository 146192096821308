import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { ListMenuSidebar } from "../../assets/icons/CustomIcon";
import { CustomInput, InputComponent, ModalComponent } from "../../components";
import { Axios } from "../../config";

export default function MenuModal({
  openModal,
  closeModal,
  getMenu,
  dataEdit,
}) {
  const classes = useStyles();
  const [parent, setParent] = useState(
    dataEdit ? (dataEdit?.parent_id === 0 ? true : false) : false
  );
  const [rows, setRows] = useState([]);
  const [bodyMenu, setBodyMenu] = useState(
    dataEdit || {
      title: "",
      description: "",
      url: "",
      parent_id: 0,
      icon_id: 0,
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const getMenuFunction = () => {
    Axios.get("/menu")
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
        }
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getMenuFunction();
    if (dataEdit?.id) {
      setValue("title", dataEdit.title);
      setValue("parent_id", dataEdit.parent_id);
      setValue("description", dataEdit.description);
      setValue("url", dataEdit.url);
    }
  }, []);

  const handleEditMenu = () => {
    Axios.put(`/menu/update/${bodyMenu.id}`, {
      title: bodyMenu.title,
      description: bodyMenu.description,
      url: bodyMenu.url,
      parent_id: dataEdit.parent_id,
      icon_id: bodyMenu.icon_id,
    })
      .then((res) => {
        if (res.status === 200) {
          closeModal();
          Swal.fire({
            title: "Success",
            text: res.data.message || `Success edit menu`,
            icon: "success",
          }).then(() => getMenu());
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (val) => {
    let body = parent
      ? {
          title: val.title,
          description: val.description,
          url: val.url,
          parent_id: 0,
          icon_id: +val.icon_id,
        }
      : {
          title: val.title,
          description: val.description,
          url: val.url,
          parent_id: val.parent_id,
          icon_id: 0,
        };
    if (dataEdit?.id) {
      Axios.put(`/menu/update/${dataEdit?.id}`, body)
        .then((res) => {
          if (res.status === 200) {
            closeModal();
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success update menu`,
              icon: "success",
            }).then(() => getMenu());
          }
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post("/menu/create", body)
        .then((res) => {
          if (res.status === 200) {
            closeModal();
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success add new menu`,
              icon: "success",
            }).then(() => getMenu());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ModalComponent open={openModal} onClose={closeModal}>
      <Grid className={classes.ModalWrapper} conteiner>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>{dataEdit?.id ? "Update Menu" : "Create Menu"}</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid sx={{ padding: "24px", paddingTop: "4px" }}>
          <Grid
            sx={{
              "& h4": {
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "6px",
              },
            }}
          >
            <h4>Level</h4>
            <FormControl
              disabled={dataEdit?.id}
              onChange={(a) => {
                setParent(Boolean(Number(a.target.value)));
              }}
            >
              <RadioGroup row value={parent ? 1 : 0}>
                <FormControlLabel
                  value={1}
                  control={<Radio size="small" />}
                  label="Parent"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio size="small" />}
                  label="Child"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <form action="" id="modal_menu" onSubmit={handleSubmit(onSubmit)}>
            <Collapse in={!parent}>
              <CustomInput.Select
                label="Parent"
                register={{ ...register("parent_id", { required: !parent }) }}
                error={Boolean(errors["parent_id"])}
                value={watch("parent_id") || dataEdit?.parent_id}
              >
                {rows
                  .filter((res) => res.parent_id === 0)
                  .map((val) => {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.title}
                      </MenuItem>
                    );
                  })}
              </CustomInput.Select>
            </Collapse>
            <InputComponent
              label="Menu Title"
              id="title"
              register={{ ...register("title", { required: true }) }}
              error={Boolean(errors["title"])}
            />
            <InputComponent
              label="Description"
              id="description"
              multiline
              value={watch("description")}
              register={{ ...register("description", { required: true }) }}
              error={Boolean(errors["description"])}
            />
            <InputComponent
              label="URL"
              id="url"
              value={watch("url")}
              register={{ ...register("url", { required: true }) }}
              error={Boolean(errors["url"])}
            />
            <Collapse in={parent}>
              <CustomInput.Select
                label="Default Icon"
                register={{ ...register("icon_id", { required: parent }) }}
                value={watch("icon_id") || dataEdit?.icon_id}
                error={Boolean(errors["icon_id"])}
                sx={{
                  "& .MuiInputBase-inputSizeSmall": {
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    "& svg": {
                      paddingRight: "8px",
                      marginTop: "2px",
                    },
                  },
                }}
              >
                {ListMenuSidebar.map((val) => {
                  return (
                    <MenuItem
                      value={val.id}
                      sx={{ "& span": { marginRight: "12px" } }}
                    >
                      <span className="icon-custom">{val.icon}</span>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </CustomInput.Select>
            </Collapse>
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          type="submit"
          form="modal_menu"
          // onClick={bodyMenu.id ? handleEditMenu : handleAddMenu}
          sx={(theme) => theme.buttonGreen}
          fullWidth
        >
          {dataEdit?.id ? "Update Menu" : "Create Menu"}
        </Button>
      </Grid>
    </ModalComponent>
  );
}

const useStyles = makeStyles({
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
