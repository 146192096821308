import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, Chip, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ActionButton, TableComponent, UserModal } from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";
import FilterUserInternalEksternal from "../../Filter/FilterUserInternalEksternal";
export default function TableDetailPartnerUser({ id = "" }) {
  const dataParams = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [params, setParams] = useState({
    sort: "-id",
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    id: id,
  });

  const [filterValue, setFilterValue] = useState({
    roles: [],
    status: null,
  });
  useEffect(() => {
    getDataTable();
  }, [params, filterValue]);

  const getDataTable = () => {
    setLoading(true);
    const filterPayload = {
      ...filterValue,
      roles: filterValue?.roles?.map((el) => el.id),
    };
    Axios.get(
      `/user/${params?.id}?` + ObjectToParams({ ...params, ...filterPayload })
    ).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  const columns = [
    {
      name: "Action",
      renderCell: (params) => (
        <ActionButton
          onEdit={(e) => {
            e.stopPropagation();
            setOpen(params);
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(params.id);
          }}
        />
      ),
    },
    {
      name: "Name",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Role",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          params?.user_role?.map((val) => val.name.split(", "))
        ),
    },
    {
      name: "Email",
      renderCell: (params) => (loading ? <Skeleton /> : params.email),
    },
    {
      name: "Status",
      renderCell: ({ is_active }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active ? "Active" : "Inactive"}
            color={is_active ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/user/delete/${id}`).then((res) => {
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Berhasil mengubah role menus",
          }).then(() => getDataTable());
        });
      }
    });
  };

  return (
    <>
      <TableComponent
        rows={rows}
        column={columns}
        defaultValueSearch={params?.search}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        onFilterTable
        multipleFilterComponent={
          <FilterUserInternalEksternal setSelectedFilter={setFilterValue} />
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create User
            </Button>
          </Grid>
        }
      />
      {open && (
        <UserModal
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
          partnerId={params?.id}
        />
      )}
    </>
  );
}
