import Cookie from "js-cookie";

export function getToken() {
  const tokens = Cookie.get("token");
  return tokens;
}

export function getUser() {
  return JSON.parse(Cookie.get("data-user"));
}

export function setToken(data) {
  Cookie.set("token", data);
}

export function setUser(data) {
  Cookie.set("data-user", JSON.stringify(data));
}

export function setUserRole(data) {
  Cookie.set("user-role", JSON.stringify(data));
}

export function getUserRole() {
  return JSON.parse(Cookie.get("user-role"));
}

export function getRole() {
  const user = getUser();
  if (user) {
    return user.roleID;
  }
  return null;
}
export function logoutCookie() {
  Cookie.remove("user-role", { path: "/" });
  Cookie.remove("data-user", { path: "/" });
  Cookie.remove("token", { path: "/" });
}
