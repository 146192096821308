import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Chip, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  BasicButton,
  CustomButton,
  FilterPartner,
  ModalPartner,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
  URL_API,
} from "../../config";

export default function Partner() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          sort: "-id",
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const column = [
    {
      name: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={(e) => {
              e.stopPropagation();
              setOpen(params);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Partner Name",
      renderCell: (params) =>
        loading ? (
          <Skeleton type="text" />
        ) : (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              "& img": {
                height: "24px",
              },
            }}
          >
            {" "}
            <img src={`${URL_API}/public/${params.logo}`} alt="" />{" "}
            <Grid
              sx={{
                border: "1px solid #E4E7EB",
                height: "20px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            />
            <p>{params.partner_name}</p>
          </Grid>
        ),
    },
    {
      name: "Product",
      renderCell: (params) =>
        loading ? <Skeleton type="text" /> : params.total_product,
    },
    {
      name: "Status",
      renderCell: ({ is_active_flag }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active_flag ? "Active" : "Inactive"}
            color={is_active_flag ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/partner/delete/${id}`).then((res) => {
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Berhasil mengubah role menus",
          }).then(() => getDataTable());
        });
      }
    });
  };

  const getDataTable = () => {
    setLoading(true);
    const filterParams = `&andFilter=${
      filterValue?.partner_type
        ? "partner_type_id:" + filterValue?.partner_type + ","
        : ""
    }${
      typeof filterValue?.status === "boolean"
        ? "is_active_flag:" + filterValue.status
        : ""
    }`;
    const checkParamsFilter = Object.values(filterValue).every(
      (el) => !el && el !== false
    );
    Axios.get(
      `/partner?` +
        ObjectToParams(params) +
        `${checkParamsFilter ? "" : filterParams}`
    ).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    getDataTable();
  }, [params, filterValue]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Partner" hiddenBackButton />
      <Grid mt="28px">
        <TableComponent
          rows={rows}
          column={column}
          defaultValueSearch={dataParams?.search}
          onRowsClick={(val) =>
            !loading &&
            navigate({
              pathname: "/partner/detail",
              search: createSearchParams({
                id: val.id,
              }).toString(),
            })
          }
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={params?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <Grid display="flex" gap="16px">
              <CustomButton.Filter
                open={openFilter}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <FilterPartner
                  onApply={(value) => {
                    setFilterValue({ ...value });
                    setOpenFilter(false);
                  }}
                  onReset={() => {
                    setFilterValue({
                      partner_type: "",
                      status: "",
                    });
                    setOpenFilter(false);
                  }}
                  filters={{
                    partner_type: filterValue.partner_type,
                    status: filterValue.status,
                  }}
                />
              </CustomButton.Filter>
              <BasicButton
                onClick={() => setOpen(true)}
                label="Create Partner"
              />
            </Grid>
          }
        />
        {open && (
          <ModalPartner
            openModal={open}
            closeModal={() => setOpen(false)}
            getDataTable={getDataTable}
            dataPartner={rows}
          />
        )}
      </Grid>
    </Grid>
  );
}
