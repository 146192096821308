const Colors = {
  PRIMARY_LIGHT: "#e0f2fe",
  PRIMARY: "#2FB2EB",
  PRIMARY_DARK: "#0284c7",
  SECONDARY_LIGHT: "#F4FADC",
  SECONDARY: "#9AB825",
  SECONDARY_DARK: "#8CA41D",
};

export default Colors;
