import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Axios, DEFAULT_PAGINATION_LIMIT, ObjectToParams } from "../../config";

const useDashboardFBA = () => {
  const [dataProduct, setDataProduct] = useState([]);
  const [dataDashboard, setDataDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Reconciliation Queue");
  const [rows, setRows] = useState([]);
  const [meta, setMeta] = useState({});
  const [merchantSettlement, setMerchantSettlement] = useState([]);
  const [merchantFee, setMerchantFee] = useState([]);
  const [bankSettlement, setBankSettlement] = useState([]);
  const [bankFee, setBankFee] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [principalList, setPrincipalList] = useState([]);
  const [preParams, setPreParams] = useState({
    principal_code: "",
    bank_code: "",
  });

  const [params, setParams] = useState({
    search: "",
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    date: dayjs(new Date()).format("MM-YYYY"),
    bank_code: "",
    principle_code: "",
  });

  const getProduct = () => {
    Axios.get("/product").then((res) => {
      setDataProduct(res.data.data);
      setParams((params) => ({ ...params, product: res.data.data[0]?.id }));
    });
  };

  const getDashboard = () => {
    Axios.get(
      `/dashboard?product=${params?.product}&date=${params?.date}&principal_code=${params.principle_code}&bank_code=${params.bank_code}`
    ).then((res) => {
      setDataDashboard(res?.data.data);
    });
  };

  const getMismatch = () => {
    Axios.get(`/dashboard/mismatch-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getError = () => {
    Axios.get(`/dashboard/error-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getOngoingReconQueue = () => {
    Axios.get(`/dashboard/ongoin-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getReconQueue = () => {
    Axios.get(`/dashboard/reconciliation-queue?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res?.data?.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getTop5 = async () => {
    const interation = [
      Axios.get(
        `/dashboard/top-five-merchat-settlement?product=${params.product}`
      ),
      Axios.get(`/dashboard/top-five-merchat-fee?product=${params.product}`),
      Axios.get(
        `/dashboard/top-five-bank-settlement?product=${params.product}`
      ),
      Axios.get(`/dashboard/top-five-bank-fee?product=${params.product}`),
    ];

    const [merchantSettlement, merchantFee, bankSettlement, bankFee] =
      await Promise.all(interation);

    setMerchantFee(merchantSettlement.data.data);
    setMerchantFee(merchantFee.data.data);
    setBankSettlement(bankSettlement.data.data);
    setBankFee(bankFee.data.data);
  };

  const getBankAndPrincipal = async () => {
    const interation = [
      Axios.get("/product_partner/partner-type?partner_type_code=acquirer"),
      Axios.get("/product_partner/partner-type?partner_type_code=principal"),
    ];

    const [bankList, principalList] = await Promise.all(interation);

    setBankList(bankList.data.data);
    setPrincipalList(principalList.data.data);
  };

  useEffect(() => {
    getProduct();
    getBankAndPrincipal();
  }, []);

  useEffect(() => {
    if (!params?.product) return;
    getTop5();
    getDashboard();
  }, [params.product, params]);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "Reconciliation Queue") getReconQueue();
    else if (activeTab === "Ongoing Reconciliation") getOngoingReconQueue();
    else if (activeTab === "Error") getError();
    else getMismatch();
  }, [activeTab, params]);

  return {
    dataProduct,
    dataDashboard,
    loading,
    activeTab,
    rows,
    meta,
    merchantSettlement,
    merchantFee,
    bankSettlement,
    bankFee,
    bankList,
    principalList,
    preParams,
    params,
    setActiveTab,
    setParams,
    setPreParams,
    allowDetail: () => activeTab === "Error" || activeTab === "Missmatch",
  };
};

export default useDashboardFBA;
