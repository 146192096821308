import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
  sort: "",
};

export default function TableMissmatchError() {
  const classes = useStyles();
  let id = 1;

  const [SearchParams, setSearchParams] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Partner, setPartner] = useState("");
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [params, setParams] = useState(initialParams);
  const [error, setError] = useState(null);

  const column = [
    {
      name: "Date & Time",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },

    {
      name: "Message",
      renderCell: ({ user_role }) =>
        loading ? <Skeleton /> : user_role?.map((res) => res.name).join(", "),
    },
    {
      name: "Detail",
      renderCell: (params) => (loading ? <Skeleton /> : params.email),
    },
  ];

  useEffect(() => {
    let mappingParams = Object.keys(params)
      .map((obj) => {
        console.log(obj);
        console.log(params[obj]);
        return obj + "=" + params[obj];
      })
      .join("&");
    setSearchParams(mappingParams);
  }, [params]);

  useEffect(() => {
    if (id && SearchParams) onGetData();
  }, [SearchParams]);

  useEffect(() => {
    if (id) onGetPartner();
  }, [id]);

  const onGetPartner = async () => {
    await Axios.get("/partner/detail/" + id)
      .then((res) => (res.data ? setPartner(res.data.data.name) : ""))
      .catch((err) => console.log(err));
  };

  const onGetData = async () => {
    setLoading(true);
    console.log("id", id);
    await Axios.get("/user/" + id + "?" + SearchParams)

      .then((res) => {
        if (res.data) {
          setMeta(res.data.meta);
          setRows(res.data.data || []);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setMeta({});
        setRows([]);
      });
    setLoading(false);
  };

  return (
    <Fragment>
      <TableComponent
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        totalData={meta?.total}
        page={meta?.page}
        rows={rows}
        column={column}
        withTitle="Error"
      />
    </Fragment>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
});
