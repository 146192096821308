import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import { CustomInput, ListRecon, TitleWithBackButton } from "../../components";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashboardDetailId() {
  const navigate = useNavigate();
  const { id } = useParams();

  const data = {
    labels: [],
    datasets: [
      {
        label: "# of Votes",
        data: [200, 80, 30, 10],
        backgroundColor: ["#54B371", "#DE5242", "#EA9437", "#D137EA"],
        borderColor: ["#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  const handleHover = () => {
    console.log("HOVER");
  };
  const handleLeave = () => {
    console.log("LEAVE");
  };

  const config = {
    type: "pie",
    data: data,
    options: {
      plugins: {
        legend: {
          onHover: handleHover,
          onLeave: handleLeave,
        },
      },
    },
  };

  const dataCount = [
    {
      count: "2,890",
      desc: "Success",
      color: "#34774C",
    },
    {
      count: "80",
      desc: "Error",
      color: "#A63124",
    },
    {
      count: "30",
      desc: "Missmatch",
      color: "#A84D20",
    },
    {
      count: "10",
      desc: "Error & Missmatch",
      color: "#9620A8",
    },
  ];
  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton
        title="Blibli"
        EndElement={<CustomInput.MonthPicker />}
      />
      <Grid
        container
        mt="28px"
        padding="24px"
        sx={(theme) => {
          return {
            ...theme.CardWrapper,
            "& h2, p": {
              margin: 0,
            },
            "& h2": {
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "36px",
              color: "#9AB825",
              mb: "16px",
            },
            "& p": {
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#687083",
            },
            "& .center": {
              borderRight: "1px solid #E4E7EB",
              borderLeft: "1px solid #E4E7EB",
              paddingLeft: "24px",
            },
          };
        }}
      >
        <Grid container>
          <Grid item md={4}>
            <h2>2,300,000</h2>
            <p>Transaction Count</p>
          </Grid>
          <Grid item md={4} className="center">
            <h2>Rp 0</h2>
            <p>Total Fee</p>
          </Grid>
          <Grid item md={4} pl="24px">
            <h2>Rp 0</h2>
            <p>Total Settlement</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid mt="24px">
        <Grid
          sx={(theme) => {
            return {
              ...theme.CardWrapper,
              "& h2": {
                margin: 0,
                padding: "24px",
                paddingLeft: "20px",
              },
              "& hr": {
                border: "1px solid #E4E7EB",
                backgroundColor: "#E4E7EB",
              },
              "& .body-chart": {
                padding: "24px",
              },
              "& .content-right": {
                padding: "56px",
              },
              "& .item": {
                "& h2": {
                  fontWeight: 600,
                  fontSize: "30px",
                  lineHeight: "36px",
                  margin: 0,
                  "& .circle": {
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    marginRight: "16px",
                    display: "inline-block",
                    marginBottom: "2px",
                  },
                },
                "& p": {
                  margin: 0,
                  marginLeft: "30px",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#687083",
                },
              },
            };
          }}
          container
        >
          <Grid container display="inline">
            <h2>Transaction</h2>
            <hr />
          </Grid>
          <Grid container className="body-chart">
            <Grid item md={3}>
              <Pie data={data} config={config} />
            </Grid>
            <Grid item md={9} className="content-right">
              <Grid sx={{ height: "100%" }} container>
                {dataCount.map((res) => (
                  <Grid item md={6} className="item" sx={{ color: res.color }}>
                    <h2>
                      <Grid className="circle" backgroundColor={res.color} />
                      {res.count}
                      <br />
                      <p>{res.desc}</p>
                    </h2>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid mt="24px">
        <Grid
          sx={(theme) => {
            return {
              ...theme.CardWrapper,
              "& h2": {
                margin: 0,
                padding: "24px",
                paddingLeft: "20px",
              },
              "& hr": {
                border: "1px solid #E4E7EB",
                backgroundColor: "#E4E7EB",
              },
            };
          }}
          container
        >
          <Grid container display="inline">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <h2 style={{ fontSize: "20px" }}>Error / Missmatch</h2>
              <p
                style={{ fontSize: "20px", color: "#aaa", marginLeft: "-20px" }}
              >
                (248)
              </p>
            </Box>
            <hr />
          </Grid>
          <Grid container padding="24px">
            {[1, 2, 3, 4, 5].map((res) => (
              <Grid container mt={res !== 1 && "16px"}>
                <ListRecon
                  error
                  onRowsClick={() =>
                    navigate({
                      pathname: `/dashboard/${id}/error`,
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
