import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  BasicButton,
  CustomInput,
  ModalProduct,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";

export default function Product() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataCurrency, setDataCurrency] = useState([]);
  const [selectedCurrency, setSelectCurrency] = useState([]);

  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const column = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={() => setOpen(params)}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Product Code",
      renderCell: ({ product_code }) =>
        loading ? <Skeleton type="text" /> : product_code,
    },
    {
      name: "Product Name",
      renderCell: ({ product_desc }) =>
        loading ? <Skeleton type="text" /> : product_desc,
    },
    {
      name: "Currency",
      renderCell: ({ currency }) =>
        loading ? <Skeleton type="text" /> : currency?.currency_desc,
    },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/product/delete/${id}`).then((res) => console.log(res));
        Swal.fire("Deleted!", "Your file has been deleted.", "success").then(
          () => getDataTable()
        );
      }
    });
  };

  const getDataTable = () => {
    setLoading(true);
    Axios.get(
      selectedCurrency.length
        ? `/product${search}&inFilter=currency_id:${selectedCurrency.join(",")}`
        : `/product${search}`
    )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setMeta(res.data.meta);
          setRows(res.data.data);
        }, 800);
      })
      .catch((err) => {
        console.log("err tab prod", err);
      });
  };

  const getDataCurrency = () => {
    Axios.get("/currency").then((res) => {
      setDataCurrency(res.data.data);
    });
  };

  useEffect(() => {
    getDataCurrency();
  }, []);

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  useEffect(() => {
    getDataTable();
  }, [selectedCurrency]);
  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Product" hiddenBackButton />
      <Grid mt="24px">
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <Grid display="flex" justifyContent="flex-end">
              <CustomInput.Select
                placeholder="Currency"
                value={selectedCurrency}
                onChange={({ target }) => {
                  setSelectCurrency(target.value);
                }}
                renderValue={(selected) =>
                  dataCurrency
                    .filter((val) => selected.includes(val.id))
                    .map((val) => val.currency_desc)
                    .join(", ")
                }
                multiple
                sx={{
                  width: "200px",
                  height: "40px",
                  marginRight: "12px",
                }}
              >
                {dataCurrency.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      <Checkbox checked={selectedCurrency.includes(val.id)} />
                      <ListItemText primary={val.currency_desc} />
                    </MenuItem>
                  );
                })}
              </CustomInput.Select>

              <BasicButton
                label="Create Product"
                fullWidth={true}
                onClick={() => setOpen(true)}
              />
            </Grid>
          }
        />
        {open && (
          <ModalProduct
            openModal={open}
            closeModal={() => setOpen(false)}
            getDataTable={getDataTable}
          />
        )}
      </Grid>
    </Grid>
  );
}
