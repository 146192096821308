import { Button, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterPricing,
  ModalPricingNew,
  TableComponent,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  FilterObject,
  ObjectToParams,
} from "../../../config";

export default function TablePricing() {
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [dataEdit, setDataEdit] = useState();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
  });
  const [filterData, setFilterData] = useState({
    price_model_type_code: "",
    price_scheme_type_code: "",
  });
  const column = [
    {
      name: "Action",
      renderCell: (params) => (
        <ActionButton
          onEdit={(e) => {
            e.stopPropagation();
            setOpen(params);
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(params.id);
          }}
        />
      ),
    },
    {
      name: "Pricing Name",
      renderCell: (params) => (loading ? <Skeleton /> : params?.name ?? "-"),
    },
    {
      name: "Pricing Scheme",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.price_scheme_type_code,
    },
    {
      name: "Pricing Model",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.price_model_type_code ?? "-",
    },
  ];

  useEffect(() => {
    getDataTable();
  }, [params, filterData]);

  const getDataTable = () => {
    setLoading(true);
    let filterParams = "&andFilter=";
    if (Object.values(filterData).some((value) => value)) {
      Object.entries(filterData).forEach((el) => {
        if (el[1]) filterParams += `${el[0]}:${el[1]},`;
      });
    }
    Axios.get(
      filterParams !== "&andFilter="
        ? "/pricing?" + ObjectToParams(params) + filterParams
        : "/pricing?" + ObjectToParams(params)
    )
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setMeta(res.data.meta);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const handleDelete = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/pricing/delete/${val}`).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success delete menu`,
              icon: "success",
            });
            getDataTable();
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterPricing
                onApply={(value) => {
                  setFilterData({ ...filterData, ...FilterObject(value) });
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setFilterData({
                    price_model_type_code: "",
                    price_scheme_type_code: "",
                  });
                  setOpenFilter(false);
                }}
                filters={{
                  price_model_type_code: filterData.price_model_type_code,
                  price_scheme_type_code: filterData.price_scheme_type_code,
                }}
              />
            </CustomButton.Filter>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create Pricing
            </Button>
          </Grid>
        }
      />
      {open && (
        <ModalPricingNew
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
        />
      )}
    </Fragment>
  );
}
