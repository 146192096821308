import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Grid } from "@mui/material";
import React, { useState } from "react";

const CollapseComponent = ({
  children,
  header,
  title,
  disableCollapse,
  noLine,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid
      sx={{
        "& hr": {
          borderBottom: "1px solid #BCC1CC",
        },
      }}
    >
      <h4>{title}</h4>
      <Grid
        ml="24px"
        sx={{
          "& h4": {
            alignItems: "center",
            display: "flex",
            color: "#97AD3E!important",
            "& svg": {
              marginLeft: "9px",
            },
          },
        }}
      >
        {header}
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          {children}
        </Collapse>
        {!disableCollapse && (
          <h4 className="read-more" onClick={() => setOpen(!open)}>
            {!open ? "Read More" : "Read Less"}
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </h4>
        )}
      </Grid>
      {!noLine && <hr />}
    </Grid>
  );
};

export default CollapseComponent;
