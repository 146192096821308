import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FinpointLogo, SecureBanner } from "../../assets";
import { CardComponent, OTP } from "../../components";
import { Axios } from "../../config/helper";

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [otp, setOTP] = useState("");
  const [countdown, setCountdown] = useState(180);
  const [resetLoading, setResetLoading] = useState(false);
  const [body, setBody] = useState({
    email: "",
    password: "",
  });

  const validateOtp = otp.length === 6;

  const handleReset = () => {
    setLoading(true);
    setError(null);
    setOTP("");
    Axios.post("/totp/reset/confirm", {
      email: body.email,
      password: body.password,
      otp: otp,
    })
      .then(() => {
        setTimeout(() => {
          navigate("/login");
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setError(err);
        setSuccess(null);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setBody(location?.state?.body);
  }, [location]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleResendOtp = () => {
    setResetLoading(true);
    setError(null);
    setSuccess(null);
    setOTP("");
    Axios.post("/totp/reset", {
      email: body.email,
      password: body.password,
    })
      .then((res) => {
        setSuccess(res.data.message);
        setCountdown(180);
      })
      .catch((err) => setError(err))
      .finally(() => setResetLoading(false));
  };

  return (
    <Grid className={classes.root} container>
      <Grid item md={6} className={classes.leftContainer}>
        <Grid className={classes.leftContent}>
          <Grid className={classes.logoContainer}>
            <img src={FinpointLogo} alt="" />
          </Grid>
          <Grid className={classes.cardContainer}>
            <CardComponent
              fullWidth
              boxShadow="0px 12px 24px rgba(0, 0, 0, 0.05)"
            >
              <Grid className={classes.cardContent}>
                <Typography>
                  Masukkan kode OTP yang kami kirimkan ke email anda
                </Typography>

                <Grid className={classes.formContainer}>
                  {error && (
                    <Alert severity="error">
                      {error?.response?.data?.errors[0]?.message}
                    </Alert>
                  )}
                  {success && (
                    <Alert severity="success">
                      OTP sent successfully, please check your email
                    </Alert>
                  )}
                </Grid>
                <Grid className={classes.form}>
                  <Box justifyContent="center" display="flex">
                    <OTP value={otp} onChange={setOTP} length={6} />
                  </Box>

                  <Grid
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="12px"
                    mb="12px"
                    sx={{
                      "& a": {
                        fontWeight: 700,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: countdown === 0 ? "#97AD3E" : "#B0B0B0",
                        textDecoration: "none",
                        pointerEvents: countdown === 0 ? "auto" : "none",
                      },
                    }}
                  >
                    <Typography sx={{ color: "#B0B0B0" }} variant="body2">
                      {countdown > 0
                        ? `Resend OTP in ${Math.floor(countdown / 60)}:${(
                            "0" +
                            (countdown % 60)
                          ).slice(-2)}`
                        : ""}
                    </Typography>
                    <a
                      onClick={handleResendOtp}
                      style={{
                        pointerEvents: countdown === 0 ? "auto" : "none",
                        cursor: countdown === 0 ? "pointer" : "not-allowed",
                      }}
                    >
                      {resetLoading ? "Resending OTP..." : "Resend OTP"}
                    </a>
                  </Grid>

                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => handleReset()}
                    disabled={!validateOtp || loading}
                    className={classes.customButton}
                  >
                    {loading ? (
                      <CircularProgress size={30} color="inherit" />
                    ) : (
                      "Verify OTP"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.rightImageContainer}>
        <img src={SecureBanner} alt="" className={classes.rightImage} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#F7F9FC",
    overflow: "hidden",
  },
  leftContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  leftContent: {
    width: "100% ",
    padding: "20px",
    height: "100%",
    paddingTop: "36px",
    marginRight: "172px",
    marginLeft: "172px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  logoContainer: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  cardContainer: {
    height: "100% !important",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContent: {
    textAlign: "center",
    "& h5 ": {
      fontWeight: "700 !important",
      fontSize: "20px !important",
      lineHeight: "24px !important",
      color: "black !important",
    },
  },
  formContainer: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    "& ul, li": {
      margin: 0,
      textAlign: "left",
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  forgotPassword: {
    textAlign: "right",
    marginBottom: theme.spacing(1.5),
    "& a": {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#97AD3E",
      textDecoration: "none",
      "&:hover": {
        color: "#83992A",
        textDecoration: "underline",
      },
    },
  },
  customButton: {
    height: "48px !important",
    backgroundColor: "#A0B842 !important",
    "&:hover": {
      backgroundColor: "#83992A",
    },
    "&:disabled": {
      backgroundColor: "#e4e4e7 !important",
      cursor: "not-allowed !important",
    },
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    position: "relative !important",
  },
  signupText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    "& a": {
      color: "#97AD3E",
      textDecoration: "none",
      fontWeight: 700,
    },
    "&:hover a": {
      color: "#83992A",
      textDecoration: "underline",
    },
  },
  rightImageContainer: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rightImage: {
    height: "200%",
    borderRadius: "50% 0% 0% 50%",
    marginTop: "-50%",
    objectFit: "contain",
    width: "100%",
  },
}));
