import { Button, Fade, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  TableContract,
  TableContractPartner,
  TableContractProduct,
  TitleWithBackButton,
} from "../../components";

export default function Contract() {
  const [buttonActive, setButtonActive] = useState("Contract");
  let { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [detailProduct, setDetailProduct] = useState({});
  const classes = useStyles({ buttonActive });

  return (
    <Grid className={classes.wrapper}>
      <Grid
        className="header"
        sx={{
          mt: 2,
          pl: 3,
          pt: "28px",
          pb: "28px",
        }}
      >
        <TitleWithBackButton title={"Contract"} />
        <Grid mt="24px" display="flex" gap="12px">
          {["Contract", "Contract Partner", "Contract Product"].map(
            (res, i) => (
              <Button
                variant="text"
                onClick={() => setButtonActive(res)}
                sx={{
                  color: buttonActive === res ? "#9AB825" : "#687083",
                  backgroundColor:
                    buttonActive === res && "rgba(165, 189, 68, 0.1)",
                }}
                key={i}
              >
                {res}
              </Button>
            )
          )}
        </Grid>
      </Grid>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        {buttonActive === "Contract" && (
          <Fade in={buttonActive === "Contract"} timeout={600}>
            <Grid container>
              <TableContract />
            </Grid>
          </Fade>
        )}
        {buttonActive === "Contract Partner" && (
          <Fade in={true} timeout={600}>
            <Grid container>
              <TableContractPartner />
            </Grid>
          </Fade>
        )}
        {buttonActive === "Contract Product" && (
          <Fade in={true} timeout={600}>
            <Grid container>
              <TableContractProduct />
            </Grid>
          </Fade>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  wrapper: {
    "& .header": {
      backgroundColor: "white",
      boxShadow:
        "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
      "& h1": {
        margin: 0,
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    "& buton": {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
