import React from "react";
import { Forbidden } from "../../components";
import { getUserRole } from "../../config";
import { User, UserPO } from "./index";

export default function HandlingUser() {
  const role = getUserRole();
  if (role === "FSA") {
    return <User />;
  } else if (role === "PO") {
    return <UserPO />;
  } else {
    return <Forbidden />;
  }
}
