import { Grid, MenuItem } from "@mui/material";
import { useState } from "react";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterPricing({ onApply, onReset, filters }) {
  const [dataFilter, setDataFilter] = useState(
    filters || {
      price_scheme_type_code: null,
      price_model_type_code: null,
    }
  );

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Pricing Scheme"
          value={dataFilter?.price_scheme_type_code}
          onChange={({ target }) =>
            setDataFilter({
              ...dataFilter,
              price_scheme_type_code: target.value,
            })
          }
        >
          {[
            { value: "fixed", label: "Fixed Price" },
            { value: "mdr", label: "MDR" },
          ].map((val) => (
            <MenuItem value={val.value}>{val.label}</MenuItem>
          ))}
        </CustomInput.Select>
        <CustomInput.Select
          placeholder="Pricing Model"
          value={dataFilter?.price_model_type_code}
          onChange={({ target }) =>
            setDataFilter({
              ...dataFilter,
              price_model_type_code: target.value,
            })
          }
        >
          {[
            { label: "Tier / Progressive", value: "progressive" },
            { label: "Flat", value: "flat" },
          ].map((val) => (
            <MenuItem value={val.value}>{val.label}</MenuItem>
          ))}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
