import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  ActionButton,
  InputComponent,
  ModalComponent,
  TableComponent,
} from "../../../components";
import { Axios } from "../../../config";

export default function ModalDataFormat({
  open,
  onClose,
  classes,
  product_id,
  getDataTable,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      rsn_data_format_id: "",
      rsn_data_format_description: "",
      data_format_type: "",
      folder_path: "",
      file_pattern: "",
      record_delimeter: "",
      primary_key_header: "",
      secondary_key_header: "",
      transaction_amount_header: "",
      row_code_position: 0,
      record_data_row_code: {
        data_row_code: [],
        data_row_description: [],
        type: [],
      },
      record_header_row_code: {
        header_row_code: [],
        header_row_description: [],
      },
    },
  });

  const getDataDetail = () => {
    setLoading(true);
    Axios.get(`data_format/detail/${open?.id}`).then((res) => {
      Object.keys(getValues()).forEach((val) => {
        const { data } = res.data;
        if (val === "record_header_row_code") {
          setValue("record_header_row_code", {
            header_row_code: data[val].map((res) => res.header_row_code),
            header_row_description: data[val].map(
              (res) => res.header_row_description
            ),
          });
        } else if (val === "record_data_row_code") {
          setValue("record_data_row_code", {
            data_row_code: data[val].map((res) => res.data_row_code),
            data_row_description: data[val].map(
              (res) => res.data_row_description
            ),
            type: data[val].map((res) => res.type),
          });
        } else {
          setValue(val, data[val]);
        }
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (open?.id) {
      getDataDetail();
    }
  }, []);

  const onSubmit = (val) => {
    let body = {
      ...val,
      record_data_row_codes: val.record_data_row_code,
      row_code_position: Number(val.row_code_position),
    };
    delete body.record_data_row_code;
    let dataError = {};
    if (
      body.record_data_row_codes.data_row_code.length === 0 ||
      body.record_header_row_code.header_row_code.length === 0
    ) {
      if (body.record_data_row_codes.data_row_code.length === 0) {
        dataError = { ...dataError, record_data_row_code: true };
      }
      if (body.record_header_row_code.header_row_code.length === 0) {
        dataError = { ...dataError, record_header_row_code: true };
      }
    }
    setError(dataError);
    if (dataError.record_data_row_code || dataError.record_header_row_code) {
      return;
    } else {
      onClose();
      if (open?.id) {
        Axios.put(`/data_format/update/${open?.id}`, body).then((res) => {
          Swal.fire({
            icon: res.status === 200 ? "success" : "error",
            title: res.status === 200 ? "Success" : "Error",
            text: res.data.message || "Berhasil Edit Assign Pricing",
          }).then(() => getDataTable());
        });
      } else {
        Axios.post(`/data_format/create/${product_id}`, body).then((res) => {
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Berhasil Edit Assign Pricing",
          }).then(() => getDataTable());
        });
      }
    }
  };

  return (
    <ModalComponent open={Boolean(open)} onClose={onClose} fullSize hideScroll>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          className={classes.ModalWrapper}
          conteiner
          display="flex"
          flexDirection="column"
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "16px",
              paddingBottom: "10px",
              backgroundColor: "white",
              boxShadow:
                "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05);",
              height: "fit-content",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                "& div": {
                  borderRight: "1px solid #E4E7EB",
                  marginLeft: "10px",
                  marginRight: "16px",
                },
                "& h3": {
                  marginTop: "auto",
                  marginBottom: "auto",
                },
              }}
            >
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
              <div></div>
              <h3>{open?.id ? "Edit Data Format" : "Create Data Format"}</h3>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid
            container
            sx={{
              mt: "32px",
              height: "100vh",
              overflowY: "auto",
              paddingBottom: "200px",
            }}
            justifyContent="center"
          >
            <Grid
              item
              md={11}
              className={classes.wrapper}
              sx={{
                paddingTop: 0,
                height: "fit-content",
              }}
            >
              <InputComponent
                disabled={loading}
                label="Data Format Name"
                register={{ ...register("name", { required: true }) }}
                error={errors}
                id="name"
              />
            </Grid>
            <Grid container sx={{ padding: "0px", mt: "24px" }} md={11}>
              <Grid item md={4} sx={{ paddingRight: "12px" }}>
                <Grid className={classes.wrapper}>
                  <InputComponent
                    disabled={loading}
                    label="RSN Data Format ID"
                    register={{
                      ...register("rsn_data_format_id", { required: true }),
                    }}
                    error={Boolean(errors["rsn_data_format_id"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="RSN Data Format Description"
                    multiline
                    height="100px"
                    register={{
                      ...register("rsn_data_format_description", {
                        required: true,
                      }),
                    }}
                    error={Boolean(errors["rsn_data_format_description"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Data Format Type"
                    register={{
                      ...register("data_format_type", { required: true }),
                    }}
                    error={Boolean(errors["data_format_type"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Folder Path"
                    register={{
                      ...register("folder_path", { required: true }),
                    }}
                    error={Boolean(errors["folder_path"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="File Pattern"
                    register={{
                      ...register("file_pattern", { required: true }),
                    }}
                    error={Boolean(errors["file_pattern"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Record Delimeter"
                    register={{
                      ...register("record_delimeter", { required: true }),
                    }}
                    error={Boolean(errors["record_delimeter"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Primary Key Header"
                    register={{
                      ...register("primary_key_header", { required: true }),
                    }}
                    error={Boolean(errors["primary_key_header"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Secondary Key Header"
                    register={{
                      ...register("secondary_key_header", { required: true }),
                    }}
                    error={Boolean(errors["secondary_key_header"])}
                  />
                  <InputComponent
                    disabled={loading}
                    label="Transaction Amount Header"
                    error={Boolean(errors["transaction_amount_header"])}
                    register={{
                      ...register("transaction_amount_header", {
                        required: true,
                      }),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item md={8} sx={{ paddingLeft: "12px" }}>
                <Grid className={classes.wrapper}>
                  <InputComponent
                    disabled={loading}
                    label="Row Code Position"
                    inputType="number"
                    register={{
                      ...register("row_code_position", {
                        required: true,
                        min: 1,
                      }),
                    }}
                    error={Boolean(errors["row_code_position"])}
                    errorMessage={
                      errors["row_code_position"]?.type === "min" &&
                      "Value cannot be less than 1"
                    }
                  />
                  <Grid container className={classes.tableWrapper}>
                    <h4>Record Header Row Codes</h4>
                    <TableHRC
                      rows={watch("record_header_row_code")}
                      setValue={setValue}
                    />
                    {error?.record_header_row_code && (
                      <Grid container sx={{ color: "red" }}>
                        <p>This field cannot be empty!</p>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container className={classes.tableWrapper}>
                    <h4>Record Data Row Codes</h4>
                    <TableRDC
                      rows={watch("record_data_row_code")}
                      setValue={setValue}
                    />
                    {error?.record_data_row_code && (
                      <Grid container sx={{ color: "red" }}>
                        <p>This field cannot be empty!</p>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
}

const TableHRC = ({ rows, setValue }) => {
  const [open, setOpen] = useState(false);
  const initialDataSelect = {
    header_row_code: 0,
    header_row_description: "",
  };
  const [dataSelect, setDataSelect] = useState(initialDataSelect);
  const errors = {
    header_row_code: Boolean(
      dataSelect.header_row_code === 0 || !dataSelect.header_row_code
    ),
    header_row_description: Boolean(!dataSelect.header_row_description),
  };

  const RecordHRColumn = [
    {
      name: "Action",
      renderCell: (params, i) => (
        <ActionButton
          onEdit={() => {
            setOpen(true);
            setDataSelect({
              header_row_code: rows.header_row_code[i],
              header_row_description: rows.header_row_description[i],
              index: i,
            });
          }}
          onDelete={() => {
            setDataSelect({ initialDataSelect });
            setOpen(false);
            setValue("record_header_row_code", {
              header_row_code: rows.header_row_code.filter(
                (a, index) => index !== i
              ),
              header_row_description: rows.header_row_description.filter(
                (a, index) => index !== i
              ),
            });
          }}
        />
      ),
    },
    {
      name: "Record Header Row Code",
      renderCell: (params) => params,
    },
    {
      name: "Record Header Row Description",
      renderCell: (params, i) => rows.header_row_description[i],
    },
  ];

  const handleSave = () => {
    setOpen(false);
    if (dataSelect.index !== undefined) {
      rows.header_row_code[dataSelect.index] = Number(
        dataSelect.header_row_code
      );
      rows.header_row_description[dataSelect.index] =
        dataSelect.header_row_description;
    } else {
      setValue("record_header_row_code", {
        ...rows,
        header_row_code: [
          ...rows.header_row_code,
          Number(dataSelect.header_row_code),
        ],
        header_row_description: [
          ...rows.header_row_description,
          dataSelect.header_row_description,
        ],
      });
    }
    setDataSelect(initialDataSelect);
  };

  return (
    <>
      <TableComponent column={RecordHRColumn} rows={rows.header_row_code} />
      <h4
        className="link"
        onClick={() => {
          setDataSelect(initialDataSelect);
          setOpen(!open);
        }}
      >
        + Add New Row
      </h4>
      {Boolean(open) && (
        <Grid container spacing="12px" alignItems="start">
          <Grid item>
            <InputComponent
              inputType="number"
              value={dataSelect.header_row_code}
              onChange={(e) =>
                setDataSelect({
                  ...dataSelect,
                  header_row_code: e.target.value,
                })
              }
              label="Record Header Row Code"
              error={errors["header_row_code"]}
            />
          </Grid>
          <Grid item>
            <InputComponent
              value={dataSelect.header_row_description}
              onChange={(e) =>
                setDataSelect({
                  ...dataSelect,
                  header_row_description: e.target.value,
                })
              }
              label="Record Header Row Description"
              error={errors["header_row_description"]}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={Boolean(
                errors["header_row_code"] || errors["header_row_description"]
              )}
              onClick={handleSave}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "42%",
                minWidth: "120px",
              }}
            >
              {dataSelect?.index !== undefined ? "Update" : "Add"}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const TableRDC = ({ rows, setValue }) => {
  const [open, setOpen] = useState(false);
  const initialDataSelect = {
    data_row_code: 0,
    data_row_description: "",
    type: "",
  };
  const [dataSelect, setDataSelect] = useState(initialDataSelect);
  const errors = {
    data_row_code: Boolean(
      dataSelect.data_row_code === 0 || !dataSelect.data_row_code
    ),
    data_row_description: Boolean(!dataSelect.data_row_description),
    type: Boolean(!dataSelect.type),
  };

  const handleSave = () => {
    setOpen(false);
    if (dataSelect.index !== undefined) {
      rows.data_row_code[dataSelect.index] = Number(dataSelect.data_row_code);
      rows.data_row_description[dataSelect.index] =
        dataSelect.data_row_description;
      rows.type[dataSelect.index] = dataSelect.type;
    } else {
      setValue("record_data_row_code", {
        ...rows,
        data_row_code: [
          ...rows.data_row_code,
          Number(dataSelect.data_row_code),
        ],
        data_row_description: [
          ...rows.data_row_description,
          dataSelect.data_row_description,
        ],
        type: [...rows.type, dataSelect.type],
      });
    }
    setDataSelect(initialDataSelect);
  };

  const RecordDataColumn = [
    {
      name: "Action",
      renderCell: (params, i) => (
        <ActionButton
          onEdit={() => {
            setOpen(true);
            setDataSelect({
              data_row_code: rows.data_row_code[i],
              data_row_description: rows.data_row_description[i],
              type: rows.type[i],
              index: i,
            });
          }}
          onDelete={() => {
            setDataSelect({ initialDataSelect });
            setOpen(false);
            setValue("record_data_row_code", {
              data_row_code: rows.data_row_code.filter(
                (a, index) => index !== i
              ),
              data_row_description: rows.data_row_description.filter(
                (a, index) => index !== i
              ),
              type: rows.type.filter((a, index) => index !== i),
            });
          }}
        />
      ),
    },
    {
      name: "Record Header Row Code",
      renderCell: (params) => params,
    },
    {
      name: "Record Header Row Description",
      renderCell: (params, i) => rows.data_row_description[i],
    },
    {
      name: "Type",
      renderCell: (params, i) => rows.type[i],
    },
  ];

  return (
    <>
      <TableComponent column={RecordDataColumn} rows={rows.data_row_code} />
      <h4
        className="link"
        onClick={() => {
          setOpen(!open);
          setDataSelect(initialDataSelect);
        }}
      >
        + Add New Row
      </h4>
      {open && (
        <Grid container spacing="12px" alignItems="start">
          <Grid item>
            <InputComponent
              inputType="number"
              value={dataSelect.data_row_code}
              sx={{
                "& .MuiFormControl-root": {
                  border: "2px solid blue",
                },
              }}
              onChange={(e) =>
                setDataSelect({
                  ...dataSelect,
                  data_row_code: e.target.value,
                })
              }
              label="Record Header Row Code"
              error={Boolean(errors["data_row_code"])}
            />
          </Grid>
          <Grid item>
            <InputComponent
              value={dataSelect.data_row_description}
              onChange={(e) =>
                setDataSelect({
                  ...dataSelect,
                  data_row_description: e.target.value,
                })
              }
              label="Record Header Row Description"
              error={Boolean(errors["data_row_description"])}
            />
          </Grid>
          <Grid item>
            <InputComponent
              value={dataSelect.type}
              onChange={(e) =>
                setDataSelect({
                  ...dataSelect,
                  type: e.target.value,
                })
              }
              label="type"
              error={Boolean(errors["type"])}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={Boolean(
                errors["data_row_code"] ||
                  errors["data_row_description"] ||
                  errors["type"]
              )}
              onClick={handleSave}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "42%",
                minWidth: "120px",
              }}
            >
              {dataSelect?.index !== undefined ? "Update" : "Add"}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
