import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import {
  DividerImage,
  ElectronicPaymentBanner,
  MultipleDeploymentImg,
  OptimiseRoutingImg,
  ScalableReliableImg,
} from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function ElectronicPatmentPage() {
  const classes = useStyles();

  return (
    <Fragment>
      <ButtonAppBar />
      <section className={classes.root}>
        <section className={classes.banner}>
          <Container>
            <h1>Electronic Payment Solutions</h1>
            <p>
              As transaction volumes grow and the number of transaction sources
              and destinations explodes, the pressure on switches is growing.
              <br />
              <br />
              With our solution, issuers, acquirers and processors can manage
              the fast-moving payments landscape and deliver the best levels of
              service to their customers.
              <br />
              <br />
              Our Solution, can directly connect external devices and integrate
              with electronic and mobile payments. this includes built-in
              interfaces for the most common types payment terminals, and
              international and regional payment networks. In other words, the
              system is easily adaptable to existing customer infrastructure. It
              connects readily with online banking systems and interacts with
              external payment systems providers.
            </p>
          </Container>
          <img src={ElectronicPaymentBanner} alt="" />
        </section>
        <section className={classes.bodyContent}>
          <Container>
            <Grid ml={{ md: "102px" }} mr={{ md: "102px" }}>
              <Grid container spacing={{ md: "64px", xs: "12px" }}>
                <Grid item md={6}>
                  <img src={MultipleDeploymentImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Multiple Deployment Options​</h2>
                  <p>
                    Avoiding hardware vendor lock-in, Switch operates on a wide
                    variety of industry platforms and hardware. It can be
                    deployed to replace a legacy switch or work in parallel with
                    existing systems.​
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6} className="content" order={{ xs: 1, md: 0 }}>
                  <h2>Optimise Routing​​</h2>
                  <p>
                    Switch’s proprietary algorithms optimize the routing of
                    authorization requests. The message format can be quickly
                    changed in accordance with specific requirements. ​
                  </p>
                </Grid>
                <Grid item md={6}>
                  <img src={OptimiseRoutingImg} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={ScalableReliableImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Scalable, Reliable, Adaptable​​</h2>
                  <p>
                    The solution operates in a wide range of environments, from
                    supporting an individual bank’s standalone ATM / POS
                    network, to interfacing with other domestic and
                    international networks and supporting the running of
                    national switches.​
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <img src={DividerImage} alt="" className="divider" />
        </section>
        <FooterLandingPage />
      </section>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
      fontFamily: ["Raleway", "sans-serif"],
      "& button": {
        textTransform: "none",
      },
      "& h1,h2,h3,h4,p": {
        margin: 0,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "48px",
      },
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      display: "flex",
      background: "linear-gradient(90deg, #30BBF2 0%, #B2CC49 100%)",
      position: "relative",
      color: "white",
      "& h1": {
        [theme.breakpoints.up("md")]: {
          marginTop: "168px",
          fontWeight: 300,
          fontSize: "54px",
          lineHeight: "56px",
          width: "588px",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "18px",
          width: "160px",
        },
      },
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "6px",
          lineHeight: "9px",
          width: "200px",
          marginTop: "12px",
          marginBottom: "12px",
        },
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
        width: "588px",
        marginBottom: "128px",
        marginTop: "48px",
      },
      "& img": {
        position: "absolute",
        height: "100%",
        right: "0px",
      },
    },
    bodyContent: {
      backgroundColor: "#F7F9FC",
      marginTop: "120px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10px",
      },
      "& .content": {
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          marginBottom: "24px",
        },
        marginTop: "103px",
        marginBottom: "103px",
        "& h2": {
          fontWeight: 700,
          fontSize: "40px",
          lineHeight: "48px",
          color: "#2C3038",
        },
        "& p": {
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "30px",
          textAlign: "justify",
          marginTop: "24px",
        },
      },
      "& .divider": {
        height: "100%",
        marginBottom: "-3px",
      },
    },
  };
});
