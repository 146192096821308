import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { ListMenuSidebar } from "../../assets/icons/CustomIcon";
import { ModalComponent, TableComponent } from "../../components";
import { Axios } from "../../config";

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    height: "100%",
    "& h3": {
      margin: 0,
    },
    display: "flex",
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
});

export default function RolePage(params) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4]);

  const column = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <Fragment>
            {loading ? (
              <Skeleton />
            ) : (
              <Tooltip title="Edit Role">
                <IconButton onClick={() => setOpen(params)}>
                  <AccountTreeIcon size="small" style={{ fill: "#A0B842" }} />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        );
      },
    },
    {
      name: "Role",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Type",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={params.type}
            color={params.type === "Internal" ? "success" : "warning"}
            variant="outlined"
            size="small"
          />
        ),
    },
    {
      name: "Role Code",
      renderCell: (params) => (loading ? <Skeleton /> : params.code),
    },
    {
      name: "Description",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Box
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            <Typography>{params.description}</Typography>
          </Box>
        ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    Axios.get("/role").then((res) => {
      setRows(res.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, []);

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <h1>Role</h1>
        </Grid>
        {open && (
          <ModalRole
            open={open}
            onClose={() => setOpen(false)}
            classes={classes}
          />
        )}
        <TableComponent rows={rows} column={column} />
      </Grid>
    </Fragment>
  );
}

const ModalRole = ({ open, onClose, classes }) => {
  const [listMenu, setListMenu] = useState([]);

  const getDataMenu = () => {
    Axios.get(`role/menu-assigned/${open?.id}`).then((res) => {
      setListMenu(res.data.data);
    });
  };

  useEffect(() => {
    getDataMenu();
  }, []);

  const handleSave = () => {
    let menu = [];
    listMenu.forEach((res) => {
      menu.push({
        menu_id: res.id,
        status: res.is_checked,
      });
      if (res.child_menu) {
        res.child_menu.forEach((val) => {
          menu.push({
            menu_id: val.id,
            status: val.is_checked,
          });
        });
      }
    });

    Axios.put(`/role/update/${open?.id}`, {
      menu: menu,
    }).then((res) => {
      console.log(res);
      onClose();
      setTimeout(() => {
        Swal.fire({
          title: "Success",
          text: res.data.message,
          icon: "success",
        });
      }, 500);
    });
  };

  const handleCheck = ({ target }) => {
    let cloneMenu = [...listMenu];
    cloneMenu.map((val) => {
      if (val.id === Number(target.value)) {
        val.is_checked = target.checked;
        if (!target.checked && val.child_menu) {
          val.child_menu.map((res) => {
            res.is_checked = false;
          });
        }
      } else {
        val.child_menu &&
          val.child_menu.map((res) => {
            if (res.id === Number(target.value)) {
              res = res.is_checked = target.checked;
            }
          });
      }
    });
    setListMenu(cloneMenu);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDrag = (params) => {
    if (!params.destination) {
      return;
    }

    const items = reorder(
      listMenu,
      params.source.index,
      params.destination.index
    );
    setListMenu(items);
  };

  const handleDragUpdate = (params, b) => {
    // setDestination(params.destination);
    console.log(params);
  };

  return (
    <ModalComponent open={Boolean(open)} onClose={onClose} fullSize>
      <Grid className={classes.ModalWrapper} conteiner flexDirection="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
            backgroundColor: "white",
            boxShadow:
              "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05);",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Save Changes
          </Button>
        </Grid>
        <Grid
          container
          sx={{ backgroundColor: "#E5E5E5", height: "100%", padding: "32px" }}
          justifyContent="center"
        >
          <Grid
            item
            md={8}
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              boxShadow:
                "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
              padding: "24px",
              maxHeight: "800px",
              overflow: "auto",
            }}
          >
            <Grid
              sx={{
                "& h4": {
                  margin: 0,
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                },
              }}
            >
              <h4>Menu</h4>
              <Grid container mt="24px">
                <DragDropContext
                  onDragEnd={handleDrag}
                  // onDragUpdate={handleDragUpdate}
                >
                  <Droppable droppableId="character">
                    {(provided) => (
                      <Grid
                        container
                        className="character"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {listMenu.map((res, i) => {
                          return (
                            <Draggable
                              key={res.id}
                              draggableId={`${res.id}`}
                              index={i}
                              type="TASK"
                            >
                              {(provided) => (
                                <Grid
                                  container
                                  className="item"
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <ListMenuComp
                                    val={res}
                                    handleCheck={handleCheck}
                                  />
                                  {res.child_menu &&
                                    res.child_menu.map((res) => {
                                      return (
                                        <ListMenuComp
                                          val={res}
                                          child
                                          handleCheck={handleCheck}
                                        />
                                      );
                                    })}
                                </Grid>
                              )}
                            </Draggable>
                          );
                        })}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* NOT USING NOW */}
                {/* {listMenu.map((val) => {
                  return (
                    <>
                      <ListMenuComp
                        val={val}
                        handleCheck={handleCheck}
                        menu_id={menu_id}
                      />
                      {val.child_menu &&
                        val.child_menu.map((res) => {
                          return (
                            <ListMenuComp
                              val={res}
                              child
                              handleCheck={handleCheck}
                              menu_id={menu_id}
                            />
                          );
                        })}
                    </>
                  );
                })} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

const ListMenuComp = ({ val, child, handleCheck }) => {
  return (
    <>
      <Grid
        item
        sx={{
          border: "1px solid #9AB825",
          width: "100%",
          display: "flex",
          marginTop: "12px",
          borderRadius: "6px",
          alignItems: "center",
          padding: "10px",
          marginLeft: child && "24px",
          color: "#9AA2B1",
          // marginLeft: val.child_menu ? "" : "22px",
        }}
      >
        <Checkbox
          value={val.id}
          checked={val.is_checked}
          onChange={handleCheck}
          sx={{
            color: "#9AB825",
            "&.Mui-checked": {
              color: "#9AB825",
            },
          }}
        />
        {
          ListMenuSidebar.filter((res) => Number(res.id) === val.icon_id)?.pop()
            ?.icon
        }
        <Grid
          sx={{
            "& h4, p": {
              margin: 0,
            },
            "& h4": {
              margin: 0,
              color: "#092540",
            },
            marginLeft: "14px",
          }}
        >
          <h4>{val?.title}</h4>
          <p>{val?.url}</p>
        </Grid>
      </Grid>
    </>
  );
};
