import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React, { useEffect } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

export default function CustomFilter({
  dataFilter = null,
  selectedAppliedFilter,
  setSelectedAppliedFilter,
}) {
  const [open, setOpen] = React.useState(false);
  const [FilterData, setFilterData] = React.useState([]);
  const [IsLoading, setIsLoading] = React.useState(false);
  const [SelectedFilter, setSelectedFilter] = React.useState([]);

  useEffect(() => {
    setFilterData(dataFilter);
  }, []);

  useEffect(() => {
    setSelectedFilter(selectedAppliedFilter);
  }, [open]);

  const handleClick = () => {
    setOpen((prev) => !prev);
    setSelectedFilter([]);
  };

  const handleClickAway = () => {
    setOpen(false);
    setSelectedFilter([]);
  };

  const handleApply = async () => {
    setIsLoading(true);
    await setSelectedAppliedFilter(SelectedFilter);
    setIsLoading(false);
  };

  const handleReset = async () => {
    setIsLoading(true);
    setSelectedFilter([]);
    await setSelectedAppliedFilter([]);
    setIsLoading(false);
    setOpen(false);
  };

  const handleSelectedFilter = (newSelected) => {
    let checkData;
    setSelectedFilter((prev) => {
      if (prev.length > 0) {
        let find = prev.find((pre) => pre.name === newSelected.name);
        if (find) {
          let filter = prev.filter((pre) => pre.name !== newSelected.name);
          checkData = [...filter, newSelected];
        } else checkData = [...prev, newSelected];
      } else checkData = [newSelected];
      return checkData;
    });
  };

  const styles = {
    position: "absolute",
    top: 45,
    right: 0,
    zIndex: 1,
    border: "1px solid #D1D5DC",
    padding: "15px 25px",
    bgcolor: "background.paper",
    borderRadius: "6px",
    width: "auto",
    boxShadow: "1px 1px 20px rgba(0,0,0,0.15)",
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <Box sx={{ position: "relative" }}>
        <Button
          onClick={handleClick}
          sx={{
            color: "black",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            padding: "10px 20px",
            border: "1px solid #D1D5DC",
            borderRadius: "6px",
          }}
        >
          <FilterListIcon fontSize="small" color="error" />
          Filter
        </Button>
        {open ? (
          <Box sx={styles}>
            <h3>Filter</h3>
            <Grid>
              {FilterData.map(
                (data, i) =>
                  data && (
                    <DropdownCheckmark
                      key={i}
                      data={data}
                      handleSelectedFilter={handleSelectedFilter}
                      selectedAppliedFilter={selectedAppliedFilter}
                      IsLoading={IsLoading}
                    />
                  )
              )}
            </Grid>
            <Grid sx={{ textAlign: "right", padding: "10px 0" }}>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#A0B842",
                  color: "#A0B842",
                  width: "100px",
                  margin: "5px",
                }}
                onClick={handleReset}
                disabled={IsLoading}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#A0B842",
                  width: "100px",
                  margin: "5px",
                }}
                onClick={handleApply}
                disabled={IsLoading}
              >
                Apply
              </Button>
            </Grid>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}

export function DropdownCheckmark({
  data,
  handleSelectedFilter,
  selectedAppliedFilter,
  IsLoading,
}) {
  const [personName, setPersonName] = React.useState([]);

  useEffect(() => {
    if (selectedAppliedFilter.length > 0) {
      let find = selectedAppliedFilter.find(
        (select) => select.name === data.name
      );
      if (find) setPersonName(find.selected);
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value, id },
    } = event;
    let selected = typeof value === "string" ? value.split(",") : value;
    handleSelectedFilter({ name: data.name, selected });
    setPersonName(selected);
  };

  return (
    <FormControl sx={{ m: 1, width: "250px" }}>
      <InputLabel
        id="demo-multiple-checkbox-label"
        sx={{
          background: "white",
          color: "gray",
          border: "1px solid #D1D5DC",
          border: "none",
          fontSize: "14px",
        }}
        color="success"
      >
        {data.name}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={personName}
        disabled={IsLoading}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        color="success"
        sx={{
          margin: 0,
          fontSize: "14px",
          overflow: "auto",
          maxHeight: "300px",
        }}
      >
        {data.options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.name}
            style={{
              fontSize: "14px",
            }}
          >
            <Checkbox
              checked={personName.indexOf(option.name) > -1}
              color="success"
            />
            <ListItemText
              primary={option.name}
              name={option.id}
              style={{
                fontSize: "14px !important",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
