import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/layout";

// DASHBOARD
import {
  BillingDetail,
  DashboarHandler,
  DashboardDetail,
  DashboardDetailId,
  DashboardDetailPA,
  DashboardMismatch,
  DashboardMissmatchDetail,
  DashboardMissmatchError,
  NotFoundPage,
  PartnerUser,
  PartnerUserDetail,
  ReconciliationError,
  TransactionDetails,
  TransactionError,
  TransactionMismatch,
} from "./pages";

import {
  ForgotPassword,
  Login,
  NewPassword,
  Register,
  ResetTotp,
} from "./pages/Auth";

import {
  AboutUsPage,
  ContactUs,
  DigitalBankingPage,
  ElectronicPatmentPage,
  FraudManagementPage,
  Home,
  PrivacyPolicy,
  TermsAndCondition,
} from "./pages/LandingPage";

import {
  Contract,
  Currency,
  DetailPartner,
  Partner,
  PartnerType,
  Pricing,
  Product,
  SystemParameter,
} from "./pages/Administration";

import {
  Billing,
  DailyReconciliation,
  DataFormat,
  DetailDataFormat,
  Schedule,
} from "./pages/Reconcilliation";

import {
  HandlingUser,
  MenuPage,
  RolePage,
  UserEksternalDetail,
  UserPO,
} from "./pages/Security";

import { StaticReport, ViewTransaction } from "./pages/Transaction";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-totp" element={<ResetTotp />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/new-password" element={<NewPassword />} />

      <Route
        path="/electronic-payment-solution"
        element={<ElectronicPatmentPage />}
      />
      <Route
        path="/digital-banking-platform"
        element={<DigitalBankingPage />}
      />
      <Route
        path="/fraud-management-solution"
        element={<FraudManagementPage />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<Layout />}>
        {/* NEW PAGES */}
        <Route path="dashboard" element={<DashboarHandler />} />
        <Route path="dashboard/detail" element={<DashboardDetail />} />
        <Route path="dashboard/:id" element={<DashboardDetailId />} />
        <Route
          path="dashboard/:id/partner-admin"
          element={<DashboardDetailPA />}
        />

        <Route path="dashboard/error" element={<DashboardMismatch />} />
        <Route
          path="dashboard/:id/error"
          element={<DashboardMissmatchError />}
        />
        <Route
          path="dashboard/:id/missmatch"
          element={<DashboardMissmatchDetail />}
        />
        <Route
          path="dashboard/transaction/:id"
          element={<TransactionDetails />}
        />
        <Route
          path="dashboard/transaction/error/:id"
          element={<TransactionError />}
        />
        <Route
          path="dashboard/transaction/mismatch/:id"
          element={<TransactionMismatch />}
        />

        <Route path="system-parameter" element={<SystemParameter />} />

        <Route path="schedule" element={<Schedule />} />
        <Route path="user" element={<HandlingUser />} />
        <Route path="security/user" element={<UserPO />} />
        <Route path="user-eksternal/:id" element={<UserEksternalDetail />} />
        <Route path="role" element={<RolePage />} />
        <Route path="data-format" element={<DataFormat />} />
        <Route path="data-format/detail" element={<DetailDataFormat />} />
        <Route path="partner" element={<Partner />} />
        <Route path="currency" element={<Currency />} />
        <Route path="partner/detail" element={<DetailPartner />} />
        <Route path="partner-type" element={<PartnerType />} />
        <Route path="product" element={<Product />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contract" element={<Contract />} />
        {/* <Route path='product/detail' element={<DetailProduct />} /> */}
        <Route path="invoice" element={<Billing />} />
        <Route path="invoice/:id" element={<BillingDetail />} />
        <Route path="daily-reconciliation" element={<DailyReconciliation />} />
        <Route path="menu" element={<MenuPage />} />
        <Route path="partner-user" element={<PartnerUser />} />
        <Route path="partner-user/:id" element={<PartnerUserDetail />} />
        <Route path="static-report" element={<StaticReport />} />
        <Route path="view-transaction" element={<ViewTransaction />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="reconcilliation-error/:id"
          element={<ReconciliationError />}
        />
      </Route>
    </Routes>
  );
}
export default App;
