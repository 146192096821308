import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  TableDetailPartnerProduct,
  TableDetailPartnerUser,
  TitleWithBackButton,
} from "../../components";
import { Axios } from "../../config";

export default function DetailPartner() {
  const classes = useStyles();
  let { search } = useLocation();
  const [buttonActive, setButtonActive] = useState("User");
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [detailPartner, setDetailPartner] = useState({});

  const getPartnerByID = () => {
    Axios.get(`/partner/detail/${dataParams.id}`).then((res) => {
      setDetailPartner(res.data.data);
    });
  };

  useEffect(() => {
    getPartnerByID();
  }, []);

  return (
    <Grid className={classes.wrapper}>
      <Grid
        className="header"
        sx={{
          mt: 2,
          pl: 3,
          pt: "28px",
          pb: "28px",
        }}
      >
        <TitleWithBackButton
          title={detailPartner?.partner_name ?? "Partner Name"}
        />
        <Grid mt="24px">
          {["User", "Product Partner"].map((res, i) => (
            <Button
              variant="text"
              onClick={() => setButtonActive(res)}
              sx={{
                color: buttonActive === res ? "#9AB825" : "#687083",
                backgroundColor:
                  buttonActive === res && "rgba(165, 189, 68, 0.1)",
              }}
              key={i}
            >
              {res}
            </Button>
          ))}
        </Grid>
      </Grid>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        {buttonActive === "User" ? (
          <TableDetailPartnerUser id={dataParams?.id} />
        ) : (
          <TableDetailPartnerProduct id={dataParams?.id} />
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  wrapper: {
    "& .header": {
      backgroundColor: "white",
      boxShadow:
        "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
      "& h1": {
        margin: 0,
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    "& buton": {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
