import axios from "axios";
import { getToken } from "./cookies";

export const URL_API_FINPOINT = process.env.REACT_APP_URL_API;

export const AxiosFinpoint = axios.create({
  baseURL: URL_API_FINPOINT,
  headers: {
    "Access-Control-Allow-Origin": "  *",
  },
});

AxiosFinpoint.interceptors.request.use(
  (config) => {
    const tokens = getToken();
    if (tokens) {
      config.headers["Authorization"] = `Bearer ${tokens}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AxiosFinpoint.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const handleErrorInput = (error, val) => {
  let errorVar = error.filter((res) => res.name === val);
  return errorVar.length > 0 ? true : false;
};

export const formatNumber = (number, separator, float) => {
  if (number === "" || number === null) {
    return 0;
  }
  if (!float) {
    if (typeof number === "string") {
      number = number.replaceAll(separator, "");
      number = number.replace(/[^0-9]/g, "");
    }
    return parseFloat(number)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
  } else {
    const regex = /[^\d.]|\.(?=.*\.)/g;
    const subst = ``;
    number = number.replace(regex, subst);
    return number;
  }
};
