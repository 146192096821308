import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomInput,
  ModalComponent,
  TableComponent,
} from "../../../components";
import { Axios } from "../../../config";

export default function TableTypeRow({ dataFormat, getDataDetail }) {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/data_format/${dataFormat?.id}/row/delete/${id}`).then(
          (res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            }).then(() => getDataDetail());
          }
        );
      }
    });
  };

  return (
    <Grid>
      <h4>Record Data Format Type Row</h4>
      <TableComponent
        rows={dataFormat?.data_format_type_row || []}
        column={[
          {
            name: "Action",
            renderCell: (params) => (
              <ActionButton
                onEdit={(e) => {
                  setOpen(params);
                }}
                onDelete={(e) => {
                  handleDelete(params.id);
                }}
              />
            ),
          },
          {
            name: "Parent",
            renderCell: (params) =>
              dataFormat?.data_format_type_row?.find(
                ({ id }) =>
                  id === params?.parent_header_data_format_type_row_id?.Uint
              )?.data_format_type_row_code || "-",
          },
          {
            name: "Data Format Type Row Code",
            renderCell: (params) => params.data_format_row_code_type_code,
          },
          {
            name: "Date Format Row Code Type",
            renderCell: (params) => params.data_format_type_row_code,
          },
          {
            name: "Date Format Type Row Desc",
            renderCell: (params) => params.data_format_type_row_desc,
          },
        ]}
      />
      <h4 className="link" onClick={() => setOpen(true)}>
        + Add New Row
      </h4>
      {open && (
        <ModalTypeColumn
          open={open}
          onClose={onClose}
          dataFormatId={dataFormat?.id}
          getDataDetail={getDataDetail}
          rows={dataFormat?.data_format_type_row}
        />
      )}
    </Grid>
  );
}

const ModalTypeColumn = ({
  open,
  onClose,
  dataFormatId,
  getDataDetail,
  rows,
}) => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {},
  });
  const [parent, setParent] = useState(false);

  useEffect(() => {
    if (open?.id) {
      setValue(
        "parent_header_data_format_type_row_id",
        open?.parent_header_data_format_type_row_id?.Uint
      );
      setValue("data_format_type_row_code", open?.data_format_type_row_code);
      setValue(
        "data_format_row_code_type_code",
        open?.data_format_row_code_type_code
      );
      setValue("data_format_type_row_desc", open?.data_format_type_row_desc);
    }
  }, []);

  const onSubmit = (values) => {
    const value = {
      ...values,
      parent_header_data_format_type_row_id:
        values.parent_header_data_format_type_row_id
          ? +values.parent_header_data_format_type_row_id
          : null,
    };
    onClose();
    if (open.id) {
      Axios.put(
        `/data_format/${dataFormatId}/row/update/${open?.id}`,
        value
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataDetail());
      });
    } else {
      Axios.post(`/data_format/${dataFormatId}/row/create`, value).then(
        (res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          }).then(() => getDataDetail());
        }
      );
    }
  };

  const listCodeType = parent ? ["row-header"] : ["header", "record", "footer"];

  return (
    <ModalComponent open={Boolean(open)} onClose={onClose}>
      <Grid
        conteiner
        style={{
          maxHeight: "90vh",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>{open?.id ? "Edit" : "Add"} Row</h3>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr />
        <form action="" id="add-type-column" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            sx={{
              padding: "24px",
              paddingTop: "4px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <FormControl
              sx={{
                "& .Mui-checked": {
                  color: "#9AB825",
                },
              }}
              disabled={open?.id}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(a, b) => setParent(Boolean(b))}
                value={parent ? "true" : ""}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Parent"
                />
                <FormControlLabel value="" control={<Radio />} label="Child" />
              </RadioGroup>
            </FormControl>
            <Collapse
              in={!parent}
              timeout="auto"
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <CustomInput.Select
                register={{
                  ...register("parent_header_data_format_type_row_id"),
                }}
                label="Parent"
                value={watch("parent_header_data_format_type_row_id") ?? ""}
                marginTop="0px"
                required
              >
                {rows
                  .filter(
                    ({ parent_header_data_format_type_row_id }) =>
                      parent_header_data_format_type_row_id.Uint === 0
                  )
                  .map((val) => (
                    <MenuItem value={val.id}>
                      {val.data_format_type_row_code}
                    </MenuItem>
                  ))}
              </CustomInput.Select>
            </Collapse>
            <CustomInput.Select
              register={{
                ...register("data_format_row_code_type_code"),
              }}
              label="Data Format Row Code Type"
              value={watch("data_format_row_code_type_code") ?? ""}
              marginTop="0px"
              required
            >
              {listCodeType.map((val) => (
                <MenuItem value={val}>{val}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput
              register={{ ...register("data_format_type_row_code") }}
              label="Data Format Type Row Code"
              required
            />
            <CustomInput
              register={{ ...register("data_format_type_row_desc") }}
              label="Data Format Type Row Description"
              required
              multiline
              rows={3}
            />
          </Grid>
        </form>
        <hr />
        <Grid sx={{ padding: "20px" }}>
          <Button
            type="submit"
            form="add-type-column"
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
            fullWidth
          >
            {open?.id ? "Edit" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};
