import { Button, ButtonGroup, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Colors } from "../../assets";
import { URL_API } from "../../config";

export default function CardMerchant({
  title,
  feeData = [],
  settlementData = [],
}) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("Settlement");

  return (
    <Grid component={Paper} width="100%">
      <Grid className={classes.header}>
        <h3>{title}</h3>
        <ButtonGroup className={classes.buttonGroup} variant="string">
          {["Settlement", "Fee"].map((val) => (
            <Button
              key={val}
              type="button"
              onClick={() => {
                setActiveTab(val);
              }}
              className={activeTab === val ? "active" : ""}
            >
              {val}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid className={classes.content}>
        {(activeTab === "Settlement" ? settlementData : feeData).map(
          (el, i) => (
            <div key={i} className={classes.card}>
              <h6 className={classes.number}>{i + 1}</h6>
              <div className={classes.imgContainer}>
                <img
                  alt="merchant-pic"
                  src={`${URL_API}/public/${el.partner_logo}`}
                  className={classes.image}
                />
              </div>
              <div>
                <p className={classes.name}>{el.partner_name}</p>
                <p className={classes.price}>
                  Rp {el.total.toLocaleString("id-ID")}
                </p>
              </div>
            </div>
          )
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  header: {
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E7EB",
  },
  buttonGroup: {
    border: "1px solid #E4E7EB",
    borderRadius: "8px !important",
    overflow: "hidden",
    "& button": {
      fontWeight: 600,
      color: "#687083",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(128, 128, 128, 0.07)!important",
      },
    },
    "& .active": {
      backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      color: Colors.SECONDARY_DARK + "!important",
      "&:hover": {
        color: Colors.SECONDARY_DARK + "!important",
        backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      },
    },
  },
  content: {
    padding: "36px 24px",

    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  number: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "36px",
    color: "#E4E7EB",
  },
  card: { display: "flex", alignItems: "center", gap: "16px" },
  imgContainer: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    background: "#F5F6F7",
    overflow: "hidden",
  },
  image: { width: "100%", height: "100%", objectFit: "cover" },
  name: {
    margin: 0,
    padding: 0,
    color: "#092540",
    fontWeight: 600,
  },
  price: {
    padding: 0,
    margin: 0,
    fontSize: "14px",
    color: "#687083",
  },
});
