import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Colors } from "../../../assets";
import {
  InputComponent,
  ModalComponent,
  RadioInputRow,
} from "../../../components";
import { Axios } from "../../../config";

export default function ModalCurrency({ openModal, closeModal, getDataTable }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const statusList = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  useEffect(() => {
    if (openModal?.id) {
      Axios.get(`/currency/${openModal?.id}`).then(({ data }) => {
        console.log(data.data);
        setValue("currency_code", data.data.currency_code);
        setValue("currency_desc", data.data.currency_desc);
        setValue("is_active_flag", data.data.is_active_flag ? "true" : "false");
      });
    }
  }, []);

  const onSubmit = (value) => {
    const values = {
      ...value,
      is_active_flag: value.is_active_flag === "true" ? true : false,
    };
    if (openModal?.id) {
      Axios.put(`/currency/update/${openModal?.id}`, values)
        .then((res) => {
          if (res.status === 201) {
            closeModal();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post("/currency/create", values)
        .then((res) => {
          if (res.status === 201) {
            closeModal();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ModalComponent open={Boolean(openModal)} onClose={closeModal}>
      <Grid>
        <Grid className={classes.ModalWrapper} container>
          <h3>{openModal?.id ? `Update` : `Create`} Currency</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid className={classes.inputContainer}>
          <form onSubmit={handleSubmit(onSubmit)} id="form-partner">
            <InputComponent
              register={{ ...register("currency_code", { required: true }) }}
              label="Currency Code"
              id="code"
              error={errors}
              errorMessage="Currency Code cannot be empty!"
            />
            <InputComponent
              register={{ ...register("currency_desc", { required: true }) }}
              label="Name"
              id="name"
              error={errors}
              errorMessage="Name cannot be empty!"
            />
            {openModal.id && (
              <Grid>
                <h4 className={classes.label}>Status</h4>
                <Stack
                  className="mt-16"
                  direction="column"
                  spacing={0.5}
                  width="100%"
                >
                  <Controller
                    name="is_active_flag"
                    control={control}
                    defaultValue={statusList[0].value}
                    render={({ field: { onChange, value } }) => (
                      <RadioInputRow
                        name="is_active_flag"
                        data={statusList}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          type="submit"
          form="form-partner"
          className={classes.btnSubmit}
          fullWidth
        >
          {openModal?.id ? `Update` : `Create`} Currency
        </Button>
      </Grid>
    </ModalComponent>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
  },
  ModalWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px 10px 24px",

    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  inputContainer: {
    padding: "24px",
    paddingTop: "4px",
  },
  Input: {
    border: "1px solid",
  },
  Errors: {
    margin: 0,
    color: "red",
    marginTop: "4px",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "6px",
  },
  btnSubmit: {
    backgroundColor: Colors.SECONDARY + "!important",
    "&:hover": {
      backgroundColor: Colors.SECONDARY_DARK + "!important",
    },
    textTransform: "none !important",
    fontWeight: 600 + "!important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
});
