import { Grid, Skeleton } from "@mui/material";
import React from "react";

const DashboardSkeleton = () => {
  return (
    <Grid sx={{ padding: "40px 25px 0px 25px" }}>
      <Grid>
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={300} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} width={500} />
      </Grid>

      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rounded" height={150} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rounded" height={150} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rounded" height={150} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rounded" height={150} />
        </Grid>
      </Grid>

      <Grid sx={{ mt: 5 }}>
        <Skeleton variant="rounded" height={400} />
      </Grid>
    </Grid>
  );
};

export default DashboardSkeleton;
