import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  CssBaseline,
  Grid,
  IconButton,
  List,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Colors, FinpointLogo } from "../../assets";
import { Axios, setUser, setUserRole } from "../../config";
import Copyright from "./Copyright";
import { MainListItems } from "./listitem";
import ProfileMenu from "./Profile";

function DashboardContent({ children }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [dataUser, setDataUser] = useState({});
  const [menus, setMenus] = useState([]);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getDataUser();
    getMenu();
  }, []);

  const getMenu = () => {
    Axios.get("/profil/menu").then((res) => {
      // console.log(res)
      setMenus(res.data.data || []);
    });
  };

  const getDataUser = () => {
    Axios.get("/profil")
      .then((res) => {
        setDataUser(res.data.data);
        setUser(res.data.data);
        setUserRole(res.data.data.currnet_role.code);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img src={FinpointLogo} alt=""></img>
            </Typography>

            <ProfileMenu dataUser={dataUser} classes={classes.profile} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <List
            component="nav"
            sx={{
              marginTop: "85px",
            }}
          >
            <IconButton
              onClick={toggleDrawer}
              sx={{
                ml: 1,
                display: { xs: "inline-block", sm: "none" },
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            {MainListItems({ menus: menus })}
          </List>
        </Drawer>
        <Box component="main" className={classes.mainContainer}>
          <Toolbar />
          <Grid>
            <Outlet />
            <Copyright />
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      position: "absolute !important",
      height: "80px !important",
      backgroundColor: "white !important",
      color: "black !important",
      boxShadow: "none !important",
      borderBottom: "1px solid #dbdbdb !important",
    },
    toolBar: {
      pr: "24px",
      marginTop: "auto",
      marginBottom: "auto",
    },
    mainContainer: {
      backgroundColor: "#F9FAFB",
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      "& button": {
        textTransform: "none",
      },
      paddingBottom: "32px",
    },
    imageSelect: {
      width: "34px",
      height: "20px",
      marginRight: "8px",
      objectFit: "cover",
    },
    profile: {
      "& .name": {
        marginLeft: "10px",
        display: "inline-block",
      },
      "& h3": {
        margin: 0,
        fontWeightRegular: 300,
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& p": {
        margin: 0,
        fontWeightRegular: 400,
        fontSize: "12px",
        color: "#687083",
      },
      display: "flex",
      marginLeft: 24,
      alignItems: "center",
    },
  };
});

const drawerWidth = 272;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: Colors.PRIMARY,
    color: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeightRegular: 400,
    fontSize: 14,
    NeutralLabel: {
      fontWeightRegular: 600,
      fontSize: "14px",
      lineHeight: "20px",
      margin: 0,
    },
  },
  colorPrimary: "#2FB2EB",
  colorSecondary: "#9AB825",
  customContainer: {
    mt: 5,
    mb: 4,
    pl: 3,
    pr: 3,
  },
  buttonGreen: {
    backgroundColor: "#A0B842",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  CardWrapper: {
    boxShadow:
      "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
    border: "1px solid #E4E7EB",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  },
});

export default function Layout(param) {
  return <DashboardContent>{param.children}</DashboardContent>;
}
