import { Grid } from "@mui/material";
import React from "react";
import { TablePartnerUser, TitleWithBackButton } from "../../components";

export default function PartnerUser() {
  return (
    <Grid>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid sx={{ marginBottom: "28px" }}>
          <TitleWithBackButton title="Partner User" hiddenBackButton />
        </Grid>
        <TablePartnerUser />
      </Grid>
    </Grid>
  );
}
