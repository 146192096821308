import { Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterProductPartner({ onApply, onReset, filters }) {
  const [dataPartner, setDataPartner] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      partner_type_id: null,
      data_format_type_id: null,
    }
  );

  const getDataFormat = () => {
    Axios.get("/data_format/product").then(
      ({ data }) => setDataProduct(data?.data) || []
    );
  };

  const getDataPartnerType = () => {
    Axios.get("/partner-type").then(
      ({ data }) => setDataPartner(data?.data) || []
    );
  };

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    getDataPartnerType();
    getDataFormat();
  }, []);
  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Partner Type"
          value={dataFilter?.partner_type_id}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, partner_type_id: target.value })
          }
        >
          {dataPartner.map((val) => (
            <MenuItem value={val.id}>{val?.partner_type_desc}</MenuItem>
          ))}
        </CustomInput.Select>
        <CustomInput.Select
          placeholder="Data Format"
          value={dataFilter?.data_format_type_id}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, data_format_type_id: target.value })
          }
        >
          {dataProduct.map((val) => (
            <MenuItem value={val.id}>{val?.data_format_type_code}</MenuItem>
          ))}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
