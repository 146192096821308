import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TreeItem, TreeView, treeItemClasses } from "@mui/x-tree-view";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CustomAutocomplete } from "../../components";
import { DownloadReport, TreeItemComponent } from "./index";
// import TreeReport from "./TreeReport";

const TreeReport = ({
  expanded,
  errors,
  control,
  watch,
  dataMerchant,
  data,
  dataUser,
  bankCode,
  institutionCode,
  institutionName,
}) => {
  const [selectedData, setSelectedData] = useState([]);
  const [partnerType, setPartnerType] = useState("");
  useEffect(() => {
    let merchant_code = watch("merchant_code");

    if (merchant_code !== "") {
      let dataMerchant = data?.filter(
        (item) => item.merchant_code === merchant_code
      );
      setSelectedData(dataMerchant);
    } else {
      setSelectedData([]);
    }
  }, [watch("merchant_code")]);

  useEffect(() => {
    const partner_type = dataUser?.partner?.partner_types?.name;
    setPartnerType(partner_type);
    setSelectedData(
      data.filter((item) => item.merchant_code === data[0].merchant_code)
    );
    // }
  }, [data]);

  return (
    <TreeView
      sx={{ flexGrow: 1, marginLeft: 5, paddingLeft: 0 }}
      expanded={expanded}
    >
      {partnerType === "Merchant" || partnerType === "merchant" ? (
        <>
          {selectedData.map((dt, i) => (
            <StyledTreeItem
              nodeId="2"
              label={
                <TreeItemComponent
                  text={dayjs(dt.date_report).format("D MMMM YYYY")}
                  isDate
                />
              }
              key={i}
            >
              <StyledTreeItem
                nodeId="4"
                label={
                  <DownloadReport
                    url={dt.path}
                    fileName={dt.report_file_name}
                  />
                }
              />
            </StyledTreeItem>
          ))}
        </>
      ) : (
        <>
          <StyledTreeItem
            nodeId="2"
            aria-label="controlled"
            label={
              <TreeItemComponent
                text={
                  bankCode
                    ? "Bank " + bankCode[0]?.split("-")?.pop()
                    : "bankCode"
                }
              />
            }
          >
            <StyledTreeItem
              nodeId="3"
              label={
                <CustomAutocomplete
                  id="merchant_code"
                  error={errors}
                  value={
                    dataMerchant?.data
                      .filter((val) => val === watch("merchant_code"))
                      .pop() || dataMerchant.data[0]
                  }
                  required={true}
                  label="Merchant"
                  loading={dataMerchant.loading}
                  defaultValue={dataMerchant.data[0]}
                  control={control}
                  name="merchant_code"
                  options={dataMerchant?.data}
                  getOptionLabel={(option) => option}
                  width={"200px"}
                  disableClearable
                  sx={{
                    backgroundColor: "white",
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(errors["merchant_code"])}
                      {...params}
                      placeholder="Select Merchant"
                      margin="normal"
                      sx={{
                        backgroundColor: "white",
                      }}
                    />
                  )}
                />
              }
            >
              {selectedData.map((dt, i) => (
                <StyledTreeItem
                  nodeId="4"
                  label={
                    <TreeItemComponent
                      text={dayjs(dt.date_report).format("D MMMM YYYY")}
                      isDate
                    />
                  }
                  key={i}
                >
                  <StyledTreeItem
                    nodeId="6"
                    label={
                      <DownloadReport
                        url={dt.path}
                        fileName={dt.report_file_name}
                      />
                    }
                  />
                </StyledTreeItem>
              ))}
            </StyledTreeItem>
          </StyledTreeItem>
          {/* start out paling bawah */}
          <StyledTreeItem nodeId="11">
            {data
              ?.filter((item) => item.merchant_code === "")
              .map((dt, i) => (
                <StyledTreeItem
                  nodeId="12"
                  label={
                    <DownloadReport
                      url={dt.path}
                      fileName={dt.report_file_name}
                    />
                  }
                  key={i}
                />
              ))}
          </StyledTreeItem>
          {/* end out paling bawah */}
        </>
      )}
      {/* </StyledTreeItem> */}
    </TreeView>
  );
};

const StyledTreeItem = styled(TreeItem)(({ rootNode, theme }) => {
  const borderColor = "gray";

  return {
    position: "relative",
    top: 0,
    "& .MuiTreeItem-content": {
      padding: 0,
    },
    "& .MuiTreeItem-label": {
      padding: 0,
    },
    "& .MuiTreeItem-iconContainer": {
      display: "none !important",
      padding: 0,
      width: "0px !important",
    },
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 25,
      left: -16,
      top: 30,
      borderBottom: !rootNode ? `1px dotted ${borderColor}` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      // marginLeft: 25,
      paddingLeft: 20,
      borderLeft: `1px dotted ${borderColor}`,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(1),
        // flexDirection: "row-reverse",
      },
    },
  };
});

export default TreeReport;
