import { Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterPartner({
  onApply,
  onReset,
  filters,
  isPatnerUser = null,
}) {
  const [dataPartnerType, setDataPartnerType] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      partner_type: null,
      status: null,
    }
  );

  const getDataProduct = () => {
    Axios.get("/product").then(({ data }) => setDataProduct(data?.data) || []);
  };

  const getDataPartnerType = () => {
    Axios.get("/partner-type").then(
      ({ data }) => setDataPartnerType(data?.data) || []
    );
  };

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    if (!isPatnerUser) getDataProduct();
    getDataPartnerType();
  }, []);
  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Partner Type"
          value={dataFilter?.partner_type}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, partner_type: target.value })
          }
        >
          {dataPartnerType.map((val) => (
            <MenuItem value={val.id}>{val?.partner_type_code}</MenuItem>
          ))}
        </CustomInput.Select>
        {!isPatnerUser && (
          <CustomInput.Select
            placeholder="Status"
            value={dataFilter?.status}
            onChange={({ target }) =>
              setDataFilter({ ...dataFilter, status: target.value })
            }
          >
            {[
              { label: "Active", value: true },
              { label: "Inactive", value: false },
            ].map((val) => (
              <MenuItem value={val.value}>{val.label}</MenuItem>
            ))}
          </CustomInput.Select>
        )}
      </Grid>
    </FilterWrapper>
  );
}
