import { Grid } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const initialChartData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [90, 15, 30, 5],
      backgroundColor: ["#54B371", "#DE5242", "#EA9437", "#D137EA"],
    },
  ],
};

const initialDataCount = [
  {
    count: "90",
    desc: "Success",
    color: "#34774C",
  },
  // {
  //   count: '15',
  //   desc: 'Error',
  //   color: '#A63124',
  // },
  {
    count: "30",
    desc: "Missmatch",
    color: "#A84D20",
  },
  // {
  //   count: '45',
  //   desc: 'Error & Missmatch',
  //   color: '#9620A8',
  // },
];

const PieChart = ({ dataTrx, date, dataCount, chartData }) => {
  const [DatasetChart, setDatasetChart] = useState(chartData);
  const [DataCount, setDataCount] = useState(dataCount);
  const [Config, setConfig] = useState(null);

  useEffect(() => {
    configOption();
    // configData();
  }, []);
  const configData = () => {
    let trxMaping, counting;
    if (dataTrx) {
      trxMaping = {
        labels: [],
        datasets: [
          {
            label: "Tes",
            data: [90, 15, 30, 45],
            backgroundColor: ["#54B371", "#DE5242", "#EA9437", "#D137EA"],
          },
        ],
      };
      counting = initialDataCount;
    } else trxMaping = initialChartData;

    setDataCount(counting);
    setDatasetChart(trxMaping);
  };

  const configOption = () => {
    const cfg = {
      type: "pie",
      data: DatasetChart,
      options: {
        plugins: {
          legend: {
            onHover: handleHover,
            onLeave: handleLeave,
          },
        },
      },
    };
    setConfig(cfg);
  };

  const handleHover = () => {
    console.log("HOVER");
  };
  const handleLeave = () => {
    console.log("LEAVE");
  };

  if (!Config) return null;

  return (
    <Grid
      sx={(theme) => {
        return {
          ...theme.CardWrapper,
          "& h2": {
            margin: 0,
            padding: "24px",
            paddingLeft: "20px",
          },
          "& hr": {
            border: "1px solid #E4E7EB",
            backgroundColor: "#E4E7EB",
          },
          "& .body-chart": {
            padding: "24px",
          },
          "& .content-right": {
            padding: "56px",
          },
          "& .item": {
            "& h2": {
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "36px",
              margin: 0,
              "& .circle": {
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "16px",
                display: "inline-block",
                marginBottom: "2px",
              },
            },
            "& p": {
              margin: 0,
              marginLeft: "30px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#687083",
            },
          },
        };
      }}
      container
    >
      <Grid container display="inline">
        <h2>Transaction</h2>
        <hr />
      </Grid>
      <Grid container className="body-chart">
        <Grid item md={3}>
          <Pie data={DatasetChart} config={Config} />
        </Grid>
        <Grid item md={9} className="content-right">
          <Grid sx={{ height: "100%" }} container>
            {DataCount.map((res) => (
              <Grid item md={6} className="item" sx={{ color: res.color }}>
                <h2>
                  <Grid className="circle" backgroundColor={res.color} />
                  {res.count}
                  <br />
                  <p>{res.desc}</p>
                </h2>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PieChart;
