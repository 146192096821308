import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CustomButton,
  FilterInvoiceDetail,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  FilterObject,
  currencyFormat,
} from "../../config";

function BillingDetail() {
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);

  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    product_id: "",
  });
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([1, 2, 3, 4, 5]);

  const column = [
    {
      name: "Product",
      renderCell: ({ product_desc }) => product_desc,
    },
    {
      name: "Quantity",
      renderCell: ({ quantity }) => quantity,
    },
    {
      name: "Price",
      renderCell: ({ proce }) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {currencyFormat(proce)}
        </Grid>
      ),
    },
    {
      name: "Amount",
      renderCell: ({ amount }) => currencyFormat(amount),
    },
    {
      name: "Description",
      renderCell: ({ billing_item_desc }) => billing_item_desc,
    },
  ];

  const getDataTable = () => {
    setLoading(true);
    Axios.get(
      `/invoice/detail-items/${id}?search=${params.search}&offset=${params.offset}&limit=${params.limit}&page=${params.page}&product_id=${params.product_id}`
    ).then((res) => {
      if (res.data.data === null) setRows([]);
      else setRows(res.data.data);
      setMeta(res.data.meta);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    getDataTable();
  }, [params]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Invoice Detail" />
      <Grid mt="24px">
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={params?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <Grid display="flex" gap="16px">
              <CustomButton.Filter
                open={openFilter}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <FilterInvoiceDetail
                  onApply={(value) => {
                    setParams({ ...params, ...FilterObject(value) });
                    setOpenFilter(false);
                  }}
                  onReset={() => {
                    setParams({ ...params, product_id: null });
                    setOpenFilter(false);
                  }}
                  filters={{ product_id: params.product_id }}
                />
              </CustomButton.Filter>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}

export default BillingDetail;
