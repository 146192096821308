import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";

export default function TableTransactionMismatch({ id }) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);

  const [meta, setMeta] = useState({});
  const [params, setParams] = useState({
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
  });

  const column = [
    {
      name: "ID",
      renderCell: (params) => (loading ? <Skeleton /> : params?.id ?? "-"),
    },
    {
      name: "Date",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          dayjs(params?.date).format("DD MMMM YYYY") ?? "-"
        ),
    },
    {
      name: "Merchant / Bank",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.bank_name ?? "-",
    },
    {
      name: "Principal",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.principal_name ?? "-",
    },
    {
      name: "Count",
      renderCell: (params) => (loading ? <Skeleton /> : params?.count ?? "-"),
    },
  ];

  useEffect(() => {
    getDataTable();
  }, [params]);

  const getDataTable = () => {
    setLoading(true);
    Axios.get(
      `/dashboard/monitoring/detail/list-mismatch/${id}?` +
        ObjectToParams(params)
    )
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setMeta(res.data.meta);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
      />
    </Fragment>
  );
}
