import React from "react";
import Forbidden from "../../components/Global/Forbidden";
import { getUserRole } from "../../config/cookies";
import DashboardMismatchPO from "./DashboardMismatchPO";

function DashboarHandler() {
  const role = getUserRole();
  console.log("dashboard handler role", role);

  if (role === "PO") {
    return <DashboardMismatchPO />;
  } else {
    return <Forbidden />;
  }
}

export default DashboarHandler;
