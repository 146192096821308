import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CustomButton } from "../../../components";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ModalCreateContractPartner = ({
  openModalCreateContract,
  handleCloseModalCreateContract,
  onApply,
  product,
  onChangeProduct,
  contract,
  onChangeContract,
  contractStatus,
  onChangeContractStatus,
  productList,
  contractList,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth={"xs"}
        open={openModalCreateContract}
        onClose={handleCloseModalCreateContract}
      >
        <BootstrapDialogTitle onClose={handleCloseModalCreateContract}>
          Create Contract Partner
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p className={classes.label}>Product</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={product}
            onChange={onChangeProduct}
          >
            {productList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.partner_name}
                </MenuItem>
              );
            })}
          </TextField>

          <p className={classes.label}>Contract</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={contract}
            onChange={onChangeContract}
          >
            {contractList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.contract_no}
                </MenuItem>
              );
            })}
          </TextField>

          <p className={classes.label}>Status</p>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={contractStatus}
              name="radio-buttons-group"
              onChange={onChangeContractStatus}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            onClick={onApply}
            style={{
              width: "100%",
              height: "40px",
            }}
          >
            Save Changes
          </CustomButton>{" "}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles({
  input: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "6px",
      width: "344px",
    },
  },
  hiddenActions: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1A3650",
  },
});

export default ModalCreateContractPartner;
