import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { formatNumber } from "../../config/helper";

const ButtonSubmitDate = ({ onAccept, onCancel, onClear, onSetToday }) => {
  return (
    <DialogActions>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#A0B842",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
        }}
        onClick={onAccept}
      >
        Submit
      </Button>
    </DialogActions>
  );
};

export default function InputComponent({
  label,
  onChange,
  type,
  multiline,
  height,
  width,
  placeholder,
  id,
  disabled,
  marginTop,
  error,
  value,
  children,
  errorMessage,
  register,
  sx,
  multiple,
  renderValue,
  control,
  name = "",
  startAdornment,
  endAdornment,
  isFloat,
  fontSize,
  textAlign,
  inputType,
  showButton = false,
  formatDate,
  viewFormat,
  customIcon = false,
  withMinutes = false,
  required,
  variant = "outlined",
  readOnly = false,
  autocomplete = false,
}) {
  const [showPassword, setShowPassword] = useState(false);

  let isError =
    typeof error === "object"
      ? error[id]
      : typeof error === "boolean"
      ? error
      : false;

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <Grid
      sx={{
        "& h4": {
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          marginBottom: "6px",
          marginTop: marginTop || "20px",
        },
        "& .msgError": {
          margin: 0,
          marginTop: "4px",
          color: "red",
        },
        width: width,
      }}
    >
      {label && <h4>{label}</h4>}
      {type === "select" ? (
        <FormControl
          disabled={disabled}
          fullWidth
          sx={{ backgroundColor: "white", ...sx }}
          id={id}
          helperText="fgal"
        >
          <Select
            labelId={id}
            id={id}
            error={Boolean(isError)}
            multiple={multiple}
            onChange={onChange || register?.onChange}
            value={value || "select"}
            name={id}
            size="small"
            label={label}
            renderValue={renderValue}
            inputProps={{
              ...register,
            }}
          >
            {children}
          </Select>
        </FormControl>
      ) : type === "checkbox" ? (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup id={id}>
            <FormControlLabel control={children} label="Gilad Gray" />
          </FormGroup>
        </FormControl>
      ) : type === "password" ? (
        <FormControl fullWidth variant="outlined">
          <TextField
            id={id}
            size="small"
            placeholder={label || placeholder}
            type={!showPassword && "password"}
            onChange={onChange || register?.onChange}
            disabled={disabled}
            error={Boolean(isError)}
            InputProps={{
              ...register,
              autoComplete: "new-password",
              sx: {
                fontSize: "13px",
              },
              form: {
                autoComplete: "off",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      ) : type === "date" ? (
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          render={() => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat={formatDate || "DD/MM/YYYY"}
                onChange={(event) => {
                  let e = {
                    target: {
                      value: dayjs(event).format("MM-DD-YYYY 00:00:00"),
                      id: id,
                    },
                  };
                  if (withMinutes) {
                    onChange(dayjs(event).format("DD/MM/YYYY"));
                  } else {
                    onChange(e);
                  }
                }}
                disabled={disabled}
                value={value}
                id={id}
                // {...restField}
                // closeOnSelect={name === 'start_date' ? true : false}
                closeOnSelect={showButton ? false : true}
                views={viewFormat || ["day"]}
                components={
                  (showButton
                    ? { ActionBar: ButtonSubmitDate }
                    : { ActionBar: null },
                  { OpenPickerIcon: customIcon ? customIcon : null })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyDown={onKeyDown}
                    variant="outlined"
                    size="small"
                    required={required}
                    sx={{ backgroundColor: "white", ...sx }}
                    readOnly
                    autoComplete={autocomplete}
                    // helpertext={params?.error ? errorMessage : "f"}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      ) : type === "number" ? (
        <Controller
          name={name}
          id={id}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <FieldInputOutline
              name={name}
              id={id}
              value={value ? formatNumber(value, ",", isFloat) : ""}
              // value={value}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              // required
              error={Boolean(isError)}
              // helperText={errorMessage}
              fullWidth
              placeholder={placeholder ? placeholder : label}
              inputProps={{
                style: {
                  height: height ? height : "40px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  fontSize: fontSize ? fontSize : "13px",
                  textAlign: textAlign ? textAlign : "left",
                },
              }}
            />
          )}
        />
      ) : (
        <TextField
          disabled={disabled}
          id={id}
          placeholder={placeholder ? placeholder : label}
          variant={variant}
          fullWidth
          sx={{
            margin: 0,
            backgroundColor: "white",
            "& .MuiInputBase-multiline": {
              border: "1px solid",
              padding: "0!important",
              paddingTop: "10px!important",
            },
            ...sx,
          }}
          multiline={multiline}
          onChange={onChange ? onChange : register?.onChange}
          error={Boolean(isError)}
          value={value}
          // autoComplete={false}
          // autoComplete='off'

          autoComplete="new-password"
          type={inputType || type || "text"}
          // helperText={errorMessage}
          inputProps={{
            ...register,
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
            readOnly: readOnly,
            style: {
              height: height ? height : "40px",
              padding: "0px",
              paddingLeft: "10px",
              fontSize: fontSize ? fontSize : "13px",
              textAlign: textAlign ? textAlign : "left",
            },
          }}
        />
      )}
      {Boolean(isError) && (
        <p className="msgError">
          {errorMessage ? errorMessage : `${label || id} cannot be empty!`}{" "}
        </p>
      )}
    </Grid>
  );
}

const CssTextField = withStyles({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },
    "& label.Mui-focused": {
      color: "#A0B842",
    },
    "& input.Mui-disabled": {
      backgroundColor: grey[100],
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#A0B842",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: grey[300],
      },
      "&:hover fieldset": {
        borderColor: "#A0B842",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A0B842",
      },
      "&.Mui-error fieldset": {
        borderColor: `${red[500]}!important`,
      },
    },
  },
})(TextField);

// eslint-disable-next-line import/prefer-default-export
export const FieldInputOutline = (props) => {
  const {
    id,
    onChange,
    onBlur,
    style,
    sx,
    value,
    placeholder,
    startAdornment,
    endAdornment,
    fullWidth,
    readOnly,
    error,
    helperText,
    inputProps,
    ref,
    params,
    field,
    multiline,
    minRows,
    required,
    type,
    disabled,
    name,
    defaultValue,
  } = props;
  return (
    <CssTextField
      id={id}
      multiline={multiline}
      minRows={minRows}
      style={style}
      defaultValue={defaultValue}
      type={type}
      sx={sx}
      required={required}
      FormHelperTextProps={{
        sx: {
          fontSize: 12,
          marginLeft: 1,
        },
      }}
      variant="outlined"
      value={value}
      placeholder={placeholder}
      size="small"
      name={name}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment,
        endAdornment,
        inputProps,
        sx: {
          fontSize: 15,
          borderRadius: 1,
          backgroundColor: error ? red[50] : disabled ? grey[100] : "white",
        },
      }}
      {...field}
      {...params}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};
