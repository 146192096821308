import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const CollapseComponentPrivacy = ({
  children,
  header,
  title,
  disableCollapse,
  noLine,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <h4>{title}</h4>
      <Grid className={classes.title}>
        {header}
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          {children}
        </Collapse>
        {!disableCollapse && (
          <h4 className={classes.readMore} onClick={() => setOpen(!open)}>
            {!open ? "Read More" : "Read Less"}
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </h4>
        )}
      </Grid>
      {!noLine && <hr />}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    "& hr": {
      borderBottom: "1px solid #BCC1CC",
    },
  },
  title: {
    marginLeft: "24px",
    "& h4": {
      alignItems: "center",
      display: "flex",
      color: "#97AD3E!important",
      "& svg": {
        marginLeft: "9px",
      },
    },
  },
  readMore: {
    cursor: "pointer",
  },
}));

export default CollapseComponentPrivacy;
