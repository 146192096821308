import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Axios, DEFAULT_PAGINATION_LIMIT, ObjectToParams } from "../../config";

const useDashboardFSA = (active, initialParams) => {
  const [dataProduct, setDataProduct] = useState([]);
  const [dataDashboard, setDataDashboard] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    search: "",
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    product: null,
    date: dayjs(new Date()).format("MM-YYYY"),
    institution: null,
    ...initialParams,
  });
  const [meta, setMeta] = useState({});

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (params.product) getDashboard();
  }, [params.product, params.date]);

  useEffect(() => {
    setLoading(true);
    if (active === "Reconciliation Queue") getReconQueue();
    else if (active === "Ongoing Reconciliation") getOngoingReconQueue();
    else if (active === "Error") getError();
    else getMismatch();
  }, [active, params]);

  const getDashboard = () => {
    Axios.get(`/dashboard?product=${params.product}&date=${params.date}`)
      .then((res) => {
        setDataDashboard(res.data.data);
      })
      .catch(() => {
        console.info("error get dashboard");
      });
  };

  const getProduct = () => {
    Axios.get("/product")
      .then((res) => {
        setDataProduct(res.data.data);
        setParams((prevParams) => ({
          ...prevParams,
          product: res.data.data[0]?.id,
        }));
      })
      .catch(() => {
        console.info("Error get product");
      });
  };

  const getMismatch = () => {
    Axios.get(`/dashboard/mismatch-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res.data.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getError = () => {
    Axios.get(`/dashboard/error-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res.data.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getOngoingReconQueue = () => {
    Axios.get(`/dashboard/ongoin-reconciliation?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res.data.meta);
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const getReconQueue = () => {
    Axios.get(`/dashboard/reconciliation-queue?` + ObjectToParams(params))
      .then((res) => {
        setRows(res.data.data);
        setMeta(res.data.meta);
      })
      .catch(() => {
        console.info("Error get recon");
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  return {
    dataProduct,
    dataDashboard,
    rows,
    loading,
    params,
    meta,
    setParams,
    setLoading,
  };
};

export default useDashboardFSA;
