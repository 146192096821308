import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Axios } from "../../../config";

const ItemParameter = ({ data, getData, dataParam, setDataParam }) => {
  const classes = useStyles();
  const [editProses, setEditProses] = useState(!data.id);
  const [value, setValue] = useState(data);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSave = () => {
    if (value.value) {
      if (value.id) {
        Axios.put(`/parameter/update/${value.id}`, {
          value: value.value,
        }).then((res) => {
          Swal.fire({
            title: "Success",
            text: `Success edit parameter`,
            icon: "success",
          }).then(() => {
            getData();
            setEditProses(false);
          });
        });
      } else {
        Axios.post("/parameter/create", value).then((res) => {
          Swal.fire({
            title: "Success",
            text: `Success add new parameter`,
            icon: "success",
          }).then(() => {
            getData();
            setEditProses(false);
          });
        });
      }
    } else {
      setError(true);
    }
  };
  const handleEdit = () => {
    if (data.id) {
      setEditProses(false);
      setValue({ ...data });
    } else {
      setDataParam(dataParam.filter((val) => val !== data));
    }
    setError(false);
  };

  useEffect(() => {
    setValue(data);
    setEditProses(!data.id);
  }, [data, data.id]);

  return (
    <Grid className={classes.cardWrapper}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Text successfully copied!
        </Alert>
      </Snackbar>
      <Grid
        className="header-content"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>{value.title}</h2>
        <Grid gap="100px">
          {value.data_type !== "integer" && !editProses && (
            <Tooltip title="Copy text">
              <IconButton
                onClick={() => {
                  setOpen(true);
                  navigator.clipboard.writeText(value.value);
                }}
                aria-label="delete"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {editProses && (
            <Button
              className="ButtonNormal"
              variant="outlined"
              size="small"
              onClick={handleEdit}
              sx={{ marginRight: "16px" }}
            >
              Cancel
            </Button>
          )}
          {editProses ? (
            <Button
              className="active"
              variant="contained"
              size="small"
              onClick={() => handleSave()}
            >
              Save Changes
            </Button>
          ) : (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => setEditProses(true)}
                aria-label="delete"
              >
                <EditNoteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid className="content" container alignItems="center">
        {value.data_type === "integer" ? (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            container
          >
            <Typography>
              Number of maximum parallel reconciliation process per node
            </Typography>
            <TextField
              error={error}
              id="outlined-basic"
              variant="outlined"
              size="small"
              helperText={error && "Value cannot be empty!"}
              sx={{
                width: "80px",
                marginLeft: "16px",
                backgroundColor: "#F9FAFB",
              }}
              disabled={!editProses}
              value={value?.value}
              onChange={(e) => setValue({ ...value, value: e.target.value })}
              inputProps={{
                style: {
                  textAlign: "center",
                },
              }}
            />
          </Grid>
        ) : (
          <>
            {editProses ? (
              <TextField
                error={error}
                placeholder={`Input ${value.title}`}
                variant="outlined"
                value={value?.value}
                size="small"
                sx={{ width: "600px" }}
                onChange={(e) => setValue({ ...value, value: e.target.value })}
                helperText={error && "Value cannot be empty!"}
              />
            ) : (
              <Typography>{value.value}</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  cardWrapper: {
    border: "1px solid #E4E7EB",
    borderRadius: "10px",
    backgroundColor: "white",
    marginBottom: "20px",
    filter:
      "drop-shadow(0px 0.5px 2px rgba(65, 78, 98, 0.12)) drop-shadow(0px 0px 1px rgba(65, 78, 98, 0.05))",
    "& .header-content": {
      padding: "8px 23px 8px 23px",
      borderBottom: "1px solid #E4E7EB",
      "& h2": {
        margin: 0,
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "30px",
        textTransform: "capitalize",
      },
      "& .ButtonNormal": {
        margin: "5px 10px 5px 0px",
        color: "black",
        borderColor: "#D1D5DC",
      },
      "& .active": {
        backgroundColor: "#A0B842",
        color: "white",
        "&:hover": {
          backgroundColor: "#83992A",
        },
      },
    },
    "& .content": {
      padding: "23px",
      borderBottom: "1px solid #E4E7EB",

      "& p": {
        lineHeight: "20px",
        textTransform: "capitalize",
      },
    },
  },
  Button: {
    border: "1px solid red",
    color: "red",
  },
  Divider: {
    borderBottom: "1px solid #E4E7EB",
  },
});

export default ItemParameter;
