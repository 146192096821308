import { Grid, MenuItem } from "@mui/material";
import { useState } from "react";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterInvoice({ onApply, onReset, filters }) {
  const [dataFilter, setDataFilter] = useState(
    filters || {
      billing_cycle_type: null,
      status: null,
    }
  );

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
      sx={{ position: "absolute", top: "12px", right: "-54px", zIndex: 1 }}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Billing Cycle Type"
          value={dataFilter?.billing_cycle_type}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, billing_cycle_type: target.value })
          }
        >
          {[
            { value: "D", label: "Daily" },
            { value: "W", label: "Weekly" },
            { value: "M", label: "Monthly" },
          ].map((val) => (
            <MenuItem value={val.value}>{val.label}</MenuItem>
          ))}
        </CustomInput.Select>
        <CustomInput.Select
          placeholder="Status"
          value={dataFilter?.status}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, status: target.value })
          }
        >
          {[
            { label: "Active", value: "true" },
            { label: "Inactive", value: "false" },
          ].map((val) => (
            <MenuItem value={val.value}>{val.label}</MenuItem>
          ))}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
