import { Container, Grid } from "@mui/material";
import React from "react";
import FinpointLogo from "../../assets/images/finpoint-logo.png";

export default function FooterLandingPage() {
  return (
    <Container>
      <Grid mt="80px" container>
        <Grid
          item
          md={4}
          sx={{
            "& p": {
              fontWeight: 300,
              fontSize: "16px",
              lineHeight: "24px",
              marginTop: "32px",
              // fontFamily: ["Open Sans", "sans-serif"],
              color: "#2C3038",
            },
          }}
        >
          <img src={FinpointLogo} alt="" />
          <p>
            PT Finpoint Solusi Indonesia <br /> Ebenezer Building <br />{" "}
            Setiabudi Selatan, Jakarta <br />{" "}
            <a href="https://www.info@finpoint.id">info@finpoint.id</a> <br />{" "}
            (+6221) 5799 4700
          </p>
        </Grid>
        <Grid
          item
          md={8}
          sx={{
            "& h2": {
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "24px",
              // fontFamily: ["Open Sans", "sans-serif"],
            },
            "& .MuiGrid-root": {
              marginTop: "24px",
              "& a": {
                textDecoration: "none",
                fontWeight: 300,
                fontSize: "16px",
                lineHeight: "24px",
                // fontFamily: ["Open Sans", "sans-serif"],
                color: "#555A64",
                display: "flex",
                marginBottom: "12px",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
            },
          }}
          container
          justifyContent="space-between"
          gap="24px"
          mt={{ xs: "24px", md: "0px" }}
        >
          <div item md={4} xs={12}>
            <h2>Our Solutions</h2>
            <Grid>
              <a href="/digital-banking-platform">Digital Banking Platform​</a>
              <a href="/fraud-management-solution">
                Fraud Management Solutions​
              </a>
              <a href="/electronic-payment-solution">
                Electronic Payment Solutions
              </a>
            </Grid>
          </div>
          <div item md={4} xs={6}>
            <h2>Support</h2>
            <Grid>
              <a href="/contact-us">Contact Us</a>
              <a href="/terms-and-conditions">Terms & Conditions</a>
              <a href="/privacy-policy">Privacy Policy​</a>
            </Grid>
          </div>
          <div item md={4} xs={6}>
            <h2>Company</h2>
            <Grid>
              <a href="/about-us">About Us</a>
              <a href="/about-us/#partner">Partner</a>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          textAlign: "center",
          paddingTop: "24px",
          paddingBottom: "24px",
          borderTop: "1px solid #EAEDF2",
          marginTop: "64px",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "24px",
          // fontFamily: ["Open Sans", "sans-serif"],
        }}
      >
        Copyright © Finpoint {process.env.REACT_APP_VERSION}
      </Grid>
    </Container>
  );
}
