import CancelIcon from "@mui/icons-material/Cancel";
import HeightIcon from "@mui/icons-material/Height";
import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomChip, TitleWithBackButton } from "../../components";
import { Axios } from "../../config/helper";

const DashboardMissmatchError = () => {
  const classes = useStyles();
  const params = useParams();
  const [dataDetail, setDataDetail] = useState({});

  useEffect(() => {
    getDetailError();
  }, []);

  const getDetailError = () => {
    Axios.get(`dashboard/error-reconciliation/detail/${params?.id}`).then(
      (res) => setDataDetail(res?.data?.data)
    );
  };

  return (
    <>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <TitleWithBackButton
          title={`Error Detail: Transaction #ID-${dataDetail?.rns_process_schedule_id}`}
        />
        <Grid sx={{ boxShadow: "1" }} className={classes.ListRoot} container>
          <Grid
            className={classes.ListWrapper}
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingRight: "8px" }}
          >
            <h4 style={{ fontSize: "11px", fontWeight: "600" }}>
              {`#ID-${dataDetail?.rns_process_schedule_id}`}
              <NoiseControlOffIcon
                sx={{
                  fontSize: "12px",
                  mx: "8px",
                }}
              />{" "}
              {dayjs(dataDetail?.rns_process_start_at).format(
                "MMM D, YYYY h:mm A"
              )}
            </h4>
            <CustomChip
              label="Error"
              size="small"
              variant="outlined"
              color="error"
            />
          </Grid>
          <Grid
            sx={{
              border: "1px solid #E4E7EB",
              padding: "16px",
              backgroundColor: "white",
              "& img": {
                paddingLeft: "24px",
                paddingRight: "24px",
              },
            }}
            container
          >
            <Grid
              item
              sx={{
                paddingRight: "40px",
                color: "#687083",
              }}
            >
              <h4>
                {`${
                  dataDetail?.merchant_partner &&
                  dataDetail?.merchant_partner + " / "
                }` + dataDetail?.bank_partner}
              </h4>
            </Grid>
            <Box
              sx={{ display: "flex", alignItems: "center", marginX: "20px" }}
            >
              <HeightIcon
                sx={{
                  rotate: "90deg",
                }}
              />
            </Box>
            <Grid
              item
              sx={{
                paddingRight: "40px",
                color: "#687083",
              }}
              alignItems="center"
            >
              <h4>{dataDetail?.principal_partner || "-"}</h4>
            </Grid>

            <Grid
              item
              sx={{
                paddingRight: "40px",
                color: "#687083",
                ml: "24px",
                pl: "24px",
                borderLeft: "1px solid #E4E7EB",
              }}
            >
              <h5>
                {dataDetail?.count ?? 1}{" "}
                <CancelIcon
                  fontSize="small"
                  color="error"
                  sx={{ marginLeft: "4px" }}
                />
              </h5>
              <p>Error</p>
            </Grid>
          </Grid>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    py: "6px",
                    px: "16px",
                    fontWeight: 600,
                    color: "#687083",
                  }}
                >
                  Detail
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white" }}>
              <TableCell>{dataDetail?.rns_process_status_desc}</TableCell>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((a, b) => ({
  ListRoot: {
    borderRadius: "6px",
    marginTop: "28px",
    "& h4": {
      padding: "11px",
      margin: 0,
      color: "#687083",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      margin: 0,
      color: "black",
      display: "flex",
      marginBottom: "4px",
      marginTop: "5px",
      alignItems: "center",
      "& img": {
        padding: 0,
        marginLeft: "4px",
      },
    },
    "& p": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      padding: 0,
      margin: 0,
      marginTop: "5px",
    },
  },
  ListWrapper: {
    backgroundColor: "#F9FAFB",
    borderRadius: "6px 6px 0px 0px",
    border: "1px solid #E4E7EB",
    borderBottom: "none",
  },
}));

export default DashboardMissmatchError;
