import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { BpRadio, InputComponent, ModalComponent } from "../../../components";
import { Axios, checkRole, getUser, getUserRole } from "../../../config";

const useStyles = makeStyles({
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});

const initialData = {
  name: "",
  email: "",
  password: "",
  role_id: 1,
};

export default function ModalUser({
  openModal,
  closeModal,
  getDataTable,
  partnerId = null,
  accessFrom = "",
}) {
  const [dataRole, setDataRole] = useState([]);
  const classes = useStyles();
  const [menuSelect, setMenuSelect] = useState([]);
  const [dataUser, setDataUser] = useState(initialData);
  const [checked, setChecked] = useState(false);
  const [LDAP, setLDAP] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState();
  const [TheRole, setTheRole] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      // partner_type_id: null,
    },
  });

  useEffect(() => {
    setValue("is_ldap", openModal.is_ldap ? true : false);
    setLDAP(openModal.is_ldap ? true : false);
    let user = getUser();
    let role = getUserRole();

    if (openModal?.id) {
      setValue("name", openModal?.name);
      setValue("email", openModal?.email);
      setValue("role_id", openModal?.user_role);
      setValue("username", openModal?.username);
      // setChecked(openModal?.is_active);
      setMenuSelect(openModal?.user_role);
    } else {
      setMenuSelect([]);
    }
    Axios.get("/role").then((res) => {
      if (res.status === 200) {
        let data = checkRole(user, res.data.data);
        setTheRole(role);
        setDataRole(data);
      }
    });
  }, []);

  const onSubmit = async (values) => {
    values = {
      ...values,
      role_id: values.role_id.map((val) => val.id),
      is_ldap: values.is_ldap === "true",
    };
    setIsLoading(true);
    setIsError(null);
    if (openModal?.id) {
      await Axios.put(
        `/user/update${openModal.id ? "/" + openModal.id : ""}`,
        values
      )
        .then((res) => {
          closeModal();
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Succesfully updated partner",
          }).then(() => getDataTable());
        })
        .catch((err) => {
          setIsError(err.message);
          if (err.response)
            if (err.response.data.errors)
              setIsError(err.response.data.errors[0].message);
        });
    } else {
      await Axios.post(
        `/user/create${partnerId ? "/" + partnerId : ""}`,
        values
      )
        .then((res) => {
          closeModal();
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Succesfully added partner",
          }).then(() => getDataTable());
        })
        .catch((err) => {
          setIsError(err.message);
          if (err.response)
            if (err.response.data.errors)
              setIsError(err.response.data.errors[0].message);
        });
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);
    if (event.target.name === "is_ldap")
      setLDAP(event.target.value === "true" ? true : false);
  };

  return (
    <ModalComponent open={Boolean(openModal)} onClose={closeModal}>
      <Grid
        className={classes.ModalWrapper}
        conteiner
        style={{
          maxHeight: "90vh",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>{openModal.id ? "Edit" : "Create"} User</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid sx={{ padding: "24px", paddingTop: "4px" }}>
          <form action="" id="add-user-form" onSubmit={handleSubmit(onSubmit)}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              register={{ ...register("is_ldap") }}
              value={openModal.is_ldap}
              defaultValue={false}
              onChange={handleChange}
            >
              <FormControlLabel
                value={false}
                control={<BpRadio />}
                label="Non LDAP"
                name="is_ldap"
                disabled={openModal?.id}
              />
              <FormControlLabel
                value={true}
                control={<BpRadio />}
                label="LDAP"
                name="is_ldap"
                disabled={openModal?.id}
              />
            </RadioGroup>
            <InputComponent
              label="Role"
              type="select"
              register={{ ...register("role_id", { required: true }) }}
              error={Boolean(errors["role_id"])}
              multiple
              value={menuSelect}
              disabled={IsLoading}
              renderValue={(selected) =>
                selected.map((res) => res.name).join(", ")
              }
              onChange={(event, a) => {
                const {
                  target: { value },
                } = event;
                if (menuSelect.some((res) => res.id === a.props.value.id)) {
                  setMenuSelect(
                    value.filter((val) => val.id !== a.props.value.id)
                  );
                } else {
                  setMenuSelect(
                    typeof value === "string" ? value.split(",") : value
                  );
                }
              }}
            >
              {dataRole.map((res) => (
                <MenuItem value={res}>
                  <Checkbox
                    checked={menuSelect.some((val) => val.id === res.id)}
                  />
                  {res.name}
                </MenuItem>
              ))}
            </InputComponent>
            <InputComponent
              label="Name"
              id="name"
              disabled={IsLoading}
              register={{ ...register("name", { required: true }) }}
              error={Boolean(errors["name"])}
            />
            <InputComponent
              label="Username"
              id="username"
              disabled={IsLoading}
              register={{ ...register("username", { required: true }) }}
              error={Boolean(errors["username"])}
            />
            <InputComponent
              label="Email"
              id="email"
              disabled={IsLoading}
              register={{ ...register("email", { required: true }) }}
              error={Boolean(errors["email"])}
            />
            {!LDAP && (
              <>
                <InputComponent
                  label="Password"
                  type="password"
                  disabled={IsLoading}
                  register={{
                    ...register("password", {
                      required: openModal.id ? false : true,
                    }),
                  }}
                  error={openModal.id ? false : Boolean(errors["password"])}
                />
                {openModal.id && !IsError && (
                  <p style={{ color: "gray", fontSize: 14 }}>
                    Leave it empty if you won't to change
                  </p>
                )}
                <InputComponent
                  label="Confirm Password"
                  type="password"
                  disabled={IsLoading}
                  register={{
                    ...register("confirmation_password"),
                  }}
                  error={
                    openModal.id
                      ? false
                      : Boolean(errors["confirmation_password"])
                  }
                />
              </>
            )}
            {Boolean(IsError) && (
              <p style={{ color: "red" }}>
                {IsError ? IsError : `Field cannot be empety!`}{" "}
              </p>
            )}
          </form>
        </Grid>
        <hr className={classes.Divider} />
        <Grid sx={{ padding: "20px" }}>
          <Button
            type="submit"
            form="add-user-form"
            disabled={IsLoading}
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
            fullWidth
          >
            {openModal.id ? "Edit" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
}
