import { Button, Grid, MenuItem } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CustomInput, InputComponent, ModalComponent } from "../../components";
import { Axios } from "../../config";

export default function ModalSchedule({ open, onClose, getDataTable }) {
  const [dataPartner, setDataPartner] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFormat, setDataFormat] = useState([]);
  const [time, setTime] = useState({
    hours: "",
    minute: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  const getDataPartner = () => {
    Axios.get("/partner?status=false").then(
      ({ data }) => setDataPartner(data?.data) || []
    );
  };

  const getDataProduct = () => {
    Axios.get("/product").then(({ data }) => setDataProduct(data?.data) || []);
  };

  const getDataFormat = () => {
    Axios.get("/data_format/product").then(
      ({ data }) => setDataFormat(data?.data) || []
    );
  };

  const getScheduleDetail = (id) => {
    Axios.get(`/schadule/detail/${id}`).then((res) => {
      const data = res.data.data;
      setValue("rns_schedule_code", data?.rns_schedule_code);
      setValue("rns_schedule_desc", data?.rns_schedule_desc);
      setValue("rns_partner_id", data?.partner?.id);
      setValue("data_format_type_id", data?.data_format_type?.id);
      setValue("rns_interval_flag", data?.rns_interval_flag);
      setValue("last_rns_process_status_flag", data?.rns_interval_flag);
      setValue(
        "product_id",
        data?.rns_process_schedule_product?.map((val) => val.product.id)
      );
    });
  };

  useEffect(() => {
    if (open?.id) getScheduleDetail(open?.id);
    getDataPartner();
    getDataProduct();
    getDataFormat();
  }, []);

  const onSubmit = (val) => {
    const value = {
      ...val,
      product_id: [val.product_id],
      rns_start_at: `${getValues("rns_start_at")} ${time?.hours}:${
        time.minute
      }`,
      next_process_at: "",
    };
    onClose();
    if (open?.id) {
      Axios.put(`/schadule/update/${open?.id}`, value).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataTable());
      });
    } else {
      Axios.post("/schadule/create", value).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataTable());
      });
    }
  };

  const timeStyle = {
    "&::-webkit-outer-spin-button, & *::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  };

  // console.log(watch('product_id'));
  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={open?.id ? "Update Schedule" : "Create Schedule"}
    >
      <Grid conteiner>
        <Grid sx={{ padding: "24px", paddingTop: "4px", paddingRight: "0px" }}>
          <form id="create" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              display="flex"
              flexDirection="column"
              gap="20px"
              height="560px"
              overflow={"auto"}
              paddingRight="24px"
            >
              <CustomInput
                label="Schedule Code"
                placeholder="Input Schedule Code"
                required
                register={{
                  ...register("rns_schedule_code"),
                }}
              />
              <CustomInput
                label="Schedule Desc"
                placeholder="Input Schedule Desc"
                multiline
                rows={3}
                required
                register={{
                  ...register("rns_schedule_desc"),
                }}
              />
              <CustomInput.Select
                value={watch("rns_partner_id") ?? null}
                label="Partner"
                placeholder="Select Partner"
                marginTop="0px"
                required
                register={{
                  ...register("rns_partner_id"),
                }}
              >
                {dataPartner.map((val) => (
                  <MenuItem value={val.id}>{val?.partner_name}</MenuItem>
                ))}
              </CustomInput.Select>
              <CustomInput.Select
                value={watch("product_id") ?? null}
                label="Product"
                placeholder="Select Product"
                marginTop="0px"
                required
                register={{
                  ...register("product_id"),
                }}
              >
                {dataProduct.map((val) => (
                  <MenuItem value={val.id}>{val?.product_desc}</MenuItem>
                ))}
              </CustomInput.Select>
              <CustomInput.Select
                value={watch("data_format_type_id") ?? null}
                label="Data Format"
                placeholder="Original Data Format"
                marginTop="0px"
                required
                register={{
                  ...register("data_format_type_id"),
                }}
              >
                {dataFormat.map((val) => (
                  <MenuItem value={val.id}>
                    {val?.data_format_type_code}
                  </MenuItem>
                ))}
              </CustomInput.Select>
              <CustomInput.Select
                value={watch("rns_interval_flag") ?? null}
                label="Interval"
                placeholder="Select Interval"
                marginTop="0px"
                required
                register={{
                  ...register("rns_interval_flag"),
                }}
              >
                {[
                  "Daily",
                  "Weekly",
                  "Biweekly",
                  "Monthly",
                  "Quaterly",
                  "Semesterly",
                  "Yearly",
                ].map((val) => (
                  <MenuItem value={val[0]}>{val}</MenuItem>
                ))}
              </CustomInput.Select>
              <Grid display="flex" alignItems="flex-end" gap="12px">
                <InputComponent
                  value={
                    moment(watch("rns_start_at"), "DD/MM/YYYY") ?? undefined
                  }
                  label="Start at"
                  marginTop="0px"
                  type="date"
                  id="date"
                  name="date"
                  control={control}
                  error={errors}
                  formatDate={"DD/MM/YYYY"}
                  viewFormat={["day", "month", "year"]}
                  withMinutes
                  register={{ ...register("rns_start_at") }}
                  sx={{ width: "100%" }}
                  required
                  onChange={(e) => {
                    setValue("rns_start_at", e);
                  }}
                />
                <Grid display="flex" gap="12px" alignItems="center">
                  <CustomInput
                    width="60px"
                    min={0}
                    max={23}
                    type="number"
                    sx={timeStyle}
                    required
                    onChange={({ target }) =>
                      setTime({ ...time, hours: target.value })
                    }
                  />{" "}
                  :
                  <CustomInput
                    width="60px"
                    min={0}
                    max={59}
                    type="number"
                    sx={timeStyle}
                    required
                    onChange={({ target }) =>
                      setTime({ ...time, minute: target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid display="flex" alignItems="flex-end" gap="12px">
                <InputComponent
                  value={
                    moment(watch("rns_start_at"), "DD/MM/YYYY") ?? undefined
                  }
                  label="Next Process at"
                  marginTop="0px"
                  type="date"
                  disabled
                  id="date"
                  name="date"
                  control={control}
                  error={errors}
                  formatDate={"DD/MM/YYYY"}
                  viewFormat={["day", "month", "year"]}
                  withMinutes
                  register={{ ...register("rns_start_at") }}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setValue("rns_start_at", e);
                  }}
                />
                <Grid display="flex" gap="12px" alignItems="center">
                  <CustomInput
                    width="60px"
                    min={0}
                    max={23}
                    sx={timeStyle}
                    disabled
                    required
                    onChange={({ target }) =>
                      setTime({ ...time, hours: target.value })
                    }
                  />{" "}
                  :
                  <CustomInput
                    width="60px"
                    min={0}
                    max={59}
                    sx={timeStyle}
                    disabled
                    required
                    onChange={({ target }) =>
                      setTime({ ...time, minute: target.value })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <hr />
      <Grid sx={{ padding: "20px" }}>
        <Button
          type="submit"
          form="create"
          variant="contained"
          sx={{
            backgroundColor: "#A0B842",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
          }}
          fullWidth
        >
          {open?.id ? "Update" : "Create"}
        </Button>
      </Grid>
    </ModalComponent>
  );
}
