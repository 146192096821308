import { Button, Container, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { DividerImage, MapBanner } from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function ContactUs() {
  const classes = useStyles();

  return (
    <Fragment>
      <ButtonAppBar />
      <section className={classes.root}>
        <section className={classes.banner}>
          <Container>
            <h1>We are here to help</h1>
            <p>
              Ebenezer Building
              <br />
              Setiabudi Selatan, Jakarta
            </p>
            <Grid
              display="flex"
              gap={{ md: "80px", xs: "12px" }}
              className="content"
            >
              <div>
                <h4>Email Us</h4>
                <h3>info@finpoint.id</h3>
              </div>
              <div>
                <h4>Call any time</h4>
                <h3>(+6221) 5799 4700</h3>
              </div>
            </Grid>
          </Container>
          <img src={MapBanner} alt="" />
        </section>
        <section className={classes.bodyContent}>
          <Container>
            <Grid
              sx={{
                marginLeft: { md: "202px", sx: "0px" },
                marginRight: { md: "202px", sx: "0px" },
                paddingTop: { md: "120px", xs: "24px" },
                paddingBottom: { md: "120px", xs: "24px" },
              }}
            >
              <Grid
                container
                className="content"
                spacing={{ md: "24px", xs: "12px" }}
              >
                <Grid item xs={12}>
                  <p>
                    Further case studies available upon request. Please provide
                    some information on your goals and we’ll move the
                    conversation on from there.
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <h3>Full Name:</h3>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-contact"
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3>Email Address:</h3>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-contact"
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3>Company (optional):</h3>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-contact"
                  />
                </Grid>
                <Grid item xs={6}>
                  <h3>Phone (optional):</h3>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-contact"
                  />
                </Grid>
                <Grid item xs={12}>
                  <h3>Phone (optional):</h3>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-contact"
                    multiline
                    inputProps={{}}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#97AD3E",
                    }}
                  >
                    {" "}
                    Send Message{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <img src={DividerImage} alt="" className="divider" />
        </section>
        <FooterLandingPage />
      </section>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
      fontFamily: ["Raleway", "sans-serif"],
      "& button": {
        textTransform: "none",
      },
      "& h1,h2,h3,h4,p": {
        margin: 0,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "48px",
      },
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      display: "flex",
      position: "relative",
      "& h1": {
        [theme.breakpoints.up("md")]: {
          marginTop: "168px",
          fontWeight: 300,
          fontSize: "54px",
          lineHeight: "56px",
          width: "588px",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "18px",
          width: "160px",
        },
      },
      "& p": {
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: "64px",
        width: "588px",
        marginTop: "32px",
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "8px",
          lineHeight: "12px",
          width: "200px",
          marginTop: "12px",
          marginBottom: "12px",
        },
      },
      "& img": {
        position: "absolute",
        height: "100%",
        right: "0px",
      },
      "& .content": {
        "& h4": {
          [theme.breakpoints.down("md")]: {
            fontWeight: 600,
            fontSize: "8px",
            lineHeight: "12px",
          },
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
        },
        "& h3": {
          [theme.breakpoints.down("md")]: {
            fontWeight: 600,
            fontSize: "8px",
            lineHeight: "12px",
          },
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "30px",
          color: "#01AEF2",
          marginTop: "8px",
        },
        [theme.breakpoints.down("md")]: {
          paddingBottom: "24px",
        },
        paddingBottom: "200px",
      },
    },
    bodyContent: {
      backgroundColor: "#F7F9FC",
      "& .divider": {
        width: "100%",
        marginBottom: "-3px",
      },
      "& .content": {
        "& p": {
          [theme.breakpoints.down("md")]: {
            marginBottom: "24px",
          },
          fontWeight: 300,
          fontSize: "20px",
          lineHeight: "30px",
          marginBottom: "48px",
        },
        "& h3": {
          marginBottom: "8px",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
        },
        "& .input-contact": {
          backgroundColor: "white",
          "& .MuiOutlinedInput-input": {
            [theme.breakpoints.down("md")]: {
              padding: "6px",
            },
            padding: "12px",
            paddingLeft: "20px",
          },
        },
      },
    },
  };
});
