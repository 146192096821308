import { Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterInvoiceDetail({ onApply, onReset, filters }) {
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      product_id: null,
    }
  );

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    (() => {
      Axios.get("/product").then((res) => {
        setDataProduct(res.data.data);
      });
    })();
  }, []);

  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Select Product"
          value={dataFilter?.product_id}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, product_id: target.value })
          }
        >
          {dataProduct.map((val) => (
            <MenuItem value={val.id}>{val.product_desc}</MenuItem>
          ))}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
