import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import FinpointLogo from "../../assets/images/finpoint-logo.png";

const pages = ["Solution", "Support", "About Us"];

const ButtonAppBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [idMenu, setIdMenu] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [openChild, setOpenChild] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (e, page) => {
    if (page !== "About Us") {
      setAnchorElNav(e.currentTarget);
      setIdMenu(e.target.id);
    } else {
      navigate("/about-us");
    }
  };

  const menuChild = () => {
    let menus = [];
    if (idMenu === "Solution") {
      menus.push(
        {
          name: "Digital Banking Platform​",
          url: "/digital-banking-platform",
        },
        {
          name: "Fraud Management Solutions​",
          url: "/fraud-management-solution",
        },
        {
          name: "Electronic Payment Solutions​",
          url: "/electronic-payment-solution",
        }
      );
    } else if (idMenu === "Support") {
      menus.push(
        {
          name: "Contact Us​",
          url: "/contact-us",
        },
        {
          name: "Terms & Conditions​",
          url: "/terms-and-conditions",
        },
        {
          name: "Privacy Policy​",
          url: "/privacy-policy",
        }
      );
    }
    return menus;
  };

  const menuChildSecond = (res) => {
    let menus = [];
    if (res === "Solution") {
      menus.push(
        {
          name: "Digital Banking Platform​",
          url: "/digital-banking-platform",
        },
        {
          name: "Fraud Management Solutions​",
          url: "/fraud-management-solution",
        },
        {
          name: "Electronic Payment Solutions​",
          url: "/electronic-payment-solution",
        }
      );
    } else if (res === "Support") {
      menus.push(
        {
          name: "Contact Us​",
          url: "/contact-us",
        },
        {
          name: "Terms & Conditions​",
          url: "/terms-and-conditions",
        },
        {
          name: "Privacy Policy​",
          url: "/privacy-policy",
        }
      );
    }
    return menus;
  };

  const handleClickChild = (val) => {
    if (val === "About Us") {
      navigate("/about-us");
    } else {
      if (openChild === val) {
        setOpenChild(null);
      } else {
        setOpenChild(val);
      }
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #dbdbdb",
      }}
    >
      <Container
        sx={{
          "& button": {
            textTransform: "none",
            my: 2,
            color: "#9b9da0",
            display: "block",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "30px",
            fontFamily: ["Nunito!important", "sans-serif"],
          },
        }}
      >
        <Toolbar disableGutters>
          <Grid sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img src={FinpointLogo} alt="" />
            </Link>
          </Grid>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
                "& .MuiIconButton-colorInherit": {
                  margin: 0,
                  marginTop: "8px",
                },
              },
            }}
          >
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            onClick={() => navigate("/")}
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={FinpointLogo} alt="" />
          </Typography>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                  gap: "32px",
                },
              }}
            >
              {pages.map((page, i) => (
                <Button
                  key={i}
                  id={page}
                  onClick={(e) => {
                    handleCloseNavMenu();
                    handleClick(e, page);
                  }}
                  endIcon={page !== "About Us" && <ArrowDropDownIcon />}
                  sx={{ display: "flex!important" }}
                >
                  {page}
                </Button>
              ))}
              <Button
                variant="contained"
                sx={{
                  color: "white!important",
                  padding: "8px",
                  paddingLeft: "28px",
                  paddingRight: "28px",
                  backgroundColor: "#97AD3E",
                  "&:hover": {
                    backgroundColor: "#83992A",
                  },
                }}
                onClick={(e) => navigate("/login")}
                size="small"
              >
                Sign In
              </Button>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {menuChild().map((res, i) => {
                return (
                  <MenuItem
                    onClick={(e) => {
                      handleCloseNavMenu();
                    }}
                    sx={{
                      "& a": {
                        textDecoration: "none",
                        color: "#555A64",
                      },
                    }}
                  >
                    <Link to={res.url}>
                      <Typography textAlign="center">{res.name}</Typography>
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        </Toolbar>
      </Container>
      <Grid
        sx={{ display: { xs: "flex", md: "none" }, color: "black" }}
        fullWidth
      >
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          {pages.map((res, i) => (
            <Grid key={i}>
              <ListItemButton
                onClick={() => handleClickChild(res)}
                fullWidth
                sx={{
                  paddingTop: "6px",
                  paddingBottom: "6px",
                }}
              >
                <ListItemText primary={res} />
              </ListItemButton>
              <Collapse
                in={openChild === res}
                timeout="auto"
                unmountOnExit
                sx={{
                  width: "100%",
                  "& a": {
                    color: "black",
                    textDecoration: "none",
                  },
                }}
              >
                {menuChildSecond(res).map((val) => {
                  return (
                    <Link to={val.url}>
                      <ListItemButton
                        fullWidth
                        sx={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          marginLeft: "10px",
                        }}
                      >
                        <ListItemText primary={val.name} />
                      </ListItemButton>
                    </Link>
                  );
                })}
              </Collapse>
            </Grid>
          ))}
        </Collapse>
      </Grid>
    </AppBar>
  );
};
export default ButtonAppBar;
