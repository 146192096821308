import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import React from "react";
import { CustomButton } from "../../../components";

const useStyles = makeStyles({
  input: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "6px",
      width: "344px",
    },
  },
  hiddenActions: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1A3650",
  },
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ModalCreateContract = ({
  openModalCreateContract,
  handleCloseModalCreateContract,
  handleChangeContractNumber,
  contractDate,
  hoursContractDate,
  minuteContractDate,
  secondContractDate,
  handleOpenModalContractDate,
  openModalContractDate,
  handleCloseModalContractDate,
  onChangeContractDate,
  handleChangeHoursContractDate,
  handleChangeMinuteContractDate,
  handleChangeSecondContractDate,
  billingType,
  onChangeBillingType,
  billingDate,
  hoursBillingDate,
  minuteBillingDate,
  secondBillingDate,
  handleOpenModalBillingDate,
  handleCloseModalBillingDate,
  openModalBillingDate,
  onChangeBillingDate,
  handleChangeHoursBillingDate,
  handleChangeMinuteBillingDate,
  handleChangeSecondBillingDate,
  lastBillingDate,
  hoursLastBillingDate,
  minuteLastBillingDate,
  secondLastBillingDate,
  handleOpenModalLastBillingDate,
  handleCloseModalLastBillingDate,
  openModalLastBillingDate,
  onChangeLastBillingDate,
  handleChangeHoursLastBillingDate,
  handleChangeMinuteLastBillingDate,
  handleChangeSecondLastBillingDate,
  feeFlag,
  onChangeFeeFlag,
  contractEndDate,
  hoursContractEndDate,
  minuteContractEndDate,
  secondContractEndDate,
  handleOpenModalContractEndDate,
  handleCloseModalContractEndDate,
  openModalContractEndDate,
  onChangeContractEndDate,
  handleChangeHoursContractEndDate,
  handleChangeMinuteContractEndDate,
  handleChangeSecondContractEndDate,
  onApply,
  contractStatus,
  onChangeContractStatus,
  amountFeeFlag,
  onChangeAmountFeeFlag,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        maxWidth={"xs"}
        open={openModalCreateContract}
        onClose={handleCloseModalCreateContract}
      >
        <BootstrapDialogTitle onClose={handleCloseModalCreateContract}>
          Create Contract
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p className={classes.label}>Contract Number</p>
          <TextField
            name="contract-number"
            type="number"
            onChange={handleChangeContractNumber}
            className={classes.input}
          />

          <p className={classes.label}>Contract Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${dayjs(contractDate).format(
              "DD MMMM YYYY"
            )} (${hoursContractDate}:${minuteContractDate}:${secondContractDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalContractDate}
          />

          <p className={classes.label}>Billing Cycle Type</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={billingType}
            onChange={onChangeBillingType}
          >
            <MenuItem value={"D"}>Daily</MenuItem>
            <MenuItem value={"W"}>Weekly</MenuItem>
            <MenuItem value={"M"}>Monthly</MenuItem>
          </TextField>

          <p className={classes.label}>Billing Cycle Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${dayjs(billingDate).format(
              "DD MMMM YYYY"
            )} (${hoursBillingDate}:${minuteBillingDate}:${secondBillingDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalBillingDate}
          />

          <p className={classes.label}>Last Billing Cycle Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${dayjs(lastBillingDate).format(
              "DD MMMM YYYY"
            )} (${hoursLastBillingDate}:${minuteLastBillingDate}:${secondLastBillingDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalLastBillingDate}
          />

          <p className={classes.label}>Include Fee Flag</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={feeFlag}
            onChange={onChangeFeeFlag}
          >
            <MenuItem value={"T"}>True</MenuItem>
            <MenuItem value={"F"}>False</MenuItem>
          </TextField>

          <p className={classes.label}>Amount Fee Flag</p>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.input}
            value={amountFeeFlag}
            onChange={onChangeAmountFeeFlag}
          >
            <MenuItem value={"T"}>True</MenuItem>
            <MenuItem value={"F"}>False</MenuItem>
          </TextField>

          <p className={classes.label}>Contract End Date</p>

          <TextField
            id="input-with-icon-textfield"
            value={`${dayjs(contractEndDate).format(
              "DD MMMM YYYY"
            )} (${hoursContractEndDate}:${minuteContractEndDate}:${secondContractEndDate})`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalContractEndDate}
          />
          <p className={classes.label}>Status</p>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={contractStatus}
              name="radio-buttons-group"
              onChange={onChangeContractStatus}
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            onClick={onApply}
            style={{
              width: "100%",
              height: "40px",
            }}
          >
            Save Changes
          </CustomButton>{" "}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalContractDate}
        onClose={handleCloseModalContractDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={contractDate}
              onChange={onChangeContractDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursContractDate}
              onChange={handleChangeHoursContractDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteContractDate}
              onChange={handleChangeMinuteContractDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondContractDate}
              onChange={handleChangeSecondContractDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openModalBillingDate} onClose={handleCloseModalBillingDate}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={billingDate}
              onChange={onChangeBillingDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursBillingDate}
              onChange={handleChangeHoursBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteBillingDate}
              onChange={handleChangeMinuteBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondBillingDate}
              onChange={handleChangeSecondBillingDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalLastBillingDate}
        onClose={handleCloseModalLastBillingDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={lastBillingDate}
              onChange={onChangeLastBillingDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursLastBillingDate}
              onChange={handleChangeHoursLastBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteLastBillingDate}
              onChange={handleChangeMinuteLastBillingDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondLastBillingDate}
              onChange={handleChangeSecondLastBillingDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalContractEndDate}
        onClose={handleCloseModalContractEndDate}
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={contractEndDate}
              onChange={onChangeContractEndDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursContractEndDate}
              onChange={handleChangeHoursContractEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteContractEndDate}
              onChange={handleChangeMinuteContractEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondContractEndDate}
              onChange={handleChangeSecondContractEndDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalCreateContract;
