import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BasicCard, DataHeading, InputComponent } from "../../components";
import { URL_API, currencyFormat, getUser } from "../../config";
import { useDashboardPA } from "../../hooks";

const DashboardPA = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    register,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { body, dataDashboard, dataMonitoring, handleChange } = useDashboardPA(
    new Date()
  );

  return (
    <Box
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <DataHeading
        title={`Welcome ${getUser().name}!`}
        endElement={
          <InputComponent
            value={body.date}
            type="date"
            id="date"
            name="date"
            control={control}
            error={errors}
            sx={{ width: "100%" }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        }
      />

      <Box className={classes.itemsContainer}>
        <BasicCard
          title="Ongoing Reconciliation"
          value={dataDashboard?.ongoing_reconciliation || 0}
        />
        <BasicCard
          title="Failed Reconciliation"
          value={dataDashboard?.failed_reconciliation || 0}
        />
      </Box>

      <Grid className={classes.cardWrapper}>
        <Grid className="content" container justifyContent="space-between">
          <h2>Partner Monitoring</h2>
        </Grid>
        <div className={classes.Divider} />
        <Box className="content" container alignItems="center" display="flex">
          <Grid container spacing="20px">
            {dataMonitoring?.map((dt, i) => (
              <Grid
                key={i}
                xs={12}
                container
                sx={{
                  paddingTop: "20px",
                  paddingBottom: i === dataMonitoring.length - 1 ? "0" : "20px",
                  borderBottom:
                    i === dataMonitoring.length - 1
                      ? "none"
                      : "1px solid #E4E7EB",
                }}
              >
                <Grid
                  item
                  xs={2}
                  className={classes.imgContainer}
                  onClick={() =>
                    navigate({
                      pathname: `/dashboard/${
                        dt?.merchant?.id ?? 1
                      }/partner-admin?date=${moment(body.date).format(
                        "DD-MM-YYYY"
                      )}`,
                      state: {
                        id: dt?.id ?? 1,
                      },
                    })
                  }
                >
                  <img src={URL_API + `/public/${dt?.merchant?.logo}`} alt="" />
                </Grid>

                <Grid item xs={5}>
                  <Box lassName={classes.transactionCountContainer}>
                    <Box className={classes.transactionContent}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Transaction Count
                      </Typography>
                      <Box sx={{ my: "4px", marginRight: "7px" }}>
                        <Typography className={classes.transactionTitle}>
                          {dt?.transction_count || 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Total Amount
                      </Typography>
                      <Typography>
                        {currencyFormat(dt?.total_amount)}
                      </Typography>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>Fee</Typography>
                      <Typography>{currencyFormat(dt?.fee)}</Typography>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Settlement Amount
                      </Typography>
                      <Typography>
                        {currencyFormat(dt?.settlement_amount)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={5}>
                  <Box sx={{ paddingLeft: "20px" }}>
                    <Box className={classes.transactionContent}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Mismatch Transaction Count
                      </Typography>
                      <Box sx={{ my: "4px", marginRight: "7px" }}>
                        <Typography className={classes.failedTitle}>
                          {dt?.mismatch_transction_count || 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Mismatch Total Amount
                      </Typography>
                      <Typography>
                        {currencyFormat(dt?.mismatch_total_amount)}
                      </Typography>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Missmatch Fee
                      </Typography>
                      <Typography>
                        {currencyFormat(dt?.mismatch_fee)}
                      </Typography>
                    </Box>
                    <Box className={classes.transactionItems}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Missmatch Settlement Amount
                      </Typography>
                      <Typography>
                        {currencyFormat(dt?.mismatch_settlement_amount)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  itemsContainer: { marginTop: "24px", display: "flex", gap: "24px" },
  cardWrapper: {
    border: "1px solid #E4E7EB",
    borderRadius: "12px",
    backgroundColor: "white",
    marginBottom: "16px",
    marginTop: "24px",
    filter:
      "drop-shadow(0px 0.5px 2px rgba(65, 78, 98, 0.12)) drop-shadow(0px 0px 1px rgba(65, 78, 98, 0.05))",
    "& .content": {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "24px",
      paddingRight: "24px",
      "& h2": {
        margin: 0,
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "30px",
      },
      "& p": {
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& img": {
        marginLeft: "8px",
      },
      "& .ButtonNormal": {
        color: "black",
        borderColor: "#D1D5DC",
        fontWeight: 550,
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& .active": {
        backgroundColor: "#A0B842",
        color: "white",
      },
    },
  },
  transactionCountContainer: {
    borderRight: "1px solid #E4E7EB",
    paddingRight: "20px",
  },
  transactionContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EFFCF4",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "16px",
    borderRadius: "6px",
    marginBottom: "5px",
  },
  transactionTitle: {
    px: "10px",
    backgroundColor: "#54B371",
    color: "white",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  failedTitle: {
    px: "10px",
    backgroundColor: "#DE5242",
    color: "white",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  transactionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "16px",
  },
  failedContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FCF3F2",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "16px",
    borderRadius: "6px",
    marginBottom: "5px",
  },
  Button: {
    border: "1px solid red",
    color: "red",
  },
  Divider: {
    borderBottom: "1px solid #E4E7EB",
  },
  imgContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    "& img": {
      width: "100%",
      padding: "12px",
      paddingRight: "48px",
      paddingTop: 0,
    },
  },
});

export default DashboardPA;
