import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CustomInput, ModalComponent } from "../../../components";
import { Axios } from "../../../config";

export default function ModalPricingNew({
  openModal,
  closeModal,
  getDataTable,
}) {
  const classes = useStyles();
  const [active, setActive] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      // partner_type_id: null,
      price_range: [
        {
          start_range: 0,
          end_range: 0,
          price_value: 0,
        },
      ],
      price_scheme_type_code: "mdr",
    },
  });

  const onSubmit = (value) => {
    let values = {
      ...value,
      price_range: value.price_range.map(
        ({ start_range, end_range, price_value }) => ({
          start_range: +start_range,
          end_range: +end_range,
          price_value: +price_value,
        })
      ),
    };
    if (openModal?.id) {
      Axios.put(`/pricing/update/${openModal?.id}`, values).then((res) => {
        closeModal();
        Swal.fire({
          icon: res.status === 200 ? "success" : "error",
          title: res.status === 200 ? "Success" : "Error",
          text: res.data.message || "Berhasil Edit Assign Pricing",
        }).then(() => getDataTable());
      });
    } else {
      Axios.post("/pricing/create", values)
        .then((res) => {
          closeModal();
          Swal.fire({
            icon: res.status === 201 ? "success" : "error",
            title: res.status === 201 ? "Success" : "Error",
            text: res.data.message || "Berhasil Edit Assign Pricing",
          }).then(() => getDataTable());
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              err?.response?.data?.errors?.[0]?.message ||
              "Internal server error",
          });
        });
    }
  };

  const addMore = () => {
    console.log(getValues("price_range"));
    const befValues = getValues("price_range");
    befValues.push({
      start_range: 0,
      end_range: 0,
      price_value: 0,
    });
    setValue("price_range", befValues);
  };

  useEffect(() => {
    if (openModal?.id) {
      getPricingDetail();
    }
  }, []);

  const getPricingDetail = () => {
    Axios.get(`pricing/detail/${openModal?.id}`).then(({ data }) => {
      const {
        price_code,
        name,
        price_desc,
        price_scheme_type_code,
        price_model_type_code,
        price_range,
        price_value,
      } = data?.data;
      console.log(data?.data);
      setValue("price_code", price_code);
      setValue("name", name);
      setValue("price_desc", price_desc);
      setValue("price_scheme_type_code", price_scheme_type_code);
      setValue("price_model_type_code", price_model_type_code);
      setValue("price_range", price_range);
      setValue("price_value", price_value);
    });
  };

  return (
    <ModalComponent
      open={Boolean(openModal)}
      onClose={closeModal}
      fullSize
      backgroundColor="#F5F6F7"
    >
      <Grid className={classes.header}>
        <Grid container alignItems="center">
          <Grid className="button-wrapper">
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <h3>Create Pricing bossku</h3>
        </Grid>
        <Button
          color="primary"
          type="submit"
          form="modalPricing"
          className="button"
          variant="outlined"
        >
          {openModal?.id ? "Update" : "Create"}
        </Button>
      </Grid>

      <Grid container justifyContent="center">
        <Grid className={classes.Content}>
          <form action="" onSubmit={handleSubmit(onSubmit)} id="modalPricing">
            <CustomInput
              register={{ ...register("price_code") }}
              required
              label="Pricing Code"
            />
            <CustomInput
              register={{ ...register("name") }}
              required
              label="Pricing Name"
              mt="24px"
            />
            <CustomInput
              register={{ ...register("price_desc") }}
              required
              label="Pricing Desc"
              mt="24px"
            />

            <Grid className="Content">
              <h3>Pricing Scheme</h3>
              <RadioGroup
                {...register("price_scheme_type_code", {
                  onChange: (e) =>
                    setValue("price_scheme_type_code", e.target.value),
                })}
                value={watch("price_scheme_type_code") ?? null}
              >
                <Grid className="item-wrapper">
                  <Grid
                    className={`item ${
                      watch("price_scheme_type_code") === "fixed" && "active"
                    }`}
                    container
                  >
                    <Grid className="header" container>
                      <FormControlLabel
                        value="fixed"
                        control={<Radio />}
                        label="Fixed Price"
                      />
                    </Grid>
                    {watch("price_scheme_type_code") === "fixed" && (
                      <Collapse
                        in={watch("price_scheme_type_code") === "fixed"}
                      >
                        <Grid className="body">
                          <CustomInput
                            type="number"
                            onChange={({ target }) =>
                              setValue("price_value", +target.value)
                            }
                            value={
                              watch("price_value") > 0
                                ? watch("price_value")
                                : null
                            }
                            label="Price per transaction"
                            width="240px"
                            startAdornment="Rp"
                            required
                            min={1}
                          />
                        </Grid>
                      </Collapse>
                    )}
                  </Grid>
                </Grid>

                <Grid className="item-wrapper" mt="18px">
                  <Grid
                    className={`item ${
                      watch("price_scheme_type_code") === "mdr"
                    }`}
                    container
                  >
                    <Grid className="header" container>
                      <FormControlLabel
                        type="number"
                        value="mdr"
                        control={<Radio />}
                        label="MDR (Merchant Discount Rate)"
                      />
                    </Grid>
                    {watch("price_scheme_type_code") === "mdr" && (
                      <Collapse in={watch("price_scheme_type_code") === "mdr"}>
                        <Grid className="body">
                          <CustomInput
                            type="number"
                            value={
                              watch("price_value") > 0
                                ? watch("price_value")
                                : null
                            }
                            onChange={({ target }) => {
                              setValue("price_scheme_type_code", "mdr");
                              setValue("price_value", +target.value);
                            }}
                            label="Percentage of transaction amount"
                            width="240px"
                            required
                            endAdornment="%"
                            min={1}
                            max={100}
                          />
                        </Grid>
                      </Collapse>
                    )}
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid className="Content">
              <h3>Pricing Model</h3>
              <RadioGroup
                onChange={({ target }) => {
                  const { value } = target;
                  if (value !== getValues("price_model_type_code")) {
                    setValue("price_range", [
                      {
                        start_range: 0,
                        end_range: 0,
                        price_value: watch("price_value"),
                      },
                    ]);
                  }
                  setValue("price_model_type_code", value);
                  setValue(
                    "price_scheme_type_code",
                    watch("price_scheme_type_code") ?? "mdr"
                  );
                }}
                value={watch("price_model_type_code") ?? null}
              >
                <Grid className="item-wrapper">
                  <Grid
                    className={`item ${
                      watch("price_model_type_code") === "flat" && "active"
                    }`}
                    container
                  >
                    <Grid className="header" container>
                      <FormControlLabel
                        value="flat"
                        control={<Radio />}
                        label="Flat"
                      />
                    </Grid>
                    <Collapse in={watch("price_model_type_code") === "flat"}>
                      <Grid className="body">
                        {watch("price_scheme_type_code") === "fixed" ? (
                          <CustomInput
                            value={watch("price_range[0].price_value") ?? null}
                            onChange={({ target }) =>
                              setValue(
                                "price_range[0]?.price_value",
                                target.value
                              )
                            }
                            label="Price / transaction"
                            width="240px"
                            startAdornment="Rp"
                            disabled
                          />
                        ) : (
                          <CustomInput
                            value={watch("price_range[0]?.price_value") ?? null}
                            onChange={({ target }) =>
                              setValue(
                                "price_range[0].price_value",
                                target.value
                              )
                            }
                            label="Percentage / transaction"
                            width="240px"
                            endAdornment="%"
                            disabled
                            max={100}
                          />
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>

                <Grid mt="16px" className="item-wrapper">
                  <Grid
                    className={`item ${
                      watch("price_model_type_code") === "tier" && "active"
                    }`}
                    container
                  >
                    <Grid className="header" container>
                      <FormControlLabel
                        value="tier"
                        control={<Radio />}
                        label="Tier / Progressive"
                      />
                    </Grid>
                    <Collapse in={watch("price_model_type_code") === "tier"}>
                      {watch("price_model_type_code") === "tier" && (
                        <Grid className="body">
                          <table>
                            <thead>
                              <tr>
                                <td>Tier</td>
                                <td>Transaction</td>
                                <td>
                                  {watch("price_scheme_type_code") === "fixed"
                                    ? "Price"
                                    : "Percentage"}{" "}
                                  / Transaction
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {watch("price_range").map((val, i) => {
                                return (
                                  <tr>
                                    <td className="column">
                                      <span>Tier {i + 1}</span>
                                    </td>
                                    <td>
                                      <Grid display="flex" alignItems="center">
                                        <CustomInput
                                          value={
                                            watch(
                                              `price_range[${i}].start_range`
                                            ) ?? null
                                          }
                                          required
                                          register={{
                                            ...register(
                                              `price_range[${i}].start_range`
                                            ),
                                          }}
                                          width="110px"
                                          type="number"
                                          min={
                                            +watch("price_range")?.[i - 1]
                                              ?.end_range + 1 || 1
                                          }
                                        />
                                        <Grid ml="8px" mr="8px">
                                          -
                                        </Grid>
                                        <CustomInput
                                          value={watch(
                                            `price_range[${i}].end_range`
                                          )}
                                          required
                                          register={{
                                            ...register(
                                              `price_range[${i}].end_range`
                                            ),
                                          }}
                                          width="110px"
                                          type="number"
                                          min={
                                            +watch(
                                              `price_range[${i}]?.start_range`
                                            )
                                              ? +watch(
                                                  `price_range[${i}]?.start_range`
                                                ) + 1
                                              : null
                                          }
                                        />
                                      </Grid>
                                    </td>
                                    <td>
                                      <CustomInput
                                        required
                                        startAdornment={
                                          watch("price_scheme_type_code") ===
                                            "fixed" && "Rp"
                                        }
                                        endAdornment={
                                          watch("price_scheme_type_code") ===
                                            "mdr" && "%"
                                        }
                                        width="160px"
                                        value={watch(
                                          `price_range[${i}].price_value`
                                        )}
                                        register={{
                                          ...register(
                                            `price_range[${i}].price_value`
                                          ),
                                        }}
                                        type="number"
                                        disabled={i === 0}
                                        min={1}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Grid mt="24px" className="addMore" onClick={addMore}>
                            + Add More Tier
                          </Grid>
                        </Grid>
                      )}
                    </Collapse>
                  </Grid>
                </Grid>

                <Grid mt="16px" className="item-wrapper">
                  <Grid
                    className={`item ${
                      watch("price_model_type_code") === "volume" && "active"
                    }`}
                    container
                  >
                    <Grid className="header" container>
                      <FormControlLabel
                        value="volume"
                        control={<Radio />}
                        label="Volume Pricing"
                      />
                    </Grid>
                    <Collapse in={watch("price_model_type_code") === "volume"}>
                      {watch("price_model_type_code") === "volume" && (
                        <Grid className="body">
                          <table>
                            <thead>
                              <tr>
                                <td>Tier</td>
                                <td>Transaction</td>
                                <td>
                                  {watch("price_scheme_type_code") === "fixed"
                                    ? "Price"
                                    : "Percentage"}{" "}
                                  / Transaction
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {watch("price_range").map((val, i) => {
                                return (
                                  <tr>
                                    <td className="column">
                                      <span>Option {i + 1}</span>
                                    </td>
                                    <td>
                                      <CustomInput
                                        value={
                                          watch(
                                            `price_range[${i}].start_range`
                                          ) ?? null
                                        }
                                        register={{
                                          ...register(
                                            `price_range[${i}].start_range`
                                          ),
                                        }}
                                        width="160px"
                                        type="number"
                                      />
                                    </td>
                                    <td>
                                      <CustomInput
                                        value={
                                          watch("price_range")[i].end_range
                                        }
                                        register={{
                                          ...register(
                                            `price_range[${i}].end_range`
                                          ),
                                        }}
                                        width="200px"
                                        type="number"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Grid mt="24px" className="addMore" onClick={addMore}>
                            + Add More Option
                          </Grid>
                        </Grid>
                      )}
                    </Collapse>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </ModalComponent>
  );
}

const useStyles = makeStyles({
  header: {
    padding: "26px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: "white",
    "& .button-wrapper": {
      borderRight: "1px solid #E4E7EB",
      marginRight: "16px",
      paddingRight: "16px",
      "& svg": {
        color: "#9AA2B1",
      },
    },
    "& .button": {
      textTransform: "none",
      color: "white",
      backgroundColor: "#9AB825",
      border: "none",
    },
  },
  Content: {
    width: "1016px",
    marginTop: "32px",
    marginBottom: "32px",
    backgroundColor: "white",
    boxShadow:
      "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
    borderRadius: "12px",
    padding: "24px",
    "& .Content": {
      "& h3": {
        marginTop: "24px",
        marginBottom: "16px",
      },
    },
    "& .item-wrapper": {
      "& .active": {
        border: "1px solid #9AB825!important",
      },
      "& .item": {
        border: "1px solid #E4E7EB",
        borderRadius: "10px",
        "& .header": {
          padding: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
          borderBottom: "1px solid #E4E7EB",
          "& svg": {
            color: "#9AB825",
          },
          "& .MuiFormControlLabel-label": {
            fontWeight: 600,
            fontSize: "16px",
          },
        },
        "& .body": {
          padding: "24px",
          paddingLeft: "60px",
          "& table": {
            "& thead": {
              "& td": {
                minWidth: "80px",
                fontWeight: 600,
                fontSize: "14px",
                color: " #687083",
              },
            },
            "& tbody": {
              "& .column": {
                paddingTop: "18px",
              },
              "& td": {
                paddingRight: "32px",
                fontSize: "14px",
                fontWeight: 400,
              },
            },
          },
          "& .addMore": {
            fontWeight: 600,
            fontSize: "14px",
            color: "#9AB825",
            cursor: "pointer",
          },
        },
      },
    },
  },
});
