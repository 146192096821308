import { Grid, Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton, TableComponent } from "../../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT, URL_API } from "../../../config";
import FilterPartner from "../../Filter/FilterPartner";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
  sort: "-id",
};

export default function TablePartnerUser({}) {
  const navigate = useNavigate();
  const [SearchParams, setSearchParams] = useState("");
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3]);
  const [params, setParams] = useState(initialParams);
  const [partnerTypeId, setPartnerTypeId] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const column = [
    {
      name: "Partner Name",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <img
            src={URL_API + "/public/" + params.logo}
            alt={params.partner_name}
            width={60}
          />
        ),
      minWidth: 120,
    },
    {
      name: "",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Partner Type",
      renderCell: (params) => (loading ? <Skeleton /> : params.partner_type),
    },
    {
      name: "User",
      renderCell: (params) => (loading ? <Skeleton /> : params.total_user),
    },
  ];

  useEffect(() => {
    if (SearchParams) onGetData();
  }, [SearchParams]);

  useEffect(() => {
    let mappingParams = Object.keys(params)
      .map((obj) => obj + "=" + params[obj])
      .join("&");
    console.log("mappingParams", mappingParams);
    setSearchParams(mappingParams);
  }, [params]);

  const onGetData = async () => {
    setLoading(true);
    await Axios.get(
      partnerTypeId
        ? `/user/partner?${SearchParams}&andFilter=partner_type_id:${partnerTypeId}`
        : `/user/partner?${SearchParams}`
    )
      .then((res) => {
        // console.log('res', res)
        if (res.data) {
          setMeta(res.data.meta);
          setRows(res?.data?.data?.Data || res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setMeta({});
        setRows([]);
      });
    setLoading(false);
  };

  useEffect(() => {
    onGetData();
  }, [partnerTypeId]);

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        onRowsClick={(val) => navigate(`/partner-user/${val.id}`)}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        endButton={
          <Grid display="flex" gap="16px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterPartner
                isPatnerUser={true}
                onApply={({ partner_type }) => {
                  setPartnerTypeId(partner_type);
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({
                    ...params,
                  });
                  setPartnerTypeId("");
                  setOpenFilter(false);
                }}
                filters={{
                  partner_type: partnerTypeId,
                }}
              />
            </CustomButton.Filter>
          </Grid>
        }
      />
    </Fragment>
  );
}
