import { Button, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterContractProduct,
  ModalCreateContractProduct,
  ModalEditContractProduct,
  TableComponent,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";

export default function TableContractProduct({}) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [dataEdit, setDataEdit] = useState();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    product_id: "",
    contract_id: "",
    sorts: "DESC",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [openModalCreateContract, setOpenModalCreateContract] = useState(false);
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState("");
  const [contractList, setContractList] = useState([]);
  const [contract, setContract] = useState("");
  const [contractStatus, setContractStatus] = useState(true);
  const [productListFilter, setProductListFilter] = useState([]);
  const [contractListFilter, setContractListFilter] = useState([]);
  const [productFilter, setProductFilter] = useState(0);
  const [contractFilter, setContractFilter] = useState(0);
  const [openModalEditContract, setOpenModalEditContract] = useState(false);
  const [contractStatusEdit, setContractStatusEdit] = useState(true);
  const [contractId, setContractId] = useState("");
  const [contractEdit, setContractEdit] = useState("");
  const [productEdit, setProductEdit] = useState("");

  const onChangeContractStatusEdit = (e) => {
    setContractStatusEdit(e.target.value);
  };

  const onChangeContractEdit = (e) => {
    setContractEdit(e.target.value);
  };

  const onChangeProductEdit = (e) => {
    setProductEdit(e.target.value);
  };
  const handleCloseModalEditContract = () => {
    setOpenModalEditContract(false);
  };

  const handleOpenModalEditContract = (
    productFilter,
    contractFilter,
    contractStatus,
    contractId
  ) => {
    setOpenModalEditContract(true);
    // getDataDetail();
    setContractEdit(contractFilter);
    setProductEdit(productFilter);
    setContractStatusEdit(contractStatus);
    setContractId(contractId);
  };

  const onChangeContractStatus = (e) => {
    setContractStatus(e.target.value);
  };

  const onChangeContract = (e) => {
    setContract(e.target.value);
  };

  const onChangeContractFilter = (e) => {
    setContractFilter(e.target.value);
  };

  const onChangeProduct = (e) => {
    setProduct(e.target.value);
  };

  const onChangeProductFilter = (e) => {
    setProductFilter(e.target.value);
  };

  const handleOpenModalCreateContract = () => {
    setOpenModalCreateContract(true);
  };

  const handleCloseModalCreateContract = () => {
    setOpenModalCreateContract(false);
    setContract("");
    setProduct("");
    setContractStatus(true);
  };

  const column = [
    {
      name: "Action",
      renderCell: (params) => (
        <ActionButton
          onEdit={(e) => {
            e.stopPropagation();
            handleOpenModalEditContract(
              params?.product?.id,
              params?.contract?.id,
              params?.is_active_flag,
              params?.id
            );
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(params?.id);
          }}
        />
      ),
    },
    {
      name: "Product",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.product?.product_code ?? "-",
    },
    {
      name: "Contract",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.contract?.contract_no ?? "-",
    },
    {
      name: "Status",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : params?.is_active_flag === true ? (
          "Active"
        ) : (
          "Inactive" ?? "-"
        ),
    },
  ];

  useEffect(() => {
    getDataTable();
    getProductList();
    getContractList();
  }, [params]);

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/contract/product?" + ObjectToParams(params))
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setMeta(res.data.meta);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const getProductList = () => {
    setLoading(true);
    Axios.get("/product")
      .then((res) => {
        if (res.status === 200) {
          setProductList(res.data.data);
          setProductListFilter(res.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const getContractList = () => {
    setLoading(true);
    Axios.get("/contract")
      .then((res) => {
        if (res.status === 200) {
          setContractList(res.data.data);
          setContractListFilter(res.data.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const handleDelete = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/contract/product/delete/${val}`).then((res) => {
          if (res.status === 200 || res.status === 201) {
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success delete menu`,
              icon: "success",
            });
            getDataTable();
          }
        });
      }
    });
  };

  const handleSubmit = () => {
    const payload = {
      contract_id: contract,
      product_id: product,
      is_active_flag: contractStatus === "true" ? true : false,
    };

    Axios.post("/contract/product/create", payload).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setOpenModalCreateContract(false);
        Swal.fire({
          title: "Success",
          text: res.data.message || `Success create contract`,
          icon: "success",
        });
        getDataTable();
      }
    });
  };

  const handleEdit = () => {
    const payload = {
      contract_id: contractEdit,
      product_id: productEdit,
      is_active_flag: contractStatusEdit === "true" ? true : false,
    };

    Axios.put(`/contract/product/update/${contractId}`, payload).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setOpenModalEditContract(false);
        Swal.fire({
          title: "Success",
          text: res.data.message || `Success Edit contract`,
          icon: "success",
        });
        getDataTable();
      }
    });
  };
  const filterOnApply = () => {
    setParams({
      ...params,
      contract_id: contractFilter,
      product_id: productFilter,
    });
  };

  const filterOnReset = () => {
    setParams({
      ...params,
      contract_id: "",
      product_id: "",
    });
    setContractFilter("");
    setProductFilter("");
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <Button
              onClick={() => handleOpenModalCreateContract()}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create Contract Product
            </Button>
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterContractProduct
                productList={productListFilter}
                onChangeProduct={onChangeProductFilter}
                product={productFilter}
                contractList={contractListFilter}
                contract={contractFilter}
                onChangeContract={onChangeContractFilter}
                onApply={filterOnApply}
                onReset={filterOnReset}
              />
            </CustomButton.Filter>
          </Grid>
        }
      />
      {openModalCreateContract && (
        <ModalCreateContractProduct
          openModalCreateContract={openModalCreateContract}
          handleCloseModalCreateContract={handleCloseModalCreateContract}
          productList={productList}
          onChangeProduct={onChangeProduct}
          product={product}
          contractList={contractList}
          contract={contract}
          onChangeContract={onChangeContract}
          contractStatus={contractStatus}
          onChangeContractStatus={onChangeContractStatus}
          onApply={handleSubmit}
        />
      )}

      {openModalEditContract && (
        <ModalEditContractProduct
          openModalEditContract={openModalEditContract}
          handleCloseModalEditContract={handleCloseModalEditContract}
          productList={productList}
          onChangeProduct={onChangeProductEdit}
          product={productEdit}
          contractList={contractList}
          contract={contractEdit}
          onChangeContract={onChangeContractEdit}
          contractStatus={contractStatusEdit}
          onChangeContractStatus={onChangeContractStatusEdit}
          onApply={handleEdit}
        />
      )}
    </Fragment>
  );
}
