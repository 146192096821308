import { Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  BasicButton,
  ModalDataFormatV2,
  TableComponent,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";

export default function DataFormat() {
  const classes = useStyles();
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          sort: "-id",
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  const column = [
    {
      name: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={(e) => {
              e.stopPropagation();
              setOpen(params);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Data Format Code",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.data_format_type_code,
    },
    {
      name: "Data Format",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.data_format_type_desc,
    },
    {
      name: "Last Update",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Grid
            sx={{
              "& p": {
                margin: 0,
                fontSize: "12px",
                color: "#687083",
              },
            }}
          >
            {moment(new Date(params.UpdatedAt)).format("DD MMMM YYYY")}
            <p>{moment(new Date(params.UpdatedAt)).format("HH:mm:ss")}</p>
          </Grid>
        ),
    },
  ];

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/data_format/delete/${id}`).then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message || "Berhasil mengubah role menus",
          }).then(() => getDataTable());
        });
      }
    });
  };

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/data_format/product" + search).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <h1>Data Format</h1>
        </Grid>
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <BasicButton
              label="Create Data Format"
              onClick={() => setOpen(true)}
            />
          }
        />
        {open && (
          <ModalDataFormatV2
            open={open}
            onClose={() => setOpen(false)}
            getDataTable={getDataTable}
          />
        )}
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
});
