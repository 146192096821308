import moment from "moment";
import { useEffect, useState } from "react";
import { Axios } from "../../config";

const useDashboardPA = (initialDate) => {
  const [body, setBody] = useState({ date: initialDate });
  const [dataDashboard, setDataDashboard] = useState({});
  const [dataMonitoring, setDataMonitoring] = useState([]);

  useEffect(() => {
    getDataDashboard();
  }, [body.date]);

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };

  const getDataDashboard = () => {
    Axios.get(`/dashboard?date=${moment(body.date).format("DD-MM-YYYY")}`).then(
      (res) => setDataDashboard(res?.data?.data)
    );
    Axios.get(
      `/dashboard/monitoring?date=${moment(body.date).format("DD-MM-YYYY")}`
    ).then((res) => setDataMonitoring(res?.data?.data));
  };

  return {
    body,
    dataDashboard,
    dataMonitoring,
    handleChange,
  };
};

export default useDashboardPA;
