import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import {
  ChatbotImg,
  CustomerGoalsImg,
  DigitalBankingBanner,
  DivideIntoPersonalImg,
  DividerImage,
  EasyTransactionImg,
  TradingInvestmentImg,
} from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function DigitalBankingPage() {
  const classes = useStyles();

  return (
    <Fragment>
      <ButtonAppBar />
      <section className={classes.root}>
        <section className={classes.banner}>
          <Container>
            <h1>Digital Banking Platform​</h1>
            <p>
              The world of banking has changed over the past years with the
              proliferation of neobanks bringing intuitive and new experiences
              to the end customer. About long dedicated to retail customers,
              digital banking apps are now bringing more and more specialized
              services from SME banking to expat or student dedicated banking
              experiences.
              <br />
              <br />
              Digital Banking means straight through processing and automation
              from onboarding to the delivery of payment instruments as well as
              day to day transactional needs. To differentiate in this market,
              it is necessary to provide true value to the end customer which
              goes beyond finance. Finance is the end of a journey, it is the
              result of the customer initiatives within their lifestyle, when
              buying goods, travelling, growing financially, investing, sharing
              money and goods or running a business.
            </p>
          </Container>
          <img src={DigitalBankingBanner} alt="" />
        </section>
        <section className={classes.bodyContent}>
          <Container>
            <Grid ml={{ md: "102px" }} mr={{ md: "102px" }}>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={EasyTransactionImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Easy Transactions with QR Payments​​</h2>
                  <p>
                    This much awaited feature is now available to customers and
                    merchants, offering static and dynamic QR support and the
                    ability to handle multiple QR codes​
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6} className="content" order={{ xs: 1, md: 0 }}>
                  <h2>Customer Goals and Loyalty​</h2>
                  <p>
                    Customer’s goals are smartly blended with loyalty features,
                    making it possible for financial institutions to expand
                    their business.​
                  </p>
                </Grid>
                <Grid item md={6}>
                  <img src={CustomerGoalsImg} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={TradingInvestmentImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Trading and Investment Competences ​​​</h2>
                  <p>
                    Buying and selling of investments can also be supported
                    through the mobile banking app. The app can integrate with
                    any third-party investment app or local exchange house.​
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6} className="content" order={{ xs: 1, md: 0 }}>
                  <h2>Dive into Personal Finance Management​​</h2>
                  <p>
                    Management on a daily, weekly or monthly basis, using
                    personalised alerts suiting the way customers want to manage
                    their finance and control their financial health​
                  </p>
                </Grid>
                <Grid item md={6}>
                  <img src={DivideIntoPersonalImg} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ md: "64px", xs: "12px" }}
                mt={{ md: "120px", xs: "24px" }}
              >
                <Grid item md={6}>
                  <img src={ChatbotImg} alt="" />
                </Grid>
                <Grid item md={6} className="content">
                  <h2>Chatbot with Speech-to-Text Interactions​​​​</h2>
                  <p>
                    With the smart platform that has been seamlessly integrated,
                    the app will be capable of recognising the consumer’s voice
                    while supporting both speech-to-text and text-to-speech.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <img src={DividerImage} alt="" className="divider" />
        </section>
        <FooterLandingPage />
      </section>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
      fontFamily: ["Raleway", "sans-serif"],
      "& button": {
        textTransform: "none",
      },
      "& h1,h2,h3,h4,p": {
        margin: 0,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "48px",
      },
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      display: "flex",
      background: "linear-gradient(90deg, #30BBF2 0%, #B2CC49 100%)",
      position: "relative",
      color: "white",
      "& h1": {
        [theme.breakpoints.up("md")]: {
          marginTop: "168px",
          fontWeight: 300,
          fontSize: "54px",
          lineHeight: "56px",
          width: "588px",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "18px",
          width: "160px",
        },
      },
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "6px",
          lineHeight: "9px",
          width: "200px",
          marginTop: "12px",
          marginBottom: "12px",
        },
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: "128px",
        width: "588px",
        marginTop: "48px",
      },
      "& img": {
        position: "absolute",
        height: "100%",
        right: "0px",
      },
    },
    bodyContent: {
      backgroundColor: "#F7F9FC",
      marginTop: "120px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10px",
      },
      "& .content": {
        marginTop: "103px",
        marginBottom: "103px",
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          marginBottom: "24px",
        },
        "& h2": {
          fontWeight: 700,
          fontSize: "40px",
          lineHeight: "48px",
          color: "#2C3038",
        },
        "& p": {
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "30px",
          textAlign: "justify",
          marginTop: "24px",
        },
      },
      "& .divider": {
        height: "100%",
        marginBottom: "-3px",
      },
    },
  };
});
