import { Grid } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import { TableComponent, TitleWithBackButton } from "../../components";
import { Axios, currencyFormat } from "../../config";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashboardDetailPA() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataDashboard, setDataDashboard] = useState({});
  const [dataTable, setDataTabel] = useState([]);

  const data = {
    labels: [],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dataDashboard?.transaction_success || 0,
          dataDashboard?.transancation_mismatch || 0,
          dataDashboard?.transaction_error || 0,
        ],
        backgroundColor: ["#54B371", "#DE5242", "#EA9437", "#D137EA"],
        borderColor: ["#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  const handleHover = () => {
    console.log("HOVER");
  };
  const handleLeave = () => {
    console.log("LEAVE");
  };

  const config = {
    type: "pie",
    data: data,
    options: {
      plugins: {
        legend: {
          onHover: handleHover,
          onLeave: handleLeave,
        },
      },
    },
  };

  const dataCount = [
    {
      count: currencyFormat(dataDashboard?.transaction_success || 0),
      desc: "Success",
      color: "#34774C",
    },
    {
      count: currencyFormat(dataDashboard?.transancation_mismatch || 0),
      desc: "Error",
      color: "#A63124",
    },
    {
      count: currencyFormat(dataDashboard?.transaction_error || 0),
      desc: "Missmatch",
      color: "#A84D20",
    },
  ];

  useEffect(() => {
    getDataDeatil();
  }, []);

  const getDataDeatil = () => {
    Axios.get(`/dashboard/monitoring/detail/${id}`)
      .then(({ data }) => setDataDashboard(data?.data))
      .then(() => {
        Axios.get(`/dashboard/monitoring/detail/list-mismatch/${id}`).then(
          ({ data }) => setDataTabel(data?.data)
        );
      });
  };

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title={dataDashboard?.merchant} />
      {console.log()}
      <Grid
        container
        mt="28px"
        padding="24px"
        sx={(theme) => {
          return {
            ...theme.CardWrapper,
            "& h2, p": {
              margin: 0,
            },
            "& h2": {
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "36px",
              color: "#9AB825",
              mb: "16px",
            },
            "& p": {
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#687083",
            },
            "& .center": {
              borderRight: "1px solid #E4E7EB",
              borderLeft: "1px solid #E4E7EB",
              paddingLeft: "24px",
            },
          };
        }}
      >
        <Grid container>
          <Grid item md={4}>
            <h2>{currencyFormat(dataDashboard?.transaction_count)}</h2>
            <p>Transaction Count</p>
          </Grid>
          <Grid item md={4} className="center">
            <h2>{currencyFormat(dataDashboard?.total_fee)}</h2>
            <p>Total Fee</p>
          </Grid>
          <Grid item md={4} pl="24px">
            <h2>{currencyFormat(dataDashboard?.total_sattlement)}</h2>
            <p>Total Settlement</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid mt="24px">
        <Grid
          sx={(theme) => {
            return {
              ...theme.CardWrapper,
              "& h2": {
                margin: 0,
                padding: "24px",
                paddingLeft: "20px",
              },
              "& hr": {
                border: "1px solid #E4E7EB",
                backgroundColor: "#E4E7EB",
              },
              "& .body-chart": {
                padding: "24px",
              },
              "& .content-right": {
                padding: "56px",
              },
              "& .item": {
                "& h2": {
                  fontWeight: 600,
                  fontSize: "30px",
                  lineHeight: "36px",
                  margin: 0,
                  "& .circle": {
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    marginRight: "16px",
                    display: "inline-block",
                    marginBottom: "2px",
                  },
                },
                "& p": {
                  margin: 0,
                  marginLeft: "30px",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#687083",
                },
              },
            };
          }}
          container
        >
          <Grid container display="inline">
            <h2>Transaction</h2>
            <hr />
          </Grid>
          <Grid container className="body-chart">
            <Grid item md={3}>
              <Pie data={data} config={config} />
            </Grid>
            <Grid item md={9} className="content-right">
              <Grid sx={{ height: "100%" }} container>
                {dataCount.map((res) => (
                  <Grid item md={6} className="item" sx={{ color: res.color }}>
                    <h2>
                      <Grid className="circle" backgroundColor={res.color} />
                      {res.count}
                      <br />
                      <p>{res.desc}</p>
                    </h2>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid mt="24px">
        <Grid
          sx={(theme) => {
            return {
              ...theme.CardWrapper,
              "& h2": {
                margin: 0,
                padding: "24px",
                paddingLeft: "20px",
              },
              "& hr": {
                border: "1px solid #E4E7EB",
                backgroundColor: "#E4E7EB",
              },
            };
          }}
          container
        >
          <Grid container display="inline"></Grid>
          <Grid container padding="24px">
            <Grid
              sx={{
                background: "rgba(154, 184, 37, 0.1)",
                py: "10px",
                px: "20px",
                color: "#9AB825",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              Transaction Error
            </Grid>
            <Grid container mt="24px">
              <TableComponent
                column={[
                  {
                    name: "ID",
                    renderCell: ({ rns_process_history_id }) =>
                      rns_process_history_id,
                  },
                  {
                    name: "Date",
                    renderCell: ({ date }) =>
                      moment(new Date(date)).format("lll"),
                  },
                  {
                    name: "Merchant / Bank",
                    renderCell: ({
                      merchant_name,
                      remote_transaction_amount,
                    }) => (
                      <Grid>
                        {currencyFormat(remote_transaction_amount)}
                        <Grid
                          sx={{
                            color: "#687083",
                          }}
                        >
                          {merchant_name}
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    name: "Principal",
                    renderCell: ({
                      principal_name,
                      original_transaction_amount,
                    }) => (
                      <Grid>
                        {currencyFormat(original_transaction_amount)}
                        <Grid
                          sx={{
                            color: "#687083",
                          }}
                        >
                          {principal_name}
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    name: "Difference",
                    renderCell: ({ difference_amount, id }) => (
                      <Grid
                        onClick={() => navigate(`/dashboard/${id}/missmatch`)}
                        color="red"
                        sx={{ cursor: "pointer" }}
                      >
                        {currencyFormat(difference_amount)}
                      </Grid>
                    ),
                  },
                  {
                    name: "Count",
                    renderCell: ({ count, id }) => <Grid>{count ?? 1}</Grid>,
                  },
                ]}
                rows={dataTable}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
