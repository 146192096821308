import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomInput,
  ModalComponent,
  TableComponent,
} from "../../../components";
import { Axios } from "../../../config";

export default function TableTypeColumn({ dataFormat, getDataDetail }) {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/data_format/${dataFormat?.id}/column/delete/${id}`).then(
          (res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            }).then(() => getDataDetail());
          }
        );
      }
    });
  };

  return (
    <Grid>
      <h4>Record Data Format Type Column</h4>
      <TableComponent
        rows={dataFormat?.data_format_type_column || []}
        column={[
          {
            name: "Action",
            renderCell: (params) => (
              <ActionButton
                onEdit={(e) => {
                  setOpen(params);
                }}
                onDelete={(e) => {
                  handleDelete(params.id);
                }}
              />
            ),
          },
          {
            name: "Data Format Column Code Type",
            renderCell: (params) => params.data_format_column_type_code,
          },
          {
            name: "Column Header",
            renderCell: (params) => params.column_header,
          },
          {
            name: "Column Position",
            renderCell: (params) => params.column_position,
          },
          {
            name: "Column Start Pos",
            renderCell: (params) => params.column_start_pos,
          },
          {
            name: "Column End Pos",
            renderCell: (params) => params.column_end_pos,
          },
        ]}
      />
      <h4 className="link" onClick={() => setOpen(true)}>
        + Add New Row
      </h4>
      {open && (
        <ModalTypeColumn
          open={open}
          onClose={onClose}
          dataFormatId={dataFormat?.id}
          getDataDetail={getDataDetail}
        />
      )}
    </Grid>
  );
}

const ModalTypeColumn = ({ open, onClose, dataFormatId, getDataDetail }) => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (open?.id) {
      setValue(
        "data_format_column_type_code",
        open?.data_format_column_type_code
      );
      setValue("column_header", open?.column_header);
      setValue("column_position", open?.column_position);
      setValue("column_start_pos", open?.column_start_pos);
      setValue("column_end_pos", open?.column_end_pos);
    }
  }, []);

  const onSubmit = (values) => {
    const value = {
      ...values,
      column_end_pos: +values.column_end_pos,
      column_position: +values.column_position,
      column_start_pos: +values.column_start_pos,
    };
    onClose();
    if (open.id) {
      Axios.put(
        `/data_format/${dataFormatId}/column/update/${open?.id}`,
        value
      ).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(() => getDataDetail());
      });
    } else {
      Axios.post(`/data_format/${dataFormatId}/column/create`, value).then(
        (res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          }).then(() => getDataDetail());
        }
      );
    }
  };

  return (
    <ModalComponent open={Boolean(open)} onClose={onClose}>
      <Grid
        conteiner
        style={{
          maxHeight: "90vh",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "16px",
            paddingBottom: "10px",
          }}
        >
          <h3>{open?.id ? "Edit" : "Add"} Row</h3>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr />
        <form action="" id="add-type-column" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            sx={{
              padding: "24px",
              paddingTop: "4px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <CustomInput.Select
              register={{
                ...register("data_format_column_type_code"),
              }}
              label="Data Format Column Code Type"
              value={watch("data_format_column_type_code") ?? ""}
              marginTop="0px"
            >
              {[
                "row-id",
                "acquirer-code",
                "primary-key",
                "secondary-key",
                "transaction-amount",
                "currency-code",
                "transaction-date",
                "transaction-time",
              ].map((val) => (
                <MenuItem value={val}>{val}</MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput
              register={{ ...register("column_header") }}
              label="Column Header"
              required
            />
            <CustomInput
              register={{ ...register("column_position") }}
              label="Column Position"
              required
              type="number"
              min={0}
            />
            <CustomInput
              register={{ ...register("column_start_pos") }}
              label="Column Start Pos"
              type="number"
              required
              min={0}
            />
            <CustomInput
              register={{ ...register("column_end_pos") }}
              label="Column End Pos"
              type="number"
              required
              min={0}
            />
          </Grid>
        </form>
        <hr />
        <Grid sx={{ padding: "20px" }}>
          <Button
            type="submit"
            form="add-type-column"
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
            fullWidth
          >
            {open?.id ? "Edit" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};
