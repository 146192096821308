import { Grid, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputComponent from "../../components/Global/InputComponent";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterPartner({ onApply, onReset, filters }) {
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [dataMerchant, setDataMerchant] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      product: null,
      merchant: null,
      bank: null,
      date: null,
    }
  );

  const getDataProduct = () => {
    Axios.get("/product").then(({ data }) => setDataProduct(data?.data) || []);
  };

  const getDataMerchant = () => {
    Axios.get("/product_partner/partner-type?partner_type_code=merchant").then(
      ({ data }) => setDataMerchant(data?.data) || []
    );
  };
  const getDataBank = () => {
    Axios.get("/product_partner/partner-type?partner_type_code=acquirer").then(
      ({ data }) => setDataBank(data?.data) || []
    );
  };
  const getAllListFilter = () => {
    return Promise.all([getDataProduct(), getDataBank(), getDataMerchant()]);
  };
  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    getAllListFilter();
  }, []);
  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, product: target.value })
          }
          placeholder="Product"
          value={dataFilter.product}
        >
          {dataProduct.map((val) => {
            return (
              <MenuItem value={val.product_code}>{val.product_desc}</MenuItem>
            );
          })}
        </CustomInput.Select>
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, merchant: target.value })
          }
          placeholder="Merchant"
          value={dataFilter?.merchant}
        >
          {dataMerchant.map((val) => {
            return (
              <MenuItem value={val.partner_Code}>{val.partner_name}</MenuItem>
            );
          })}
        </CustomInput.Select>
        <CustomInput.Select
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, bank: target.value })
          }
          placeholder="Bank"
          value={dataFilter.bank}
        >
          {dataBank.map((val) => {
            return (
              <MenuItem value={val.partner_Code}>{val.partner_name}</MenuItem>
            );
          })}
        </CustomInput.Select>
        <InputComponent
          value={dayjs(dataFilter.date).format("MM-DD-YYYY 00:00:00") ?? null}
          placeholder="Date"
          type="date"
          id="date"
          name="date"
          control={control}
          error={errors}
          sx={{ width: "100%" }}
          onChange={({ target }) => {
            if (moment(target.value).isValid())
              setDataFilter({
                ...dataFilter,
                date: target?.value?.split(" ")?.[0],
              });
          }}
        />
      </Grid>
    </FilterWrapper>
  );
}
