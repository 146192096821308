import { Button, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { Colors } from "../../assets";
import { ItemParameter, ModalAddData } from "../../components";
import { Axios } from "../../config";

export default function SystemParameter(params) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dataParam, setDataParam] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    Axios.get("/parameter").then((res) => {
      setDataParam(res.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <h1>System Parameter</h1>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            className={classes.button}
          >
            Create Parameter
          </Button>
        </Grid>

        {loading ? (
          <Grid className={classes.loading}>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                height={150}
                borderRadius={10}
              />
            ))}
          </Grid>
        ) : (
          dataParam.map((res) => (
            <ItemParameter
              data={res}
              getData={getData}
              dataParam={dataParam}
              setDataParam={setDataParam}
            />
          ))
        )}
        {open && (
          <ModalAddData
            onClose={() => setOpen(false)}
            open={open}
            setDataParam={(val) =>
              setDataParam((dataParam) => [val, ...dataParam])
            }
          />
        )}
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  button: {
    backgroundColor: Colors.SECONDARY + " !important",
    "&:hover": {
      backgroundColor: Colors.SECONDARY_DARK + " !important",
    },
    fontWeight: 600 + "!important",
  },
  loading: {
    gap: "24px",
    display: "grid",
  },
});
