import { Grid } from "@mui/material";
import React from "react";

export default function NotFoundPage(params) {
  return (
    <Grid
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& h1": {
          fontSize: "120px",
          margin: 0,
        },
        "& h2": {
          margin: 0,
        },
      }}
    >
      <Grid padding={4}>
        <h1>404</h1>
        <h2>Not Found</h2>
        <h3>The resource requested could not be found on this server!</h3>
      </Grid>
    </Grid>
  );
}
