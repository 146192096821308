import { Button, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterProductPartner,
  ModalProductPartner,
  TableComponent,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";

export default function TableDetailPartnerProduct({ id }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [openFilter, setOpenFilter] = useState(false);
  const [params, setParams] = useState({
    sort: "-id",
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    id: id,
  });

  useEffect(() => {
    getDataTable();
  }, [params]);

  const getDataTable = () => {
    setLoading(true);
    Axios.get(`/product_partner/${params?.id}?` + ObjectToParams(params)).then(
      (res) => {
        setMeta(res.data.meta);
        setTimeout(() => {
          setRows(res.data.data || []);
          setLoading(false);
        }, 1000);
      }
    );
  };

  const columns = [
    {
      name: "Action",
      renderCell: (params) => (
        <ActionButton
          onEdit={(e) => {
            e.stopPropagation();
            setOpen(params);
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(params.ID);
          }}
        />
      ),
    },
    {
      name: "Product Code",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.product?.product_code,
    },
    {
      name: "Product",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.product?.product_desc,
    },
    {
      name: "Partner Type",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.partner_type?.partner_type_desc,
    },
    {
      name: "Parent Partner",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          params?.parent_product_partner?.partner?.partner_code
        ),
    },
    {
      name: "Data Format",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.data_format_type?.data_format_type_desc,
    },
  ];

  const handleDelete = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/product_partner/delete/${val}`).then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
            });
            getDataTable();
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={columns}
        defaultValueSearch={params?.search}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterProductPartner
                onApply={(value) => {
                  setParams({ ...params, ...value });
                  console.log(value);
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({
                    ...params,
                    partner_id: null,
                    product_id: null,
                    interval: null,
                  });
                  setOpenFilter(false);
                }}
                filters={{
                  partner_type_id: params.partner_type_id,
                  data_format_type_id: params.data_format_type_id,
                }}
              />
            </CustomButton.Filter>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create Product Partner
            </Button>
          </Grid>
        }
      />
      {open && (
        <ModalProductPartner
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
          partnerId={params?.id}
          id={params.id}
        />
      )}
    </Fragment>
  );
}
