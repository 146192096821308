import { Grid } from "@mui/material";
import React from "react";

export default function Forbidden() {
  return (
    <Grid
      sx={(theme) => {
        return {
          ...theme.customContainer,
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };
      }}
    >
      <Grid
        sx={{
          textAlign: "center",
          "& h1": {
            fontSize: "120px",
            margin: 0,
            marginBottom: "-20px",
          },
          "& h3": {
            fontSize: "32px",
            margin: 0,
          },
          "& h5": {
            fontSize: "18px",
            margin: 0,
          },
        }}
      >
        <h1>403</h1>
        <h3>Forbidden</h3>
        <h5>You don't have permission to access this resource.</h5>
      </Grid>
    </Grid>
  );
}
