import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Axios, setToken, setUser, setUserRole } from "../../config";

const useLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    typeQR: false,
    typeOTP: false,
  });
  const [error, setError] = useState([]);
  const [qrImage, setQrImage] = useState("");
  const [body, setBody] = useState({
    email: "",
    password: "",
  });
  const [tokenLogin, setTokenLogin] = useState("");
  const [responseError, setResponseError] = useState({});

  const closeVerificationDialog = (type) =>
    setOpenDialog((currentState) => ({
      ...currentState,
      [type]: false,
    }));

  const openTypeDialog = (previousType, type) =>
    setOpenDialog((currentState) => ({
      ...currentState,
      [previousType]: false,
      [type]: true,
    }));

  const handleLogin = async (e) => {
    e.preventDefault();
    var result = Object.entries(body);
    var dataError = [];
    result.forEach((res) => {
      if (res[1] === "") {
        dataError.push({
          name: res[0],
          message: `${res[0]} tidak boleh kosong!`,
        });
      }
    });
    if (dataError.length === 0) {
      setLoading(true);
      Axios.post("/login-v2", {
        email: body.email,
        password: body.password,
      })
        .then(async (res) => {
          const isOtpAvailable = res?.data?.data?.user?.mfa_method_flag;
          if (res?.status === 200) {
            setTokenLogin(res.data.data.access_token);
            if (!isOtpAvailable) {
              setOpenDialog((currentState) => ({
                ...currentState,
                typeQR: true,
              }));
              setQrImage(res?.data?.data?.user?.barcode || "");
            } else {
              setOpenDialog((currentState) => ({
                ...currentState,
                typeOTP: true,
              }));
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setResponseError(error);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    } else {
      setError(dataError);
      setLoading(false);
    }
  };

  const handleOtp = (otp) => {
    setOtpLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${tokenLogin}` },
    };

    Axios.post(
      "/totp",
      {
        email: body.email,
        password: body.password,
        otp: Number(otp),
        mfa_method_flag: false,
      },
      config
    )
      .then((res) => {
        const data = res?.data?.data;
        setToken(data.access_token);
        setUser(data.user);
        setUserRole(data.user.role[0].code);
        setTimeout(() => {
          navigate("/dashboard");
          setOtpLoading(false);
        }, 1000);
      })
      .catch((err) => setError(err))
      .finally(() => setOtpLoading(false));
  };

  const handleReset = () => {
    setResetLoading(true);
    Axios.post("/totp/reset", {
      email: body.email,
      password: body.password,
    })
      .then((res) => {
        setTimeout(() => {
          navigate("/reset-totp", { state: { body } });
        }, 1000);
      })
      .catch((err) => setError(err))
      .finally(() => setResetLoading(false));
  };

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
    setError(error.filter((val) => val.name !== e.target.id));
  };

  return {
    loading,
    otpLoading,
    resetLoading,
    openDialog,
    error,
    qrImage,
    body,
    responseError,
    closeVerificationDialog,
    openTypeDialog,
    handleLogin,
    handleOtp,
    handleChange,
    handleReset,
  };
};

export default useLogin;
