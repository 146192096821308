import { Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Axios } from "../../config/helper";
import CustomInput from "../Global/CustomInput";
import FilterWrapper from "./FilterWrapper";

export default function FilterSchedule({ onApply, onReset, filters }) {
  const [dataPartner, setDataPartner] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [dataFilter, setDataFilter] = useState(
    filters || {
      partner_id: null,
      product_id: null,
      interval: null,
    }
  );

  const getDataProduct = () => {
    Axios.get("/product").then(({ data }) => setDataProduct(data?.data) || []);
  };

  const getDataPartner = () => {
    Axios.get("/partner?status=false").then(
      ({ data }) => setDataPartner(data?.data) || []
    );
  };

  const isHiiden = Object.keys(dataFilter).every(function (x) {
    return dataFilter[x] === "" || dataFilter[x] === null;
  });

  useEffect(() => {
    getDataPartner();
    getDataProduct();
  }, []);
  return (
    <FilterWrapper
      hiddenReset={isHiiden}
      onApply={() => onApply(dataFilter)}
      onReset={onReset}
    >
      <Grid width="100%" display="flex" flexDirection="column" gap="12px">
        <CustomInput.Select
          placeholder="Partner"
          value={dataFilter?.partner_id || ""}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, partner_id: target.value })
          }
        >
          {dataPartner?.map((val) => (
            <MenuItem value={val.id}>{val?.partner_name}</MenuItem>
          ))}
        </CustomInput.Select>
        <CustomInput.Select
          placeholder="Product"
          value={dataFilter?.product_id || ""}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, product_id: target.value })
          }
        >
          {dataProduct?.map((val) => (
            <MenuItem value={val.id}>{val?.product_desc}</MenuItem>
          ))}
        </CustomInput.Select>
        <CustomInput.Select
          placeholder="Interval"
          value={dataFilter?.interval || ""}
          onChange={({ target }) =>
            setDataFilter({ ...dataFilter, interval: target.value })
          }
        >
          {[
            "Daily",
            "Weekly",
            "Biweekly",
            "Monthly",
            "Quaterly",
            "Semesterly",
            "Yearly",
          ]?.map((val) => (
            <MenuItem value={val[0]}>{val}</MenuItem>
          ))}
        </CustomInput.Select>
      </Grid>
    </FilterWrapper>
  );
}
