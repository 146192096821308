import { getUserRole } from "./cookies";

export const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthsArrayLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatToThousands = (nominal) => {
  const number = Number(nominal);
  const rounded = Number.isInteger(number) ? number : number.toFixed(2);
  const [integerPart, decimalPart] = rounded.toString().split(".");
  const remainder = integerPart.length % 3;

  let formatted = integerPart.substr(0, remainder);
  const thousands = integerPart.substr(remainder).match(/\d{1,3}/gi);

  if (thousands) {
    const separator = remainder ? "," : "";
    formatted += separator + thousands.join(",");
  }

  if (decimalPart !== undefined) {
    formatted += "." + decimalPart;
  }

  return formatted;
};

export const ObjectToParams = (object) => {
  let search = Object.entries(object)
    .map(([key, val]) => {
      if (val) {
        return `${key}=${val}`;
      }
    })
    .filter((val) => val)
    .join("&");
  return search;
};

export const FilterObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export function checkRole(profil, api) {
  const role = getUserRole();
  switch (role) {
    case "PA":
    case "PO":
      const current = profil.currnet_role.name.split(" ")[0];
      return api.filter((data) => data.name.split(" ")[0] === current);
    case "FBA":
      return api.filter((val) => val.code === "PA" || val.code === "PO");
    case "FSA":
      return api.filter((val) => val.name.includes("Finpoint"));
    default:
      return api;
  }
}

export function currencyFormat(amount) {
  if (amount === null || amount === undefined) amount = 0;
  const [integerPart, decimalPart] = amount.toString().split(",");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `Rp. ${formattedInteger}${decimalPart ? "," + decimalPart : ""}`;
}
