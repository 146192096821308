import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { TableMenu, TitleWithBackButton } from "../../components";

export default function MenuPage(params) {
  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          console.log(theme);
          return { ...theme.customContainer };
        }}
      >
        <Grid sx={{ marginBottom: "28px" }}>
          <TitleWithBackButton title="Menu" hiddenBackButton />
        </Grid>
        <TableMenu />
      </Grid>
    </Fragment>
  );
}
