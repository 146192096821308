import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterSchedule,
  ModalSchedule,
  TableComponent,
} from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT, ObjectToParams } from "../../config";

export default function Schedule() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState({
    sort: "-id",
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    partner_id: null,
    product_id: null,
    interval: null,
  });
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const intervalFlag = {
    D: "Daily",
    W: "Weekly",
    B: "Biweekly",
    M: "Monthly",
    Q: "Quaterly",
    S: "Semesterly",
    Y: "Yearly",
  };

  const column = [
    {
      name: "Action",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <ActionButton
            onEdit={(e) => {
              e.stopPropagation();
              setOpen(params);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(params.id);
            }}
          />
        );
      },
    },
    {
      name: "Schedule Code",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.rns_schedule_code,
    },
    {
      name: "Schedule Desc",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Box
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            <Typography>{params.rns_schedule_desc}</Typography>
          </Box>
        ),
    },
    {
      name: "Partner",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.partner?.partner_name,
    },
    {
      name: "Product",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          params.rns_process_schedule_product
            ?.map((val) => val.product.product_desc)
            .join(", ")
        ),
    },
    {
      name: "Data Format",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.data_format_type?.data_format_type_code,
    },
    {
      name: "Interval",
      renderCell: (params) =>
        loading ? <Skeleton /> : intervalFlag[params.rns_interval_flag],
    },
    {
      name: "Start At",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Grid
            sx={{
              "& p": {
                margin: 0,
                fontSize: "12px",
                color: "#687083",
              },
            }}
          >
            {moment(new Date(params.rns_start_at)).format("DD MMMM YYYY")}
            <p>{moment(new Date(params.rns_start_at)).format("HH:mm:ss")}</p>
          </Grid>
        ),
    },
    {
      name: "Next Process At",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <Grid
            sx={{
              "& p": {
                margin: 0,
                fontSize: "12px",
                color: "#687083",
              },
            }}
          >
            {moment(new Date(params.next_process_at)).format("DD MMMM YYYY")}
            <p>{moment(new Date(params.next_process_at)).format("HH:mm:ss")}</p>
          </Grid>
        ),
    },
  ];

  useEffect(() => {
    getDataTable();
  }, [params]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/schadule/delete/${id}`).then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          }).then(() => getDataTable());
        });
      }
    });
  };

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/schadule?" + ObjectToParams(params)).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  return (
    <Fragment>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <Grid
          justifyContent="space-between"
          container
          className={classes.header}
        >
          <h1>Schedule</h1>
        </Grid>
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={params?.limit}
          totalData={meta?.total}
          page={meta?.page}
          endButton={
            <Grid display="flex" gap="16px">
              <CustomButton.Filter
                open={openFilter}
                onClick={() => setOpenFilter(!openFilter)}
              >
                <FilterSchedule
                  onApply={(value) => {
                    setParams({ ...params, ...value });
                    setOpenFilter(false);
                  }}
                  onReset={() => {
                    setParams({
                      ...params,
                      partner_id: null,
                      product_id: null,
                      interval: null,
                    });
                    setOpenFilter(false);
                  }}
                  filters={{
                    partner_id: params.partner_id,
                    product_id: params.product_id,
                    interval: params.interval,
                  }}
                />
              </CustomButton.Filter>
              <CustomButton onClick={() => setOpen(true)}>
                Create Schedule
              </CustomButton>
            </Grid>
          }
        />
        {open && (
          <ModalSchedule
            open={open}
            onClose={() => setOpen(false)}
            getDataTable={getDataTable}
          />
        )}
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
    "& h1": {
      margin: 0,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  Input: {
    border: "1px solid",
  },
});
