import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FinpointLogo, SecureBanner } from "../../assets";
import { CardComponent, InputComponent } from "../../components";

export default function ForgotPassword(params) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [body, setBody] = useState({
    email: "",
    password: "",
  });
  const [responseError, setResponseError] = useState({});

  useEffect(() => {}, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleErrorInput = (val) => {
    let errorVar = error.filter((res) => res.name === val);
    return errorVar.length > 0 ? true : false;
  };

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
    setError(error.filter((val) => val.name !== e.target.id));
  };

  return (
    <Grid className={classes.root} container>
      <Grid item md={6} className={classes.leftContainer}>
        <Grid className={classes.leftContent}>
          <Grid className={classes.logoContainer}>
            <img src={FinpointLogo} alt="" />
          </Grid>
          <Grid className={classes.cardContainer}>
            <CardComponent
              fullWidth
              boxShadow="0px 12px 24px rgba(0, 0, 0, 0.05)"
            >
              <Grid className={classes.cardContent}>
                <h5>Reset Password</h5>
                <Grid className={classes.formContainer}>
                  {error.length > 0 && (
                    <Alert severity="error">
                      <ul>
                        {error.map((res) => {
                          return <li>{res.message}</li>;
                        })}
                      </ul>
                    </Alert>
                  )}
                  {responseError.name && (
                    <Alert severity="error">
                      <AlertTitle>{responseError.code}</AlertTitle>
                      {responseError.message}
                      {responseError.response.data && (
                        <ul>
                          {Array.isArray(responseError.response.data) ? (
                            responseError.response.data.errors.map((val) => (
                              <li>{val.message}</li>
                            ))
                          ) : (
                            <li>{responseError.response.data.message}</li>
                          )}
                        </ul>
                      )}
                    </Alert>
                  )}
                </Grid>
                <Grid className={classes.form}>
                  <form onSubmit={handleLogin}>
                    <Grid className={classes.inputContainer}>
                      <InputComponent
                        error={handleErrorInput("email")}
                        onChange={handleChange}
                        id="email"
                        placeholder="Email Address"
                        height="48px"
                        disabled={loading}
                      />
                    </Grid>
                    <Button
                      className={classes.customButton}
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={30} color="inherit" />
                      ) : (
                        "Send"
                      )}
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} className={classes.rightImageContainer}>
        <img src={SecureBanner} alt="" className={classes.rightImage} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#F7F9FC",
    overflow: "hidden",
  },
  leftContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  leftContent: {
    width: "100% ",
    padding: "20px",
    height: "100%",
    paddingTop: "36px",
    marginRight: "172px",
    marginLeft: "172px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  logoContainer: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  cardContainer: {
    height: "100% !important",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContent: {
    textAlign: "center",
    "& h5 ": {
      fontWeight: "700 !important",
      fontSize: "20px !important",
      lineHeight: "24px !important",
      color: "black !important",
    },
  },
  formContainer: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    "& ul, li": {
      margin: 0,
      textAlign: "left",
    },
  },
  form: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  forgotPassword: {
    textAlign: "right",
    marginBottom: theme.spacing(1.5),
    "& a": {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#97AD3E",
      textDecoration: "none",
      "&:hover": {
        color: "#83992A",
        textDecoration: "underline",
      },
    },
  },
  customButton: {
    height: "48px !important",
    backgroundColor: "#A0B842 !important",
    "&:hover": {
      backgroundColor: "#83992A",
    },
    "&:disabled": {
      backgroundColor: "#e4e4e7 !important",
      cursor: "not-allowed !important",
    },
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    position: "relative !important",
  },
  rightImageContainer: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rightImage: {
    height: "200%",
    borderRadius: "50% 0% 0% 50%",
    marginTop: "-50%",
    objectFit: "contain",
    width: "100%",
  },
}));
