import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import DateRangeOutlined from "@mui/icons-material/DateRangeOutlined";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
const ComponentTreeItem = ({ text, isDate }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {isDate ? (
        <DateRangeOutlined
          color="disabled"
          fontSize="small"
          className={classes.dateIcon}
        />
      ) : (
        <ChevronRightOutlined
          color="disabled"
          fontSize="small"
          className={classes.chevIcon}
        />
      )}

      <Stack direction="column" flexGrow={1}>
        <Typography className={classes.title}>{text}</Typography>
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "596px",
    marginTop: "15px",
    marginBottom: "5px",
    borderRadius: "4px",
    padding: "2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dateIcon: { marginRight: "15px" },
  chevIcon: { marginRight: "15px", marginLeft: "5px" },
  title: { fontSize: "14px", fontWeight: "800" },
}));

export default ComponentTreeItem;
