import CancelIcon from "@mui/icons-material/Cancel";
import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomChip, TitleWithBackButton } from "../../components";
import { Axios, currencyFormat } from "../../config";

const DashboardMissmatchDetail = () => {
  const classes = useStyles();
  const params = useParams();
  const [dataDetail, setDataDetail] = useState({});

  useEffect(() => {
    getDetailError();
  }, []);

  const getDetailError = () => {
    Axios.get(`/dashboard/monitoring/detail/mismatch/${params?.id}`).then(
      (res) => setDataDetail(res?.data?.data)
    );
  };

  return (
    <>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        <TitleWithBackButton
          title={`Mismatch Detail: Transaction #ID-${dataDetail?.rns_process_history_id}`}
        />
        <Grid sx={{ boxShadow: "1" }} className={classes.ListRoot} container>
          <Grid
            className={classes.ListWrapper}
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingRight: "8px" }}
          >
            <h4 style={{ fontSize: "11px", fontWeight: "600" }}>
              {`#ID-${dataDetail?.rns_process_history_id}`}
              <NoiseControlOffIcon
                sx={{
                  fontSize: "12px",
                  mx: "8px",
                }}
              />{" "}
              {dayjs(dataDetail?.rns_process_start_at).format(
                "MMM D, YYYY h:mm A"
              )}
            </h4>
            <CustomChip
              label="Mismatch"
              size="small"
              variant="outlined"
              color="error"
            />
          </Grid>
          <Grid
            sx={{
              border: "1px solid #E4E7EB",
              padding: "16px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              "& img": {
                paddingLeft: "24px",
                paddingRight: "24px",
              },
            }}
          >
            <Grid
              item
              sx={{
                color: "#687083",
                "& h4, p": {
                  margin: 0,
                },
              }}
            >
              <h5>{currencyFormat(dataDetail?.remote_transaction_amount)}</h5>
              <p>
                {dataDetail.merchant_name &&
                  `${dataDetail.merchant_name + " / "}` + dataDetail?.bank_name}
              </p>
            </Grid>
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                width="26"
                height="8"
                viewBox="0 0 26 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.478478 3.64645C0.283216 3.84171 0.283216 4.15829 0.478478 4.35355L3.66046 7.53553C3.85572 7.7308 4.1723 7.7308 4.36757 7.53553C4.56283 7.34027 4.56283 7.02369 4.36757 6.82843L1.53914 4L4.36757 1.17157C4.56283 0.976311 4.56283 0.659728 4.36757 0.464466C4.1723 0.269204 3.85572 0.269204 3.66046 0.464466L0.478478 3.64645ZM25.1856 4.35355C25.3808 4.15829 25.3808 3.84171 25.1856 3.64645L22.0036 0.464466C21.8083 0.269204 21.4918 0.269204 21.2965 0.464466C21.1012 0.659728 21.1012 0.976311 21.2965 1.17157L24.1249 4L21.2965 6.82843C21.1012 7.02369 21.1012 7.34027 21.2965 7.53553C21.4918 7.7308 21.8083 7.7308 22.0036 7.53553L25.1856 4.35355ZM0.832031 4.5H24.832V3.5H0.832031V4.5Z"
                  fill="#092540"
                />
              </svg>
            </div>
            <Grid
              item
              sx={{
                color: "#687083",
              }}
              alignItems="center"
            >
              <h5>{currencyFormat(dataDetail?.original_transaction_amount)}</h5>
              <p>{dataDetail?.principal_name}</p>
            </Grid>
            <h4>=</h4>
            <Grid
              item
              sx={{
                color: "#687083",
              }}
              alignItems="center"
            >
              <h5>{currencyFormat(dataDetail?.difference_amount)}</h5>
              <p>Difference</p>
            </Grid>
            <Grid
              item
              sx={{
                color: "#687083",
                ml: "24px",
                pl: "24px",
                borderLeft: "1px solid #E4E7EB",
                paddingLeft: "100px",
              }}
            >
              <h5>
                {dataDetail?.count}
                <CancelIcon fontSize="small" color="error" sx={{ ml: "4px" }} />
              </h5>

              <p>Missmatch</p>
            </Grid>
          </Grid>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    py: "6px",
                    px: "16px",
                    fontWeight: "600",
                    color: "#687083",
                    width: "600px",
                  }}
                >
                  Message
                </TableCell>
                <TableCell
                  sx={{
                    py: "6px",
                    px: "16px",
                    fontWeight: 600,
                    color: "#687083",
                  }}
                >
                  Detail
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white" }}>
              <TableCell>{dataDetail?.mismatch_type}</TableCell>
              <TableCell>{dataDetail?.mismatch_description}</TableCell>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((a, b) => ({
  ListRoot: {
    borderRadius: "6px",
    marginTop: "28px",
    "& h4": {
      padding: "11px",
      margin: 0,
      color: "#687083",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      margin: 0,
      color: "black",
      display: "flex",
      marginBottom: "4px",
      marginTop: "5px",
      alignItems: "center",
      "& img": {
        padding: 0,
        marginLeft: "4px",
      },
    },
    "& p": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      padding: 0,
      margin: 0,
      marginTop: "5px",
    },
  },
  ListWrapper: {
    backgroundColor: "#F9FAFB",
    borderRadius: "6px 6px 0px 0px",
    border: "1px solid #E4E7EB",
    borderBottom: "none",
  },
}));

export default DashboardMissmatchDetail;
