import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets";
import {
  BasicCard,
  CardComponent,
  CardMerchant,
  CustomInput,
  DataHeading,
  TableComponent,
} from "../../components";
import { currencyFormat, getUser } from "../../config";
import { useDashboardFBA } from "../../hooks";

const DashboardFBA = () => {
  const {
    dataProduct,
    dataDashboard,
    loading,
    activeTab,
    rows,
    meta,
    merchantSettlement,
    merchantFee,
    bankSettlement,
    bankFee,
    bankList,
    principalList,
    preParams,
    params,

    setActiveTab,
    setParams,
    setPreParams,
    allowDetail,
  } = useDashboardFBA();

  const classes = useStyles();
  const navigate = useNavigate();

  const column =
    activeTab === "Reconciliation Queue"
      ? [
          { name: "ID", renderCell: ({ id }) => id || "-" },
          {
            name: "Date",
            renderCell: ({ rns_process_start_at }) =>
              rns_process_start_at
                ? moment(new Date(rns_process_start_at)).format("lll")
                : "-",
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ bank_partner }) => bank_partner,
          },
          {
            name: "Principal",
            renderCell: ({ principal_partner }) => principal_partner,
          },
        ]
      : activeTab === "Ongoing Reconciliation"
      ? [
          { name: "ID", renderCell: ({ id }) => id || "-" },
          {
            name: "Date",
            renderCell: ({ date }) =>
              date ? moment(new Date(date)).format("lll") : "-",
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ merchant_name }) => merchant_name,
          },
          {
            name: "Principal",
            renderCell: ({ principal_name }) => principal_name,
          },
          {
            name: "Transaction Amount",
            renderCell: ({ transaction_amount }) =>
              currencyFormat(transaction_amount),
          },
          {
            name: "Fee Amount",
            renderCell: ({ fee_amount }) => currencyFormat(fee_amount),
          },
        ]
      : activeTab === "Error"
      ? [
          { name: "ID", renderCell: ({ id }) => id },
          {
            name: "Date",
            renderCell: ({ rns_process_start_at }) =>
              moment(new Date(rns_process_start_at)).format("lll"),
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ bank_partner }) => bank_partner,
          },
          {
            name: "Principal",
            renderCell: ({ principal_partner }) => principal_partner,
          },
          {
            name: "Count",
            renderCell: ({ count, id }) => (
              <Grid
                onClick={() => navigate(`/dashboard/${id}/error`)}
                color="red"
                sx={{ cursor: "pointer" }}
              >
                {count ?? 1}
              </Grid>
            ),
          },
        ]
      : [
          { name: "ID", renderCell: ({ id }) => id },
          {
            name: "Date",
            renderCell: ({ date }) => moment(new Date(date)).format("lll"),
          },
          {
            name: "Merchant / Bank",
            renderCell: ({ merchant_name, remote_transaction_amount }) => (
              <Grid>
                {currencyFormat(remote_transaction_amount)}
                <Grid
                  sx={{
                    color: "#687083",
                  }}
                >
                  {merchant_name}
                </Grid>
              </Grid>
            ),
          },
          {
            name: "Principal",
            renderCell: ({ principal_name, original_transaction_amount }) => (
              <Grid>
                {currencyFormat(original_transaction_amount)}
                <Grid
                  sx={{
                    color: "#687083",
                  }}
                >
                  {principal_name}
                </Grid>
              </Grid>
            ),
          },
          {
            name: "Difference",
            renderCell: ({ difference_amount }) => (
              <Grid color="red">{currencyFormat(difference_amount)}</Grid>
            ),
          },
          {
            name: "Count",
            renderCell: ({ count, id }) => (
              <Grid
                onClick={() => navigate(`/dashboard/${id}/missmatch`)}
                sx={{ cursor: "pointer" }}
              >
                {count ?? 1}
              </Grid>
            ),
          },
        ];

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <DataHeading title={`Welcome ${getUser().name}!`} />
      {/* Filter */}
      <Grid container className={classes.filterContainer} gap="20px">
        <Grid className={classes.filterItem}>
          Product
          <CustomInput.Select
            onChange={(e) => setParams({ ...params, product: e.target.value })}
            value={params.product ?? null}
            width="192px"
            placeholder="Select Product"
          >
            {dataProduct.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.product_desc}
              </MenuItem>
            ))}
          </CustomInput.Select>
        </Grid>
        <Grid className={classes.filterItem} gap="3px">
          Period
          <CustomInput.MonthPicker
            onChange={(val) => setParams({ ...params, date: val })}
          />
        </Grid>
      </Grid>

      {/* List Item */}
      <Grid className={classes.itemList} container spacing="24px">
        {[
          "Reconciliation Queue",
          "Ongoing Reconciliation",
          "Total Error",
          "Total Mismatch",
        ].map((item) => {
          const selected =
            item === "Total Error"
              ? "Error"
              : item === "Total Mismatch"
              ? "Missmatch"
              : item;
          return (
            <Grid key={item} item>
              <BasicCard
                onClick={(_) => {
                  const selected =
                    item === "Total Error"
                      ? "Error"
                      : item === "Total Mismatch"
                      ? "Missmatch"
                      : item;
                  setActiveTab(selected);
                  const container = document.getElementById(
                    "container-table-dashboard-fba"
                  );
                  if (container) {
                    container.scrollIntoView();
                  }
                }}
                title={item}
                value={`${
                  dataDashboard?.[item.toLowerCase()?.split(" ")?.join("_")] ||
                  "0"
                }`}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Table */}
      <Grid component={Paper} container borderRadius="8px">
        <Grid container className={classes.headerTable}>
          <Button
            className={classes.btnRefresh}
            onClick={() =>
              setParams({
                ...params,
                bank_code: preParams.bank_code,
                principle_code: preParams.principal_code,
              })
            }
            variant="outlined"
            startIcon={<RefreshIcon fontSize="small" />}
          >
            Refresh
          </Button>

          <Grid display="flex" alignItems="center" gap="10px">
            <CustomInput.Select
              placeholder="Bank"
              sx={{ width: "160px" }}
              onChange={(e) =>
                setPreParams({ ...preParams, bank_code: e.target.value })
              }
            >
              {bankList.map((val) => (
                <MenuItem value={val?.partner_Code}>
                  {val.partner_name}
                </MenuItem>
              ))}
            </CustomInput.Select>
            <CustomInput.Select
              placeholder="Principal"
              sx={{ width: "160px" }}
              onChange={(e) =>
                setPreParams({ ...preParams, principal_code: e.target.value })
              }
            >
              {principalList.map((val) => (
                <MenuItem value={val?.partner_Code}>
                  {val.partner_name}
                </MenuItem>
              ))}
            </CustomInput.Select>

            <Button
              className={classes.btnDetail}
              onClick={() =>
                navigate(`/dashboard/transaction/${params.product}`)
              }
              fullWidth
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
            >
              See Details
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ width: "100%" }} />

        <Grid container className={classes.itemsContainer}>
          <Grid container>
            <Grid sx={{ marginBottom: "24px" }} container spacing="24px">
              <Grid item>
                <CardComponent
                  counter={`${dataDashboard.transaction_count}`}
                  desc="Transaction Count"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  counter={
                    "Rp" + dataDashboard.total_fee?.toLocaleString("id-ID")
                  }
                  desc="Total Fee"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  counter={
                    "Rp" + dataDashboard.total_amount?.toLocaleString("id-ID")
                  }
                  desc="Total Amount"
                />
              </Grid>
              <Grid item>
                <CardComponent
                  counter={
                    "Rp" +
                    dataDashboard.total_settlement?.toLocaleString("id-ID")
                  }
                  desc="Total Settlement"
                />
              </Grid>
            </Grid>
            <ButtonGroup className={classes.buttonGroup} variant="string">
              {[
                "Reconciliation Queue",
                "Ongoing Reconciliation",
                "Error",
                "Missmatch",
              ].map((val) => (
                <Button
                  key={val}
                  type="button"
                  onClick={() => {
                    setActiveTab(val);
                  }}
                  className={activeTab === val ? "active" : ""}
                >
                  {val}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>

        <Grid
          className={classes.table}
          container
          id="container-table-dashboard-fba"
        >
          <TableComponent
            loading={loading}
            rows={rows || []}
            column={column}
            onPageChange={(val) => setParams({ ...params, page: val })}
            onRowsChange={({ target }) =>
              setParams({ ...params, limit: target.value, page: 1 })
            }
            limit={params?.limit}
            totalData={meta?.total}
            page={meta?.page}
            onRowsClick={(data) => {
              if (allowDetail())
                navigate(`/dashboard/${data.id}/${activeTab.toLowerCase()}`);
            }}
          />
        </Grid>
      </Grid>
      <Grid className={classes.merchant}>
        <CardMerchant
          title="Top 5 Merchant"
          settlementData={merchantSettlement}
          feeData={merchantFee}
        />
        <CardMerchant
          title="Top 5 Bank Acquirer"
          settlementData={bankSettlement}
          feeData={bankFee}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  filterContainer: {
    display: "flex",
    gap: "20px",
  },
  filterItem: {
    display: "flex !important",
    flexDirection: "column !important",
  },
  itemList: { marginBottom: "24px", marginTop: "8px !important" },
  headerTable: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 24px 14px 24px",
  },
  btnRefresh: {
    color: Colors.SECONDARY_DARK + "!important",
    borderColor: Colors.SECONDARY_DARK + "!important",
    "&:hover": {
      borderColor: Colors.SECONDARY_DARK,
    },
  },
  btnDetail: {
    backgroundColor: Colors.SECONDARY + "!important",
    color: "white",
    fontWeight: 600 + "!important",
  },
  itemsContainer: {
    padding: "24px",
    "& .active": {
      backgroundColor: "rgba(165, 189, 68, 0.1)",
      color: "#9AB825",
    },
  },
  buttonGroup: {
    border: "1px solid #E4E7EB",
    borderRadius: "8px !important",
    overflow: "hidden",
    "& button": {
      fontWeight: 600,
      color: "#687083",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(128, 128, 128, 0.07)!important",
      },
    },
    "& .active": {
      backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      color: Colors.SECONDARY_DARK + "!important",
      "&:hover": {
        color: Colors.SECONDARY_DARK + "!important",
        backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      },
    },
  },
  table: {
    padding: "0px 24px 24px 24px",
  },
  merchant: {
    marginTop: "24px",
    gap: "24px",
    display: "flex",
    alignItems: "start",
  },
});

export default DashboardFBA;
