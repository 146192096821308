import { Box, Grid } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];
const barSize = {
  barThickness: 16,
  maxBarThickness: 12,
};

const initialChartData = {
  labels,
  datasets: [
    {
      label: "Success",
      data: [],
      backgroundColor: "#DE5242",
      ...barSize,
    },
    {
      label: "Error / Missmatch",
      data: [],
      backgroundColor: "#54B371",
      ...barSize,
    },
  ],
};

const LineChart = ({
  dataTrx,
  date,
  containerLegendId = "container-legend",
}) => {
  const [DatasetChart, setDatasetChart] = useState(initialChartData);
  const [Options, setOptions] = useState(null);
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);

    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "grid";
      listContainer.style.gridTemplateColumns = "auto auto auto auto";
      listContainer.style.rowGap = "17px";
      listContainer.style.columnGap = "0px";
      listContainer.style.width = "100%";
      // listContainer.style.marginLeft = '50px';
      // listContainer.style.maxWidth = maxWidthLegend;
      listContainer.style.margin = "0 0 0 50px";
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };
  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      // var dataset = chart.config.data.datasets[0];
      // var offset = 0;

      // for (var i = 0; i < dataset._meta[0].data.length; i++) {
      //   var model = dataset._meta[0].data[i]._model;
      //   model.x += offset;
      //   model.controlPointNextX += offset;
      //   model.controlPointPreviousX += offset;
      // }

      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";
        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer?.appendChild(text);

        li?.appendChild(boxSpan);
        li?.appendChild(textContainer);
        ul?.appendChild(li);
      });
    },
  };
  useEffect(() => {
    configOption();
    configData();
  }, []);

  const configData = () => {
    if (dataTrx.length) {
      let trxMaping = {
        labels: dataTrx.map((idx) => idx.time),
        datasets: [
          {
            label: "Success",
            data: dataTrx.map((trx) => trx.success),
            backgroundColor: "#54B371",
            ...barSize,
          },
          {
            label: "Mismatch",
            data: dataTrx.map((trx) => trx.mismatch),
            backgroundColor: "#F9C851",
            ...barSize,
          },
          {
            label: "Error",
            data: dataTrx.map((trx) => trx.transaction_error),
            backgroundColor: "#DE5242",
            ...barSize,
          },
        ],
      };

      setDatasetChart(trxMaping);
    }
  };

  const configOption = () => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        htmlLegend: {
          containerID: containerLegendId,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title: (context) => {
              return context[0]?.dataset?.label;
            },
            label: (context) => {
              return context?.dataset?.typeData + ": " + context.parsed.y;
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              color: "#E4E7EB",
            },
          },
        },
      },
      borderColor: "#E4E7EB",
    };
    setOptions(options);
  };

  if (!Options) return null;

  return (
    <Grid sx={{ height: "auto" }}>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>
          Transaction{" "}
          <span
            style={{
              color: "#9AA2B1",
              fontWeight: "300",
              paddingLeft: "5px",
            }}
          >
            {date}
          </span>
        </h2>
        <Box
          // sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
          id={containerLegendId}
        >
          {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          <span
            style={{
              height: "15px",
              width: "15px",
              margin: "0 5px",
              backgroundColor: "#54B371",
            }}
          />
          Success
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          <span
            style={{
              height: "15px",
              width: "15px",
              margin: "0 5px",
              backgroundColor: "#F9C851",
            }}
          />
          Mismatch
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              height: "15px",
              width: "15px",
              margin: "0 5px",
              backgroundColor: "#DE5242",
            }}
          />
          Error
        </div> */}
        </Box>
      </Grid>
      <Grid item sx={{ height: 280 }}>
        {/* <Box
          // sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
          id={containerLegendId}
        /> */}
        <Line
          options={Options}
          data={!dataTrx.datasets.length ? initialChartData : dataTrx}
          plugins={[htmlLegendPlugin]}
        />
      </Grid>
    </Grid>
  );
};

export default LineChart;
