import { Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  CustomButton,
  FilterPartner,
  TableComponent,
} from "../../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT, URL_API } from "../../../config";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
};

export default function TableUserEksternal() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0 ? dataParams : initialParams
  );

  const column = [
    {
      name: "Partner Name",
      renderCell: (params) =>
        loading ? (
          <Skeleton type="text" />
        ) : (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              "& img": {
                height: "24px",
              },
            }}
          >
            {" "}
            <img src={`${URL_API}/public/${params.logo}`} alt="" />{" "}
            <Grid
              sx={{
                border: "1px solid #E4E7EB",
                height: "20px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            />
            <p>{params.name}</p>
          </Grid>
        ),
    },
    {
      name: "Partner Type",
      renderCell: (params) => params.partner_type,
    },
    {
      name: "User",
      renderCell: (params) => params.total_user,
    },
  ];

  const [dataPartnerType, setDataPartnerType] = useState({
    loading: true,
    data: [],
  });
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState("");
  const [partnerTypeId, setPartnerTypeId] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const getListPartnerType = async () => {
    await Axios.get(`/partner-type`).then((res) => {
      // console.log('partner type', res)
      setDataPartnerType({
        loading: false,
        data: res.data.data.partner_types || [],
      });
    });
  };

  useEffect(() => {
    getListPartnerType();
  }, []);

  const getDataTable = () => {
    setLoading(true);
    Axios.get(
      partnerTypeId
        ? `/user/partner${search}&andFilter=partner_type_id:${partnerTypeId}`
        : `/user/partner${search}`
    ).then((res) => {
      // console.log("res", res);
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data?.Data || []);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  useEffect(() => {
    let idPrincipals = selectedFilter?.map((el) => el.id).toString();
    setPartnerTypes(idPrincipals);
  }, [selectedFilter]);

  useEffect(() => {
    getDataTable();
  }, [partnerTypes, partnerTypeId]);

  return (
    <Grid>
      <TableComponent
        // onFilterTable
        // dataFilter={dataPartnerType}
        // filterPlaceholder='Partner Type'
        // // multipleValue
        // filterComponent={
        //   <FilterPartner
        //     onApply={() => {
        //       console.info('info reset');
        //     }}
        //     onReset={console.info('info reset')}
        //     isPartnerUser
        //   />
        // }
        // filterType='single'
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        rows={rows}
        column={column}
        onRowsClick={(val) => navigate(`/user-eksternal/${val.id}`)}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={dataParams?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterPartner
                isPatnerUser={true}
                onApply={({ partner_type }) => {
                  setPartnerTypeId(partner_type);
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({
                    ...params,
                  });
                  setPartnerTypeId("");
                  setOpenFilter(false);
                }}
                filters={{
                  partner_type: partnerTypeId,
                }}
              />
            </CustomButton.Filter>
          </Grid>
        }
      />
    </Grid>
  );
}
