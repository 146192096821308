import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import SuccesIcon from "../../assets/icons/icon-success.svg";
import EmailSuccessIcon from "../../assets/icons/mail-success.svg";

const SuccessComponent = ({ verified = false }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.successContainer}>
      <img src={verified ? SuccesIcon : EmailSuccessIcon} alt="success" />
      <p className={classes.successTitle}>
        {verified ? "Change Password Succed!" : "Confirmation Change Password"}
      </p>
      <p className={classes.successMessage}>
        {verified
          ? "Your password has been changed successfully!"
          : "Password change confirmation has been sent to your email, please check and confirm!"}
      </p>
      {verified && (
        <Button
          type="submit"
          variant="contained"
          fullWidth
          className={classes.customButton}
          onClick={() => (window.location.href = "/dashboard")}
        >
          Back
        </Button>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  successContainer: {
    marginTop: "12px",
    marginBottom: "12px",
    color: "#1A3650",
    textAlign: "center",
    lineHeight: "24px",
  },
  successTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  successMessage: {
    color: "#1A3650",
    fontSize: "14px",
  },
  customButton: {
    height: "48px !important",
    backgroundColor: "#A0B842 !important",
    "&:hover": {
      backgroundColor: "#83992A",
    },
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    position: "relative !important",
    marginTop: "24px !important",
  },
}));

export default SuccessComponent;
