import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Colors } from "../../assets";
import {
  CardComponent,
  InputComponent,
  TableComponent,
  TitleWithBackButton,
} from "../../components";
import { formatToThousands } from "../../config";
import { AxiosFinpoint } from "../../config/helperFinpoint";

// for loading animation only
let dummy = {
  date: "",
  time: "",
  transaction_id: "",
  card_number: "",
  merchant_ref_num: "",
  retrieval_reference_number: "",
  transaction_type: "",
  description: "",
  amount: 0,
  status: "",
  source_of_fund: "",
};

let initialBody = {
  merchant_ref_no: "", // 1200157293
  svbo_acquirer_network_id: "", // 2001
  retrieval_reference: "", // 226602354910
  transaction_id: "", // trn_gvhtTDukdPR3dZu6GGmF8DpZc0mN
  start_date: dayjs(new Date()).format("MM-DD-YYYY 00:00:00"), // '01-10-2022 09:30:01' //format = mm-dd-yyyy jam:menit:detik
  end_date: dayjs(new Date()).format("MM-DD-YYYY 00:00:00"), // '03-11-2022 09:30:01' //format = mm-dd-yyyy jam:menit:detik
  trans_status: "",
  page_num: 1,
  size_data: 10,
};

let initialMeta = {
  total_success_trans: 0,
  total_fail_trans: 0,
  total_trans: 0,
};

export default function ViewTransaction() {
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const dummyData = [dummy, dummy, dummy];

  const [body, setBody] = useState(initialBody);

  const [error, setError] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);

  const [rows, setRows] = useState(dummyData);
  const [Meta, setMeta] = useState(initialMeta);
  const handleSearch = () => {
    setBody({ ...body, page_num: 1 });
    setTimeout(() => {
      onLoadData();
    }, 500);
  };

  const onLoadData = async () => {
    setIsLoading(true);
    setRows(dummyData);
    setError(null);

    await AxiosFinpoint.post("/report/transaction", body)
      .then((res) => {
        if (res) {
          const result = res.data.data.data;
          if (result)
            if (result.status === "SUCCESS" && result.details.length > 0) {
              setRows(result.details);
              setMeta(result);
            } else {
              setError("No data found");
              setRows([]);
            }
          else {
            setError(res.data.description);
            setMeta(initialMeta);
            setRows([]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error : ", error);
        setRows([]);
        setMeta(initialMeta);
        setError(error.message);

        setIsLoading(false);
        if (error.response.data.message) setError(error.response.data.message);
      });
  };

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };
  const onRowsChange = (e) =>
    setBody({ ...body, size_data: e.target.value, page_num: 1 });
  const onPageChange = (page) => setBody({ ...body, page_num: page });

  useEffect(() => {
    onLoadData();
  }, [body.size_data, body.page_num]);

  const Columns = [
    {
      name: "No",
      renderCell: (params, i) =>
        IsLoading ? (
          <Skeleton type="text" />
        ) : (
          body.size_data * (body.page_num - 1) + (i + 1)
        ),
    },
    {
      name: "Date",
      renderCell: (params, i) =>
        IsLoading ? (
          <Skeleton type="text" />
        ) : params.date ? (
          dayjs(params.date).format("DD MMMM YYYY")
        ) : (
          ""
        ),
      minWidth: 150,
    },
    {
      name: "Time",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.time,
      minWidth: 120,
    },
    {
      name: "Transaction ID",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.transaction_id,
      minWidth: 120,
    },
    {
      name: "Merchant Ref Num",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.merchant_ref_num,
      minWidth: 120,
    },
    {
      name: "Card Num",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.card_number,
    },
    {
      name: "Retrieval Reference Num",
      renderCell: (params, i) =>
        IsLoading ? (
          <Skeleton type="text" />
        ) : (
          params.retrieval_reference_number
        ),
      minWidth: 200,
    },
    {
      name: "Transaction Type",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.transaction_type,
      minWidth: 150,
    },
    {
      name: "Desciption",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.description,
      minWidth: 500,
    },
    {
      name: "Amount",
      renderCell: (params, i) =>
        IsLoading ? (
          <Skeleton type="text" />
        ) : (
          `Rp${formatToThousands(params.amount)},-`
        ),
    },
    {
      name: "Status",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.status,
    },
    {
      name: "Source of Fund",
      renderCell: (params, i) =>
        IsLoading ? <Skeleton type="text" /> : params.source_of_fund,
    },
  ];

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="View Transaction" hiddenBackButton />
      <Grid container spacing="15px">
        <Grid item md={3}>
          <InputComponent
            onChange={handleChange}
            disabled={IsLoading}
            label="Merchant Ref Number"
            id="merchant_ref_no"
            value={body.merchant_ref_no}
          />
        </Grid>
        <Grid item md={3}>
          <InputComponent
            onChange={handleChange}
            disabled={IsLoading}
            label="Bank Code"
            id="svbo_acquirer_network_id"
            value={body.svbo_acquirer_network_id}
          />
        </Grid>
        <Grid item md={3}>
          <InputComponent
            onChange={handleChange}
            disabled={IsLoading}
            label="Transaction ID"
            id="transaction_id"
            value={body.transaction_id}
          />
        </Grid>
        <Grid item md={3}>
          <InputComponent
            onChange={handleChange}
            disabled={IsLoading}
            label="Retrieval Reference"
            id="retrieval_reference"
            value={body.retrieval_reference}
          />
        </Grid>
        <Grid item md={3}>
          <InputComponent
            value={dayjs(body.start_date).format("MM-DD-YYYY 00:00:00")}
            disabled={IsLoading}
            label="Start Date"
            type="date"
            id="start_date"
            name="start_date"
            control={control}
            error={errors}
            sx={{ width: "100%" }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </Grid>
        <Grid item md={3}>
          <InputComponent
            value={dayjs(body.end_date).format("MM-DD-YYYY 00:00:00")}
            disabled={IsLoading}
            label="End Date"
            type="date"
            id="end_date"
            name="end_date"
            control={control}
            error={errors}
            sx={{ width: "100%" }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            marginTop: "4px",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: Colors.SECONDARY + " !important",
              borderRadius: "8px !important",
              "&:disabled": {
                backgroundColor: "#B7B7B780 !important",
              },
            }}
            onClick={() => handleSearch()}
            variant="contained"
            disabled={IsLoading}
          >
            {IsLoading ? (
              <CircularProgress size={23} sx={{ color: "white" }} />
            ) : (
              <SearchIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </Grid>
      </Grid>

      {error && (
        <Grid mt="24px">
          <Alert severity="error">
            <span>{error}</span>
          </Alert>
        </Grid>
      )}

      <Grid mt="24px">
        <Grid container spacing="24px">
          <Grid item md={3}>
            <CardComponent
              fullWidth
              title="Total Approved Transaction"
              count={Meta.total_success_trans.toString()}
            />
          </Grid>
          <Grid item md={3}>
            <CardComponent
              fullWidth
              title="Total Failed Transaction"
              count={Meta.total_fail_trans.toString()}
            />
          </Grid>
          <Grid item md={3}>
            <CardComponent
              fullWidth
              title="Total Transaction"
              count={Meta.total_trans.toString()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid mt="24px">
        <TableComponent
          rows={rows}
          column={Columns}
          limit={body.size_data}
          page={body.page_num}
          totalData={Meta.total_trans}
          onRowsChange={onRowsChange}
          onPageChange={onPageChange}
        />
      </Grid>
    </Grid>
  );
}
