import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { DividerImage, PrivacyBanner } from "../../assets";
import {
  ButtonAppBar,
  CollapseComponentPrivacy,
  FooterLandingPage,
} from "../../components";

export default function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <ButtonAppBar />
      <section className={classes.banner}>
        <div className="blocker" />
        <img src={PrivacyBanner} alt="" />
        <img src={DividerImage} alt="" className="divider" />
        <div className="content">
          <h1>Privacy Policy</h1>
        </div>
      </section>
      <section className={classes.body}>
        <Container>
          <Grid ml={{ md: "140px" }} mr={{ md: "140px" }}>
            <Grid
              textAlign="center"
              pt={{ md: "128px", xs: "32px" }}
              mt="20px"
              pb={{ md: "128px", xs: "32px" }}
            >
              {/* <img src={IconPrivacy} alt="" /> */}
              <p>
                <h4>Last updated August 01, 2022 </h4>
                This privacy notice for PT Finpoint Solusi Indonesia (doing
                business as Finpoint) ('Finpoint', 'we', 'us', or 'our',),
                describes how and why we might collect, store, use, and/or share
                ('process') your information when you use our services
                ('Services'), such as when you:
                <ul>
                  <li>
                    Visit our website at https://finpoint.id, or any website of
                    ours that links to this privacy notice
                  </li>
                  <li>
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                  </li>
                </ul>
                <h4>Questions or concerns? </h4>
                Reading this privacy notice will help you understand your
                privacy rights and choices. If you do not agree with our
                policies and practices, please do not use our Services. If you
                still have any questions or concerns, please contact us
                at info@finpoint.id.
                <h4>SUMMARY OF KEY POINTS </h4>
                <h4>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for.{" "}
                </h4>
                <h4>What personal information do we process?</h4>
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact
                with Finpoint and the Services, the choices you make, and the
                products and features you use.
                <h4>Do we process any sensitive personal information?</h4>
                We do not process sensitive personal information.
                <h4>Do we receive any information from third parties? </h4>
                We do not receive any information from third parties.
                <h4>How do we process your information? </h4>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so.
                <h4>
                  In what situations and with which parties do we share personal
                  information?
                </h4>
                We may share information in specific situations and with
                specific third parties.
                <h4>How do we keep your information safe? </h4>
                We have organisational and technical processes and procedures in
                place to protect your personal information. However, no
                electronic transmission over the internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot
                promise or guarantee that hackers, cybercriminals, or
                other unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information.
                <h4>What are your rights?</h4>
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.
                <h4>How do you exercise your rights?</h4>
                The easiest way to exercise your rights is by filling out our
                data subject request form by contacting us. We will consider and
                act upon any request in accordance with applicable data
                protection laws.
                <br />
                <br />
                Want to learn more about what Finpoint does with any information
                we collect?
                <h4>TABLE OF CONTENTS</h4>
                1. What Information Do We Collect?
                <br />
                2. How Do We Process Your Information? <br />
                3. When And With Whom Do We Share Your Personal Information?{" "}
                <br />
                4. Do We Use Cookies And Other Tracking Technologies?
                <br />
                5. How Long Do We Keep Your Information? <br />
                6. How Do We Keep Your Information Safe? <br />
                7. Do We Collect Information From Minors? <br />
                8. What Are Your Privacy Rights? <br />
                9. Controls For Do-Not-Track Features <br />
                10. Do California Residents Have Specific Privacy Rights? <br />
                11. Do We Make Updates To This Notice? <br />
                12. How Can You Contact Us About This Notice? <br />
                13. How Can You Review, Update, Or Delete The Data We Collect
                From You? <br />
                <CollapseComponentPrivacy
                  header="We collect personal information that you provide to us."
                  title="1. What Information Do We Collect?"
                >
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services,
                  or otherwise when you contact us.
                  <h4>Personal Information Provided by You.</h4>
                  The personal information that we collect depends on the
                  context of your interactions with us and the Services, the
                  choices you make, and the products and features you use. The
                  personal information we collect may include the following:
                  <ul>
                    <li>names,</li>
                    <li>email addresses,</li>
                    <li>company name</li>
                  </ul>
                  <h4>Sensitive Information.</h4>
                  We do not process sensitive information.
                  <br />
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </CollapseComponentPrivacy>
                <CollapseComponentPrivacy
                  title="2. How Do We Process Your Information?"
                  header="We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent."
                >
                  <br />
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                  <ul>
                    <li>
                      <span className="bold">
                        To facilitate account creation and authentication and
                        otherwise manage user accounts.{" "}
                      </span>
                      We may process your information so you can create and log
                      in to your account, as well as keep your account in
                      working order.
                    </li>
                    <li>
                      <span className="bold">
                        To deliver and facilitate delivery of services to the
                        user.{" "}
                      </span>
                       We may process your information to provide you with the
                      requested service.
                    </li>
                    <li>
                      <span className="bold">
                        To evaluate and improve our Services, products,
                        marketing, and your experience.{" "}
                      </span>
                      We may process your information when we believe it is
                      necessary to identify usage trends, determine the
                      effectiveness of our promotional campaigns, and to
                      evaluate and improve our Services, products, marketing,
                      and your experience.
                    </li>
                    <li>
                      <span className="bold">
                        To comply with our legal obligations.{" "}
                      </span>
                      We may process your information to comply with our legal
                      obligations, respond to legal requests, and exercise,
                      establish, or defend our legal rights.
                    </li>
                  </ul>
                </CollapseComponentPrivacy>
                <CollapseComponentPrivacy
                  title="3. When And With Whom Do We Share Your Personal Information?"
                  header="We may share information in specific situations described in this section and/or with the following third parties."
                >
                  <br />
                  We may need to share your personal information in the
                  following situations:
                  <ul>
                    <li>
                      <span className="bold">Business Transfers. </span>
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                    </li>
                  </ul>
                </CollapseComponentPrivacy>
                <CollapseComponentPrivacy
                  title="4. Do We Use Cookies And Other Tracking Technologies?"
                  header="We may use cookies and other tracking technologies to collect and store your information."
                >
                  <br />
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Notice.
                </CollapseComponentPrivacy>
                <CollapseComponentPrivacy
                  title="5. How Long Do We Keep Your Information?"
                  header="We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law."
                />
                <CollapseComponentPrivacy
                  title="6. How Do We Keep Your Information Safe?"
                  header="We aim to protect your personal information through a system of organisational and technical security measures."
                />
                <CollapseComponentPrivacy
                  title="7. Do We Collect Information From Minors?"
                  header="We do not knowingly collect data from or market to children under 18 years of age."
                />
                <CollapseComponentPrivacy
                  title="8. What Are Your Privacy Rights? "
                  header="You may review, change, or terminate your account at any time."
                />
                <CollapseComponentPrivacy
                  title="9. Controls For Do-Not-Track Features"
                  header="Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice."
                />
                <CollapseComponentPrivacy
                  title="10. Do California Residents Have Specific Privacy Rights?"
                  header="Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. "
                />
                <CollapseComponentPrivacy
                  title="11. Do We Make Updates To This Notice?"
                  header="Yes, we will update this notice as necessary to stay compliant with relevant laws."
                />
                <CollapseComponentPrivacy
                  title="12. How Can You Contact Us About This Notice?"
                  header="If you have questions or comments about this notice, you may email us at info@finpoint.id or by post to:"
                  disableCollapse
                >
                  <br />
                  <span>
                    PT Finpoint Solusi Indonesia
                    <br /> Ebenezer Building, Jl. Setia Budi Selatan No.1,
                    RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota
                    Jakarta Selatan Daerah Khusus Ibukota Jakarta 12920
                    Indonesia{" "}
                  </span>
                </CollapseComponentPrivacy>
                <CollapseComponentPrivacy
                  title="13. How Can You Review, Update, Or Delete The Data We Collect From You? "
                  header="Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us. "
                  disableCollapse
                  noLine
                />
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
      <FooterLandingPage />
    </section>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      "& img": {
        width: "100%",
      },
      position: "relative",
      "& .divider": {
        position: "absolute",
        bottom: "0px",
        width: "100%",
        left: "0px",
      },
      "& .blocker": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        background: "linear-gradient(72.4deg, #289ECC 0.01%, #8EA33A 100%)",
        // opacity: "0.4",
        zIndex: -1,
      },
      "& .content": {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 2,
        top: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& h1": {
          [theme.breakpoints.down("md")]: {
            fontWeight: 300,
            fontSize: "16px",
            lineHeight: "18px",
          },
          fontWeight: 300,
          fontSize: "72px",
          lineHeight: "72px",
          color: "white",
        },
      },
    },
    body: {
      backgroundColor: "#F7F9FC",
      marginTop: "-20px",
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "12px",
          lineHeight: "16px",
        },
        fontWeight: 300,
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "left",
        "& h4": {
          marginBottom: "24px",
          marginBottom: "24px",
          color: "#2C3038",
        },
        "& .bold": {
          fontWeight: 600,
        },
      },
    },
  };
});

// const CollapseComponent = ({
//   children,
//   header,
//   title,
//   disableCollapse,
//   noLine,
// }) => {
//   const [open, setOpen] = useState(false);
//   return (
//     <Grid
//       sx={{
//         "& hr": {
//           borderBottom: "1px solid #BCC1CC",
//         },
//       }}
//     >
//       <h4>{title}</h4>
//       <Grid
//         ml="24px"
//         sx={{
//           "& h4": {
//             alignItems: "center",
//             display: "flex",
//             color: "#97AD3E!important",
//             "& svg": {
//               marginLeft: "9px",
//             },
//           },
//         }}
//       >
//         {header}
//         <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
//           {children}
//         </Collapse>
//         {!disableCollapse && (
//           <h4 className="read-more" onClick={() => setOpen(!open)}>
//             {!open ? "Read More" : "Read Less"}
//             {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//           </h4>
//         )}
//       </Grid>
//       {!noLine && <hr />}
//     </Grid>
//   );
// };
