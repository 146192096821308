import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ClickAwayListener,
  Collapse,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomFilter from "../Global/CustomFilter";
import SelectFilterComponent from "./SelectFilterComponent";

export default function TableComponent({
  column,
  rows,
  onRowsClick,
  onSearchTable,
  onFilterTable,
  onRowsChange,
  onPageChange,
  size,
  collapse,
  endButton,
  defaultValueSearch,
  limit = 10,
  totalData = 0,
  page = 1,
  filterType = "multi",
  dataFilter = { data: [], loading: false },
  multipleValue = false,
  filterPlaceholder = "Filter",
  setSelectedFilter,
  selectedFilter,
  multipleFilterComponent,
  withTitle,
  filterComponent,
  loading,
  sizeAutoComplete = null,
}) {
  const classes = useStyles();
  const [tableColapse, setTableColapse] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) return;
    setOpen(!open);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // partner_type_id: null,
    },
  });

  const onSearch = (val) => {
    onSearchTable(val);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        overflow: "hidden",
        border: "1px solid #E4E7EB",
        borderRadius: "8px",
      }}
    >
      {withTitle && (
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingTop: "15px",
            px: "25px",
            marginBottom: "-5px",
          }}
        >
          {withTitle}
        </Typography>
      )}
      {(onSearchTable || onFilterTable) && (
        <Toolbar sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Grid container>
            {onSearchTable && (
              <FormControl sx={{ width: "200px" }} size="small">
                <form action="" onSubmit={handleSubmit(onSearch)}>
                  <OutlinedInput
                    placeholder="Search"
                    inputProps={{ ...register("search") }}
                    defaultValue={defaultValueSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          type="submit"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </form>
              </FormControl>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" gap="12px">
            {onFilterTable && (
              <>
                {filterType === "multi" ? (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<FilterListIcon sx={{ color: "#9AA2B1" }} />}
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        padding: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      onClick={handleToggle}
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open ? "composition-filter" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                    >
                      Filter
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      sx={{
                        zIndex: 999,
                      }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                          sx={{ marginTop: "-15px" }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              {multipleFilterComponent ? (
                                multipleFilterComponent
                              ) : (
                                <p>Filter</p>
                              )}
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <>
                    {filterType === "custom" ? (
                      <CustomFilter
                        dataFilter={dataFilter}
                        selectedAppliedFilter={selectedFilter}
                        setSelectedAppliedFilter={setSelectedFilter}
                      />
                    ) : (
                      <SelectFilterComponent
                        dataFilter={dataFilter}
                        multipleValue={multipleValue}
                        filterPlaceholder={filterPlaceholder}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                        sizeAutoComplete={sizeAutoComplete}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {filterComponent}
            {endButton && endButton}
          </Grid>
        </Toolbar>
      )}
      <div style={{ overflow: "auto" }}>
        <Table
          sx={{ minWidth: 700, whiteSpace: "nowrap" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {collapse && (
                <StyledTableCell
                  size="small"
                  sx={{
                    backgroundColor: "#F9FAFB",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#687083",
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: "10px",
                  }}
                ></StyledTableCell>
              )}
              {column.map((res, i) => {
                return (
                  <StyledTableCell
                    key={i}
                    size="small"
                    sx={{
                      backgroundColor: "#F9FAFB",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "30px",
                      width: res.minWidth,
                      paddingLeft: size === "small" ? 0 : "",
                      paddingRight: size === "small" ? 0 : "",
                    }}
                  >
                    {res.name}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              return (
                <Fragment key={index}>
                  <StyledTableRow
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRowsClick && onRowsClick(row);
                    }}
                    sx={{
                      ":hover": {
                        backgroundColor: onRowsClick && "#D6F0FD",
                        cursor: onRowsClick && "pointer",
                      },
                    }}
                  >
                    {collapse && (
                      <StyledTableCell sx={{ padding: 0 }}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            tableColapse === row.id
                              ? setTableColapse(null)
                              : setTableColapse(row.id);
                          }}
                          sx={{
                            transform:
                              tableColapse === row.id && "rotate(180deg)",
                          }}
                        >
                          <ExpandCircleDownOutlinedIcon />
                        </IconButton>{" "}
                      </StyledTableCell>
                    )}
                    {column.map((res, i) => {
                      return (
                        <StyledTableCell
                          key={i}
                          align={res.align || "left"}
                          sx={{ padding: size === "small" ? 0 : "" }}
                        >
                          {loading ? (
                            <Skeleton type="text" />
                          ) : res.renderCell ? (
                            res.renderCell(row, index)
                          ) : (
                            "No Data"
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                  {collapse && (
                    <StyledTableRow sx={{ backgroundColor: "#F5F6F7" }}>
                      <TableCell sx={{ padding: 0 }}></TableCell>
                      <TableCell
                        sx={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        colSpan={column?.length}
                      >
                        <Collapse
                          in={tableColapse === row.id}
                          timeout="auto"
                          unmountOnExit
                          sx={{ width: "100%" }}
                        >
                          {collapse(row)}
                        </Collapse>
                      </TableCell>
                    </StyledTableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {rows?.length === 0 && (
        <Grid
          container
          sx={{
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 400,
            fontSize: "14px",
            color: "#687083",
          }}
        >
          No data yet
        </Grid>
      )}
      {(onRowsChange || onPageChange) && (
        <Toolbar
          sx={{
            paddingLeft: "0px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#687083",
          }}
        >
          <Grid container>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {onRowsChange && (
                <>
                  Rows per page
                  <FormControl sx={{ ml: 1.5 }}>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={limit}
                      onChange={onRowsChange}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
            {onPageChange && (
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                justifyContent="flex-end"
              >
                Show {limit * (page - 1) + 1} -{" "}
                {limit * page > totalData ? totalData : limit * page} from{" "}
                {totalData}
                <IconButton
                  disabled={page === 1}
                  onClick={() => onPageChange(page - 1)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  disabled={limit * page > totalData ? true : false}
                  onClick={() => onPageChange(page + 1)}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      )}
    </TableContainer>
  );
}

const useStyles = makeStyles({});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
