import { Box, Divider, Link, Typography } from "@mui/material";
import React from "react";

const Copyright = () => {
  return (
    <Box
      position="absolute"
      bottom={0}
      width="83.7%"
      backgroundColor="white"
      textAlign="center"
    >
      <Divider />
      <Typography variant="body2" color="text.secondary" p={2}>
        {"Copyright © "}
        <Link color="inherit" href="">
          Finpoint
        </Link>{" "}
        {process.env.REACT_APP_VERSION}
        {"."}
      </Typography>
    </Box>
  );
};

export default Copyright;
