import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Colors } from "../../../assets";
import {
  InputComponent,
  ModalComponent,
  RadioInputRow,
} from "../../../components";
import { Axios, URL_API } from "../../../config";

export default function ModalPartnerBA({
  openModal,
  closeModal,
  getDataTable,
}) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    getFieldState,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (openModal?.id) {
      Axios.get(`/partner/detail/${openModal?.id}`).then(({ data }) => {
        console.log(data);
        setValue("partner_name", data.data.partner_name);
        setValue("partner_code", data.data.partner_code);
        setValue("logo", data.data.logo);
        setValue("is_active_flag", data.data.is_active_flag);
      });
    }
  }, []);

  const onSubmit = (values) => {
    const body = {
      partner_name: values?.partner_name,
      partner_code: values?.partner_code,
      logo: values?.logo[0],
      is_active_flag: values?.is_active_flag,
    };

    const formData = new FormData();
    Object.keys(body).forEach((val) => {
      formData.append(val, body[val]);
    });

    if (openModal?.id) {
      Axios.put(`/partner/update/${openModal?.id}`, formData)
        .then((res) => {
          if (res.status === 201) {
            closeModal();
            Swal.fire({
              icon: res.status === 201 ? "success" : "error",
              title: res.status === 201 ? "Success" : "Error",
              text: res.data.message || "Berhasil mengubah partner",
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post("/partner/create", formData)
        .then((res) => {
          if (res.status === 201) {
            closeModal();
            Swal.fire({
              icon: res.status === 201 ? "success" : "error",
              title: res.status === 201 ? "Success" : "Error",
              text: res.data.message || "Berhasil menambah partner",
            }).then(() => getDataTable());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <ModalComponent open={Boolean(openModal)} onClose={closeModal}>
      <Grid>
        <Grid container className={classes.ModalWrapper}>
          <h3>{openModal?.id ? `Update` : `Create`} Partner</h3>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <hr className={classes.Divider} />
        <Grid className={classes.inputContainer}>
          <Grid className={classes.logoContainer} container>
            <Grid container alignItems="center">
              <img
                src={(() => {
                  const logo = watch("logo");
                  if (openModal?.id) {
                    if (typeof logo === "object" && logo?.length > 0) {
                      return URL.createObjectURL(watch("logo")[0]);
                    } else {
                      return `${URL_API}/public/${openModal.logo}`;
                    }
                  } else {
                    if (logo?.length > 0) {
                      return URL.createObjectURL(logo[0]);
                    } else {
                      return null;
                    }
                  }
                })()}
                alt=""
              />
              <Button
                variant="contained"
                component="label"
                className={classes.chooseBtn}
              >
                Choose a Logo
                <input
                  {...register("logo", {
                    required: {
                      message: "Image cannot be empty!",
                      value: !openModal?.id ? true : false,
                    },
                    ...(getValues()?.logo?.[0] instanceof File && {
                      validate: {
                        lessThan2MB: (files) =>
                          +files[0]?.size <= 2000000 || "Max upload file 2MB",
                      },
                    }),
                  })}
                  type="file"
                  accept="image/png"
                  alt=""
                  hidden
                />
              </Button>
            </Grid>
            {errors["logo"] && (
              <p className={classes.Errors}>
                {errors["logo"]?.message || "Image cannot be empty!"}
              </p>
            )}
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)} id="form-partner">
            <InputComponent
              register={{ ...register("partner_name", { required: true }) }}
              label="Name"
              id="name"
              error={errors}
              errorMessage="Name cannot be empty!"
            />
            <InputComponent
              register={{ ...register("partner_code", { required: true }) }}
              label="Code"
              id="code"
              error={errors}
              errorMessage="Code cannot be empty!"
            />
            {openModal.id && (
              <Grid className={classes.label}>
                <h4>Status</h4>
                <Stack
                  className="mt-16"
                  direction="column"
                  spacing={0.5}
                  width="100%"
                >
                  <Controller
                    name="is_active_flag"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <RadioInputRow
                        name="is_active_flag"
                        data={[
                          { value: true, label: "Active" },
                          { value: false, label: "Inactive" },
                        ]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
      <hr className={classes.Divider} />
      <Grid sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          type="submit"
          form="form-partner"
          className={classes.btnSubmit}
          fullWidth
        >
          {openModal?.id ? `Save Changes` : `Create`}
        </Button>
      </Grid>
    </ModalComponent>
  );
}

const useStyles = makeStyles({
  header: {
    marginBottom: "28px",
  },
  ModalWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px 10px 24px",

    "& h3": {
      margin: 0,
    },
  },
  Divider: {
    border: "1px solid #E4E7EB",
  },
  inputContainer: {
    padding: "24px",
    paddingTop: "4px",
  },
  Input: {
    border: "1px solid",
  },
  Errors: {
    margin: 0,
    color: "red",
    marginTop: "4px",
  },
  logoContainer: {
    "& h4": {
      margin: 0,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "6px",
    },
    "& img": {
      width: "64px",
      height: "64px",
      borderRadius: "50%",
      marginRight: "16px",
      backgroundColor: "#F5F5F5",
      objectFit: "contain",
      padding: "12px",
    },
  },
  chooseBtn: {
    backgroundColor: "white !important",
    textTransform: "none !important",
    fontWeight: 600 + "!important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
    height: "40px !important",
    color: "black !important",
  },
  label: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "6px",
  },
  btnSubmit: {
    backgroundColor: Colors.SECONDARY + "!important",
    "&:hover": {
      backgroundColor: Colors.SECONDARY_DARK + "!important",
    },
    textTransform: "none !important",
    fontWeight: 600 + "!important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
});
