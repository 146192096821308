import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, Chip, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  FilterUserInternalEksternal,
  TableComponent,
  UserModal,
} from "../../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../../config";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
};

export default function TableUserInternal() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0 ? dataParams : initialParams
  );
  const [selectedFilter, setSelectedFilter] = useState({
    status: "",
    roles: [],
  });

  const column = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <ActionButton
                onEdit={() => setOpen(params)}
                onDelete={() => handleDelete(params)}
              />
            )}
          </>
        );
      },
    },
    {
      name: "Name",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Role",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          params.user_role?.map((res) => res.name).join(", ")
        ),
    },
    {
      name: "Email",
      renderCell: (params) => (loading ? <Skeleton /> : params.email),
    },
    {
      name: "Status",
      renderCell: ({ is_active }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active ? "Active" : "Inactive"}
            color={is_active ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  const getDataTable = (filterStatus = "", filterRoles = "") => {
    setLoading(true);
    Axios.get(
      `/user${search || "?"}&active=${filterStatus}&roles=${filterRoles}`
    ).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  const handleDelete = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/user/delete/${val.id}`)
          .then((res) => {
            console.log(res);
            Swal.fire("Deleted!", res.data.message, "success", 3000).then(() =>
              getDataTable()
            );
          })
          .catch((err) => console.log(err));
      }
    });
  };

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  useEffect(() => {
    let status = selectedFilter?.status;
    let idRoles = selectedFilter?.roles?.map((el) => el.id).toString();
    // console.log('status', status)
    // console.log('idRoles', idRoles)
    getDataTable(status, idRoles);
  }, [selectedFilter]);

  return (
    <Grid>
      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={dataParams?.limit}
        totalData={meta?.total}
        page={meta?.page}
        onFilterTable
        multipleFilterComponent={
          <FilterUserInternalEksternal setSelectedFilter={setSelectedFilter} />
        }
        endButton={
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{
              backgroundColor: "#A0B842",
              "&:hover": {
                backgroundColor: "#83992A",
              },
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Create User
          </Button>
        }
      />
      {open && (
        <UserModal
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
        />
      )}
    </Grid>
  );
}
