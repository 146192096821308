import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HeightIcon from "@mui/icons-material/Height";
import NoiseControlOffIcon from "@mui/icons-material/NoiseControlOff";
import { Box, Chip, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ReactLoading from "react-loading";

export default function ListRecon({
  data,
  onRowsClick,
  key,
  Loading,
  accessFrom = "",
}) {
  const classes = useStyles(onRowsClick);

  return (
    <Grid
      key={key}
      className={classes.ListRoot}
      container
      sx={{ width: accessFrom !== "" ? "728px" : "100%" }}
    >
      <Grid
        className={classes.ListWrapper}
        container
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="#F5F6F7"
      >
        <h4>
          {data?.code ? data.code : "#ID-12734512643"}
          <NoiseControlOffIcon
            sx={{
              fontSize: "12px",
              marginX: "8px",
            }}
          />
          {data?.date ? data.date : "Feb 22 2022 at 11:23"}
        </h4>
        {accessFrom === "" && (
          <Chip
            icon={
              Loading ? (
                <NoiseControlOffIcon
                  sx={{
                    color: "#295ECC!important",
                  }}
                />
              ) : (
                <FiberManualRecordIcon
                  sx={{
                    height: "10px",
                    color: "#DE5242!important",
                  }}
                />
              )
            }
            label={Loading ? "Queue" : "Error, Mismatch"}
            size="small"
            sx={{
              color: !Loading ? "#A63124" : "#295ECC",
              border: `1px solid ${!Loading ? "#F1A69E" : "#93C9FA"}`,
              marginRight: "8px",
              backgroundColor: !Loading ? "#FCF3F2" : "#F0F8FE",
            }}
          />
        )}
      </Grid>
      <Grid
        sx={{
          border: "1px solid #E4E7EB",
          padding: "16px",
          position: "relative",
          backgroundColor: "white",
          "& img": {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
          ":hover": {
            backgroundColor: onRowsClick && "#D6F0FD",
          },
        }}
        container
        onClick={onRowsClick}
      >
        <Grid
          item
          sx={{
            color: "#687083",
          }}
        >
          {Loading ? (
            <ReactLoading
              type="bars"
              color="#A3A3A3"
              width={"30px"}
              height="50%"
            />
          ) : (
            <h5>Rp250.000</h5>
          )}
          <p>JD.ID / BTN</p>
        </Grid>
        <Box sx={{ display: "flex", alignItems: "center", marginX: "30px" }}>
          <HeightIcon
            sx={{
              rotate: "90deg",
            }}
          />
        </Box>
        <Grid
          item
          sx={{
            paddingRight: "40px",
            color: "#687083",
          }}
          alignItems="center"
        >
          {Loading ? (
            <ReactLoading
              type="bars"
              color="#A3A3A3"
              width={"30px"}
              height="50%"
            />
          ) : (
            <h5>Rp250.000</h5>
          )}
          <p>Mastercard</p>
        </Grid>
        <h4>=</h4>
        <Grid
          item
          sx={{
            paddingRight: "40px",
            paddingLeft: "4px",
            color: "#687083",
            borderRight: Loading && "1px solid #E4E7EB",
          }}
        >
          {Loading ? (
            <ReactLoading
              type="bars"
              color="#A3A3A3"
              width={"30px"}
              height="50%"
            />
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <h5>Rp250.000</h5>
              <CheckCircleIcon color="success" fontSize="small" />
            </div>
          )}

          <p>Difference</p>
        </Grid>
        <Grid
          item
          sx={{
            paddingRight: "40px",
            paddingLeft: "4px",
            color: "#687083",
            borderRight: Loading && "1px solid #E4E7EB",
            pl: "24px",
            borderLeft: "1px solid #E4E7EB",
          }}
        >
          {Loading ? (
            <ReactLoading
              type="bars"
              color="#A3A3A3"
              width={"30px"}
              height="50%"
            />
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <h5>3</h5>
              <CancelIcon color="error" fontSize="small" />
            </div>
          )}

          <p>Error</p>
        </Grid>
        {!Loading && accessFrom === "" && (
          <Box
            item
            style={{
              color: "#9AB825",
              fontWeight: "600",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: "30px",
              top: "30px",
              textDecoration: "none",
            }}
            onClick={onRowsClick}
          >
            See Detail
            <ArrowRightAltIcon />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((a, b) => ({
  ListRoot: {
    borderRadius: "6px",
    "& h4": {
      padding: "11px",
      margin: 0,
      color: "#687083",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      margin: 0,
      color: "black",
      display: "flex",
      marginBottom: "4px",
      marginTop: "5px",
      alignItems: "center",
      "& img": {
        padding: 0,
        marginLeft: "4px",
      },
    },
    "& p": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      padding: 0,
      margin: 0,
      marginTop: "5px",
    },
  },
  ListWrapper: {
    backgroundColor: "#F9FAFB",
    borderRadius: "6px 6px 0px 0px",
    border: "1px solid #E4E7EB",
    borderBottom: "none",
  },
}));
