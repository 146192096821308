import { Grid } from "@mui/material";
import React from "react";
import {
  ListRecon,
  TableComponent,
  TitleWithBackButton,
} from "../../components";

export default function ReconciliationError(params) {
  const column = [
    {
      name: "Date & Time",
      renderCell: (params) => params.date,
    },
    {
      name: "Message",
      renderCell: (params) => params.message,
    },
    {
      name: "Detail",
      renderCell: (params) => params.detail,
    },
  ];
  const rows = [
    {
      id: 1,
      date: "2022/03/07 14:07:05",
      message: "Remote recon file did not found",
      detail: "2022.02.08_recon-detail_mcsend_jdid_not-found-rmt.csv",
    },
    {
      id: 1,
      date: "2022/03/07 14:07:05",
      message: "Remote recon file did not found",
      detail: "2022.02.08_recon-detail_mcsend_jdid_not-found-rmt.csv",
    },
    {
      id: 1,
      date: "2022/03/07 14:07:05",
      message: "Remote recon file did not found",
      detail: "2022.02.08_recon-detail_mcsend_jdid_not-found-rmt.csv",
    },
  ];
  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Error / Mismatch: Transaction #ID-12734512643" />
      <Grid container md={8} mt={"26px"}>
        <ListRecon error={true} />
      </Grid>
      <Grid container mt="24px">
        <TableComponent
          rows={rows}
          column={column}
          onSearchTable
          onFilterTable
          onPageChange
          onRowsChange
        />
      </Grid>
    </Grid>
  );
}
