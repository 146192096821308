import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  AboutUsBanner,
  BPCLogo,
  BtnLogo,
  Colors,
  DividerImage,
  ISOLogo,
  JDIDLogo,
  MasterCardLogo,
} from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function AboutUsPage() {
  const classes = useStyles();
  const id = useLocation().hash;

  useEffect(() => {
    if (id) {
      const section = document.querySelector(id);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [id]);

  return (
    <section className={classes.root}>
      <ButtonAppBar />
      <section className={classes.banner}>
        <div className="blocker" />
        <img src={AboutUsBanner} alt="" />
        <img src={DividerImage} alt="" className="divider" />
        <div className="content">
          <h1>About Us</h1>
        </div>
      </section>
      <section className={classes.body}>
        <Container>
          <Grid ml={{ md: "140px" }} mr={{ md: "140px" }}>
            <Grid
              textAlign="center"
              pt={{ md: "128px", xs: "32px" }}
              mt="20px"
              pb={{ md: "128px", xs: "32px" }}
              sx={({ breakpoints }) => ({
                "& img": {
                  mt: {
                    md: "32px",
                    sx: "12px",
                  },
                  width: "100%",
                },
                "& .comma": {
                  [breakpoints.down("md")]: {
                    width: "20px",
                  },
                  width: "40px",
                },
              })}
            >
              <FormatQuoteRoundedIcon
                sx={{ fontSize: "80px", color: Colors.PRIMARY }}
              />
              <p>
                We are company that focus on the provision of technology and
                services for digital transformation in Financial Service
                Industry. We are working with global partners and local team to
                develop and provide solutions to promote change and innovation
                for businesses and people in Indonesia and the regions
                <br />
                <br />
                Our solution portfolio includes electronic payment solutions,
                digital banking, and fraud management solutions, both as
                on-premise and managed services, to help our FSI customer to
                expand their product & services, and improve their business
                operations to achieve effectiveness and efficiencies by
                leveraging the digital Technology
                <br />
                <br />
                Our intention is to help our client to expedite Digital
                Transformation initiative and becoming an efficient and more
                competitive business with less risk and hassle free​.
                <br />
                <br />
                In line with Finpoint's mission, we have certifications that are
                recognized globally, especially those related to information
                security, and transaction security. Until now we already have
                ISO 27001.
                <img src={ISOLogo} alt="" />
                <br />
                <br />
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.footer}>
        <Container>
          <Grid ml={{ md: "140px" }} mr={{ md: "140px" }}>
            <Grid
              textAlign="center"
              pt={{ md: "128px", xs: "32px" }}
              mt="20px"
              pb={{ md: "128px", xs: "32px" }}
            >
              <p>
                Our partner is BPC Banking Technology
                <br />
                <br />
                With more than 300 clients which include leading financial
                institutions in over 90+ countries, and several of the world’s
                largest banks. To provide long-term support and partnership for
                our customers, we have developed a global network of offices,
                all with local language support. We make sure it works. Whether
                it’s an ATM cash withdrawal in Madrid, using contactless to pay
                for fuel in Kiev, sending money across Africa, topping up your
                mobile in Panama City, buying online in Ho Chi Minh City or
                paying for your breakfast in Kabul, we make sure it works.
                <br />
                <br />
                BPC as the architect and developer of the market-leading
                SmartVista platform, which handles all aspects of ATM
                management, billing, mobile and contactless payments,
                settlement, point of sale, card issuing and acquiring,
                microfinance and electronic payment processing
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.partner} id="partner">
        <Container>
          <Grid ml={{ md: "140px" }} mr={{ md: "140px" }}>
            <Grid
              textAlign="center"
              pt={{ md: "128px", xs: "12px" }}
              mt={{ md: "20px" }}
              pb={{ md: "120px" }}
            >
              <h2>Our Trusted Partners</h2>
              <Grid
                display="flex"
                justifyContent="space-between"
                className="content"
                sx={{
                  "& img": {
                    height: "100px",
                    width: "180px",
                    borderRadius: "6px",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={MasterCardLogo} alt="" />
                <img src={BtnLogo} alt="" />
                <img src={JDIDLogo} alt="" />
                <img src={BPCLogo} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <img src={DividerImage} alt="" className="divider" />
      </section>
      <FooterLandingPage />
    </section>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: "75px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
    banner: {
      "& img": {
        width: "100%",
      },
      position: "relative",
      "& .divider": {
        position: "absolute",
        bottom: "0px",
        width: "100%",
        left: "0px",
      },
      "& .blocker": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        background: "linear-gradient(72.4deg, #289ECC 0.01%, #8EA33A 100%)",
        zIndex: -1,
      },
      "& .content": {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 2,
        top: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& h1": {
          [theme.breakpoints.down("md")]: {
            fontWeight: 300,
            fontSize: "16px",
            lineHeight: "18px",
          },
          fontWeight: 300,
          fontSize: "72px",
          lineHeight: "72px",
          color: "white",
        },
      },
    },
    body: {
      marginTop: "-20px",
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "12px",
          lineHeight: "16px",
        },
        fontWeight: 300,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    footer: {
      backgroundColor: "#F7F9FC",
      marginTop: "-20px",
      "& p": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "12px",
          lineHeight: "16px",
        },
        fontWeight: 300,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    partner: {
      backgroundColor: "#01AEF2",
      marginTop: "-20px",
      position: "relative",
      "& h2": {
        [theme.breakpoints.down("md")]: {
          fontWeight: 300,
          fontSize: "24px",
          lineHeight: "28px",
        },
        fontWeight: 300,
        fontSize: "40px",
        lineHeight: "48px",
        color: "white",
      },
      "& .divider": {
        width: "100%",
        marginBottom: "-5px",
      },
      "& .content": {
        [theme.breakpoints.down("md")]: {
          overflow: "auto",
          gap: "12px",
          paddingBottom: "24px",
          "& img": {
            width: "100px",
          },
        },
      },
    },
  };
});
