import { Grid } from "@mui/material";
import React from "react";
import CustomButton from "../Global/CustomButton";

export default function FilterWrapper({
  onReset,
  onApply,
  children,
  hiddenReset,
  sx,
}) {
  return (
    <Grid
      width="300px"
      borderRadius="15px"
      sx={{
        backgroundColor: "white",
        padding: "24px",
        "& h4": {
          margin: 0,
        },
        ...sx,
      }}
    >
      <h4>Filter</h4>
      <Grid
        width="100%"
        display="flex"
        flexDirection="column"
        gap="12px"
        mt="24px"
      >
        {children}
      </Grid>
      <Grid container mt="24px" justifyContent="flex-end" gap="16px">
        {!hiddenReset && (
          <CustomButton.Outline onClick={onReset}>Reset</CustomButton.Outline>
        )}
        <CustomButton onClick={onApply}>Apply</CustomButton>
      </Grid>
    </Grid>
  );
}
