import { Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  CustomButton,
  FilterDailyRecon,
  TableComponent,
  TitleWithBackButton,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  FilterObject,
  formatToThousands,
} from "../../../config";

export default function DailyReconciliation() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [params, setParams] = useState(
    Object?.keys(dataParams)?.length > 0
      ? dataParams
      : {
          search: "",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [rows, setRows] = useState([1, 2, 3, 4, 5]);

  const column = [
    {
      name: "Date",
      renderCell: (params) =>
        loading ? <Skeleton /> : dayjs(params.date).format("DD-MM-YYYY"),
    },
    {
      name: "Bank",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.bank} <br /> <p>{params.bank_code}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Merchant",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.merchant} <br /> <p>{params.merchant_code}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Product",
      renderCell: (params) => (
        <Grid
          sx={{
            "& p": {
              margin: 0,
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16.8px",
            },
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {params.product} <br /> <p>{params.merchant_code}</p>
            </>
          )}
        </Grid>
      ),
    },
    {
      name: "Transaction Code",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.transaction_code,
    },
    {
      name: "Trans. Count",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.transction_count,
    },
    {
      name: "Mismatch Trans. Count",
      renderCell: (params) =>
        loading ? <Skeleton /> : params.mismatch_transction_count,
    },
  ];

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/daily-recon" + search).then((res) => {
      // console.log(res.data);
      setMeta(res.data.meta);
      setRows(res.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  return (
    <Grid
      sx={(theme) => {
        return { ...theme.customContainer };
      }}
    >
      <TitleWithBackButton title="Daily Reconciliation" hiddenBackButton />
      <Grid mt="24px">
        <TableComponent
          // onFilterTable
          rows={rows}
          column={column}
          onSearchTable={(val) => setParams({ ...params, ...val })}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          endButton={
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterDailyRecon
                onApply={(value) => {
                  setParams({
                    ...params,
                    ...FilterObject(value),
                  });
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({
                    search: "",
                    offset: 0,
                    limit: DEFAULT_PAGINATION_LIMIT,
                    page: 1,
                  });
                  setOpenFilter(false);
                }}
                filters={{
                  product: params.product,
                  merchant: params.merchant,
                  bank: params.bank,
                  date: params.date,
                }}
              />
            </CustomButton.Filter>
          }
          limit={dataParams?.limit}
          totalData={meta?.total}
          page={meta?.page}
          collapse={(data) => {
            return (
              <Grid
                sx={{
                  pt: "24px",
                  pb: "24px",
                  color: "#092540",
                  "& h3, p": {
                    margin: 0,
                  },
                  "& h3": {
                    width: "140px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginRight: "16px",
                  },
                  "& p": {
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingRight: "60px",
                  },
                  "& .mismatch": {
                    width: "210px",
                  },
                }}
              >
                <Grid container md={9}>
                  <Grid sx={{ borderRight: "1px solid #E4E7EB" }} item>
                    <Grid display="flex" alignItems="center">
                      <h3>Total Amount</h3>
                      <p>{`Rp. ${formatToThousands(data.total_amount)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center" mt="8px" mb="8px">
                      <h3>Fee</h3>
                      <p>{`Rp. ${formatToThousands(data.fee)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center">
                      <h3>Settlement Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.settlement_amount
                      )}`}</p>
                    </Grid>
                  </Grid>
                  <Grid sx={{ ml: "24px" }} item>
                    <Grid display="flex" alignItems="center">
                      <h3 className="mismatch">Mismatch Total Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.mismatch_total_amount
                      )}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center" mt="8px" mb="8px">
                      <h3 className="mismatch">Mismatch Fee</h3>
                      <p>{`Rp. ${formatToThousands(data.mismatch_fee)}`}</p>
                    </Grid>
                    <Grid display="flex" alignItems="center">
                      <h3 className="mismatch">Mismatch Settlement Amount</h3>
                      <p>{`Rp. ${formatToThousands(
                        data.mismatch_settlement_amount
                      )}`}</p>
                    </Grid>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
