import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ListMenuSidebar } from "../../assets/icons/CustomIcon";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";
import { ActionButton, CustomInput, MenuModal, TableComponent } from "../index";

export default function TableMenu({}) {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [dataEdit, setDataEdit] = useState();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0
      ? dataParams
      : {
          search: "",
          sort: "-id",
          offset: 0,
          limit: DEFAULT_PAGINATION_LIMIT,
          page: 1,
        }
  );

  const column = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <Fragment>
            {loading ? (
              <Skeleton type="text" />
            ) : (
              <ActionButton
                onEdit={() => {
                  setOpen(true);
                  setDataEdit(params);
                }}
                onDelete={() => handleDeleteMenu(params)}
              />
            )}
          </Fragment>
        );
      },
    },
    {
      name: "Title",
      renderCell: (params) =>
        loading ? (
          <Skeleton type="text" />
        ) : (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                color: "#9AA2B1",
                marginRight: "10px",
              },
            }}
          >
            {" "}
            {params?.icon_id !== 0 &&
              ListMenuSidebar.filter(
                (val) => val.id === `${params?.icon_id}`
              ).pop()?.icon}{" "}
            {params.title}
          </Grid>
        ),
    },
    {
      name: "Level",
      renderCell: ({ parent_id }) =>
        loading ? (
          <Skeleton type="text" />
        ) : (
          <Chip
            label={parent_id ? "Child" : "Parent"}
            color={parent_id ? "success" : "warning"}
            variant="outlined"
            size="small"
          />
        ),
    },
    {
      name: "Description",
      renderCell: (params) =>
        loading ? (
          <Skeleton type="text" />
        ) : (
          <Box
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            <Typography>{params.description}</Typography>
          </Box>
        ),
    },
    {
      name: "URL",
      renderCell: (params) => (loading ? <Skeleton type="text" /> : params.url),
    },
  ];

  useEffect(() => {
    if (search) {
      getMenu();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const getMenu = () => {
    setLoading(true);
    Axios.get("/menu" + search)
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setMeta(res.data.meta);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const handleDeleteMenu = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/menu/delete/${val.id}`).then((res) => {
          console.log(res);
          if (res.status === 200) {
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success delete menu`,
              icon: "success",
            });
            getMenu();
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={dataParams?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="12px">
            <CustomInput.Select
              onChange={({ target }) =>
                setParams({ ...params, level: target.value })
              }
              sx={{ width: "200px" }}
              placeholder="level"
            >
              <MenuItem value="p">Parent</MenuItem>
              <MenuItem value="c">Child</MenuItem>
            </CustomInput.Select>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                "&:hover": {
                  backgroundColor: "#83992A",
                },
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                minWidth: "120px",
              }}
            >
              Create Menu
            </Button>
          </Grid>
        }
      />
      {open && (
        <MenuModal
          rows={rows}
          openModal={true}
          closeModal={() => {
            setOpen(false);
            setDataEdit();
          }}
          getMenu={getMenu}
          dataEdit={dataEdit}
        />
      )}
    </Fragment>
  );
}
