import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BarChart,
  BasicCard,
  CustomButton,
  DataHeading,
  FilterDashboardTransaction,
  InputComponent,
  LineChart,
} from "../../components";
import { Axios, currencyFormat, FilterObject, getUser } from "../../config";

let initialBody = {
  dateRecon: new Date(),
  dateTransaction: new Date(),
};

let initialRecon = {
  failed_reconciliation: 0,
  ongoing_reconciliation: 0,
};

export default function DashboardPA() {
  let navigate = useNavigate();
  const classes = useStyles();
  const [body, setBody] = useState(initialBody);
  const [Loading, setLoading] = useState(true);
  const [Partners, setPartners] = useState([]);
  const [Recon, setRecon] = useState(initialRecon);
  const [transaction, setTransaction] = useState({});

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };
  const optionTypeDisplayTransactionData = [
    { key: "total_success", text: "Success Transaction" },
    { key: "total_failed", text: "Failed Transaction" },
    { key: "total_transaction", text: "Total Transaction" },
  ];
  const [openFilterTransaction, setOpenFilterTransaction] = useState(false);
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));

  const [params, setParams] = useState(
    Object?.keys(dataParams)?.length > 0
      ? dataParams
      : {
          product: "",
          merchant: "",
          type: "total_transaction",
          date: new Date(),
        }
  );
  const [displayTransactionKey, setDisplayTransactionKey] = useState(
    params.type
  );

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    getDataChart();
    getDataChartTransaction();
    getDataPartner();
    dayjs(new Date()).format("MM-YYYY");
  }, [body, params]);

  useEffect(() => {
    setDisplayTransactionKey(params.type);
  }, [params.type]);

  const getDataChart = async () => {
    setLoading(true);
    let rebody = dayjs(body.dateRecon).format("MM-YYYY");
    await Axios.get("/dashboard?" + "date=" + rebody)
      .then((res) => {
        if (res.data) {
          setRecon(res.data.data);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const getDataChartTransaction = async () => {
    setLoading(true);
    let rebody = dayjs(params.date).format("MM-YYYY");
    await Axios.get("/dashboard/transaction/summary", {
      params: {
        date: rebody,
        product_id: params.product,
        partner: params.merchant,
      },
    })
      .then((res) => {
        if (res.data) {
          let dataTransaction = res.data.data;
          // add color chart
          if (dataTransaction.data) {
            dataTransaction.data = dataTransaction.data?.map((el) => {
              const getColor = getRandomColor();
              return {
                ...el,
                color: getColor,
              };
            });
          }
          setTransaction(dataTransaction);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const getDataPartner = async () => {
    setLoading(true);
    let rebody = dayjs(body.date).format("MM-YYYY");
    await Axios.get("/dashboard/monitoring?" + "date=" + rebody)
      .then((res) => {
        console.log("data partner", res.data.data);
        if (res.data) {
          // setRecon(res.data.data);

          setPartners(res.data.data || []);
          // let dataTrx = res.data.data.transaction;
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={(theme) => {
        if (!theme.customContainer || theme.customContainer === "{}") {
          return {
            mt: 5,
            mb: 4,
            pl: 3,
            pr: 3,
          };
        }
        return { ...theme.customContainer };
      }}
    >
      <Box className={classes.header}>
        <DataHeading title={`Welcome ${getUser().name}!`} />
        <InputComponent
          value={dayjs(body.dateRecon).format("MMMM YYYY")}
          type="date"
          id="dateRecon"
          name="date"
          control={control}
          error={errors}
          formatDate={"MMMM YYYY"}
          viewFormat={["year", "month"]}
          customIcon={KeyboardArrowDownIcon}
          showButton
          autocomplete={false}
          sx={{ width: "100%" }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </Box>

      <Paper className={classes.chartWrapper}>
        {Loading ? (
          <Grid className={classes.loader}>
            <CircularProgress />
          </Grid>
        ) : (
          <BarChart
            dataTrx={Recon?.transaction}
            date={dayjs(body.dateRecon).format("MMMM YYYY")}
            containerLegendId={"legend-container-recon"}
          />
        )}
      </Paper>

      <Box className={classes.itemsContainer}>
        <BasicCard
          title="Ongoing Reconciliation"
          value={Recon.ongoing_reconciliation}
        />
        <BasicCard
          title="Failed Reconciliation"
          value={Recon.failed_reconciliation}
        />
      </Box>

      <Box className={classes.transactionFilter}>
        <CustomButton.Filter
          open={openFilterTransaction}
          onClick={() => setOpenFilterTransaction(!openFilterTransaction)}
        >
          <FilterDashboardTransaction
            onApply={(value) => {
              setParams({
                ...params,
                ...FilterObject(value),
              });
              setOpenFilterTransaction(false);
            }}
            onReset={() => {
              setParams({
                product: "",
                merchant: "",
                type: "total_transaction",
                date: new Date(),
              });
              setOpenFilterTransaction(false);
            }}
            filters={{
              product: params.product,
              merchant: params.merchant,
              type: params.type,
              date: params.date,
            }}
          />
        </CustomButton.Filter>
      </Box>

      <Paper className={classes.chartWrapper}>
        {Loading ? (
          <Grid className={classes.loader}>
            <CircularProgress />
          </Grid>
        ) : (
          <LineChart
            dataTrx={
              {
                labels: transaction.dates || [],
                datasets:
                  transaction?.data?.map((el) => {
                    return {
                      label: el.product_desc,
                      data: el?.chart?.map(
                        (data) => data[displayTransactionKey]
                      ),
                      backgroundColor: el.color,
                      borderColor: el.color,
                      typeData: optionTypeDisplayTransactionData.find(
                        (data) => data.key === displayTransactionKey
                      ).text,
                    };
                  }) || [],
              } || []
            }
            containerLegendId={"legend-container-transaction"}
            date={dayjs(body?.dateTransaction).format("MMMM YYYY")}
          />
        )}
      </Paper>

      <Box className={classes.itemsContainer}>
        <BasicCard
          title="Ongoing Transaction"
          value={transaction.grand_total_approved_transaction}
        />
        <BasicCard
          title="Failed Transaction"
          value={transaction.grand_total_failed_transaction}
        />
        <BasicCard
          title="Total Transaction"
          value={transaction.grand_total_transaction}
        />
      </Box>

      <Grid className={classes.cardWrapper}>
        <Grid className="content" container justifyContent="space-between">
          <h2>Partner Monitoring</h2>
        </Grid>
        <div className={classes.Divider} />
        <Box className="content" container alignItems="center" display="flex">
          <Grid container spacing="20px">
            {Loading ? (
              <Grid className={classes.loader}>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {Partners ? (
                  Partners.length === 0 ? (
                    <Grid className={classes.loader}>No data</Grid>
                  ) : (
                    <>
                      {Partners.map((partner, i) => (
                        <Grid
                          xs={12}
                          container
                          sx={{
                            paddingTop: "20px",
                            paddingBottom:
                              i === Partners.length - 1 ? "0" : "20px",
                            borderBottom:
                              i === Partners.length - 1
                                ? "none"
                                : "1px solid #E4E7EB",
                          }}
                        >
                          <Grid
                            item
                            xs={2}
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/dashboard/detail", {
                                state: {
                                  data: partner,
                                },
                              })
                            }
                          >
                            <img
                              src={`${process.env.REACT_APP_URL_API}/public/${partner.merchant.logo}`}
                              alt="partner"
                              width="100%"
                              height="100%"
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Box className={classes.transactionCountContainer}>
                              <Box className={classes.transactionContent}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Transaction Count
                                </Typography>
                                <Box sx={{ my: "4px", marginRight: "7px" }}>
                                  <Typography
                                    className={classes.transactionTitle}
                                  >
                                    {partner.transction_count}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Total Amount
                                </Typography>
                                <Typography>
                                  {currencyFormat(
                                    partner.mismatch_total_amount
                                  )}
                                </Typography>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Fee
                                </Typography>
                                <Typography>
                                  {currencyFormat(partner.fee)}
                                </Typography>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Settlement Amount
                                </Typography>
                                <Typography>
                                  {currencyFormat(partner.settlement_amount)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {/* failed */}
                          <Grid item xs={5}>
                            <Box sx={{ paddingLeft: "20px" }}>
                              <Box className={classes.failedContainer}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Mismatch Transaction Count
                                </Typography>
                                <Box sx={{ my: "4px", marginRight: "7px" }}>
                                  <Typography className={classes.failedTitle}>
                                    {partner.mismatch_transction_count}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Mismatch Total Amount
                                </Typography>
                                <Typography>
                                  {currencyFormat(
                                    partner.mismatch_total_amount
                                  )}
                                </Typography>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Missmatch Fee
                                </Typography>
                                <Typography>
                                  {currencyFormat(partner.mismatch_fee)}
                                </Typography>
                              </Box>
                              <Box className={classes.transactionItems}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Missmatch Settlement Amount
                                </Typography>
                                <Typography>
                                  {currencyFormat(
                                    partner.mismatch_total_amount
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chartWrapper: {
    mt: "24px",
    height: "auto",
    padding: "24px",
  },
  loader: {
    width: "100%",
    textAlign: "center",
    padding: "150px",
  },
  itemsContainer: { marginTop: "24px", display: "flex", gap: "24px" },
  transactionFilter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "10px",
    gap: "20px",
  },
  cardWrapper: {
    border: "1px solid #E4E7EB",
    borderRadius: "12px",
    backgroundColor: "white",
    marginBottom: "16px",
    marginTop: "24px",
    filter:
      "drop-shadow(0px 0.5px 2px rgba(65, 78, 98, 0.12)) drop-shadow(0px 0px 1px rgba(65, 78, 98, 0.05))",
    "& .content": {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "24px",
      paddingRight: "24px",
      "& h2": {
        margin: 0,
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "30px",
      },
      "& p": {
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& img": {
        marginLeft: "8px",
      },
      "& .ButtonNormal": {
        color: "black",
        borderColor: "#D1D5DC",
        fontWeight: 550,
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& .active": {
        backgroundColor: "#A0B842",
        color: "white",
      },
    },
  },
  transactionCountContainer: {
    borderRight: "1px solid #E4E7EB",
    paddingRight: "20px",
  },
  transactionContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EFFCF4",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "16px",
    borderRadius: "6px",
    marginBottom: "5px",
  },
  transactionTitle: {
    px: "10px",
    backgroundColor: "#54B371",
    color: "white",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  failedTitle: {
    px: "10px",
    backgroundColor: "#DE5242",
    color: "white",
    fontWeight: "bold",
    borderRadius: "4px",
  },
  transactionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "16px",
  },
  failedContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FCF3F2",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "16px",
    borderRadius: "6px",
    marginBottom: "5px",
  },
  Button: {
    border: "1px solid red",
    color: "red",
  },
  Divider: {
    borderBottom: "1px solid #E4E7EB",
  },
});
