import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

export default function BasicCard({ value, title, icon, onClick }) {
  const classes = useStyles();
  return (
    <Card className={classes.container} onClick={onClick}>
      <CardContent>
        <Box className={classes.icon}>{icon}</Box>
        <Typography variant="h3" component="div" className={classes.value}>
          {value}
        </Typography>
        <Typography color="text.secondary">{title}</Typography>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles({
  container: { minWidth: 250, position: "relative", cursor: "pointer" },
  icon: {
    position: "absolute",
    top: -5,
    right: -5,
  },
  value: {
    // color: Colors.secondary,
    fontWeight: 500,
    marginY: 0.5,
  },
});
