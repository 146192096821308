import { Grid } from "@mui/material";
import React from "react";

import { MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "../Global/CustomButton";
const useStyles = makeStyles({
  hiddenActions: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
  selectOption: {
    "& .MuiSelect-select": {
      color: "gray",
    },
  },
});

export default function FilterContract({
  onApply,
  onReset,
  productList,
  onChangeProduct,
  contractList,
  onChangeContract,
  product,
  contract,
}) {
  const classes = useStyles();

  return (
    <Grid
      width="300px"
      borderRadius="15px"
      sx={{
        backgroundColor: "white",
        padding: "24px",
        "& h4": {
          margin: 0,
        },
        position: "absolute",
        top: "20px",
        right: "24px",
        zIndex: 1,
      }}
    >
      <h4>Filter</h4>
      <Grid
        width="100%"
        display="flex"
        flexDirection="column"
        gap="12px"
        mt="24px"
      >
        <Grid width="100%" display="flex" flexDirection="column" gap="12px">
          <TextField
            id="outlined-select-currency"
            select
            className={classes.selectOption}
            value={product}
            onChange={onChangeProduct}
          >
            <MenuItem value={0}>Partner</MenuItem>
            {productList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.partner_name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            id="outlined-select-currency"
            select
            className={classes.selectOption}
            value={contract}
            onChange={onChangeContract}
          >
            <MenuItem value={0}>Contract</MenuItem>
            {contractList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.contract_no}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
      <Grid container mt="24px" justifyContent="flex-end" gap="16px">
        <CustomButton.Outline onClick={onReset}>Reset</CustomButton.Outline>

        <CustomButton onClick={onApply}>Apply</CustomButton>
      </Grid>
    </Grid>
  );
}
