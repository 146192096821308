import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, Chip, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ActionButton, TableComponent, UserModal } from "../../components";
import { Axios, DEFAULT_PAGINATION_LIMIT } from "../../config";

const initialParams = {
  search: "",
  offset: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
};

export default function UserPO() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const classes = useStyles();
  const navigate = useNavigate();
  const [internal, setInternal] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(
    Object.keys(dataParams).length > 0 ? dataParams : initialParams
  );

  const columnEksternal = [
    {
      name: "Partner Name",
      renderCell: (params) => params.name,
    },
    {
      name: "Partner Type",
      renderCell: (params) => params.type,
    },
    {
      name: "Partner User",
      renderCell: (params) => params.user,
    },
  ];

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/user" + search).then((res) => {
      setMeta(res.data.meta);
      setTimeout(() => {
        setRows(res.data.data || []);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (search) {
      getDataTable();
    }
  }, [search]);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const columnInternal = [
    {
      name: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <ActionButton
                onEdit={() => setOpen(params)}
                onDelete={() => console.log("delete not implemented")}
              />
            )}
          </>
        );
      },
    },
    {
      name: "Name",
      renderCell: (params) => (loading ? <Skeleton /> : params.name),
    },
    {
      name: "Role",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          params.user_role?.map((res) => res.name).join(", ")
        ),
    },
    {
      name: "Email",
      renderCell: (params) => (loading ? <Skeleton /> : params.email),
    },
    {
      name: "Status",
      renderCell: ({ is_active }) =>
        loading ? (
          <Skeleton />
        ) : (
          <Chip
            label={is_active ? "Active" : "Inactive"}
            color={is_active ? "success" : "error"}
            variant="outlined"
            size="small"
            icon={<FiberManualRecordIcon sx={{ height: "10px" }} />}
          />
        ),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid className={classes.wrapper}>
        <Grid
          className="header"
          sx={{
            mt: 2,
            pl: 3,
            pt: "28px",
            pb: "28px",
          }}
        >
          <h1>User</h1>
          <Grid>
            <Button
              variant="text"
              onClick={() => setInternal(true)}
              sx={{
                color: internal ? "#9AB825" : "#687083",
                backgroundColor: internal && "rgba(165, 189, 68, 0.1)",
              }}
            >
              User
            </Button>
          </Grid>
        </Grid>
        <Grid
          className={classes.wrapper}
          sx={(theme) => {
            return { ...theme.customContainer };
          }}
        >
          <Grid sx={{ mt: "24px" }}>
            <TableComponent
              rows={rows}
              column={internal ? columnInternal : columnEksternal}
              onRowsClick={
                internal
                  ? false
                  : (val) => navigate(`/user-eksternal/${val.id}`)
              }
              onSearchTable={(val) => setParams({ ...params, ...val })}
              onPageChange={(val) => setParams({ ...params, page: val })}
              onRowsChange={({ target }) =>
                setParams({ ...params, limit: target.value, page: 1 })
              }
              limit={dataParams?.limit}
              totalData={meta?.total}
              page={meta?.page}
              endButton={
                internal && (
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    sx={{
                      backgroundColor: "#A0B842",
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Create User
                  </Button>
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <UserModal
          openModal={open}
          closeModal={() => setOpen(false)}
          getDataTable={getDataTable}
        />
      )}
    </Fragment>
  );
}

const useStyles = makeStyles({
  wrapper: {
    "& .header": {
      backgroundColor: "white",
      boxShadow:
        "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
      "& h1": {
        margin: 0,
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    "& buton": {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
