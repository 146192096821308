import { Button, Fade, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  TablePricing,
  TablePricingPartner,
  TablePricingProduct,
  TitleWithBackButton,
} from "../../components";

export default function Pricing() {
  const classes = useStyles();
  let { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const [detailProduct, setDetailProduct] = useState({});
  const [buttonActive, setButtonActive] = useState("Pricing");

  return (
    <Grid className={classes.wrapper}>
      <Grid className={classes.header}>
        <TitleWithBackButton title={"Pricing"} />
        <Grid className={classes.buttonContainer}>
          {["Pricing", "Assign Pricing Partner", "Assign Pricing Product"].map(
            (res, i) => (
              <Button
                variant="text"
                onClick={() => setButtonActive(res)}
                sx={{
                  color: buttonActive === res ? "#9AB825" : "#687083",
                  backgroundColor:
                    buttonActive === res && "rgba(165, 189, 68, 0.1)",
                }}
                key={i}
              >
                {res}
              </Button>
            )
          )}
        </Grid>
      </Grid>
      <Grid
        sx={(theme) => {
          return { ...theme.customContainer };
        }}
      >
        {buttonActive === "Pricing" && (
          <Fade in={buttonActive === "Pricing"} timeout={600}>
            <Grid container>
              <TablePricing />
            </Grid>
          </Fade>
        )}
        {buttonActive === "Assign Pricing Partner" && (
          <Fade in={true} timeout={600}>
            <Grid container>
              <TablePricingPartner />
            </Grid>
          </Fade>
        )}
        {buttonActive === "Assign Pricing Product" && (
          <Fade in={true} timeout={600}>
            <Grid container>
              <TablePricingProduct />
            </Grid>
          </Fade>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  header: {
    backgroundColor: "white",
    boxShadow:
      "0px 0.5px 2px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
    padding: "40px 24px 24px 24px",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "18px",
    gap: "10px",
  },
  ModalWrapper: {
    "& h3": {
      margin: 0,
    },
  },
});
