import { Radio, RadioGroup, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: `#A0B842 !important`,
    },
  },
  checked: {},
});

// eslint-disable-next-line import/prefer-default-export
const RadioInputRow = (props) => {
  const classes = useStyles();
  const {
    data,
    name,
    onChange,
    value,
    row,
    field,
    defaultValue,
    error,
    helperText,
    disabled,
  } = props;
  return (
    <>
      <RadioGroup
        {...field}
        row={row}
        name={name}
        defaultValue={defaultValue}
        className="w-full px-10 -ml-10"
        value={value}
        onChange={onChange}
        sx={{
          backgroundColor: error ? green[50] : "transparent",
          borderRadius: 2,
          flexDirection: "row",
        }}
      >
        {data.map((v, i) => (
          <FormControlLabel
            key={i}
            disabled={disabled}
            value={v?.value}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={<Typography style={{ fontSize: 12 }}>{v.label}</Typography>}
            // onChange={onChange}
          />
        ))}
      </RadioGroup>
      {error && (
        <Typography style={{ color: green[500], fontSize: 12, marginLeft: 5 }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default RadioInputRow;
