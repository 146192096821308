import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import fileDownload from "js-file-download";
import React from "react";
import { Colors } from "../../assets";
import { getToken } from "../../config";
import { AxiosFinpoint, URL_API_FINPOINT } from "../../config/helperFinpoint";

const ComponentDownloadReport = ({ url, fileName }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DescriptionIcon
        color="disabled"
        fontSize="small"
        className={classes.descIcon}
      />
      <Stack direction="column" flexGrow={1}>
        <Typography className={classes.title}>{fileName}</Typography>
      </Stack>
      <Tooltip title="Download Document">
        <IconButton
          onClick={() => {
            AxiosFinpoint(`${URL_API_FINPOINT}/report/static/${url}`, {
              responseType: "blob",
              method: "get",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
              },
            }).then((response) => {
              fileDownload(
                response.data,
                url.split("/")[url.split("/").length - 1]
              );
            });
          }}
          aria-label="delete"
        >
          <DownloadIcon sx={{ color: Colors.SECONDARY }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "596px",
    marginTop: "15px",
    marginBottom: "5px",
    borderRadius: "4px",
    padding: "2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  descIcon: { marginRight: "15px" },
  button: {
    backgroundColor: "#A0B842",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  title: { fontSize: "14px", fontWeight: "400" },
}));

export default ComponentDownloadReport;
