import { Autocomplete, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export default function CustomAutocomplete({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption,
  marginTop,
  width,
  label,
  loading = true,
  required,
  value,
  error = false,
  id,
  errorMessage,
  disabled = false,
  readOnly = false,
  disableClearable = false,
}) {
  const isError = Boolean(error[id]);

  return (
    <Grid
      sx={{
        "& h4": {
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          marginBottom: "6px",
          marginTop: marginTop || "20px",
        },
        "& .msgError": {
          margin: 0,
          marginTop: "4px",
          color: "red",
        },
        "& .MuiFormControl-root": {
          margin: 0,
        },
        width: width,
      }}
    >
      <h4>{label}</h4>
      <Controller
        rules={{ required: required }}
        render={(props) => {
          return (
            <Autocomplete
              loading={loading}
              size="small"
              disabled={disabled}
              readOnly={readOnly}
              options={options}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={renderInput}
              onChange={(e, data) => props.field.onChange(data)}
              value={value || ""}
              defaultValue={defaultValue}
              disableClearable={disableClearable}
              {...props}
            />
          );
        }}
        onChange={(data) => console.log(data)}
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
      {isError && (
        <p className="msgError">
          {errorMessage ? errorMessage : `${id} cannot be empty!`}{" "}
        </p>
      )}
    </Grid>
  );
}
