import React from "react";
import Forbidden from "../../components/Global/Forbidden";
import { getUserRole } from "../../config/cookies";
import DashboardDetailPO from "./DashboardDetailPO";

function DashboarHandler() {
  const role = getUserRole();

  if (role === "PO") {
    return <DashboardDetailPO />;
  } else {
    return <Forbidden />;
  }
}

export default DashboarHandler;
