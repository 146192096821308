import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Button, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import {
  AboutUsBanner,
  AccelerateImage,
  BPCLogo,
  BtnLogo,
  DigitalBanking,
  DividerImage,
  EffectiveImage,
  ElectronicPayment,
  FraudManagement,
  IncreaseImage,
  JDIDLogo,
  LandingPageBanner,
  MasterCardLogo,
} from "../../assets";
import { ButtonAppBar, FooterLandingPage } from "../../components";

export default function Home() {
  const classes = useStyles();

  const goToViolation = (val) => {
    const value =
      val === "Home"
        ? "home"
        : val === "Partners"
        ? "our-trusted-partners"
        : val === "About"
        ? "about"
        : val === "Objectives"
        ? "objectives"
        : val === "Solution"
        ? "our-solution"
        : "our-experience";
    const section = document.querySelector(`#${value}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Fragment>
      <ButtonAppBar />
      <section className={classes.root}>
        <section className={classes.banner}>
          <Container>
            <h1>
              Your Trusted Digital Transformation Partner in Financial Service
              Business
            </h1>
            <Grid container className="button-wrapper">
              <Button
                href="/sign-up"
                variant="contained"
                className={classes.getStartedBtn}
              >
                Getting Started
              </Button>
              <Button
                href="/contact-us"
                variant="outlined"
                className={classes.getInTouchBtn}
              >
                Get In Touch
              </Button>
            </Grid>
          </Container>
          <img src={LandingPageBanner} alt="" />
        </section>
        <section className={classes.ourPartners}>
          <Container>
            <Grid container alignItems="center">
              <Grid item md={4} sx={12}>
                <h2>Our Partners</h2>
              </Grid>
              <Grid md={8} xs={12} className={classes.content}>
                <img src={MasterCardLogo} alt="" />
                <img src={BtnLogo} alt="" />
                <img src={JDIDLogo} alt="" />
                <img src={BPCLogo} alt="" />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.aboutUs}>
          <div className="layer">
            <Container sx={{ display: "block" }}>
              <h3>
                <FormatQuoteIcon
                  sx={{
                    fontSize: "150px",
                    position: "absolute",
                    left: "-50px",
                    top: "-50px",
                    color: "#cbd5e170",
                  }}
                />
                We are providing secured omnichannel payment and financial
                business services to help our customer to be succeeded in the
                digital transformation initiative​
              </h3>
            </Container>
          </div>
        </section>
        <section className={classes.whyUs}>
          <Container>
            <h2>Why Us?</h2>
            <Grid
              container
              justifyContent="space-between"
              spacing={{ xs: "24px", md: "72px" }}
            >
              <Grid item md={4} className="item">
                <img src={IncreaseImage} alt="" />
                <h3>Innovative</h3>
                <p>
                  Driving payments innovation based on digitalization,
                  artificial intelligence and the creation of ecosystems
                  delivered through the Our platform
                </p>
              </Grid>
              <Grid item md={4} className="item">
                <img src={AccelerateImage} alt="" />
                <h3>Global Expertise, Local Knowledge</h3>
                <p>
                  Partnership with global partner with their global network of
                  offices and support 290 clients in more than 95 countries
                </p>
              </Grid>
              <Grid item md={4} className="item">
                <img src={EffectiveImage} alt="" />
                <h3>Smart does IT</h3>
                <p>
                  Over 950 payments experts delivering complex projects in all
                  corners of the world
                </p>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.experience}>
          <Container>
            <Grid className="header">
              <h2>Experience Our Solution</h2>
              <p>
                Our solution portofolio includes electronic payment solutions,
                digital banking and fraud management solutions. available as
                on-premise and managed services
              </p>
            </Grid>
            <Grid container className="card-wraper" spacing="38px">
              <Grid item md={4} className="card">
                <Grid className="body-card">
                  <img src={DigitalBanking} alt="" />
                  <Grid className="body">
                    <h3>Digital Banking Platform</h3>
                    <p>
                      Enabling and accelerating digital-banking initiative for
                      small and medium sized banks​
                    </p>
                    <a href="/electronic-payment-solution">Learn more</a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className="card">
                <Grid className="body-card">
                  <img src={FraudManagement} alt="" />
                  <Grid className="body">
                    <h3>Fraud Management Solutions​​</h3>
                    <p>
                      Asses, detect, prevent , and control frauds in the
                      business operations in an affordable and convenient​ ways
                    </p>
                    <a href="/digital-banking-platform">Learn more</a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className="card">
                <Grid className="body-card">
                  <img src={ElectronicPayment} alt="" />
                  <Grid className="body">
                    <h3>Electronic Payment Solutions</h3>
                    <p>
                      Low-cost, real-time, and online payment any-time anywhere​
                    </p>
                    <a href="/fraud-management-solution">Learn more</a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <img src={DividerImage} alt="" className="divider" />
        </section>
        <FooterLandingPage />
      </section>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    fontFamily: ["Raleway", "sans-serif"],
    paddingTop: "75px",
    "& button": {
      textTransform: "none",
    },
    "& h1, h2, h3, h4, p": {
      margin: 0,
    },
    "& h2": {
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "48px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "80px",
    },
  },
  banner: {
    display: "flex",
    background: "linear-gradient(90deg, #30BBF2 0%, #B2CC49 100%)",
    position: "relative",
    color: "white",
    "& h1": {
      marginTop: "168px",
      fontWeight: 300,
      fontSize: "70px",
      lineHeight: "70px",
      width: "633px",
      [theme.breakpoints.down("md")]: {
        marginTop: "24px",
        fontSize: "16px",
        lineHeight: "18px",
        width: "160px",
      },
    },
    "& img": {
      position: "absolute",
      height: "100%",
      right: "0px",
    },
    "& .MuiContainer-maxWidthLg .button-wrapper": {
      marginTop: "56px",
      gap: "24px",
      marginBottom: "168px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
        marginTop: "12px",
        gap: "8px",
      },
      "& button": {
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "30px",
        padding: "13px 43px",
        [theme.breakpoints.down("md")]: {
          fontWeight: 160,
          fontSize: "60px",
          lineHeight: "12px",
          padding: "4px 8px",
        },
      },
    },
  },
  getStartedBtn: {
    backgroundColor: "#97AD3E !important",
    "&:hover": {
      backgroundColor: "#83992A !important",
    },
  },
  getInTouchBtn: {
    color: "white !important",
    borderColor: "white !important",
  },
  ourPartners: {
    marginTop: "93px",
    marginBottom: "93px",
    "& h2": {
      fontWeight: 300,
      fontSize: "50px",
      lineHeight: "70px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "32px",
      marginBottom: "32px",
      "& h2": {
        fontSize: "32px",
        lineHeight: "36px",
        marginBottom: "16px",
      },
      content: {
        paddingBottom: "20px",
        gap: "12px",
        "& img": {
          width: "300px",
        },
      },
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "auto",
    gap: {
      xs: "24px",
      md: "0px",
    },
    "& img": {
      height: "100px",
      border: "1px solid #EAEDF2",
      borderRadius: "6px",
      width: "180px",
      objectFit: "contain",
    },
  },
  aboutUs: {
    background: `url(${AboutUsBanner}) no-repeat right center`,
    backgroundSize: "cover",
    color: "white",
    position: "relative",
    height: "550px",
    [theme.breakpoints.down("md")]: {
      height: "140px",
    },
    "& .layer": {
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(90deg, rgba(4, 52, 70, 0.7) 9.72%, rgba(61, 99, 74, 0.7) 41.61%, rgba(122, 197, 148, 0) 79.62%)",
    },
    "& h3": {
      position: "relative",
      width: "620px",
      marginTop: "167px",
      paddingBottom: "167px",
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "44px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
        paddingBottom: "32px",
        fontSize: "12px",
        lineHeight: "16px",
        width: "200px",
      },
      "& img": {
        position: "absolute",
        left: "-30px",
        top: "-30px",
        [theme.breakpoints.down("md")]: {
          width: "20px",
          left: "-6px",
          top: "-6px",
        },
      },
    },
  },
  whyUs: {
    marginTop: "120px",
    marginBottom: "120px",
    "& h2": {
      fontWeight: 300,
      fontSize: "50px",
      lineHeight: "70px",
      marginBottom: "64px",
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
        lineHeight: "36px",
        marginBottom: "24px",
      },
    },
    "& h3": {
      marginTop: "32px",
      marginBottom: "16px",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      [theme.breakpoints.down("md")]: {
        marginTop: "16px",
      },
    },
    "& p": {
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "44px",
      marginBottom: "44px",
    },
  },
  experience: {
    background: "linear-gradient(180deg, #30BBF2 0%, #B2CC49 206.68%)",
    "& .header": {
      textAlign: "center",
      color: "white",
      paddingTop: "120px",
      paddingBottom: "64px",
      "& h2": {
        fontWeight: 300,
        fontSize: "50px",
        lineHeight: "70px",
      },
      "& p": {
        fontWeight: 300,
        fontSize: "24px",
        lineHeight: "24px",
        marginTop: "24px",
        marginLeft: "204px",
        marginRight: "204px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "0px",
          marginRight: "0px",
          paddingTop: "32px",
          paddingBottom: "24px",
        },
      },
    },
    "& .card-wraper": {
      paddingBottom: "20px",
      "& .body-card": {
        backgroundColor: "white",
        "& img": {
          width: "100%",
          height: "294px",
          objectFit: "cover",
        },
        "& .body": {
          padding: "32px",
          height: "300px",
          "& h3": {
            fontWeight: 300,
            fontSize: "30px",
            lineHeight: "32px",
            marginBottom: "20px",
          },
          "& p": {
            fontWeight: 300,
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "58px",
          },
          "& a": {
            fontWeight: 300,
            fontSize: "24px",
            lineHeight: "24px",
            textDecoration: "none",
            color: "#97AD3E",
            "&:hover": {
              color: "#83992A",
              textDecoration: "underline",
            },
          },
        },
      },
    },
    "& .divider": {
      marginBottom: "-5px",
      width: "100%",
    },
  },
}));
