import { Button, Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ActionButton,
  CustomButton,
  FilterContract,
  ModalCreateContract,
  ModalEditContract,
  TableComponent,
} from "../../../components";
import {
  Axios,
  DEFAULT_PAGINATION_LIMIT,
  ObjectToParams,
} from "../../../config";

export default function TableContract({}) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [dataEdit, setDataEdit] = useState();
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [openFilter, setOpenFilter] = useState(false);
  const [openModalStartDate, setOpenModalStartDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [openModalEndDate, setOpenModalEndDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [hours, setHours] = useState("00");
  const [minute, setMinute] = useState("00");
  const [second, setSecond] = useState("00");
  const [hoursEndDate, setHoursEndDate] = useState("00");
  const [minuteEndDate, setMinuteEndDate] = useState("00");
  const [secondEndDate, setSecondEndDate] = useState("00");
  const [params, setParams] = useState({
    search: "",
    offset: 0,
    limit: DEFAULT_PAGINATION_LIMIT,
    page: 1,
    start:
      startDate === ""
        ? ""
        : `${dayjs(startDate).format(
            "YYYY-MM-DD"
          )}${hours}:${minute}:${second}`,
    end:
      endDate === ""
        ? ""
        : `${dayjs(endDate).format(
            "YYYY-MM-DD"
          )}${hoursEndDate}:${minuteEndDate}:${secondEndDate}`,
  });
  const [openModalCreateContract, setOpenModalCreateContract] = useState(false);
  const [openModalContractDate, setOpenModalContractDate] = useState(false);
  const [contractDate, setContractDate] = useState(new Date());
  const [hoursContractDate, setHoursContractDate] = useState("00");
  const [minuteContractDate, setMinuteContractDate] = useState("00");
  const [secondContractDate, setSecondContractDate] = useState("00");
  const [billingType, setBillingType] = useState("D");
  const [billingDate, setBillingDate] = useState(new Date());
  const [hoursBillingDate, setHoursBillingDate] = useState("00");
  const [minuteBillingDate, setMinuteBillingDate] = useState("00");
  const [secondBillingDate, setSecondBillingDate] = useState("00");
  const [openModalBillingDate, setOpenModalBillingDate] = useState(false);
  const [lastBillingDate, setLastBillingDate] = useState(new Date());
  const [hoursLastBillingDate, setHoursLastBillingDate] = useState("00");
  const [minuteLastBillingDate, setMinuteLastBillingDate] = useState("00");
  const [secondLastBillingDate, setSecondLastBillingDate] = useState("00");
  const [openModalLastBillingDate, setOpenModalLastBillingDate] =
    useState(false);
  const [feeFlag, setFeeFlag] = useState("T");
  const [contractEndDate, setContractEndDate] = useState(new Date());
  const [hoursContractEndDate, setHoursContractEndDate] = useState("00");
  const [minuteContractEndDate, setMinuteContractEndDate] = useState("00");
  const [secondContractEndDate, setSecondContractEndDate] = useState("00");
  const [openModalContractEndDate, setOpenModalContractEndDate] =
    useState(false);
  const [contractStatus, setContractStatus] = useState("inactive");
  const [contractNumber, setContractNumber] = useState();
  const [amountFeeFlag, setAmountFeeFlag] = useState("T");
  const [openModalEditContract, setOpenModalEditContract] = useState(false);

  const [contractId, setContractId] = useState();

  const handleOpenModalEditContract = (contractId) => {
    setOpenModalEditContract(true);
    setContractId(contractId);
  };

  const handleCloseModalEditContract = () => {
    setOpenModalEditContract(false);
  };

  const onChangeAmountFeeFlag = (e) => {
    setAmountFeeFlag(e.target.value);
  };

  const handleChangeContractNumber = (e) => {
    setContractNumber(e.target.value);
  };

  const onChangeContractStatus = (e) => {
    setContractStatus(e.target.value);
  };

  const handleOpenModalContractEndDate = () => {
    setOpenModalContractEndDate(true);
  };

  const handleCloseModalContractEndDate = () => {
    setOpenModalContractEndDate(false);
  };

  const onChangeContractEndDate = (date) => {
    setContractEndDate(date.$d);
  };

  const handleChangeHoursContractEndDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursContractEndDate(e.target.value);
        } else {
          setHoursContractEndDate(hoursContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteContractEndDate = (e) => {
    // minute only accept numbers, max 59, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteContractEndDate(e.target.value);
        } else {
          setMinuteContractEndDate(minuteContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondContractEndDate = (e) => {
    // second only accept numbers, max 59, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondContractEndDate(e.target.value);
        } else {
          setSecondContractEndDate(secondContractEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const onChangeFeeFlag = (e) => {
    setFeeFlag(e.target.value);
  };

  const handleOpenModalLastBillingDate = () => {
    setOpenModalLastBillingDate(true);
  };

  const handleCloseModalLastBillingDate = () => {
    setOpenModalLastBillingDate(false);
  };

  const onChangeLastBillingDate = (date) => {
    setLastBillingDate(date.$d);
  };

  const handleChangeHoursLastBillingDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursLastBillingDate(e.target.value);
        } else {
          setHoursLastBillingDate(hoursLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteLastBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteLastBillingDate(e.target.value);
        } else {
          setMinuteLastBillingDate(minuteLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondLastBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondLastBillingDate(e.target.value);
        } else {
          setSecondLastBillingDate(secondLastBillingDate);
        }
      }
    } else {
      return false;
    }
  };
  const handleOpenModalBillingDate = () => {
    setOpenModalBillingDate(true);
  };

  const handleCloseModalBillingDate = () => {
    setOpenModalBillingDate(false);
  };

  const onChangeBillingDate = (date) => {
    setBillingDate(date.$d);
  };

  const handleChangeHoursBillingDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursBillingDate(e.target.value);
        } else {
          setHoursBillingDate(hoursBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteBillingDate(e.target.value);
        } else {
          setMinuteBillingDate(minuteBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondBillingDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondBillingDate(e.target.value);
        } else {
          setSecondBillingDate(secondBillingDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeBillingType = (e) => {
    setBillingType(e.target.value);
  };

  const handleOpenContractDate = () => {
    setOpenModalContractDate(true);
  };

  const handleCloseContractDate = () => {
    setOpenModalContractDate(false);
  };

  const onChangeContractDate = (date) => {
    setContractDate(date.$d);
  };
  const handleChangeHoursContractDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursContractDate(e.target.value);
        } else {
          setHoursContractDate(hoursContractDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteContractDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteContractDate(e.target.value);
        } else {
          setMinuteContractDate(minuteContractDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondContractDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondContractDate(e.target.value);
        } else {
          setSecondContractDate(secondContractDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleOpenModalCreateContract = () => {
    setOpenModalCreateContract(true);
  };

  const handleCloseModalCreateContract = () => {
    setOpenModalCreateContract(false);
  };

  const handleOpenModalStartDate = () => {
    setOpenModalStartDate(true);
  };

  const handleCloseModalStartDate = () => {
    setOpenModalStartDate(false);
  };
  const handleOpenModalEndDate = () => {
    setOpenModalEndDate(true);
  };

  const handleCloseModalEndDate = () => {
    setOpenModalEndDate(false);
  };

  const onChangeStartDate = (date) => {
    setStartDate(date.$d);
  };
  const onChangeEndDate = (date) => {
    setEndDate(date.$d);
  };

  const handleChangeHours = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHours(e.target.value);
        } else {
          setHours(hours);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinute = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinute(e.target.value);
        } else {
          setMinute(minute);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecond = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecond(e.target.value);
        } else {
          setSecond(second);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeHoursEndDate = (e) => {
    // hours only accept numbers, max 23, min 0, and max length 2
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 23) {
        if (e.target.value.length <= 2) {
          setHoursEndDate(e.target.value);
        } else {
          setHoursEndDate(hoursEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeMinuteEndDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setMinuteEndDate(e.target.value);
        } else {
          setMinuteEndDate(minuteEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const handleChangeSecondEndDate = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.value <= 59) {
        if (e.target.value.length <= 2) {
          setSecondEndDate(e.target.value);
        } else {
          setSecondEndDate(secondEndDate);
        }
      }
    } else {
      return false;
    }
  };

  const getDataFromFirstWord = (string) => {
    if (string === "M") {
      return "Monthly";
    } else if (string === "W") {
      return "Weekly";
    } else if (string === "D") {
      return "Daily";
    } else if (string === "T") {
      return "True";
    } else if (string === "F") {
      return "False";
    }
  };

  const column = [
    {
      name: "Action",
      renderCell: (params) => (
        <ActionButton
          onEdit={(e) => {
            e.stopPropagation();
            handleOpenModalEditContract(params?.id);
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(params.id);
          }}
        />
      ),
    },
    {
      name: "Contract Number",
      renderCell: (params) =>
        loading ? <Skeleton /> : params?.contract_no ?? "-",
    },
    {
      name: "Contract Date",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          <>
            <p>{dayjs(params?.contract_date).format("DD MMMM YYYY")}</p>
            <p>{dayjs(params?.contract_date).format("HH:mm:ss")}</p>
          </>
        ),
    },
    {
      name: "Billing Cycle Type",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          getDataFromFirstWord(params?.billing_cycle_type_flag) ?? "-"
        ),
    },
    {
      name: "Billing Cycle Date",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          (
            <>
              <p>{dayjs(params?.billing_cycle_date).format("DD MMMM YYYY")}</p>
              <p>{dayjs(params?.billing_cycle_date).format("HH:mm:ss")}</p>
            </>
          ) ?? "-"
        ),
    },
    {
      name: "Last Billing Cycle Date",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          (
            <>
              <p>
                {dayjs(params?.last_billing_cycle_date).format("DD MMMM YYYY")}
              </p>
              <p>{dayjs(params?.last_billing_cycle_date).format("HH:mm:ss")}</p>
            </>
          ) ?? "-"
        ),
    },
    {
      name: "Include Fee Flag",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          getDataFromFirstWord(params?.include_fee_flag) ?? "-"
        ),
    },
    {
      name: "Include Amount Flag",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          getDataFromFirstWord(params?.include_amount_flag) ?? "-"
        ),
    },
    {
      name: "Contract End Date",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : (
          (
            <>
              <p>{dayjs(params?.contract_end_date).format("DD MMMM YYYY")}</p>
              <p>{dayjs(params?.contract_end_date).format("HH:mm:ss")}</p>
            </>
          ) ?? "-"
        ),
    },
    {
      name: "Status",
      renderCell: (params) =>
        loading ? (
          <Skeleton />
        ) : params?.is_active_flag === true ? (
          "Active"
        ) : (
          "Inactive" ?? "-"
        ),
    },
  ];

  useEffect(() => {
    getDataTableContract();
  }, [params]);

  const getDataTable = () => {
    setLoading(true);
    Axios.get("/pricing?" + ObjectToParams(params))
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data.data);
          setMeta(res.data.meta);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const getDataTableContract = () => {
    setLoading(true);
    const url = "/contract?" + ObjectToParams(params);
    Axios.get(url)
      .then((res) => {
        setRows(res.data.data);
        setMeta(res.data.meta);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => console.log("error", err));
  };

  const handleDelete = (val) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Will delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete(`/contract/delete/${val}`).then((res) => {
          if (res.status === 200 || res.status === 201) {
            Swal.fire({
              title: "Success",
              text: res.data.message || `Success delete menu`,
              icon: "success",
            });
            getDataTableContract();
          }
        });
      }
    });
  };

  const dateTimeStringToIso = (
    dateString,
    hoursString,
    minuteString,
    secondString
  ) => {
    const date = new Date(dateString);
    date.setHours(hoursString);
    date.setMinutes(minuteString);
    date.setSeconds(secondString);
    return date.toISOString();
  };

  const handleSubmit = () => {
    const payload = {
      contract_no: contractNumber,
      contract_date: dateTimeStringToIso(
        contractDate,
        hoursContractDate,
        minuteContractDate,
        secondContractDate
      ),
      contract_desc: "",
      billing_cycle_type_flag: billingType,
      billing_cycle_date: dateTimeStringToIso(
        billingDate,
        hoursBillingDate,
        minuteBillingDate,
        secondBillingDate
      ),
      last_billing_cycle_date: dateTimeStringToIso(
        lastBillingDate,
        hoursLastBillingDate,
        minuteLastBillingDate,
        secondLastBillingDate
      ),
      include_fee_flag: feeFlag,
      include_amount_flag: amountFeeFlag,
      contract_end_date: dateTimeStringToIso(
        contractEndDate,
        hoursContractEndDate,
        minuteContractEndDate,
        secondContractEndDate
      ),
    };

    Axios.post("/contract/create", payload).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setOpenModalCreateContract(false);
        Swal.fire({
          title: "Success",
          text: res.data.message || `Success create contract`,
          icon: "success",
        });
        getDataTableContract();
      }
    });
  };

  const filterOnReset = () => {
    setStartDate("");
    setEndDate("");
    setParams({
      ...params,
      start_date: "",
      end_date: "",
    });
  };

  const filterOnApply = () => {
    setParams({
      ...params,
      start_date: `${dayjs(startDate).format(
        "YYYY-MM-DD"
      )} ${hours}:${minute}:${second}`,
      end_date: `${dayjs(endDate).format(
        "YYYY-MM-DD"
      )} ${hoursEndDate}:${minuteEndDate}:${secondEndDate}`,
    });
  };

  return (
    <Fragment>
      <TableComponent
        rows={rows}
        column={column}
        onSearchTable={(val) => setParams({ ...params, ...val })}
        onPageChange={(val) => setParams({ ...params, page: val })}
        onRowsChange={({ target }) =>
          setParams({ ...params, limit: target.value, page: 1 })
        }
        limit={params?.limit}
        totalData={meta?.total}
        page={meta?.page}
        endButton={
          <Grid display="flex" gap="16px">
            <Button
              onClick={handleOpenModalCreateContract}
              variant="contained"
              sx={{
                backgroundColor: "#A0B842",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Create Contract
            </Button>
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterContract
                openModalStartDate={openModalStartDate}
                handleOpenModalStartDate={handleOpenModalStartDate}
                handleCloseModalStartDate={handleCloseModalStartDate}
                openModalEndDate={openModalEndDate}
                handleOpenModalEndDate={handleOpenModalEndDate}
                handleCloseModalEndDate={handleCloseModalEndDate}
                startDate={startDate}
                endDate={endDate}
                hours={hours}
                minute={minute}
                second={second}
                hoursEndDate={hoursEndDate}
                minuteEndDate={minuteEndDate}
                secondEndDate={secondEndDate}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                handleChangeHours={handleChangeHours}
                handleChangeMinute={handleChangeMinute}
                handleChangeSecond={handleChangeSecond}
                handleChangeHoursEndDate={handleChangeHoursEndDate}
                handleChangeMinuteEndDate={handleChangeMinuteEndDate}
                handleChangeSecondEndDate={handleChangeSecondEndDate}
                onReset={filterOnReset}
                onApply={filterOnApply}
              />
            </CustomButton.Filter>
          </Grid>
        }
      />
      {openModalCreateContract && (
        <ModalCreateContract
          openModalCreateContract={openModalCreateContract}
          handleCloseModalCreateContract={handleCloseModalCreateContract}
          openModalContractDate={openModalContractDate}
          handleCloseModalContractDate={handleCloseContractDate}
          contractDate={contractDate}
          onChangeContractDate={onChangeContractDate}
          hoursContractDate={hoursContractDate}
          minuteContractDate={minuteContractDate}
          secondContractDate={secondContractDate}
          handleChangeHoursContractDate={handleChangeHoursContractDate}
          handleChangeMinuteContractDate={handleChangeMinuteContractDate}
          handleChangeSecondContractDate={handleChangeSecondContractDate}
          handleOpenModalContractDate={handleOpenContractDate}
          billingType={billingType}
          onChangeBillingType={handleChangeBillingType}
          billingDate={billingDate}
          onChangeBillingDate={onChangeBillingDate}
          hoursBillingDate={hoursBillingDate}
          minuteBillingDate={minuteBillingDate}
          secondBillingDate={secondBillingDate}
          handleChangeHoursBillingDate={handleChangeHoursBillingDate}
          handleChangeMinuteBillingDate={handleChangeMinuteBillingDate}
          handleChangeSecondBillingDate={handleChangeSecondBillingDate}
          handleOpenModalBillingDate={handleOpenModalBillingDate}
          handleCloseModalBillingDate={handleCloseModalBillingDate}
          openModalBillingDate={openModalBillingDate}
          lastBillingDate={lastBillingDate}
          onChangeLastBillingDate={onChangeLastBillingDate}
          hoursLastBillingDate={hoursLastBillingDate}
          minuteLastBillingDate={minuteLastBillingDate}
          secondLastBillingDate={secondLastBillingDate}
          handleChangeHoursLastBillingDate={handleChangeHoursLastBillingDate}
          handleChangeMinuteLastBillingDate={handleChangeMinuteLastBillingDate}
          handleChangeSecondLastBillingDate={handleChangeSecondLastBillingDate}
          handleOpenModalLastBillingDate={handleOpenModalLastBillingDate}
          handleCloseModalLastBillingDate={handleCloseModalLastBillingDate}
          openModalLastBillingDate={openModalLastBillingDate}
          feeFlag={feeFlag}
          onChangeFeeFlag={onChangeFeeFlag}
          contractEndDate={contractEndDate}
          onChangeContractEndDate={onChangeContractEndDate}
          hoursContractEndDate={hoursContractEndDate}
          minuteContractEndDate={minuteContractEndDate}
          secondContractEndDate={secondContractEndDate}
          handleChangeHoursContractEndDate={handleChangeHoursContractEndDate}
          handleChangeMinuteContractEndDate={handleChangeMinuteContractEndDate}
          handleChangeSecondContractEndDate={handleChangeSecondContractEndDate}
          handleOpenModalContractEndDate={handleOpenModalContractEndDate}
          handleCloseModalContractEndDate={handleCloseModalContractEndDate}
          openModalContractEndDate={openModalContractEndDate}
          contractStatus={contractStatus}
          onChangeContractStatus={onChangeContractStatus}
          handleChangeContractNumber={handleChangeContractNumber}
          amountFeeFlag={amountFeeFlag}
          onChangeAmountFeeFlag={onChangeAmountFeeFlag}
          onApply={handleSubmit}
        />
      )}

      {openModalEditContract && (
        <ModalEditContract
          handleCloseModalEditContract={handleCloseModalEditContract}
          openModalEditContract={openModalEditContract}
          getDataTableContract={getDataTableContract}
          setOpenModalEditContract={setOpenModalEditContract}
          contractId={contractId}
        />
      )}
    </Fragment>
  );
}
