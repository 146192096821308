import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FinpointLogo } from "../../assets";
import {
  CardComponent,
  InputComponent,
  SuccessComponent,
} from "../../components";
import { Axios } from "../../config/helper";

export default function ForgotPassword(params) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Success, setSuccess] = useState(false);
  const [Verified, setVerified] = useState(false);
  const [error, setError] = useState([]);
  const [body, setBody] = useState({
    old_password: "",
    new_password: "",
    confirmation_password: "",
  });
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    // check token confirmed from email for accessing this
    // if available do de reset password
    // if not redirect to login
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    var result = Object.entries(body);
    var dataError = [];

    result.map((res) => {
      let param = res[0].replaceAll("_", " ");
      if (res[1] === "")
        dataError.push({
          name: param,
          message: `"${param}" cannot be empty!`,
        });
    });

    if (dataError.length === 0) {
      // let validateErr = ValidationPassword(body.new_password);

      if (body.new_password === body.old_Password) {
        setError([
          {
            message: "The new password cannot be the same as the old password",
          },
        ]);
        return;
      }

      // if (validateErr) {
      //   setError([{ message: validateErr }]);
      //   return;
      // }

      if (body.new_password !== body.confirmation_password) {
        setError([{ name: "", message: "Confirm new password must be same!" }]);
        return;
      }

      setError([]);
      setLoading(true);
      await Axios.post("/change-password", body)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              setSuccess(true);
              setLoading(false);
              setVerified(true);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.errors[0].message)
            setResponseError(error.response.data.errors[0].message);
          setLoading(false);
        });
    } else {
      setError(dataError);
    }
  };

  const ValidationPassword = (password) => {
    if (password.length < 7) return "New password minimum 8 character.";

    if (
      password.search(/^(?=.*\d)(?=.*[!@#$%^&*])/) < 0 &&
      (password.search(/(?=.*[a-z])/) < 0 || password.search(/(?=.*[A-Z])/) < 0)
    )
      return "New Password must be combination of letters, numbers and symbols .";
  };

  const handleErrorInput = (val) => {
    let errorVar = error.filter((res) => res.name === val);
    return errorVar.length > 0 ? true : false;
  };

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
    setError(error.filter((val) => val.name !== e.target.id));
  };

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.header} onClick={() => navigate("/dashboard")}>
        <img src={FinpointLogo} alt="logo" className={classes.logo} />
      </Grid>
      <Grid className={classes.container}>
        <CardComponent className={classes.card} fullWidth>
          <Grid>
            {Success ? (
              <SuccessComponent verified={Verified} />
            ) : (
              <>
                <h5 className={classes.title}>Change Password</h5>
                {(error.length > 0 || responseError) && (
                  <Grid className={classes.errorContainer}>
                    {error.length > 0 && (
                      <Alert severity="error">
                        <ul>
                          {error.map((res) => {
                            return <li>{res.message}</li>;
                          })}
                        </ul>
                      </Alert>
                    )}
                    {responseError && (
                      <Alert severity="error">
                        <AlertTitle>{responseError}</AlertTitle>
                      </Alert>
                    )}
                  </Grid>
                )}
                <Grid>
                  <form
                    onSubmit={handleChangePassword}
                    className={classes.form}
                  >
                    <Grid className={classes.formField}>
                      <p className={classes.label}>Old Password</p>
                      <InputComponent
                        error={handleErrorInput("old_password")}
                        onChange={handleChange}
                        id="old_password"
                        placeholder=""
                        height="48px"
                        type="password"
                        disabled={loading}
                      />
                    </Grid>
                    <Box component="ul" className={classes.passwordRules}>
                      <li>Minimum 8 characters</li>
                      <li>Password cannot contain username</li>
                      <li>
                        A combination of letters, numbers and symbols _, ., !
                      </li>
                    </Box>
                    <Grid className={classes.formField}>
                      <p className={classes.label}>New Password</p>
                      <InputComponent
                        error={handleErrorInput("new_password")}
                        onChange={handleChange}
                        id="new_password"
                        placeholder=""
                        height="48px"
                        type="password"
                        disabled={loading}
                      />
                    </Grid>
                    <Grid className={classes.formField}>
                      <p className={classes.label}>Confirm New Password</p>
                      <InputComponent
                        error={handleErrorInput("confirmation_password")}
                        onChange={handleChange}
                        id="confirmation_password"
                        placeholder=""
                        height="48px"
                        type="password"
                        disabled={loading}
                      />
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      className={classes.button}
                    >
                      {loading ? (
                        <CircularProgress
                          size={30}
                          className={classes.loading}
                        />
                      ) : (
                        "Change Password"
                      )}
                    </Button>
                  </form>
                </Grid>
              </>
            )}
          </Grid>
        </CardComponent>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F7F9FC",
    overflow: "hidden",
  },
  header: {
    width: "100%",
    backgroundColor: "#fff",
    padding: "20px",
    cursor: "pointer",
  },
  logo: {
    cursor: "pointer",
  },
  container: {
    width: "100%",
    maxWidth: "350px",
    paddingTop: "50px",
    paddingBottom: "50px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: "100%",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.05) !important",
  },
  title: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    color: "#092540 !important",
  },
  errorContainer: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    "& ul, li": {
      margin: 0,
      textAlign: "left",
    },
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: "#1A3650",
    fontSize: 14,
    fontWeight: 600,
  },
  passwordRules: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    border: "1px solid #D1D5DC",
    color: "#9AA2B1",
    borderRadius: "8px",
  },
  button: {
    height: "48px !important",
    backgroundColor: "#A0B842 !important",
    "&:hover": {
      backgroundColor: "#83992A",
    },
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    position: "relative !important",
  },
  loading: {
    color: "inherit",
  },
}));
