import { Grid } from "@mui/material";
import React from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import CustomButton from "../Global/CustomButton";
const useStyles = makeStyles({
  hiddenActions: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
});

export default function FilterContract({
  onApply,
  onReset,
  openModalStartDate,
  handleOpenModalStartDate,
  handleCloseModalStartDate,
  hours,
  minute,
  second,
  handleChangeHours,
  handleChangeMinute,
  handleChangeSecond,
  onChangeStartDate,
  startDate,
  endDate,
  handleOpenModalEndDate,
  handleCloseModalEndDate,
  openModalEndDate,
  onChangeEndDate,
  hoursEndDate,
  handleChangeHoursEndDate,
  minuteEndDate,
  handleChangeMinuteEndDate,
  secondEndDate,
  handleChangeSecondEndDate,
}) {
  const classes = useStyles();

  return (
    <Grid
      width="300px"
      borderRadius="15px"
      sx={{
        backgroundColor: "white",
        padding: "24px",
        "& h4": {
          margin: 0,
        },
        position: "absolute",
        top: "20px",
        right: "24px",
        zIndex: 1,
      }}
    >
      <h4>Filter</h4>
      <Grid
        width="100%"
        display="flex"
        flexDirection="column"
        gap="12px"
        mt="24px"
      >
        <Grid width="100%" display="flex" flexDirection="column" gap="12px">
          <TextField
            id="input-with-icon-textfield"
            value={
              startDate === ""
                ? "Contract Date"
                : `${dayjs(startDate).format(
                    "DD MMMM YYYY"
                  )} ${hours}:${minute}:${second}`
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            disabled
            placeholder="Contract Date"
            onClick={handleOpenModalStartDate}
          />
          <TextField
            id="input-with-icon-textfield"
            value={
              endDate === ""
                ? "Contract End Date"
                : `${dayjs(endDate).format(
                    "DD MMMM YYYY"
                  )} ${hoursEndDate}:${minuteEndDate}:${secondEndDate}`
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            disabled
            onClick={handleOpenModalEndDate}
          />
        </Grid>
      </Grid>
      <Grid container mt="24px" justifyContent="flex-end" gap="16px">
        <CustomButton.Outline onClick={onReset}>Reset</CustomButton.Outline>

        <CustomButton onClick={onApply}>Apply</CustomButton>
      </Grid>
      <Dialog open={openModalStartDate} onClose={handleCloseModalStartDate}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={startDate}
              onChange={onChangeStartDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hours}
              onChange={handleChangeHours}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minute}
              onChange={handleChangeMinute}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={second}
              onChange={handleChangeSecond}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openModalEndDate} onClose={handleCloseModalEndDate}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={endDate}
              onChange={onChangeEndDate}
              //hidden action button
              className={classes.hiddenActions}
            />
          </LocalizationProvider>
          <Grid
            width="100%"
            display="flex"
            gap="12px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <p>TIME</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={hoursEndDate}
              onChange={handleChangeHoursEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={minuteEndDate}
              onChange={handleChangeMinuteEndDate}
            />
            <p>:</p>
            <input
              style={{ width: "52px", height: "32px" }}
              value={secondEndDate}
              onChange={handleChangeSecondEndDate}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
