import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { logoutCookie, setToken } from "../../config/cookies";
import { Axios } from "../../config/helper";

export default function ProfileMenu({ dataUser, classes }) {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const anchorRef = React.useRef();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) return;
    setOpen(false);
  };

  function handleListKeyDown(e) {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpen(false);
    } else if (e.key === "Escape") {
      setOpen(false);
    }
  }

  const handleLogout = () => {
    logoutCookie();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const handleChangeRole = (res) => {
    if (res.id === dataUser.currnet_role.id) {
      setOpen(false);
    } else {
      setOpen(false);
      Axios.post(`/profil/role/${res.id}`, null).then((val) => {
        setToken(val.data.data.token);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      });
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let acronym = dataUser?.name
    ?.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");

  return (
    <Stack direction="row" spacing={2}>
      <div className={classes}>
        <Box
          onClick={handleToggle}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
            paddingHorizontal: "5px",
          }}
        >
          <Avatar sx={{ width: 48, height: 48 }}>{acronym}</Avatar>
          <div className="name">
            <h3>{dataUser?.name}</h3>
            <p>
              {dataUser?.currnet_role?.name}{" "}
              {[3, 4].includes(dataUser?.currnet_role?.id) &&
                `(${dataUser?.partner?.partner_name})`}
            </p>
          </div>
        </Box>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
              sx={{ marginTop: "20px" }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={() => (window.location.href = "/new-password")}
                      sx={{ fontSize: "12px", gap: "10px" }}
                    >
                      <LockPersonIcon fontSize="small" color="disabled" />
                      Change Password
                    </MenuItem>

                    <Accordion
                      expanded={expanded === "role"}
                      onChange={handleChange("role")}
                      sx={{ boxShadow: "none", justifyContent: "flex-start" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="rolebh-content"
                        id="rolebh-header"
                      >
                        <AccountTreeIcon
                          fontSize="small"
                          sx={{ marginRight: "10px" }}
                          color="disabled"
                        />
                        <Typography
                          sx={{ color: "#222 !important", fontSize: "13px" }}
                        >
                          Change Role
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ marginTop: "-25px", marginLeft: "10px" }}
                      >
                        {dataUser.user_role &&
                          dataUser.user_role.map((res, i) => {
                            return (
                              <MenuItem
                                onClick={() => handleChangeRole(res)}
                                key={i}
                                sx={{
                                  color:
                                    dataUser.currnet_role.id === res.id
                                      ? "#9AB825"
                                      : "text.secondary",
                                  fontSize: "12px",
                                }}
                              >
                                {res.name}
                              </MenuItem>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>

                    <MenuItem
                      onClick={handleLogout}
                      sx={{ color: "#DE5242", fontSize: "13px", gap: "10px" }}
                    >
                      <LogoutIcon fontSize="small" />
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
