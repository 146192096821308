import { Button, ButtonGroup, Grid, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets";
import {
  BasicCard,
  CustomButton,
  CustomInput,
  DataHeading,
  FilterDashboardFSA,
  TableComponent,
} from "../../components";
import { currencyFormat, getUser } from "../../config";
import { useDashboardFSA } from "../../hooks";

export default function DashboardFSA() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [active, setActive] = useState("Reconciliation Queue");

  const {
    dataProduct,
    dataDashboard,
    rows,
    loading,
    params,
    meta,
    setParams,
    setLoading,
  } = useDashboardFSA(active, {
    date: dayjs(new Date()).format("MM-YYYY"),
  });

  const [openFilter, setOpenFilter] = useState(false);

  const allowDetail = () => active === "Error" || active === "Missmatch";

  const columns = {
    "Reconciliation Queue": [
      {
        name: "ID",
        renderCell: ({ rns_process_schedule_id }) =>
          rns_process_schedule_id || "-",
      },
      {
        name: "Date",
        renderCell: ({ rns_process_start_at }) =>
          rns_process_start_at
            ? dayjs(new Date(rns_process_start_at)).format("lll")
            : "-",
      },
      {
        name: "Merchant / Bank",
        renderCell: ({ bank_partner }) => bank_partner,
      },
      {
        name: "Principal",
        renderCell: ({ principal_partner }) => principal_partner,
      },
    ],
    "Ongoing Reconciliation": [
      {
        name: "ID",
        renderCell: ({ rns_process_history_id }) =>
          rns_process_history_id || "-",
      },
      {
        name: "Date",
        renderCell: ({ date }) =>
          date ? dayjs(new Date(date)).format("lll") : "-",
      },
      {
        name: "Merchant / Bank",
        renderCell: ({ merchant_name }) => merchant_name,
      },
      { name: "Principal", renderCell: ({ principal_name }) => principal_name },
      {
        name: "Transaction Amount",
        renderCell: ({ transaction_amount }) =>
          currencyFormat(transaction_amount),
      },
      {
        name: "Fee Amount",
        renderCell: ({ fee_amount }) => currencyFormat(fee_amount),
      },
      {
        name: "Settlement Amount",
        renderCell: ({ settelment_amount }) =>
          currencyFormat(settelment_amount),
      },
      {
        name: "Difference Amount",
        renderCell: ({ settelment_amount, fee_amount }) =>
          currencyFormat(settelment_amount - fee_amount),
      },
      {
        name: "Difference Count",
        renderCell: ({ difference_count }) => difference_count,
      },
      { name: "Error Count", renderCell: () => 0 },
    ],
    Error: [
      {
        name: "ID",
        renderCell: ({ rns_process_schedule_id }) =>
          rns_process_schedule_id || "-",
      },
      {
        name: "Date",
        renderCell: ({ rns_process_start_at }) =>
          dayjs(new Date(rns_process_start_at)).format("lll"),
      },
      {
        name: "Merchant / Bank",
        renderCell: ({ bank_partner }) => bank_partner,
      },
      {
        name: "Principal",
        renderCell: ({ principal_partner }) => principal_partner,
      },
      {
        name: "Count",
        renderCell: ({ count, id }) => (
          <Grid
            onClick={() => navigate(`/dashboard/${id}/error`)}
            color="red"
            sx={{ cursor: "pointer" }}
          >
            {count ?? 1}
          </Grid>
        ),
      },
    ],
    Missmatch: [
      {
        name: "ID",
        renderCell: ({ rns_process_history_id }) => rns_process_history_id,
      },
      {
        name: "Date",
        renderCell: ({ date }) => dayjs(new Date(date)).format("lll"),
      },
      {
        name: "Merchant / Bank",
        renderCell: ({ merchant_name, remote_transaction_amount }) => (
          <Grid>
            {currencyFormat(remote_transaction_amount)}
            <Grid sx={{ color: "#687083" }}>{merchant_name}</Grid>
          </Grid>
        ),
      },
      {
        name: "Principal",
        renderCell: ({ principal_name, original_transaction_amount }) => (
          <Grid>
            {currencyFormat(original_transaction_amount)}
            <Grid sx={{ color: "#687083" }}>{principal_name}</Grid>
          </Grid>
        ),
      },
      {
        name: "Difference",
        renderCell: ({ difference_amount }) => (
          <Grid color="red">{currencyFormat(difference_amount)}</Grid>
        ),
      },
      {
        name: "Count",
        renderCell: ({ count, id }) => (
          <Grid
            onClick={() => navigate(`/dashboard/${id}/missmatch`)}
            sx={{ cursor: "pointer" }}
          >
            {count ?? 1}
          </Grid>
        ),
      },
    ],
  }[active];

  return (
    <Grid sx={(theme) => ({ ...theme.customContainer })}>
      <DataHeading title={`Welcome ${getUser().name}!`} />
      {/* Filter */}
      <Grid container className={classes.filterContainer} gap="20px">
        <Grid className={classes.filterItem}>
          Product
          <CustomInput.Select
            onChange={(e) => setParams({ ...params, product: e.target.value })}
            value={params.product ?? null}
            width="192px"
            placeholder="Select Product"
          >
            {dataProduct.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.product_desc}
              </MenuItem>
            ))}
          </CustomInput.Select>
        </Grid>
        <Grid className={classes.filterItem} gap="3px">
          Period
          <CustomInput.MonthPicker
            onChange={(val) => setParams({ ...params, date: val })}
          />
        </Grid>
      </Grid>

      {/* Item List */}
      <Grid className={classes.itemList} container spacing="24px">
        {[
          "Reconciliation Queue",
          "Ongoing Reconciliation",
          "Total Error",
          "Total Mismatch",
        ].map((item) => {
          const selected =
            item === "Total Error"
              ? "Error"
              : item === "Total Mismatch"
              ? "Missmatch"
              : item;
          return (
            <Grid key={item} item>
              <BasicCard
                onClick={() => {
                  setActive(selected);
                  const container = document.getElementById(
                    "container-table-dashboard-fba"
                  );
                  if (container) container.scrollIntoView();
                }}
                title={item}
                value={`${
                  dataDashboard?.[item.toLowerCase().split(" ").join("_")] ||
                  "0"
                }`}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Table */}
      <Grid mt={"24px"}>
        <Grid className={classes.buttonContainer}>
          <ButtonGroup className={classes.buttonGroup} variant="string">
            {[
              "Reconciliation Queue",
              "Ongoing Reconciliation",
              "Error",
              "Missmatch",
            ].map((val) => (
              <Button
                key={val}
                onClick={() => {
                  setActive(val);
                  setParams({ ...params, page: 1, institution: null });
                }}
                className={active === val ? "active" : ""}
              >
                {val}
              </Button>
            ))}
          </ButtonGroup>
          <Grid display="flex" gap="12px">
            <CustomButton.Filter
              open={openFilter}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterDashboardFSA
                onApply={(value) => {
                  setParams({ ...params, institution: value });
                  setOpenFilter(false);
                }}
                onReset={() => {
                  setParams({ ...params, institution: null });
                  setOpenFilter(false);
                }}
                filters={{ institution: params.institution }}
              />
            </CustomButton.Filter>
            <CustomButton.Outline variant="dark">
              Download CSV
            </CustomButton.Outline>
          </Grid>
        </Grid>
        <TableComponent
          loading={loading}
          rows={rows || []}
          column={columns}
          onPageChange={(val) => setParams({ ...params, page: val })}
          onRowsChange={({ target }) =>
            setParams({ ...params, limit: target.value, page: 1 })
          }
          limit={params.limit}
          totalData={meta.total}
          page={meta.page}
          onRowsClick={(data) => {
            if (allowDetail())
              navigate(`/dashboard/${data.id}/${active.toLowerCase()}`);
          }}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  filterContainer: {
    display: "flex",
    gap: "20px",
  },
  filterItem: {
    display: "flex !important",
    flexDirection: "column !important",
  },
  itemList: { marginBottom: "24px", marginTop: "8px !important" },
  buttonContainer: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonGroup: {
    border: "1px solid #E4E7EB",
    borderRadius: "8px !important",
    overflow: "hidden",
    "& button": {
      fontWeight: 600,
      color: "#687083",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(128, 128, 128, 0.07)!important",
      },
    },
    "& .active": {
      backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      color: Colors.SECONDARY_DARK + "!important",
      "&:hover": {
        color: Colors.SECONDARY__DARK + "!important",
        backgroundColor: Colors.SECONDARY_LIGHT + "!important",
      },
    },
  },
});
